import { useQuery } from '@tanstack/react-query';
import { ReactNode } from 'react';
import { customTwMerge } from 'tailwind-merge.config';
import { Description, Modal, Spin, Tag } from 'tera-dls';
import ConfigPermissionApi from '../../_api';
import { TYPE_ROLE } from '../../constants';
import HoverQuickView from '_common/component/HoverQuickView';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '_common/constants/common';
interface IProps {
  open: boolean;
  onCloseModal: () => void;
  id: string | number;
}
interface GridDescriptionProps {
  label?: string;
  children?: ReactNode;
  childrenClassName?: string;
}
const GridDescription = ({
  label,
  children,
  childrenClassName,
}: GridDescriptionProps) => {
  return (
    <Description
      label={label}
      className="grid-cols-4 gap-5"
      childrenClassName={customTwMerge(
        'col-span-3 font-normal text-gray-950',
        childrenClassName,
      )}
    >
      {children}
    </Description>
  );
};

const DetailConfigPermission = ({ id, onCloseModal, open }: IProps) => {
  const { data, isLoading } = useQuery(
    ['get-detail-config-permission', id],
    () => ConfigPermissionApi.getDetail(id),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  return (
    <Modal
      centered
      title="Chi tiết quyền"
      okText="Đóng"
      className="w-[500]"
      open={open}
      onOk={onCloseModal}
      onCancel={onCloseModal}
      cancelButtonProps={{ className: 'hidden' }}
      closeIcon={false}
      destroyOnClose
    >
      <Spin spinning={isLoading && !!id}>
        <div className="flex flex-col gap-1">
          <GridDescription label="Role ID">{data?.id}</GridDescription>
          <GridDescription label="Mã quyền">{data?.code}</GridDescription>
          <GridDescription label="Tiêu đề">{data?.title}</GridDescription>
          <GridDescription label="Loại quyền">
            {TYPE_ROLE[data?.type]}
          </GridDescription>
          <GridDescription label="Mặc định">
            {Boolean(data?.is_default) ? (
              <Tag color="green05">Có</Tag>
            ) : (
              <Tag color="gray01">Không</Tag>
            )}
          </GridDescription>
          <GridDescription label="Business">
            {data?.business_id}
          </GridDescription>
          <GridDescription label="Ghi chú">{data?.note}</GridDescription>
          <GridDescription label="Người tạo">
            {data?.user_created && (
              <HoverQuickView
                avatarUrl={data?.user_created?.avatar_url}
                email={data?.user_created?.email}
                phone={data?.user_created?.phone}
                name={data?.user_created?.full_name}
                sub={data?.user_created?.customer_type_text?.title}
                code={data?.user_created?.code}
              >
                <span className="line-clamp-2">
                  {data?.user_created?.full_name}
                </span>
              </HoverQuickView>
            )}
          </GridDescription>
          <GridDescription label="Ngày tạo">
            {data?.created_at &&
              moment(data.created_at).format(DATE_TIME_FORMAT)}
          </GridDescription>
          <GridDescription label="Người cập nhật">
            {data?.user_updated && (
              <HoverQuickView
                avatarUrl={data.user_updated?.avatar_url}
                email={data.user_updated?.email}
                phone={data.user_updated?.phone}
                name={data.user_updated?.full_name}
                sub={data.user_updated?.customer_type_text?.title}
                code={data.user_updated?.code}
              >
                <span className="line-clamp-2">
                  {data.user_updated?.full_name}
                </span>
              </HoverQuickView>
            )}
          </GridDescription>
          <GridDescription label="Ngày cập nhật">
            {data?.user_updated &&
              moment(data.update_at).format(DATE_TIME_FORMAT)}
          </GridDescription>
        </div>
      </Spin>
    </Modal>
  );
};

export default DetailConfigPermission;
