import DefaultImage from '_common/component/DefaultImage';
import HoverQuickView from '_common/component/HoverQuickView';
import TableTera from '_common/dof/TableTera';

const index = ({ data, ...restProps }) => {
  const columns: any = [
    {
      title: 'Họ và tên',
      dataIndex: 'full_name',
      width: 200,
      render: (full_name, record) => (
        <HoverQuickView
          avatarUrl={record?.avatar_url}
          email={record?.email}
          name={full_name}
          phone={record?.phone}
          className="line-clamp-2"
        >
          <div className="flex gap-3 items-center">
            <DefaultImage
              src={record?.avatar_url}
              alt={record?.avatar_url}
              className="w-[40px] h-[40px] rounded-full border"
            />
            <span>{full_name}</span>
          </div>
        </HoverQuickView>
      ),
    },
    {
      title: 'Tên đăng nhập',
      dataIndex: 'username',
      render: (text) => <p className="line-clamp-2">{text}</p>,
    },
    {
      title: 'Chức danh',
      dataIndex: 'job_title_text',
      render: (job_title) => <p className="line-clamp-2">{job_title?.title}</p>,
    },
    {
      title: 'Phòng ban/ Bộ phận',
      dataIndex: 'department_text',
      render: (department) => (
        <p className="line-clamp-2">{department?.title}</p>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (text) => <p className="line-clamp-2">{text}</p>,
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
    },
  ];

  return (
    <TableTera {...restProps} data={data} rowKey={'id'} columns={columns} />
  );
};

export default index;
