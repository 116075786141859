import Filter from '_common/component/Filter';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import RangePicker from '_common/dof/Control/RangePicker';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface IProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue: any;
}

function ServicePackageFilter({
  open,
  onClose,
  onFilter,
  initialValue,
}: IProps) {
  const form = useForm();

  useEffect(() => {
    if (initialValue) {
      form.reset({
        ...initialValue,
        ...(initialValue?.fromDate &&
          initialValue?.toDate && {
            date: [
              moment(initialValue?.fromDate),
              moment(initialValue?.toDate),
            ],
          }),
      });
    }
  }, [initialValue]);

  const handleSubmitForm = (values) => {
    onFilter({
      fromDate: values?.date?.[0]?.format(DATE_BACKEND_FORMAT),
      toDate: values?.date?.[1]?.format(DATE_BACKEND_FORMAT),
    });
    onClose();
  };

  const handleReset = () => {
    const values = {
      date: null,
      fromDate: null,
      toDate: null,
    };
    onFilter(values);
    onClose();
  };

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={handleSubmitForm} isLoading={false}>
        <FormTeraItem label="Ngày đăng ký" name="date">
          <RangePicker placeholder={['Từ ngày', 'Đến ngày']} />
        </FormTeraItem>

        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default ServicePackageFilter;
