import { Button, Modal } from 'tera-dls';
import ExpenseVoucherContent from './ExpenseVoucherContent';

interface IProps {
  open: boolean;
  onClose?: () => void;
  value: number | string;
}
const ExpenseVoucherDetail = (props: IProps) => {
  const { open, onClose, value } = props;
  return (
    <Modal
      title={'Chi tiết phiếu chi'}
      destroyOnClose
      closeIcon={false}
      width={800}
      cancelText="Hủy"
      okText="Đồng ý"
      onCancel={onClose}
      open={open}
      centered={true}
      footer={<Button onClick={onClose}>Đóng</Button>}
    >
      <ExpenseVoucherContent id={value} />
    </Modal>
  );
};

export default ExpenseVoucherDetail;
