import { useQuery } from '@tanstack/react-query';
import { messageError } from '_common/constants/message';
import { useEffect } from 'react';
import { Description, Modal, Spin, formatDate, notification } from 'tera-dls';
import PermissionGroupApi from '../../_api';

function DetailPermission({ id, open, onCancel }) {
  const {
    data: dataDetail,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ['get-permission-group-detail', id],
    () => PermissionGroupApi.getDetail({ id }),
    {
      enabled: !!id,
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const renderDetail = () => {
    const details = [
      {
        title: 'Mã quyền',
        value: dataDetail?.code,
      },
      {
        title: 'Tiêu đề',
        value: dataDetail?.title,
      },
      {
        title: 'Ghi chú',
        value: dataDetail?.note,
      },
      {
        title: 'Người tạo',
        value: dataDetail?.mobile,
      },
      {
        title: 'Ngày tạo',
        value:
          dataDetail?.created_at &&
          formatDate(dataDetail?.created_at, 'DD/MM/YYYY - HH:mm'),
      },
      {
        title: 'Người cập nhật',
        value: dataDetail?.website,
      },
      {
        title: 'Ngày cập nhật',
        value:
          dataDetail?.updated_at &&
          formatDate(dataDetail?.updated_at, 'DD/MM/YYYY - HH:mm'),
      },
    ];
    return details;
  };

  useEffect(() => {
    if (id) refetch();
  }, [id]);

  if (isError) {
    onCancel();
    notification.error({
      message: messageError.DATA_NOT_FOUND,
    });
  }

  return (
    <Modal
      title="Chi tiết nhóm quyền"
      open={open}
      width={500}
      okText="Đóng"
      cancelButtonProps={{
        className: 'hidden',
      }}
      onCancel={onCancel}
      onOk={onCancel}
      destroyOnClose
    >
      <Spin spinning={isLoading}>
        {renderDetail().map((item, index) => (
          <Description label={item.title} key={index}>
            {item.value}
          </Description>
        ))}
      </Spin>
    </Modal>
  );
}

export default DetailPermission;
