import { useMutation, useQuery } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import useConfirm from '_common/hooks/useConfirm';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  DropdownItem,
  getQueryParams,
  notification,
  PaginationProps,
  updateQueryParams,
} from 'tera-dls';
import ConfigPaymentMethodApi from '../../_api';
import ModalConfirmPayment from '../../components/ModalConfirmPayment';
import TableTransactionHistory from './containers/Table';
import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import Searching from './containers/Searching';
import TransactionFilter from './containers/Filter';

const TransactionHistory = () => {
  const [id, setId] = useState(null);
  const [openDetail, setOpenDetail] = useState(false);
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const confirm = useConfirm();
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);

  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery(['get-transaction-all', queryParams], () =>
    ConfigPaymentMethodApi.getTransactionAll({
      page: 1,
      limit: 10,
      ...queryParams,
    }),
  );

  const { data: dataDetail, isLoading: isLoadingDetail } = useQuery(
    ['get-transaction-find', id],
    () => ConfigPaymentMethodApi.getTransactionFind(id),
    {
      cacheTime: 300000,
      staleTime: 300000,
      enabled: !!id,
    },
  );

  const { mutate: updateStatus } = useMutation(
    (variable: any) => {
      return ConfigPaymentMethodApi.getTransactionUpdate(id, variable);
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          refetch();
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) => ErrorToast({ errorProp: error?.data }),
    },
  );

  const dataTransaction = response?.data || [];

  const handleQueryParams = (query: any) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...query,
    });
    navigate(paramString);
  };

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize =
      queryParams?.limit && Number(pageSize) !== Number(queryParams?.limit);
    handleQueryParams({
      page: isDiffPageSize ? 1 : page,
      limit: pageSize,
    });
  };

  const confirmUpdateStatus = (status: number) => {
    const statusText = {
      1: 'Hoàn thành',
      2: 'Thất bại',
      4: 'Báo lỗi',
    };
    confirm.warning({
      title: 'THAY ĐỔI TRẠNG THÁI',
      content: (
        <>
          <p>Bạn có chắc muốn thay đổi trạng thái sang</p>
          <p>
            <b>{statusText[status]}</b> không?
          </p>
        </>
      ),
      onOk: () => {
        updateStatus({
          status: status,
        });
      },
    });
  };

  const renderDropdown = (record): DropdownItem[] => {
    // const isFinished = record?.status === 1;
    // const isFailed = record?.status === 2;
    const isProcessing = record?.status === 3;
    const isError = record?.status === 4;
    const result = [];

    result.push({
      key: 1,
      label: 'Xem',
      onClick: () => {
        setId(record?.id);
        setOpenDetail(true);
      },
    });
    (isError || isProcessing) &&
      result.push({
        key: 2,
        label: 'Hoàn thành',
        onClick: () => {
          setId(record?.id);
          confirmUpdateStatus(1);
        },
      });

    (isError || isProcessing) &&
      result.push({
        key: 3,
        label: 'Thất bại',
        onClick: () => {
          setId(record?.id);
          confirmUpdateStatus(2);
        },
      });

    isProcessing &&
      result.push({
        key: 4,
        label: 'Báo lỗi',
        onClick: () => {
          setId(record?.id);
          confirmUpdateStatus(4);
        },
      });
    return result;
  };

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const handleSearch = ({ keyword }) => {
    handleUpdateQueryParams({ keyword, page: 1 });
  };

  const handleFilter = (values: any): void => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  return (
    <div className="tera-page">
      <HeaderViewListV2
        onClickFilter={() => setOpenFilterModal(true)}
        title={'LỊCH SỬ GIAO DỊCH'}
        actionLeftRender={<Searching onSearch={handleSearch} />}
      >
        <TableTransactionHistory
          renderDropdown={renderDropdown}
          hiddenColumns={['bill']}
          loading={isLoading}
          data={dataTransaction}
          pagination={{
            onChange: handleChangePage,
            total: response?.total || 0,
            current: response?.current_page || 1,
            pageSize: response?.per_page,
            to: response?.to,
            from: response?.from,
          }}
        />
      </HeaderViewListV2>
      {openDetail && (
        <ModalConfirmPayment
          data={dataDetail}
          isLoading={isLoadingDetail}
          open={openDetail}
          onClose={() => setOpenDetail(false)}
        />
      )}
      {openFilterModal && (
        <TransactionFilter
          open={openFilterModal}
          onClose={() => setOpenFilterModal(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
    </div>
  );
};

export default TransactionHistory;
