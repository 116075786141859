import { useMutation, useQueries, useQueryClient } from '@tanstack/react-query';
import { REGEX } from '_common/constants/common';
import {
  messageError,
  messageValidate,
  messageValidateLength,
  messageWarning,
} from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import Select from '_common/dof/Control/Select';
import Toggle from '_common/dof/Control/Switch';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import { convertListOrder } from '_common/utils';
import { memo, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, OptionProps, Spin, notification } from 'tera-dls';
import ControlConfigApi from '../../_api';
import { IForm, RequestControl } from '../../interfaces';

interface FormControlConfigProps {
  open: boolean;
  controlId?: number;
  pageId?: number;
  onClose: () => void;
}
const FormControlConfig = memo(
  ({ open, controlId, pageId, onClose }: FormControlConfigProps) => {
    const form = useForm<IForm>({
      defaultValues: {
        code: '',
        class_name: '',
        color: '',
        group_id: null,
        order: null,
        status: true,
        title: '',
        value: '',
      },
    });

    const {
      handleSubmit,
      reset,
      watch,
      formState: { isDirty },
    } = form;
    const confirm = useConfirm();
    const group_id = watch('group_id');
    const queryClient = useQueryClient();
    const [
      { data: dataDetail, isLoading: loadingDetail },
      { data: listGroup },
      { data: listOrderControl },
    ] = useQueries({
      queries: [
        {
          queryKey: ['get-detail-control-config', controlId],
          queryFn: () => ControlConfigApi.getDetail(controlId),
          enabled: !!controlId,
        },
        {
          queryKey: ['get-list-group-control'],
          queryFn: () => ControlConfigApi.getListGroupControl(),
          cacheTime: 300000,
          staleTime: 300000,
        },
        {
          queryKey: ['get-list-order-control', group_id, pageId],
          queryFn: () =>
            ControlConfigApi.getListOrderControl({
              group_id: group_id,
              page_id: pageId,
            }),
          enabled: !!pageId && !!group_id,
        },
      ],
    });

    const handleClose = () => {
      onClose();
      reset();
    };

    const optionsOrderControl: OptionProps[] = useMemo(() => {
      return convertListOrder(listOrderControl, pageId, dataDetail);
    }, [listOrderControl, dataDetail]);

    const onCloseModal = () => {
      if (isDirty) {
        confirm.warning({
          title: 'Thoát bản ghi',
          content: (
            <>
              <p>{messageWarning.WARNING_EXIT_1}</p>
              <p>{messageWarning.WARNING_EXIT_2}</p>
            </>
          ),
          onOk: () => {
            handleClose();
          },
        });
      } else handleClose();
    };

    const { mutate: mutateConfigControl } = useMutation(
      (variables: RequestControl) => {
        if (controlId)
          return ControlConfigApi.update({ id: controlId, values: variables });
        return ControlConfigApi.create(variables);
      },
      {
        onSuccess: (res) => {
          if (res?.code === 200) {
            queryClient.invalidateQueries(['get-list-control-config']);
            handleClose();
            notification.success({
              message: res?.msg,
            });
          }
        },
        onError: (error: any) => {
          const errorMessage = error?.data?.msg || messageError.ERROR_API;
          notification.error({ message: errorMessage });
        },
      },
    );

    const handleSubmitForm = (values: IForm) => {
      mutateConfigControl({
        class_name: values.class_name,
        code: values.code,
        color: values.color,
        group_id: values.group_id,
        order: values.order,
        title: values.title,
        value: values.value,
        page_id: pageId,
        status: values.status ? 'active' : 'inactive',
      });
    };

    useEffect(() => {
      if (dataDetail) {
        reset({
          ...dataDetail,
          order: dataDetail?.item_prev_order?.order || 0,
          code: dataDetail.code_guard,
          status: dataDetail.status === 'active' ? true : false,
        });
      }
    }, [dataDetail]);

    const optionsGroup = listGroup?.map((item) => ({
      value: item.id,
      label: item.title,
    }));

    return (
      <Modal
        title={controlId ? 'Sửa control' : 'Thêm control'}
        okText="Lưu"
        cancelText="Hủy"
        open={open}
        onCancel={onCloseModal}
        onOk={() => handleSubmit(handleSubmitForm)()}
        closeIcon={false}
      >
        <Spin spinning={loadingDetail && !!controlId}>
          <FormTera form={form}>
            <FormTeraItem
              label="Mã control"
              name="code"
              rules={[
                {
                  required: messageValidate.emptySelect,
                  maxLength: {
                    message: messageValidateLength.key,
                    value: 191,
                  },
                  pattern: {
                    message: messageValidate.code,
                    value: REGEX.CODE,
                  },
                },
              ]}
              help={
                <>
                  Nhập thông tin{' '}
                  <span className="font-medium text-gray-800">[control]</span>,
                  hệ thống sẽ tự gán theo Format:
                  <span className="font-medium text-gray-800">
                    [module]_[page]_[control]
                  </span>
                </>
              }
            >
              <Input />
            </FormTeraItem>
            <FormTeraItem
              label="Tiêu đề"
              name="title"
              rules={[
                {
                  required: messageValidate.emptySelect,
                  maxLength: {
                    message: messageValidateLength.textLong,
                    value: 255,
                  },
                },
              ]}
            >
              <Input />
            </FormTeraItem>
            <FormTeraItem label="Giá trị" name="value">
              <Input />
            </FormTeraItem>
            <FormTeraItem
              label="Nhóm control"
              name="group_id"
              rules={[
                {
                  required: messageValidate.emptySelect,
                },
              ]}
            >
              <Select options={optionsGroup} />
            </FormTeraItem>
            <FormTeraItem label="Màu sắc" name="color">
              <Input />
            </FormTeraItem>
            <FormTeraItem label="className" name="class_name">
              <Input />
            </FormTeraItem>
            <FormTeraItem label="Thứ tự đứng sau" name="order">
              <Select options={optionsOrderControl} />
            </FormTeraItem>
            <FormTeraItem label="Trạng thái" name="status">
              <Toggle />
            </FormTeraItem>
          </FormTera>
        </Spin>
      </Modal>
    );
  },
);

export default FormControlConfig;
