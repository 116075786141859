import { yupResolver } from '@hookform/resolvers/yup';
import Filter from '_common/component/Filter';
import { Controller, useForm } from 'react-hook-form';
import {
  Form,
  FormItem,
  Row,
  Select,
  getQueryParams,
  updateQueryParams,
} from 'tera-dls';
import * as yup from 'yup';
import { IFilter } from '../../interfaces';
import { useLocation, useNavigate } from 'react-router-dom';
import { statusOnOffString } from '../../constants';

const schema = yup.object().shape({
  status: yup.string().nullable(),
});

interface ModuleFilterProps {
  open: boolean;
  onClose: () => void;
  initialValue: any;
}

function ModuleFilter({ open, onClose }: ModuleFilterProps) {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      status: queryParams?.status,
    },
  });

  const handleQueryParams = (query: IFilter) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...query,
      page: 1,
    });
    navigate(location.pathname + paramString);
  };

  const handleSubmitForm = (value) => {
    handleQueryParams(value);
    onClose();
  };

  const handleReset = () => {
    const values: IFilter = {
      keyword: '',
      status: null,
    };
    handleQueryParams(values);
    onClose();
  };

  const optionsStatus = Object.keys(statusOnOffString).map((key) => ({
    label: statusOnOffString[key]?.name,
    value: key,
  }));

  return (
    <Filter
      open={open}
      onClose={onClose}
      onCancel={onClose}
      onFilter={() => handleSubmit(handleSubmitForm)()}
    >
      <Form onSubmit={handleSubmit(handleSubmitForm)}>
        <Row className="grid gap-y-0">
          <FormItem label="Trạng thái" isRequired={false}>
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  placeholder="Vui lòng chọn"
                  options={optionsStatus}
                  allowClear
                />
              )}
            />
          </FormItem>
        </Row>
        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </Form>
    </Filter>
  );
}

export default ModuleFilter;
