import { IRouteProps } from '_common/interface/router';
import { USER_URL } from '_common/constants/url';
import ListUser from './containers/ListUser';
import UserDetail from './containers/ListUser/Detail';

export const UserListRouter: IRouteProps[] = [
  {
    key: USER_URL.list.key,
    path: USER_URL.list.shortenUrl,
    component: <ListUser />,
  },
  {
    key: USER_URL.detail.key,
    path: USER_URL.detail.shortenUrl,
    component: <UserDetail />,
  },
];
