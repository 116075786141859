import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import {
  DATE_TIME_BACKEND_FORMAT,
  DATE_TIME_FORMAT,
  REGEX,
} from '_common/constants/common';
import CheckBox from '_common/dof/Control/CheckBox';
import DatePicker from '_common/dof/Control/DatePicker';
import Input from '_common/dof/Control/Input';
import Select from '_common/dof/Control/Select';
import TextArea from '_common/dof/Control/TextArea';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import UploadFiles from '_common/dof/UploadFiles';
import moment from 'moment';

import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import Group from 'styles/images/uiNew/Group.png';
import { PlusCircleOutlined, Row, notification } from 'tera-dls';
import CashReceiptApi from '../../api';
import _ from 'lodash';
import ExplainTable, { IExplainTableRef } from '../ExplainTable';
import { PERMISSION_KEY } from '../../permission';
import {
  CASH_RECEIPT_TYPE,
  PAYMENT_METHOD_TYPE,
} from 'pages/Invoice/constants';
import SelectUser from '_common/dof/Select/SelectUser';

export interface ICashReceiptFormContentRef {
  submit: () => void;
  getIsDirty: () => boolean;
  checkError: () => boolean;
}

interface IProps {
  onSuccess?: () => void;
  id?: number | string;
}

const CashReceiptFormContent = (props, ref) => {
  const { onSuccess, id } = props;
  const cashExplainRef = useRef<IExplainTableRef>(null);
  const form = useForm({ mode: 'onChange' });
  const queryClient = useQueryClient();
  const [files, setFiles] = useState<any>([]);
  const [explains, setExplains] = useState<any>();

  const submitter_id = form.watch('submitter_id');

  const { data: suggestion, refetch: refetchSuggestion } = useQuery(
    ['get-cash-receipt-suggestion'],
    () => CashReceiptApi.getCodeSuggestion(),
    {
      enabled: !id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { data: detail, refetch } = useQuery(
    ['get-cash-receipt-detail', id],
    () => CashReceiptApi.getDetail({ id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (!detail) return;
    const data = _.pick(detail, [
      'code',
      'pay_method_type',
      'ballot_type',
      'accounted_business_result',
      'note',
    ]);
    detail.files &&
      setFiles(
        detail.files?.map((item) => ({
          id: item.id,
          name: item.file_name,
          url: item.link_file,
        })),
      );

    form.reset({
      ...data,
      ...(detail?.submitter?.id && { submitter_id: detail.submitter.id }),
      ...(detail.date && { date: moment(detail.date) }),
      ...(detail.accounting_date && {
        accounting_date: moment(detail.accounting_date),
      }),
    });
  }, [detail]);

  const { mutate: mutateAction } = useMutation(
    (variables: any) =>
      id
        ? CashReceiptApi.update({ ...variables, id })
        : CashReceiptApi.create(variables),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          onSuccess && onSuccess();
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleSubmit = (values: any): void => {
    mutateAction({
      params: {
        ...values,
        date: values?.date
          ? moment(values?.date).format(DATE_TIME_BACKEND_FORMAT)
          : moment().format(DATE_TIME_BACKEND_FORMAT),
        accounting_date: values?.accounting_date
          ? moment(values?.accounting_date).format(DATE_TIME_BACKEND_FORMAT)
          : moment().format(DATE_TIME_BACKEND_FORMAT),
        ...(files && { file_uploads: files }),
        ...(explains && !id && { explains }),
      },
    });
  };

  useEffect(() => {
    if (!id && suggestion) {
      form.setValue('code', suggestion, { shouldDirty: false });
    }
  }, [suggestion, id]);

  useEffect(() => {
    if (!id) {
      form.setValue('accounted_business_result', true, { shouldDirty: false });
      form.setValue('pay_method_type', 'transfer', { shouldDirty: false });
      form.setValue('accounting_date', moment(), { shouldDirty: false });
      form.setValue('date', moment(), { shouldDirty: false });
      refetchSuggestion();
    } else {
      refetch();
    }
  }, [form, id]);

  useImperativeHandle(
    ref,
    () => ({
      checkError() {
        return cashExplainRef?.current?.checkError();
      },
      async submit() {
        const isNotEditing = await cashExplainRef?.current?.checkError();
        isNotEditing && form.handleSubmit(handleSubmit)();
      },
      async getIsDirty() {
        const isNotErrors = await cashExplainRef?.current?.checkError();
        if (detail) {
          return (
            form.formState.isDirty ||
            JSON.stringify(files) !==
              JSON.stringify(
                detail.files?.map((item) => ({
                  id: item.id,
                  name: item.file_name,
                  url: item.link_file,
                })),
              ) ||
            JSON.stringify(explains) !== JSON.stringify(detail?.explain) ||
            !isNotErrors
          );
        }
        return (
          form.formState.isDirty ||
          files?.length > 0 ||
          explains?.length > 0 ||
          !isNotErrors
        );
      },
    }),
    [form, form.formState.isDirty, handleSubmit, cashExplainRef, detail],
  );

  const paymentMethod = Object.entries(PAYMENT_METHOD_TYPE).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  const typeOptions = Object.entries(CASH_RECEIPT_TYPE).map(([key, value]) => ({
    label: value,
    value: key,
  }));

  return (
    <FormTera form={form} onSubmit={handleSubmit}>
      <div className="grid grid-cols-2 p-[16px] gap-[16px]">
        <div className="col-span-1 grid gap-5">
          <div className="text-blue-500 text-[13px] leading-[15px] inline-block font-medium">
            Thông tin chung
          </div>

          <Row className="grid grid-cols-2">
            <FormTeraItem
              name="code"
              label={'Mã phiếu thu'}
              className="col-span-1"
              rules={[
                { required: 'Vui lòng điền thông tin' },
                {
                  pattern: {
                    value: REGEX.CODE,
                    message: `Mã phiếu thu không dấu, không khoảng cách, không kí tự đặc biệt ( ngoại trừ "_")`,
                  },
                },
              ]}
            >
              <Input maxLength={191} placeholder="Vui lòng nhập" />
            </FormTeraItem>
            <FormTeraItem
              className="col-span-1"
              label="Phương thức thanh toán"
              name="pay_method_type"
            >
              <Select placeholder="Vui lòng chọn" options={paymentMethod} />
            </FormTeraItem>
            <FormTeraItem
              className="col-span-1"
              label="Loại phiếu thu"
              name="ballot_type"
              rules={[{ required: 'Vui lòng chọn thông tin' }]}
            >
              <Select
                placeholder="Vui lòng chọn"
                options={typeOptions}
                allowClear
                onChangeCustom={() => form.setValue('submitter_id', null)}
              />
            </FormTeraItem>
            <FormTeraItem
              label="Người nộp"
              name="submitter_id"
              rules={[{ required: 'Vui lòng chọn thông tin' }]}
            >
              <SelectUser paramsApi={{ include_id: submitter_id }} allowClear />
            </FormTeraItem>
            <FormTeraItem label="Ngày phiếu thu" name="date">
              <DatePicker
                placeholder="Vui lòng chọn"
                allowClear
                showTime
                format={DATE_TIME_FORMAT}
              />
            </FormTeraItem>
            <FormTeraItem label="Ngày hạch toán" name="accounting_date">
              <DatePicker
                placeholder="Vui lòng chọn"
                allowClear
                showTime
                format={DATE_TIME_FORMAT}
              />
            </FormTeraItem>
          </Row>
          <Row>
            <FormTeraItem label="" name="accounted_business_result">
              <CheckBox labelClassName="font-normal text-[13px] leading-[16px]">
                Hạch toán vào kết quả kinh doanh
              </CheckBox>
            </FormTeraItem>
          </Row>
          <Row>
            <FormTeraItem label="Ghi chú" name="note">
              <TextArea placeholder="Vui lòng nhập" />
            </FormTeraItem>
          </Row>
          <Row>
            <UploadFiles
              permission={{
                upload: id
                  ? PERMISSION_KEY.CASH_RECEIPT_UPDATE_CASH_RECEIPT
                  : PERMISSION_KEY.CASH_RECEIPT_CREATE_CASH_RECEIPT,
                delete: id
                  ? PERMISSION_KEY.CASH_RECEIPT_UPDATE_CASH_RECEIPT
                  : PERMISSION_KEY.CASH_RECEIPT_CREATE_CASH_RECEIPT,
                list: id
                  ? PERMISSION_KEY.CASH_RECEIPT_UPDATE_CASH_RECEIPT
                  : PERMISSION_KEY.CASH_RECEIPT_CREATE_CASH_RECEIPT,
                download: id
                  ? PERMISSION_KEY.CASH_RECEIPT_UPDATE_CASH_RECEIPT
                  : PERMISSION_KEY.CASH_RECEIPT_CREATE_CASH_RECEIPT,
              }}
              object_key="cash-receipt"
              folder="cash-receipt"
              fileList={files}
              maxSize={10}
              onReceiveFiles={(_, files) => {
                setFiles(files);
              }}
              onRemove={(file) => {
                setFiles((prev) => prev.filter((item) => item.id !== file.id));
              }}
              className="max-w-max"
            />
          </Row>
        </div>
        <div className="col-span-1 flex flex-col gap-5">
          <div className="text-blue-500 text-[13px] leading-[15px] inline-block font-medium">
            Danh sách hạch toán
          </div>
          <div className="flex justify-center items-center">
            <div className="flex flex-col justify-center items-center gap-[16px]">
              <img src={Group} className="h-auto w-[70px]" />
              <p className="text-center grid gap-[1px] font-normal text-sm">
                <p>Chưa có danh sách hạch toán</p>
                <p className="flex justify-center items-center gap-1">
                  Bấm
                  <PlusCircleOutlined className="w-4 inline text-green-500" />
                  để thêm hạch toán
                </p>
              </p>
            </div>
          </div>
          <ExplainTable
            type="cash-receipt"
            mode={id ? 'default' : 'soft'}
            id={id}
            objectType="cash-explain"
            onChange={setExplains}
            ref={cashExplainRef}
            onSuccess={() => {
              queryClient.invalidateQueries(['get-cash-receipt-list']);
              queryClient.invalidateQueries(['get-cash-receipt-statistic']);
            }}
          />
        </div>
      </div>
    </FormTera>
  );
};

export default forwardRef<ICashReceiptFormContentRef, IProps>(
  CashReceiptFormContent,
);
