import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  messageValidate,
  messageValidateLength,
  messageWarning,
} from '_common/constants/message';

import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { REGEX } from '_common/constants/common';
import Input from '_common/dof/Control/Input';
import Toggle from '_common/dof/Control/Switch';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectIcon from '_common/dof/Select/SelectIcon';
import SelectModule from '_common/dof/Select/SelectModule';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { Modal, Spin, notification } from 'tera-dls';
import EpicApi from '../../_api';

interface IProps {
  open: boolean;
  id?: number;
  onClose: () => void;
  onChangeDataSuccess?: () => void;
}

interface IForm {
  code: string;
  name: string;
  icon: string;
  status: boolean;
  module_id: number;
}

const EpicForm = (props: IProps) => {
  const { open = false, id, onClose, onChangeDataSuccess } = props;

  const form = useForm<IForm>({
    defaultValues: {
      code: '',
      icon: null,
      module_id: null,
      name: '',
      status: true,
    },
  });
  const {
    reset,
    handleSubmit,
    watch,
    formState: { isDirty },
  } = form;
  const confirm = useConfirm();

  const queryClient = useQueryClient();

  const handleClose = (): void => {
    reset();
    onClose();
  };

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        className: 'modal-button-center',
        onOk: () => {
          handleClose();
        },
      });
    } else {
      handleClose();
    }
  };

  const icon = watch('icon');

  const updateData = (data) => {
    const values = _.pick(data, [
      'code',
      'name',
      'module_id',
      'icon',
      'status',
    ]);
    reset({
      ...values,
      status: values.status === 'active' ? true : false,
    });
  };

  const { isLoading } = useQuery(
    ['get-epic-detail', id],
    () => EpicApi.getDetail(id),
    {
      enabled: !!open && !!id,
      onSuccess: (data) => {
        updateData(data);
      },
    },
  );

  const { mutate: mutateForm, isLoading: isLoadingSubmit } = useMutation(
    (variables: any) => {
      return id ? EpicApi.update(id, variables) : EpicApi.create(variables);
    },
    {
      onSuccess: ({ code, msg }) => {
        if (code === 200) {
          onChangeDataSuccess && onChangeDataSuccess();
          queryClient.removeQueries({
            queryKey: ['get-page-by-menu-id'],
          });
          onClose();
          notification.success({
            message: msg,
          });
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const trimData = (code: string): string => {
    return code.split(' ').join('');
  };

  const handleSubmitForm = (values: { [key: string]: any }): void => {
    const code = trimData(values.code);
    mutateForm({
      ...values,
      code,
      status: values?.status ? 'active' : 'inactive',
    });
  };

  return (
    <Modal
      centered={true}
      title={id ? ' SỬA EPIC' : 'THÊM EPIC'}
      open={open}
      width={500}
      closeIcon={false}
      okText="Lưu"
      cancelText="Huỷ"
      onOk={() => handleSubmit(handleSubmitForm)()}
      onCancel={handleCloseConfirm}
      destroyOnClose={true}
      confirmLoading={(isLoading && !!id) || isLoadingSubmit}
    >
      <Spin spinning={(isLoading && !!id) || isLoadingSubmit}>
        <FormTera form={form}>
          <FormTeraItem
            label="Mã epic"
            name="code"
            help={
              <p className="text-gray-400 text-[0.625rem] italic mt-1">
                Nhập thông tin <span className="text-gray-800">[epic]</span>, hệ
                thống sẽ tự gán theo Format:
                <span className="text-gray-800">[module]_[epic]</span>
              </p>
            }
            rules={[
              {
                required: messageValidate.emptyText,
                maxLength: {
                  value: 191,
                  message: messageValidateLength.key,
                },
                pattern: {
                  value: REGEX.CODE,
                  message: messageValidate.code,
                },
              },
            ]}
          >
            <Input />
          </FormTeraItem>
          <FormTeraItem
            label="Tên epic"
            name="name"
            rules={[
              {
                required: messageValidate.emptyText,
                maxLength: {
                  value: 255,
                  message: messageValidateLength.textLong,
                },
              },
            ]}
          >
            <Input />
          </FormTeraItem>
          <FormTeraItem
            label="Module"
            className="flex-auto"
            name="module_id"
            rules={[
              {
                required: messageValidate.emptySelect,
              },
            ]}
          >
            <SelectModule placeholder="Chọn Module" />
          </FormTeraItem>
          <FormTeraItem label="Icon" name="icon">
            <SelectIcon paramsApi={{ include_id: icon }} />
          </FormTeraItem>
          <FormTeraItem label="Trạng thái" name="status">
            <Toggle />
          </FormTeraItem>
        </FormTera>
      </Spin>
    </Modal>
  );
};

export default EpicForm;
