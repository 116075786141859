import React, { useState } from 'react';
import Content from './containers/Content';
export const StatisticCashExpenseByTimeParamContext = React.createContext(null);

const StatisticCashExpenseByTime = () => {
  const [contextParams, setContextParams] = useState<any>();
  return (
    <StatisticCashExpenseByTimeParamContext.Provider
      value={{ contextParams, setContextParams }}
    >
      <Content chartClassName="!h-[400px]" />
    </StatisticCashExpenseByTimeParamContext.Provider>
  );
};

export default StatisticCashExpenseByTime;
