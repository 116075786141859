import { useState } from 'react';
import {
  ArrowsPointingOuOutlined,
  ArrowsPointingInOutlined,
  ChartLine,
  RangePicker,
  ChartLineProps,
  Tooltip,
} from 'tera-dls';
import ModalChart from './Modal';
import { customTwMerge } from 'tailwind-merge.config';

interface IContentProps {
  type?: 'small' | 'large';
  onClose?: () => void;
  chartClassName?: string;
}

const Content = ({
  type = 'small',
  onClose,
  chartClassName,
}: IContentProps) => {
  const dataChart: ChartLineProps['data'] = {
    labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'], // Các ngày trong tuần làm nhãn cho trục x
    datasets: [
      {
        label: 'Payout', // Tên tập dữ liệu thứ nhất
        data: [0, 2.5, 1, 7.5, 9], // Dữ liệu cho tập dữ liệu thứ nhất
        borderColor: '#1A56DB', // Màu đường
        backgroundColor: '#1A56DB', // Màu nền cho điểm dữ liệu (được dùng khi `fill` là `true`)
        yAxisID: 'y', // Liên kết tập dữ liệu này với trục y đầu tiên
        tension: 0.3, // Độ cong của đường
        fill: false,
        pointRadius: 5, // Kích thước của điểm
        pointBackgroundColor: '#1A56DB', // Màu nền của điểm
        pointBorderColor: '#1A56DB', // Màu viền của điểm
      },
      {
        label: 'Click',
        data: [10, 15, 5, 35, 20],
        borderColor: '#F3722C',
        backgroundColor: '#F3722C',
        yAxisID: 'y1',
        tension: 0.3,
        fill: false,
        pointRadius: 5,
        pointBackgroundColor: '#F3722C',
        pointBorderColor: '#F3722C',
      },
      {
        label: 'Người đăng ký',
        data: [2, 1, 5, 6, 3.5],
        borderColor: '#F94144',
        backgroundColor: '#F94144',
        yAxisID: 'y',
        tension: 0.3,
        fill: false,
        pointRadius: 5,
        pointBackgroundColor: '#F94144',
        pointBorderColor: '#F94144',
      },
    ],
  };

  const optionsLine: ChartLineProps['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        position: 'left', // Trục y cho dữ liệu đầu tiên
        grid: {
          drawOnChartArea: true, // hiển thị gridline cho trục này
        },
      },
      y1: {
        // Thêm trục y thứ hai
        beginAtZero: true,
        position: 'right', // Trục y cho dữ liệu thứ hai, đặt về phía bên phải
        grid: {
          drawOnChartArea: false, // không hiển thị gridline cho trục này
        },
        // Để trục y thứ hai có độ chính xác cao hơn, có thể cần cài đặt ticks
        ticks: {
          // Cài đặt này phụ thuộc vào dữ liệu của bạn
        },
      },
    },
    elements: {
      line: {
        tension: 0.3,
      },
      point: {
        radius: 5, // Kích thước của điểm
        backgroundColor: 'red', // Màu nền của điểm
        borderColor: 'red', // Màu viền của điểm
        borderWidth: 1, // Độ dày của viền
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
  };
  const isSmallType = type === 'small';
  const [openModal, setOpenModal] = useState<boolean>();
  return (
    <>
      <div className="flex justify-between px-[10px] pt-[20px] items-center">
        <h3 className="font-medium">Báo cáo tiếp thị</h3>
        <div className="flex gap-2.5">
          <RangePicker
            placeholder={['dd/mm/yyyy', 'dd/mm/yyyy']}
            className="w-[300px] !bg-none !border-[1px] !border-[#fff] !border-b-[#374151] !focus:shadow-none"
          />
          <div className="flex items-center">
            {isSmallType ? (
              <Tooltip title="Mở rộng">
                <span>
                  <ArrowsPointingOuOutlined
                    className="text-[#374151] w-5 h-5 hover:cursor-pointer"
                    onClick={() => setOpenModal(true)}
                  />
                </span>
              </Tooltip>
            ) : (
              <Tooltip title="Thu gọn">
                <span>
                  <ArrowsPointingInOutlined
                    className="text-[#374151] w-5 h-5 hover:cursor-pointer"
                    onClick={onClose}
                  />
                </span>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      <div
        className={customTwMerge(
          'p-2.5 h-full ',
          chartClassName || 'max-h-[410px]',
        )}
      >
        <ChartLine className="h-full" data={dataChart} options={optionsLine} />
      </div>
      {openModal && (
        <ModalChart
          open={openModal}
          onClose={() => setOpenModal(false)}
          type="large"
        />
      )}
    </>
  );
};

export default Content;
