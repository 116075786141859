import { adminEndpoint } from '_common/constants/common';
import api from 'states/drivers';
export { default as StatusTypeApi } from './status_type';

const StatusTypeEndpoint = `${adminEndpoint}/administrator/data-status`;

const DataStatusApi = {
  getList: async (param?) =>
    await api
      .get(`${StatusTypeEndpoint}/list`, param)
      .then((result) => result?.data?.data),
  getDetail: async (id: string | number) =>
    await api
      .get(`${StatusTypeEndpoint}/detail/${id}`)
      .then((result) => result.data?.data),
  create: async (param?) =>
    await api
      .post(`${StatusTypeEndpoint}/create`, param)
      .then((result) => result.data),
  update: async (id: string | number, param?) =>
    await api
      .put(`${StatusTypeEndpoint}/update/${id}`, param)
      .then((result) => result.data),
  delete: async (id: string | number) =>
    await api
      .delete(`${StatusTypeEndpoint}/delete/${id}`)
      .then((result) => result.data),
  export: async (params) =>
    await api
      .post(`${StatusTypeEndpoint}/export`, params)
      .then((result) => result.data),
  import: async (params) =>
    await api
      .post(`${StatusTypeEndpoint}/import`, params)
      .then((result) => result.data),
};

export default DataStatusApi;
