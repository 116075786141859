import { useMutation, useQueries, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { REGEX } from '_common/constants/common';
import {
  messageValidate,
  messageValidateLength,
  messageWarning,
} from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import Select from '_common/dof/Control/Select';
import Toggle from '_common/dof/Control/Switch';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectTableConfig from '_common/dof/Select/SelectTableConfig';
import useConfirm from '_common/hooks/useConfirm';
import { trimData } from '_common/utils';
import _ from 'lodash';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, ValueType, notification } from 'tera-dls';
import { defaultStandingFirstOption } from '../../../constants';
import SystemTableConfigApi from '../../_api';
import { LAYOUT } from '../../constants';

interface IProps {
  open: boolean;
  id?: number;
  pageId: number;
  onClose: () => void;
  onChangeSuccess?: () => void;
}

interface IForm {
  code: string;
  name: string;
  group: string;
  layout: string;
  stand_behind: number;
  class_name: string;
  status: boolean;
}

const TableConfigForm = (props: IProps) => {
  const { open = false, id, onClose, pageId, onChangeSuccess } = props;
  const form = useForm<IForm>();
  const {
    reset,
    setValue,
    watch,
    handleSubmit,
    formState: { isDirty },
  } = form;
  const confirm = useConfirm();
  const [groupKey, setGroupKey] = useState<string>();
  const [standTable, setStandTable] = useState<ValueType>();
  const queryClient = useQueryClient();
  const handleClose = (): void => {
    reset();
    onClose();
  };

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        className: 'modal-button-center',
        onOk: () => {
          handleClose();
        },
      });
    } else {
      handleClose();
    }
  };

  const updateDetail = (data) => {
    const values = _.pick(data, ['code', 'name', 'layout', 'class_name']);
    reset({
      ...values,
      status: data?.status === 'active' ? true : false,
      group: data?.group?.key,
    });
    setGroupKey(data?.group?.key);

    if (data?.standing_behind === 0) {
      setStandTable(defaultStandingFirstOption);
      setValue('stand_behind', 0);
    } else {
      setValue('stand_behind', data?.standing_behind?.id);
      setStandTable({
        value: data?.standing_behind?.id,
        label: data?.standing_behind?.name,
      });
    }
  };

  const [{ data: groupTables }] = useQueries({
    queries: [
      {
        queryKey: ['get-group-table'],
        queryFn: () => SystemTableConfigApi.getGroupList({}),
        enabled: open,
        staleTime: 300000,
        cacheTime: 300000,
      },
      {
        queryKey: ['get-table-detail', id],
        queryFn: () => SystemTableConfigApi.getDetail({ id }),
        enabled: !!id && open,
        onSuccess: (data) => {
          updateDetail(data);
        },
      },
    ],
  });

  const cleanCacheDof = () => {
    queryClient.removeQueries({ queryKey: ['get-table-config-by-page-id'] });
  };

  const { mutate: mutateForm } = useMutation(
    (variables: any) => {
      return id
        ? SystemTableConfigApi.update({ id, params: variables })
        : SystemTableConfigApi.create(variables);
    },
    {
      onSuccess: ({ code, msg }) => {
        if (code === 200) {
          onChangeSuccess && onChangeSuccess();
          cleanCacheDof();
          handleClose();
          notification.success({
            message: msg,
          });
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleSubmitForm = (values: { [key: string]: any }): void => {
    mutateForm({
      ...values,
      code: trimData(values.code),
      page_id: pageId,
      status: values?.status ? 'active' : 'inactive',
    });
  };

  const groupTableOptions = groupTables?.map((item) => ({
    value: item.key,
    label: item.title,
  }));

  return (
    <Modal
      centered={true}
      title={id ? 'SỬA BẢNG DỮ LIỆU' : 'THÊM BẢNG DỮ LIỆU'}
      open={open}
      className=" xxs:w-[95%] md:w-[418px]"
      closeIcon={false}
      okText="Lưu"
      cancelText="Huỷ"
      onOk={() => handleSubmit(handleSubmitForm)()}
      onCancel={handleCloseConfirm}
      destroyOnClose={true}
    >
      <FormTera form={form}>
        <FormTeraItem
          label="Mã bảng dữ liệu"
          name="code"
          help={
            <span className="text-gray-400 text-[0.625rem] italic">
              Nhập thông tin <span className="text-gray-800">[table]</span>, hệ
              thống sẽ tự gán theo Format:{' '}
              <span className="text-gray-800">[module]_[page]_[table]</span>{' '}
            </span>
          }
          rules={[
            {
              required: messageValidate.emptyText,
              maxLength: {
                value: 191,
                message: messageValidateLength.key,
              },
              pattern: {
                value: REGEX.CODE,
                message: messageValidate.code,
              },
            },
          ]}
        >
          <Input />
        </FormTeraItem>
        <FormTeraItem
          label="Tiêu đề"
          name="name"
          rules={[
            {
              required: messageValidate.emptyText,
              maxLength: {
                value: 255,
                message: messageValidateLength.textLong,
              },
            },
          ]}
        >
          <Input />
        </FormTeraItem>

        <FormTeraItem
          label="Nhóm bảng dữ liệu"
          name="group"
          rules={[
            {
              required: messageValidate.emptySelect,
            },
          ]}
        >
          <Select
            showSearch
            value={groupKey}
            onChangeCustom={(value) => {
              setGroupKey(value as string);
              setValue('stand_behind', null);
              setStandTable(null);
            }}
            options={groupTableOptions}
          />
        </FormTeraItem>

        <FormTeraItem label="Layout" name="layout">
          <Select
            onChangeCustom={(value) => value}
            showSearch
            options={Object.keys(LAYOUT).map((key) => ({
              value: key,
              label: LAYOUT[key],
            }))}
          />
        </FormTeraItem>
        <FormTeraItem label="Thứ tự đứng sau" name="stand_behind">
          <SelectTableConfig
            pageId={pageId}
            groupKey={groupKey}
            disabled={!watch('group')}
            selectedValue={standTable}
            onChangeCustom={(val: ValueType) => {
              setStandTable(val);
            }}
            defaultOptions={[defaultStandingFirstOption]}
            paramsApi={{
              table_id: id,
              limit: 50,
            }}
            onClear={() => setStandTable({})}
          />
        </FormTeraItem>
        <FormTeraItem
          label="className"
          name="class_name"
          rules={[
            {
              maxLength: {
                value: 255,
                message: messageValidateLength.textLong,
              },
            },
          ]}
        >
          <Input />
        </FormTeraItem>
        <FormTeraItem label="Trạng thái" name="status">
          <Toggle />
        </FormTeraItem>
      </FormTera>
    </Modal>
  );
};

export default TableConfigForm;
