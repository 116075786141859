import { observer } from 'mobx-react';
import { useRef, useState } from 'react';

import { BUTTON_KEY } from '_common/constants/permission';
import { IPagination } from '_common/interface';
import { IFilter, IParams } from 'pages/User/interface';
import { useLocation, useNavigate } from 'react-router-dom';
import { getQueryParams, updateQueryParams } from 'tera-dls';

import ButtonDropdown from '_common/component/ButtonDropdown';
import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import UserFilter from './Filter';
import UserHeader from './Header';
import UserTable, { IUserTableRef } from './UserTable';

export interface QuerySearch {
  page?: number;
  rowPage?: number;
  full_name?: string;
  status?: null | number;
}

const ListUser = observer(() => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const actionRef = useRef<IUserTableRef>();
  const [isFilter, setIsFilter] = useState<boolean>(false);

  const handleQueryParams = (query: IFilter | IPagination | IParams) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...query,
    });
    navigate(location.pathname + paramString);
  };

  const handleSearch = (value) => {
    handleQueryParams({ keyword: value?.keyword, page: 1 });
  };

  const handleFilter = (values: IFilter) => {
    handleQueryParams({
      page: 1,
      ...values,
    });
  };

  const items = [
    {
      key: '1',
      label: 'Thêm cá nhân',
      onClick: () => {
        actionRef.current?.onCreate('individual');
      },
    },
    {
      key: '2',
      label: 'Thêm thành viên',
      onClick: () => {
        actionRef.current?.onCreate('member');
      },
    },
    {
      key: '2',
      label: 'Thêm chủ sở hữu',
      onClick: () => {
        actionRef.current?.onCreate('owner');
      },
    },
  ];

  return (
    <div className="p-2.5">
      <HeaderViewListV2
        title="DANH SÁCH NGƯỜI DÙNG"
        onClickFilter={() => setIsFilter(true)}
        buttonCreatingKey={BUTTON_KEY.USER_CREATE}
        buttonAddRender={() => (
          <ButtonDropdown
            menu={{ items }}
            containerClassName="w-[50px]"
            placement="bottom-end"
            trigger="click"
          />
        )}
        dropdownItems={[
          {
            key: 1,
            label: 'Export file excel',
          },
        ]}
        actionLeftRender={<UserHeader onSearch={handleSearch} />}
      >
        <UserTable ref={actionRef} />
      </HeaderViewListV2>
      {isFilter && (
        <UserFilter
          open={isFilter}
          onClose={() => setIsFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
    </div>
  );
});

export default ListUser;
