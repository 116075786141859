import { useQuery } from '@tanstack/react-query';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import moment from 'moment';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ChartBarProps, ChartPie, Spin, useDetectDevice } from 'tera-dls';
import { StatisticCashExpenseByPaymentMethodParamContext } from '..';
import Header from '../../Header';
import StatisticApi from '../../StatisticCashExpense/api';
import ModalChart from './Modal';
import SettingModal from './Setting';

import {
  CASH_RECEIPT_TYPE,
  EXPENSE_VOUCHER_TYPE,
} from 'pages/Invoice/constants';
import startUpIdea from 'styles/images/uiNew/start_up_idea.png';

export type OpportunityValueStatisticByStage = 'small' | 'large';

interface IProps {
  type?: OpportunityValueStatisticByStage;
  onClose?: () => void;
  chartOptions?: ChartBarProps['options'];
  chartClassName?: string;
  defaultLengthShowTick?: number;
  defaultLengthScroll?: number;
  labelMaxLength?: number;
}

const Content = (props: IProps) => {
  const { type = 'small', onClose } = props;
  const [openModal, setOpenModal] = useState<boolean>();
  const [openSetting, setOpenSetting] = useState<boolean>(false);
  const { contextParams } = useContext(
    StatisticCashExpenseByPaymentMethodParamContext,
  );
  const { widthScreen } = useDetectDevice();

  const currentWeek = [
    moment().startOf('week').format(DATE_BACKEND_FORMAT),
    moment().endOf('week').format(DATE_BACKEND_FORMAT),
  ];

  const [params, setParams] = useState<any>({
    receipt_type: Object.keys(CASH_RECEIPT_TYPE),
    expense_type: Object.keys(EXPENSE_VOUCHER_TYPE),
    receipt_date: currentWeek,
    expense_date: currentWeek,
    time: 'currentWeek',
  });

  const {
    data: response,
    refetch,
    isRefetching,
  } = useQuery(
    ['get-statistic-payment-method', params],
    () =>
      StatisticApi.byPaymentMethod({
        params: {
          ...(params?.receipt_type?.[0] && {
            receipt_type: params?.receipt_type?.join(','),
          }),
          ...(params?.expense_type?.[0] && {
            expense_type: params?.expense_type?.join(','),
          }),
          ...(params?.receipt_date?.[0] && {
            receipt_date: params?.receipt_date?.join(','),
          }),
          ...(params?.expense_date?.[0] && {
            expense_date: params?.expense_date?.join(','),
          }),
          show_by: params?.show_by,
        },
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const expenseVoucherOptions: any = {
    plugins: {
      datalabels: {
        formatter: function (_, values) {
          const index = values?.dataIndex;
          const value =
            response?.expense_voucher?.[index === 0 ? 'cash' : 'transfer'];
          return value === 0 ? '' : `${value}%`;
        },
        color: 'white',
      },
      legend: {
        position: 'bottom',
        labels: {
          padding: 20,
        },
      },
      title: {
        display: true,
        text: 'Chi',
        position: 'top',
        padding: 20,
      },
      tooltip: {
        enabled: false,
      },
      maintainAspectRatio: false,
    },
  };
  const cashReceiptOptions: any = {
    plugins: {
      datalabels: {
        formatter: function (_, values) {
          const index = values?.dataIndex;
          const value = response?.receipt?.[index === 0 ? 'cash' : 'transfer'];
          return value === 0 ? '' : `${value}%`;
        },
        color: 'white',
      },
      legend: {
        position: 'bottom',
        labels: {
          padding: 20,
        },
      },
      title: {
        display: true,
        text: 'Thu',
        position: 'top',
        padding: 20,
      },
      tooltip: {
        enabled: false,
      },
      maintainAspectRatio: false,
    },
  };

  const dataSource = useMemo(() => {
    const labels = ['Tiền mặt', 'Chuyển khoản'];
    let dataLabelsExpenseVoucher: any = {
      data: [],
      backgroundColor: ['#31C48D', '#F3722C'],
      borderColor: ['#31C48D', '#F3722C'],
      name: 'expense_voucher',
    };
    let dataLabelsCashReceipt: any = {
      data: [],
      backgroundColor: ['#31C48D', '#F3722C'],
      borderColor: ['#31C48D', '#F3722C'],
      name: 'receipt',
    };

    response &&
      Object.entries(response).forEach(([key, value]) => {
        if (key === 'expense_voucher') {
          dataLabelsExpenseVoucher = {
            ...dataLabelsExpenseVoucher,
            data: (Object.values(value) ?? [])?.map((percentage) =>
              percentage < 1 && percentage !== 0 ? 1 : percentage,
            ),
          };
        } else {
          dataLabelsCashReceipt = {
            ...dataLabelsCashReceipt,
            data: (Object.values(value) ?? [])?.map((percentage) =>
              percentage < 1 && percentage !== 0 ? 1 : percentage,
            ),
          };
        }
      });

    return {
      receipt: {
        labels,
        datasets: [dataLabelsCashReceipt],
      },
      expenseVoucher: {
        labels,
        datasets: [dataLabelsExpenseVoucher],
      },
    };
  }, [response]);

  useEffect(() => {
    contextParams && setParams(contextParams);
  }, [contextParams]);

  useEffect(() => {
    refetch();
  }, [params]);

  const isSmallType = type === 'small';
  const checkData = (data) =>
    data?.datasets?.[0]?.data.some((value) => value !== 0);

  return (
    <>
      <div className="rounded-[5px] border-[1px] bg-white h-full overflow-hidden">
        <Header
          title={'Thu chi theo phương thức thanh toán'}
          onClickRefresh={() => refetch()}
          onClickSetting={() => setOpenSetting(true)}
          onZoomOut={() => setOpenModal(true)}
          onZoomIn={() => onClose()}
          isSmallType={isSmallType}
        />
        <div className="flex w-full justify-between p-10 ">
          <Spin spinning={isRefetching}>
            <div className="flex w-[40%] items-center justify-center">
              {!isRefetching && !checkData(dataSource?.receipt) ? (
                <div className="flex flex-col">
                  <p className="text-gray-500 font-medium text-center w-full ">
                    Thu
                  </p>
                  <img src={startUpIdea} className="mb-[60px]" />
                  <p className="text-gray-400 font-normal text-center w-full ">
                    Chưa có dữ liệu
                  </p>
                </div>
              ) : (
                <ChartPie
                  key={widthScreen}
                  data={dataSource?.receipt}
                  plugins={['data-labels']}
                  options={cashReceiptOptions}
                />
              )}
            </div>
          </Spin>
          <Spin spinning={isRefetching}>
            <div className="flex w-[40%] items-center">
              {!isRefetching && !checkData(dataSource?.expenseVoucher) ? (
                <div className="flex flex-col">
                  <p className="text-gray-500 font-medium text-center w-full ">
                    Chi
                  </p>
                  <img src={startUpIdea} className="mb-[60px]" />
                  <p className="text-gray-400 font-normal text-center w-full ">
                    Chưa có dữ liệu
                  </p>
                </div>
              ) : (
                <ChartPie
                  key={widthScreen}
                  data={dataSource?.expenseVoucher}
                  plugins={['data-labels']}
                  options={expenseVoucherOptions}
                />
              )}
            </div>
          </Spin>
        </div>
      </div>
      {openModal && (
        <ModalChart
          open={openModal}
          onClose={() => setOpenModal(false)}
          type="large"
        />
      )}
      {openSetting && (
        <SettingModal
          open={openSetting}
          onClose={() => setOpenSetting(false)}
          onSubmit={setParams}
          defaultValues={params}
        />
      )}
    </>
  );
};

export default Content;
