import useConfirm from '_common/hooks/useConfirm';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectFieldConfig from '_common/dof/Select/SelectFieldConfig';
import SelectFormConfig from '_common/dof/Select/SelectFormConfig';
import SelectPageConfig from '_common/dof/Select/SelectPageConfig';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Col, Modal, OptionProps, Row, notification } from 'tera-dls';
import FormConfigApi from '../../_api';
import { messageValidate } from '_common/constants/message';

interface IDetailEmployeeGroup {
  isOpen?: boolean;
  handleClose?: () => void;
}
interface IForm {
  page: string;
  form: any[];
  field: any[];
}

const CopyForm = ({ isOpen, handleClose }: IDetailEmployeeGroup) => {
  const defaultValues = {
    page: '',
    form: [],
    field: [],
  };
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const { pageId } = useParams();
  const [pageSelected, setPageSelected] = useState<OptionProps>();
  const [formSelected, setFormSelected] = useState<OptionProps[]>([]);
  const [fieldSelected, setFieldSelected] = useState<OptionProps[]>([]);
  const [paramsListForm, setParamsListForm] = useState({});
  const [paramsListField, setParamsListField] = useState({});

  const form = useForm<IForm>({
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    setValue,
    formState: { isDirty },
    watch,
  } = form;
  const watchPage = watch('page');
  const watchForm = watch('form');

  const handleResetForm = () => {
    reset();
  };

  const handleCancel = () => {
    handleClose();
    handleResetForm();
    queryClient.setQueryData(['employee_detail_leave'], null);
  };

  const { mutate: handleMutation, isLoading } = useMutation(
    (variables: any) => {
      return FormConfigApi.cloneData(variables);
    },
    {
      onSuccess: (res) => {
        if (res.code === 200) {
          handleCancel();
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['list-form-config']);
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleClearPage = () => {
    setFormSelected([]);
    setValue('form', []);
  };

  const handleSubmitForm = (valueForm: IForm) => {
    const convertData = {
      page_id: Number(pageId), // Trang Cần Được Thêm Data
      page_target_id: Number(valueForm?.page), // Trang chứa data để lấy
      form_selected: valueForm?.form.includes('all') ? 'all' : valueForm?.form,
      field_selected: valueForm?.field.includes('all')
        ? 'all'
        : valueForm?.field,
    };
    handleMutation(convertData);
  };

  const onExitModal = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        onOk: () => {
          handleCancel();
        },
        content: (
          <>
            <p>Bạn có chắc muốn thoát bản ghi này không?</p>
            <p>Sau khi thoát dữ liệu sẽ không được lưu.</p>
          </>
        ),
      });
      return;
    }
    handleCancel();
  };

  return (
    <>
      <Modal
        centered={true}
        title={'Sao chép cấu hình form dữ liệu'}
        open={isOpen}
        onCancel={onExitModal}
        onOk={handleSubmit(handleSubmitForm)}
        closeIcon={false}
        okText="Lưu"
        cancelText="Huỷ"
        className="sm:w-[50%] md:w-[50%] lg:w-[25%]"
        confirmLoading={isLoading}
        destroyOnClose
      >
        <FormTera className="overflow-auto h-full" form={form}>
          <Row className="gap-x-10">
            <Col>
              <FormTeraItem
                name="page"
                label="Chọn trang nguồn"
                rules={[{ required: messageValidate.emptySelect }]}
              >
                <SelectPageConfig
                  paramsApi={{ page_id: pageId }}
                  onClear={handleClearPage}
                  selectedValue={pageSelected}
                  onChangeCustom={(value: OptionProps) => {
                    setPageSelected(value);
                    setParamsListForm({ page_id: Number(value?.value) });
                    handleClearPage();
                  }}
                />
              </FormTeraItem>
            </Col>
            <Col>
              <FormTeraItem
                name="form"
                label="Chọn cấu hình form"
                rules={[{ required: messageValidate.emptySelect }]}
              >
                <SelectFormConfig
                  mode="multiple"
                  isCheckAll
                  selectedValue={formSelected}
                  onClear={() => setFormSelected([])}
                  disabled={!watchPage}
                  paramsApi={paramsListForm}
                  onChangeCustom={(selected: OptionProps[]) => {
                    setFormSelected(selected);
                    const listId = selected.map((select) => select.value);
                    const checkParam = listId.includes('all')
                      ? {
                          form_ids: 'all',
                          page_id: watchPage,
                        }
                      : {
                          form_ids: String(listId),
                          page_id: watchPage,
                        };
                    setParamsListField(checkParam);
                  }}
                />
              </FormTeraItem>
            </Col>
            <Col>
              <FormTeraItem name="field" label="Chọn cấu hình trường dữ liệu">
                <SelectFieldConfig
                  mode="multiple"
                  isCheckAll
                  selectedValue={fieldSelected}
                  onClear={() => setFieldSelected([])}
                  disabled={!watchForm.length}
                  paramsApi={paramsListField}
                  onChangeCustom={(selected: OptionProps[]) => {
                    console.log('selected', selected);
                    setFieldSelected(selected);
                  }}
                />
              </FormTeraItem>
            </Col>
          </Row>
        </FormTera>
      </Modal>
    </>
  );
};

export default CopyForm;
