import UserTable from 'pages/User/containers/ListUser/UserTable';

function User({ businessId }) {
  return (
    <UserTable
      businessId={businessId}
      enableTab={false}
      createOnTitle={true}
      createProps={{
        defaultValues: { business_id: businessId },
        disableFields: ['business_id'],
        disableToggle: true,
      }}
    />
  );
}

export default User;
