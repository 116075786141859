import { useQuery } from '@tanstack/react-query';
import CardForm from '_common/component/CardForm';
import { EmployeeText } from '_common/component/EmployeeText';
import { messageError } from '_common/constants/message';
import UploadFiles from '_common/dof/UploadFiles';
import { useEffect, useState } from 'react';
import { Description, Modal, Spin, notification } from 'tera-dls';
import DataTypeApi from '../../_api';

function DataTypeDetail({ open, onClose, id }) {
  const {
    data: dataDetail,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ['get-detail-data-status', id],
    () => DataTypeApi.getDetail(id),
    {
      enabled: !!id,
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const [listFile, setListFile] = useState([]);

  const renderDetail = [
    {
      title: 'Mã trạng thái',
      value: dataDetail?.concatenated_key,
    },
    {
      title: 'Loại trạng thái',
      value: dataDetail?.objtype_key,
    },
    {
      title: 'Tiêu đề',
      value: dataDetail?.title,
    },
    {
      title: 'Thứ tự',
      value: dataDetail?.order ? Number(dataDetail?.order) : 1,
    },
    {
      title: 'Giá trị',
      value: dataDetail?.value,
    },
    {
      title: 'Màu sắc',
      value: dataDetail?.color,
    },
    {
      title: 'Mô tả',
      value: dataDetail?.description,
    },
    {
      title: 'Người tạo',
      value: (
        <EmployeeText
          code={dataDetail?.created_by?.code}
          name={dataDetail?.created_by?.full_name}
        />
      ),
    },
    {
      title: 'Ngày tạo',
      value: dataDetail?.created_by && dataDetail?.created_at,
    },
    {
      title: 'Người cập nhật',
      value: (
        <EmployeeText
          code={dataDetail?.updated_by?.code}
          name={dataDetail?.updated_by?.full_name}
        />
      ),
    },
    {
      title: 'Ngày cập nhật',
      value: dataDetail?.updated_by && dataDetail?.updated_at,
    },
  ];

  useEffect(() => {
    if (id) refetch();
  }, [id]);

  useEffect(() => {
    if (dataDetail && dataDetail?.media_id)
      setListFile([
        {
          id: dataDetail?.media_id,
          url: dataDetail?.file_url,
          name: dataDetail?.file_name,
        },
      ]);
  }, [dataDetail]);

  if (isError) {
    onClose();
    notification.error({ message: messageError.DATA_NOT_FOUND });
  }

  return (
    <Modal
      closeIcon={false}
      open={open}
      title="CHI TIẾT CẤU HÌNH TRẠNG THÁI"
      width={700}
      onOk={onClose}
      okText="Đóng"
      cancelButtonProps={{ className: 'hidden' }}
    >
      <Spin spinning={isLoading}>
        <CardForm title="THÔNG TIN CẤU HÌNH TRẠNG THÁI">
          <div className="grid gap-y-0">
            {renderDetail.map((item, key) => (
              <Description key={key} label={item?.title}>
                {item?.value}
              </Description>
            ))}
          </div>
        </CardForm>
        <CardForm title="FILE ĐÍNH KÈM">
          <UploadFiles fileList={listFile} mode="view" />
        </CardForm>
      </Spin>
    </Modal>
  );
}

export default DataTypeDetail;
