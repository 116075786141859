import _ from 'lodash';
import { PRODUCT_MEMORY_CAPACITY } from 'pages/ServicePackage/constants';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { customTwMerge } from 'tailwind-merge.config';
import {
  Button,
  InputNumber,
  MinusOutlined,
  PlusSolid,
  Tooltip,
} from 'tera-dls';
import { CartContext } from './EditableRow';

const EditableCell = ({
  children,
  className,
  dataIndex,
  record,
  onChangeQuantity,
  isView = false,
  minNumber = 1,
  hiddenLength = 0,
  isCart = false,
  ...restProps
}) => {
  const [amount, setAmount] = useState<number>();

  const maxNumber = 99;
  const { index } = useContext(CartContext);

  const ref = useRef(null);

  useEffect(() => {
    setAmount(record?.quantity);
  }, [record?.quantity]);

  const handleDecrease = useCallback(
    _.debounce(
      () =>
        setAmount((prev) => {
          let result = prev;
          if (prev !== minNumber) {
            result = prev - 1;
          }
          onChangeQuantity && onChangeQuantity(record?.id, result);
          return result;
        }),
      100,
    ),
    [onChangeQuantity, setAmount],
  );

  const handleIncrease = useCallback(
    _.debounce(
      () =>
        setAmount((prev) => {
          let result = prev;
          if (prev !== maxNumber) {
            result = prev + 1;
          }
          onChangeQuantity && onChangeQuantity(record?.id, result);
          return result;
        }),
      100,
    ),
    [onChangeQuantity, setAmount],
  );

  if (index === 1) {
    if (
      Object.keys(restProps?.style)?.length === 0 &&
      restProps?.title === undefined
    ) {
      return children;
    }
    if (!dataIndex || dataIndex === 'action') {
      return (
        <td {...restProps} className={customTwMerge(className, '!p-0 h-0')}>
          <div className="py-3 bg-[#FAFAF9] px-3 flex items-center">
            <div className="min-h-[21px] flex -ml-1">{children}</div>
          </div>
        </td>
      );
    }
    if (dataIndex === 'package') {
      return (
        <td
          {...restProps}
          className={customTwMerge(className, '!p-0 h-0')}
          colSpan={6 - hiddenLength - (isCart ? 1 : 0)}
        >
          <div className="h-full flex flex-col">
            <div
              className="py-3 bg-[#FAFAF9] px-3 flex gap-[13px] items-center h-auto"
              ref={ref}
            >
              {record?.package?.service?.file_url ? (
                <img
                  src={record?.package?.service?.file_url}
                  className="w-[20px] h-[20px] rounded-[4px]"
                />
              ) : (
                <span className="bg-[#F8285A] text-base leading-[21px] text-white px-[5px] rounded-[4px]">
                  {record?.package?.service?.name?.at(0) ??
                    record?.service_name_original?.at(0)}
                </span>
              )}
              <span className="text-[14px] leading-[22px] font-semibold line-clamp-3 h-full flex items-center">
                {record?.package?.service?.name ??
                  record?.service_name_original}
              </span>
            </div>
          </div>
        </td>
      );
    }
    return;
  }
  if (
    Object.keys(restProps?.style)?.length === 0 &&
    restProps?.title === undefined
  ) {
    return <td {...restProps} className="border-b border-gray-200" />;
  }

  if (!dataIndex || dataIndex === 'action') {
    return <td {...restProps} className="border-b border-gray-200" />;
  }

  if (dataIndex === 'package') {
    return (
      <td
        {...restProps}
        className={customTwMerge(className, '!p-0 h-0 !bg-white')}
      >
        <div className="h-full flex flex-col">
          <div className=" min-h-[56px] flex items-center px-3 text-blue-600 font-medium py-1 leading-[19px]">
            <Tooltip
              placement="top-start"
              title={
                <div className="grid gap-1.5">
                  <div>
                    Số lương người sử dụng:{' '}
                    {record?.package?.quantity_user ||
                      record?.quantity_user ||
                      0}
                  </div>
                  <div>
                    Dung lượng:{' '}
                    {PRODUCT_MEMORY_CAPACITY[
                      record?.package?.quantity_capacity
                    ] ||
                      record?.quantity_capacity ||
                      0}
                  </div>
                  <div>
                    Số lượng đơn:{' '}
                    {record?.package?.quantity_order ||
                      record?.quantity_order ||
                      0}
                  </div>
                  <div>
                    Thời gian: {record?.package?.time || record?.time || 0}{' '}
                    tháng
                  </div>
                </div>
              }
            >
              <span>
                {record?.package?.name ?? record?.package_name_original}
              </span>
            </Tooltip>
          </div>
        </div>
      </td>
    );
  }
  if (dataIndex === 'quantity' && !isView) {
    return (
      <td
        {...restProps}
        className={customTwMerge(className, '!p-0 h-0 !bg-white')}
      >
        <div className="h-full flex flex-col ">
          <div className="flex-1 flex justify-start items-center">
            <div className="py-2.5 px-3 flex gap-2">
              <Button
                type="alternative"
                className="w-[35px] p-1 text-blue-400 focus:!ring-transparent"
                onClick={handleDecrease}
                disabled={amount === minNumber}
              >
                <MinusOutlined className="w-4" />
              </Button>
              <InputNumber
                min={minNumber}
                maxLength={2}
                max={99}
                value={amount}
                onChange={(e: any) => {
                  setAmount(e);
                }}
                onBlur={() =>
                  setTimeout(
                    () =>
                      onChangeQuantity && onChangeQuantity(record?.id, amount),
                    10,
                  )
                }
                allowStepHandler={false}
                className="w-[35px] pr-2 pl-2 text-center"
              />
              <Button
                className="w-[35px] p-1 text-blue-400 focus:!ring-transparent"
                type="alternative"
                onClick={handleIncrease}
                disabled={amount === maxNumber}
              >
                <PlusSolid className="w-4" />
              </Button>
            </div>
          </div>
        </div>
      </td>
    );
  }
  return (
    <td
      {...restProps}
      className={customTwMerge(className, '!p-0 h-0 !bg-white')}
    >
      <div className="h-full flex flex-col">
        <div className="py-2.5 px-3 flex-1 flex items-center">{children}</div>
      </div>
    </td>
  );
};

export default EditableCell;
