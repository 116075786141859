import { adminEndpoint } from '_common/constants/common';
import api from 'states/drivers';
export { default as ObjectTypeApi } from './object_type';

const DataTypeEndpoint = `${adminEndpoint}/administrator/data-type`;

const DataTypeApi = {
  getList: async (param?) =>
    await api
      .get(`${DataTypeEndpoint}/list`, param)
      .then((result) => result?.data?.data),
  getDetail: async (id: string | number) =>
    await api
      .get(`${DataTypeEndpoint}/detail/${id}`)
      .then((result) => result.data?.data),
  create: async (param?) =>
    await api
      .post(`${DataTypeEndpoint}/create`, param)
      .then((result) => result.data),
  update: async (id: string | number, param?) =>
    await api
      .put(`${DataTypeEndpoint}/update/${id}`, param)
      .then((result) => result.data),
  delete: async (id: string | number) =>
    await api
      .delete(`${DataTypeEndpoint}/delete/${id}`)
      .then((result) => result.data),
  export: async (params) =>
    await api
      .post(`${DataTypeEndpoint}/export`, params)
      .then((result) => result.data),
  import: async (params) =>
    await api
      .post(`${DataTypeEndpoint}/import`, params)
      .then((result) => result.data),
};

export default DataTypeApi;
