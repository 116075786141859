import { ArrowsPointingInOutlined, Button, Modal, Tooltip } from 'tera-dls';
import Content from './Content';

interface IProps {
  open: boolean;
  onClose?: () => void;
  data: any;
}
const ModalChart = (props: IProps) => {
  const { onClose, open, data } = props;
  return (
    <Modal
      title={''}
      destroyOnClose
      closeIcon={false}
      closable
      className="modal-fullscreen"
      onCancel={onClose}
      width={1000}
      open={open}
      centered={true}
      footer={<Button onClick={onClose}>Đóng</Button>}
    >
      <div className="h-full">
        <div className="flex justify-end mr-2">
          <Tooltip title="Thu gọn">
            <span className="flex items-center">
              <ArrowsPointingInOutlined
                className="w-4 h-4 cursor-pointer"
                onClick={onClose}
              />
            </span>
          </Tooltip>
        </div>
        <Content data={data} />
      </div>
    </Modal>
  );
};

export default ModalChart;
