import Filter from '_common/component/Filter';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectBusinessId from '_common/dof/Select/SelectBusinessId';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface IProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue: any;
}

function ConfigPermissionFilter({
  open,
  onClose,
  onFilter,
  initialValue,
}: IProps) {
  const form = useForm();

  useEffect(() => {
    if (initialValue) {
      form.reset({
        ...initialValue,
      });
    }
  }, [initialValue]);

  const handleSubmitForm = (values) => {
    onFilter(values);
    onClose();
  };

  const handleReset = () => {
    const values = {
      business_id: null,
      is_default: null,
    };
    onFilter(values);
    onClose();
  };

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={handleSubmitForm} isLoading={false}>
        <FormTeraItem label="Business" name="business_id">
          <SelectBusinessId
            placeholder="Vui lòng chọn"
            paramsApi={{ include_id: form.watch('business_id') }}
          />
        </FormTeraItem>
        <FormTeraItem label="Mặc định" name="is_default">
          <Select
            placeholder="Vui lòng chọn"
            options={[
              { label: 'Có', value: 1 },
              { label: 'không', value: 0 },
            ]}
          />
        </FormTeraItem>

        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default ConfigPermissionFilter;
