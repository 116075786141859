import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { messageWarning } from '_common/constants/message';

import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { REGEX } from '_common/constants/common';
import useConfirm from '_common/hooks/useConfirm';
import { useForm } from 'react-hook-form';
import { AuthApi } from 'states/api';
import { Form, FormItem, InputPassword, Modal, notification } from 'tera-dls';
import * as yup from 'yup';

interface IProps {
  open: boolean;
  id?: number;
  onClose: () => void;
  onChangeDataSuccess?: () => void;
}

interface IForm {
  password: string;
  confirm_password: string;
}

const schema = yup.object().shape({
  password: yup
    .string()
    .required('Vui lòng nhập trường này!')
    .min(8, 'Mật khẩu tối thiểu 8 kí tự')
    .max(16, 'Không nhập quá 16 ký tự!')
    .matches(REGEX.STRING_NOT_SPACE, 'Mật khẩu không chứa khoảng trắng'),
  confirm_password: yup
    .string()
    .required('Vui lòng nhập trường này!')
    .oneOf([yup.ref('password')], 'Nhập lại mật khẩu phải giống mật khẩu')
    .min(8, 'Mật khẩu tối thiểu 8 kí tự')
    .max(16, 'Không nhập quá 16 ký tự!')
    .matches(REGEX.STRING_NOT_SPACE, 'Mật khẩu không chứa khoảng trắng'),
});

const UserFormResetPassword = (props: IProps) => {
  const { open = false, id, onClose } = props;

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<IForm>({
    resolver: yupResolver<IForm>(schema),
    mode: 'onChange',
  });
  const confirm = useConfirm();

  const resetPassword = useMutation(
    (params: any) => AuthApi.resetPassword(params),
    {
      onSuccess: (res) => {
        if (res.code === 200) {
          notification.success({
            message: res?.msg,
          });
          onClose();
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleClose = (): void => {
    reset();
    onClose();
  };

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        className: 'modal-button-center',
        onOk: () => {
          handleClose();
        },
      });
    } else {
      handleClose();
    }
  };

  const handleSubmitForm = (values): void => {
    const params = {
      ...values,
      user_id: id,
    };
    resetPassword.mutate(params);
  };

  return (
    <Modal
      centered={true}
      title="Đặt lại mật khẩu"
      open={open}
      width={500}
      closeIcon={false}
      okText="Lưu"
      cancelText="Huỷ"
      onOk={() => handleSubmit(handleSubmitForm)()}
      onCancel={handleCloseConfirm}
      destroyOnClose={true}
    >
      <Form className="h-full" onSubmit={handleSubmit(handleSubmitForm)}>
        <FormItem
          label="Mật khẩu mới"
          isError={!!errors?.password}
          messages={errors?.password?.message as string}
        >
          <InputPassword
            {...register('password')}
            placeholder="Vui lòng nhập"
            className="w-full"
          />
        </FormItem>
        <FormItem
          label="Nhập lại mật khẩu"
          isError={!!errors?.confirm_password}
          messages={errors?.confirm_password?.message as string}
        >
          <InputPassword
            {...register('confirm_password')}
            placeholder="Vui lòng nhập"
            className="w-full"
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default UserFormResetPassword;
