import { useMutation, useQueryClient } from '@tanstack/react-query';
import HoverQuickView from '_common/component/HoverQuickView';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import useConfirm from '_common/hooks/useConfirm';
import moment from 'moment';
import { useState } from 'react';
import {
  DropdownItem,
  TableProps,
  formatCurrency,
  notification,
} from 'tera-dls';
import ExpenseVoucherApi from '../../api';
import ExpenseVoucherDetail from '../Detail';
import { usePermission } from '_common/hooks/usePermission';
import { PERMISSION_KEY } from '../../permission';
import { EXPENSE_VOUCHER_URL } from '../../url';
import { useNavigate } from 'react-router-dom';

type Button = 'detail' | 'update' | 'delete';
interface IProps extends TableProps {
  activeButton?: Array<Button>;
  [key: string]: any;
}

const ExpenseVoucherTable = (props: IProps) => {
  const {
    activeButton = ['detail', 'update', 'delete'],
    data = [],
    ...restProps
  } = props;

  const navigate = useNavigate();
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const { hasPage } = usePermission();

  const [detailModel, setDetailModel] = useState<any>({ open: false });

  const { mutate: mutateDelete } = useMutation(
    (variables: any) => ExpenseVoucherApi.delete(variables),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-expense-voucher-list']);
          queryClient.invalidateQueries(['get-expense-voucher-statistic']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleDelete = (item: any) => {
    confirm.warning({
      title: 'Xóa phiếu chi',
      content: (
        <>
          <p>Bạn có chắc muốn xoá phiếu chi</p>
          <p>
            <span className="font-bold break-word">
              {`${item?.code ?? ''}`}{' '}
            </span>
            này không?
          </p>
        </>
      ),
      onOk: () => {
        mutateDelete({ id: item?.id });
      },
    });
  };

  const generateDropDownItems = (record: any): DropdownItem[] => {
    const dropdownItems: DropdownItem[] = [];
    hasPage(PERMISSION_KEY.EXPENSE_VOUCHER_VIEW_EXPENSE_VOUCHER_DETAIL) &&
      activeButton?.includes('detail') &&
      dropdownItems.push({
        key: 1,
        label: <a onClick={() => record}>Xem</a>,
        onClick: () => {
          setDetailModel({ open: true, value: record.id });
        },
      });
    hasPage(PERMISSION_KEY.EXPENSE_VOUCHER_UPDATE_EXPENSE_VOUCHER) &&
      activeButton?.includes('update') &&
      dropdownItems.push({
        key: 2,
        label: <a onClick={() => 1}>Sửa</a>,
        onClick: () => {
          navigate(`${EXPENSE_VOUCHER_URL.update.path}/${record?.id}`);
        },
      });
    hasPage(PERMISSION_KEY.EXPENSE_VOUCHER_DELETE_EXPENSE_VOUCHER) &&
      activeButton?.includes('delete') &&
      dropdownItems.push({
        key: 3,
        label: <a className="text-red-500">Xóa</a>,
        onClick: () => handleDelete(record),
      });

    return dropdownItems;
  };

  const columns: any = [
    {
      title: 'Mã phiếu chi',
      dataIndex: 'code',
      width: 100,
      render: (val) => (
        <span className="line-clamp-2 leading-[15px]">{val}</span>
      ),
    },
    {
      title: 'Người nộp',
      dataIndex: 'submitter',
      width: 150,
      render: (value) => (
        <HoverQuickView
          avatarUrl={value?.avatar_url}
          email={value?.email}
          phone={value?.phone}
          name={value?.full_name}
          sub={value?.customer_type_text?.title}
          code={value?.code}
        >
          <span className="line-clamp-2">{value?.full_name ?? ''}</span>
        </HoverQuickView>
      ),
    },
    {
      title: 'Phương thức thanh toán',
      dataIndex: 'pay_method_type_text',
      width: 150,
      render: (value) => <span className="line-clamp-2">{value ?? ''}</span>,
    },
    {
      title: 'Loại phiếu chi',
      dataIndex: 'ballot_type_text',
      width: 150,
      render: (val) => <span className="line-clamp-2 ">{val}</span>,
    },

    {
      title: 'Ngày phiếu chi',
      dataIndex: 'date',
      width: 150,
      render: (val) => (val ? moment(val).format(DATE_TIME_FORMAT) : null),
    },
    {
      title: 'Tổng giá trị',
      dataIndex: 'total_amount',
      width: 150,
      render: (value) => formatCurrency(value),
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      width: 150,
      render: (value) => <span className="line-clamp-2 ">{value}</span>,
    },
    {
      title: '',
      dataIndex: 'operation',
      width: 46,
      fixed: 'right',
      render: (_, record) => (
        <ActionDropdown
          dropdownItems={generateDropDownItems(record)}
          trigger="click"
          containerClassName="w-[50px]"
          placement="bottom-end"
        />
      ),
    },
  ];

  return (
    <>
      <TableTera rowKey={'id'} {...restProps} data={data} columns={columns} />
      {detailModel?.open && (
        <ExpenseVoucherDetail
          open={detailModel?.open}
          onClose={() => setDetailModel({ open: false })}
          value={detailModel?.value}
        />
      )}
    </>
  );
};

export default ExpenseVoucherTable;
