import { useMutation } from '@tanstack/react-query';
import DefaultImage from '_common/component/DefaultImage';
import { useStores } from '_common/hooks';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { AuthApi } from 'states/api';
import {
  ArrowRightOutlined,
  EnvelopeOutlined,
  FolderOpenOutlined,
  Popover,
  UserOutlined,
  notification,
} from 'tera-dls';
import { ReactComponent as IconExit } from 'styles/images/Icons/IconExit.svg';

const User = observer(() => {
  const {
    authStore: { user, clear },
  } = useStores();

  const currentUser = user;

  const { mutate: onLogout } = useMutation(AuthApi.logout, {
    onSuccess: () => {
      clear();
    },
    onError: (error: any) => {
      clear();
      const errorMessage = error?.msg;

      notification.error({
        message: errorMessage,
      });
    },
  });

  const dropdownItems = [
    {
      key: 'myInfo',
      icon: <UserOutlined />,
      label: <Link to="/profit">Thông tin của tôi</Link>,
    },
    {
      key: 'password',
      icon: <FolderOpenOutlined />,
      label: <a href="#">Đổi mật khẩu</a>,
    },
    {
      key: 'history',
      icon: <FolderOpenOutlined />,
      label: <a href="#">Lịch sử login</a>,
    },
    {
      key: 'logout',
      icon: <ArrowRightOutlined className="text-red-600" />,
      label: (
        <span onClick={() => onLogout()} className="text-red-600">
          Đăng xuất
        </span>
      ),
    },
  ];

  console.log(dropdownItems);

  const Content = () => {
    const classItem =
      'p-[6px] [&:not(:last-child)]:border-b hover:bg-gray-50 cursor-pointer';
    return (
      <div className="p-2.5 rounded-2.5 w-[200px] flex flex-col gap-y-5">
        <div className="flex flex-col gap-y-[14px]">
          <h3 className="text-blue-800">Tài khoản</h3>
          <div className="flex flex-col gap-y-[7px]">
            <div className="flex items-center gap-x-[7px]">
              <DefaultImage
                src={currentUser?.avatar_url}
                alt={currentUser?.full_name}
                className="w-10 h-10 rounded-full border"
              />
              <div className="flex flex-col justify-center gap-x-[5px]">
                <span className="text-blue-500 truncate">
                  {currentUser?.full_name}
                </span>
                <span className="text-[10px] text-gray-500 truncate">
                  {currentUser?.role_name}
                </span>
              </div>
            </div>
            <p className="flex items-center gap-x-[5px]">
              <EnvelopeOutlined className="w-4 h-4" />
              <span className="truncate">{currentUser?.email}</span>
            </p>
          </div>
        </div>
        <div>
          <h3 className="text-blue-800 mb-[5px]">Khác</h3>
          <ul>
            <li className={classItem}>Đổi mật khẩu</li>
            <li className={classItem}>Lịch sử login</li>
          </ul>
        </div>
        <div>
          <p
            className="flex gap-x-[5px] text-red-500 itemns-center p-[6px] hover:bg-gray-50 cursor-pointer"
            onClick={() => onLogout()}
          >
            <IconExit />
            <span>Thoát</span>
          </p>
        </div>
      </div>
    );
  };

  return (
    <Popover trigger="click" content={<Content />} placement="bottom-end">
      <div
        className="cursor-pointer flex flex-col p-4 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:p-0 md:flex-row md:gap-2.5 md:items-center md:mt-0 md:border-0 md:bg-transparent dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700"
        id="user-menu-button"
        data-dropdown-placement="bottom"
        aria-expanded="false"
      >
        <img
          src={user?.avatar_url}
          className="object-cover w-6 h-6 rounded-full"
          alt="avatar-user"
        />
      </div>
    </Popover>
  );
});

export default User;
