import { useMutation, useQueryClient } from '@tanstack/react-query';
import Button from '_common/component/Button';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { messageWarning } from '_common/constants/message';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectServicePackage from '_common/dof/Select/SelectServicePackage';
import SelectServicePackageProduct from '_common/dof/Select/SelectServicePackageProduct';
import useConfirm from '_common/hooks/useConfirm';
import ServicePackageApi from 'pages/ServicePackage/_api';
import { TYPE_SERVICE } from 'pages/ServicePackage/constants';
import { useForm } from 'react-hook-form';
import { Modal, notification } from 'tera-dls';

interface IProps {
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  value: number;
}

const ServiceCreation = (props: IProps) => {
  const { open, onClose, value: id } = props;
  const confirm = useConfirm();
  const form = useForm({ mode: 'onChange' });
  const queryClient = useQueryClient();

  const { mutate: mutateAction } = useMutation(
    (variables: any) => ServicePackageApi.addToBusiness({ ...variables }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-service-package-product-list']);
          queryClient.invalidateQueries(['get-service-package-business-list']);
          queryClient.invalidateQueries(['get-business-summary']);
          onClose();
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleCloseConfirm = () => {
    if (form.formState.isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
    } else onClose();
  };

  const handleSubmitForm = (values) => {
    mutateAction({
      params: {
        ...values,
        business_id: id,
      },
    });
  };

  const handleSubmitFormAndActive = (values) => {
    mutateAction({
      params: {
        ...values,
        business_id: id,
        save_and_active: 1,
      },
    });
  };

  const typeOptions = Object.entries(TYPE_SERVICE).map(([key, value]) => ({
    label: value,
    value: key,
  }));

  return (
    <Modal
      title={'Thêm gói dịch vụ'}
      destroyOnClose
      closeIcon={false}
      width={600}
      open={open}
      centered={true}
      footer={
        <div className="flex gap-2.5">
          <Button onClick={handleCloseConfirm} type="alternative">
            Hủy
          </Button>
          <Button onClick={form.handleSubmit(handleSubmitForm)}>Lưu</Button>
          <Button onClick={form.handleSubmit(handleSubmitFormAndActive)}>
            Lưu và Kích hoạt
          </Button>
        </div>
      }
    >
      <FormTera form={form} onSubmit={handleSubmitForm}>
        <FormTeraItem
          name={'service_type'}
          label="Loại gói dịch vụ"
          rules={[{ required: 'Vui lòng chọn' }]}
        >
          <Select
            placeholder="Vui lòng chọn"
            options={typeOptions}
            onChangeCustom={() => {
              form.setValue('service_id', null);
              form.setValue('package_id', null);
            }}
          />
        </FormTeraItem>
        <FormTeraItem
          name={'service_id'}
          label="Gói dịch vụ"
          rules={[{ required: 'Vui lòng chọn' }]}
        >
          <SelectServicePackage
            paramsApi={{
              include_id: form.watch('service_id'),
              type: form.watch('service_type'),
              show_default: 1,
            }}
            disabled={!form.watch('service_type')}
            onChangeCustom={() => {
              form.setValue('package_id', null);
            }}
          />
        </FormTeraItem>
        <FormTeraItem
          name={'package_id'}
          label="Gói sản phẩm"
          rules={[{ required: 'Vui lòng chọn' }]}
        >
          <SelectServicePackageProduct
            paramsApi={{
              include_id: form.watch('package_id'),
              service_id: form.watch('service_id'),
              business_id: id,
              // business_action_type: 'create',
            }}
            disabled={!form.watch('service_id')}
          />
        </FormTeraItem>
      </FormTera>
    </Modal>
  );
};

export default ServiceCreation;
