import { useQuery } from '@tanstack/react-query';
import { useDebounce } from '_common/hooks/useDebounce';
import { TParamsApi } from '_common/interface/api';
import ServicePackageApi from 'pages/ServicePackage/_api';
import { forwardRef, useMemo, useState } from 'react';
import { OptionProps } from 'tera-dls';
import { SelectProps, ValueType } from 'tera-dls/lib/components/Select';
import SelectEntity from '../SelectEntity';

interface IProps extends SelectProps {
  selectedValue?: ValueType[] | ValueType;
  placeholder?: string;
  paramsApi?: TParamsApi;
}

const SelectServicePackage = forwardRef<HTMLInputElement, IProps>(
  (
    { selectedValue, placeholder = 'Vui lòng chọn', paramsApi, mode, ...props },
    ref,
  ) => {
    const [search, setSearch] = useState('');
    const searchDebounce = useDebounce(search, 300);

    const { data: response } = useQuery(
      ['get-service-package-list', searchDebounce, paramsApi],
      () =>
        ServicePackageApi.getList({
          params: {
            page: 1,
            limit: 10,
            keyword: searchDebounce,
            ...paramsApi,
          },
        }),
      {
        staleTime: 300000,
        cacheTime: 300000,
      },
    );

    const options: OptionProps[] = useMemo(() => {
      const options = response?.data?.map((item) => ({
        label: `${item.code} - ${item?.name}`,
        value: item?.id,
      }));
      return options;
    }, [response, mode]);

    return (
      <SelectEntity
        ref={ref}
        allowClear
        showSearch
        labelInValue
        placeholder={placeholder}
        options={options}
        searchValue={search}
        selectedValue={selectedValue}
        onSearch={setSearch}
        {...props}
      />
    );
  },
);

export default SelectServicePackage;
