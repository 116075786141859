import {
  CONFIG_DATA_URL,
  CONFIG_PERMISSION_URL,
  CONFIG_STATUS_URL,
  GROUP_CONTROL_URL,
  MODULE_URL,
} from '_common/constants/url';
import { IRouteProps } from '_common/interface/router';
import ConfigStatus from './containers/ConfigStatus';
import ConfigDataType from './containers/ConfigDataType';
import ConfigPermission from './containers/ConfigPermission';
import SettingConfigPermission from './containers/ConfigPermission/containers/Setting';
import ModulePage from './containers/ListModule';
import GroupControlPage from './containers/ListGroupControl';
import ManagePage from './containers/ManagePage/ListPage';
import { MANAGE_EPIC_URL } from './containers/ListEpic/constants/url';
import EpicPage from './containers/ListEpic';
import {
  MANAGE_PAGE_CONFIG_FORM_URL,
  MANAGE_PAGE_URL,
} from './containers/ManagePage/constants/url';
import ManagePageDetail from './containers/ManagePage/ListPage/containers/Detail';
import ColumnConfig from './containers/ManagePage/ColumnConfig';
import TableConfigOverview from './containers/ManagePage/TableConfig';
import TableConfigDetail from './containers/ManagePage/TableConfig/container/Detail';
import FormConfig from './containers/ManagePage/FormConfig';
import FormConfigDetail from './containers/ManagePage/FormConfig/containers/Detail';
import FieldConfig from './containers/ManagePage/FormConfig/containers/FieldConfig';
import ControlConfig from './containers/ManagePage/ControlConfig';
import { CONFIG_PAYMENT_METHOD_URL } from './containers/ConfigPaymentMethod/url';
import PaymentMethod from './containers/ConfigPaymentMethod';
import { CONFIG_AFFILIATES_URL } from './containers/ConfigAffiliates/url';
import AffiliatesSummaryReport from './containers/ConfigAffiliates/index';
import ListReferer from './containers/ConfigAffiliates/containers/ListReferrer';
import PaymentMethodForm from './containers/ConfigPaymentMethod/containers/Form';
import ReferrerDetail from './containers/ConfigAffiliates/containers/ListReferrer/detail';
import TransactionHistory from './containers/ConfigPaymentMethod/containers/TransactionHistory';

export const ConfigDataTypeRouter: IRouteProps[] = [
  {
    key: CONFIG_DATA_URL.list.key,
    path: CONFIG_DATA_URL.list.shortenUrl,
    component: <ConfigDataType />,
  },
];

export const ConfigStatusRouter: IRouteProps[] = [
  {
    key: CONFIG_STATUS_URL.list.key,
    path: CONFIG_STATUS_URL.list.shortenUrl,
    component: <ConfigStatus />,
  },
];

export const ConfigPermissionRouter: IRouteProps[] = [
  {
    key: CONFIG_PERMISSION_URL.list.key,
    path: CONFIG_PERMISSION_URL.list.shortenUrl,
    component: <ConfigPermission />,
  },
  {
    key: CONFIG_PERMISSION_URL.setting.key,
    path: CONFIG_PERMISSION_URL.setting.shortenUrl,
    component: <SettingConfigPermission />,
  },
];

export const ManagePageRouter: IRouteProps[] = [
  {
    key: MANAGE_PAGE_URL.list.key,
    path: MANAGE_PAGE_URL.list.shortenUrl,
    component: <ManagePage />,
  },
  {
    key: MANAGE_PAGE_URL.detail.key,
    path: MANAGE_PAGE_URL.detail.shortenUrl,
    component: <ManagePageDetail />,
  },
  {
    key: MANAGE_PAGE_URL.columnConfig.key,
    path: MANAGE_PAGE_URL.columnConfig.shortenUrl,
    component: <ColumnConfig />,
  },
  {
    key: MANAGE_PAGE_URL.tableConfig.key,
    path: MANAGE_PAGE_URL.tableConfig.shortenUrl,
    component: <TableConfigOverview />,
  },
  {
    key: MANAGE_PAGE_URL.tableConfigDetail.key,
    path: MANAGE_PAGE_URL.tableConfigDetail.shortenUrl,
    component: <TableConfigDetail />,
  },
  {
    key: MANAGE_PAGE_CONFIG_FORM_URL.list.key,
    path: MANAGE_PAGE_CONFIG_FORM_URL.list.shortenUrl,
    component: <FormConfig />,
  },
  {
    key: MANAGE_PAGE_CONFIG_FORM_URL.detail.key,
    path: MANAGE_PAGE_CONFIG_FORM_URL.detail.shortenUrl,
    component: <FormConfigDetail />,
  },
  {
    key: MANAGE_PAGE_CONFIG_FORM_URL.configField.key,
    path: MANAGE_PAGE_CONFIG_FORM_URL.configField.shortenUrl,
    component: <FieldConfig />,
  },
  {
    key: MANAGE_PAGE_URL.controlConfig.key,
    path: MANAGE_PAGE_URL.controlConfig.shortenUrl,
    component: <ControlConfig />,
  },
];

export const ManageModuleRouter: IRouteProps[] = [
  {
    key: MODULE_URL.list.key,
    path: MODULE_URL.list.shortenUrl,
    component: <ModulePage />,
  },
];

export const ManageGroupControlRouter: IRouteProps[] = [
  {
    key: GROUP_CONTROL_URL.list.key,
    path: GROUP_CONTROL_URL.list.shortenUrl,
    component: <GroupControlPage />,
  },
];

export const ManageEpic: IRouteProps[] = [
  {
    key: MANAGE_EPIC_URL.list.key,
    path: MANAGE_EPIC_URL.list.shortenUrl,
    component: <EpicPage />,
  },
];
export const ConfigPaymentMethod: IRouteProps[] = [
  {
    key: CONFIG_PAYMENT_METHOD_URL.list.key,
    path: CONFIG_PAYMENT_METHOD_URL.list.shortenUrl,
    component: <PaymentMethod />,
  },
  {
    key: CONFIG_PAYMENT_METHOD_URL.create.key,
    path: CONFIG_PAYMENT_METHOD_URL.create.shortenUrl,
    component: <PaymentMethodForm />,
  },
  {
    key: CONFIG_PAYMENT_METHOD_URL.update.key,
    path: CONFIG_PAYMENT_METHOD_URL.update.shortenUrl,
    component: <PaymentMethodForm />,
  },
  {
    key: CONFIG_PAYMENT_METHOD_URL.transactionHistory.key,
    path: CONFIG_PAYMENT_METHOD_URL.transactionHistory.shortenUrl,
    component: <TransactionHistory />,
  },
];
export const ConfigAffiliates: IRouteProps[] = [
  {
    key: CONFIG_AFFILIATES_URL.summary_report.key,
    path: CONFIG_AFFILIATES_URL.summary_report.shortenUrl,
    component: <AffiliatesSummaryReport />,
  },
  {
    key: CONFIG_AFFILIATES_URL.list_referer.key,
    path: CONFIG_AFFILIATES_URL.list_referer.shortenUrl,
    component: <ListReferer />,
  },
  {
    key: CONFIG_AFFILIATES_URL.list_referer_detail.key,
    path: CONFIG_AFFILIATES_URL.list_referer_detail.shortenUrl,
    component: <ReferrerDetail />,
  },
];
