import { useQuery } from '@tanstack/react-query';
import VerticalBarChart from '_common/component/Chart/BarChart/VerticalBarChart';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import moment from 'moment';

import { mergeAlternate } from '_common/utils';
import {
  CASH_RECEIPT_TYPE,
  EXPENSE_VOUCHER_TYPE,
} from 'pages/Invoice/constants';
import { useContext, useEffect, useMemo, useState } from 'react';
import { customTwMerge } from 'tailwind-merge.config';
import { ChartBarProps, Spin } from 'tera-dls';
import { StatisticCashExpenseParamContext } from '..';
import Header from '../../Header';
import StatisticApi from '../api';
import ModalChart from './Modal';
import SettingModal from './Setting';

export type SaleOfCustomerTypeChartType = 'small' | 'large';
interface IProps {
  type?: SaleOfCustomerTypeChartType;
  onClose?: () => void;
  chartOptions?: ChartBarProps['options'];
  chartClassName?: string;
  defaultLengthShowTick?: number;
  labelMaxLength?: number;
  defaultLengthScroll?: number;
}

const Content = (props: IProps) => {
  const { type = 'small', onClose, chartClassName = '' } = props;
  const [openModal, setOpenModal] = useState<boolean>();
  const [openSetting, setOpenSetting] = useState<boolean>(false);

  const { contextParams } = useContext(StatisticCashExpenseParamContext);

  const currentWeek = [
    moment().startOf('week').format(DATE_BACKEND_FORMAT),
    moment().endOf('week').format(DATE_BACKEND_FORMAT),
  ];

  const [params, setParams] = useState<any>({
    pay_method_type: ['cash', 'transfer'],
    accounting_date: currentWeek,
    date: currentWeek,
    accounting_time: 'currentWeek',
    time: 'currentWeek',
  });

  useEffect(() => {
    contextParams && setParams(contextParams);
  }, [contextParams]);

  const {
    data: response,
    refetch,
    isRefetching,
  } = useQuery(
    ['get-statistic-type', params],
    () =>
      StatisticApi.byType({
        params: {
          ...(params?.pay_method_type?.[0] && {
            pay_method_type: params?.pay_method_type?.join(','),
          }),
          location_id: params?.location_id,
          ...(params?.accounting_date?.[0] && {
            accounting_date: params?.accounting_date?.join(','),
          }),
          ...(params?.date?.[0] && {
            date: params?.date?.join(','),
          }),
        },
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const dataSource = useMemo(() => {
    const merged = mergeAlternate(CASH_RECEIPT_TYPE, EXPENSE_VOUCHER_TYPE);
    const labels = [];
    const data = [];
    const backgroundColor = [];
    Object.entries(merged).forEach(([key, value]) => {
      labels.push(value);
      data.push(response?.[key] ?? 0);
      backgroundColor.push(CASH_RECEIPT_TYPE[key] ? '#31C48D' : '#F98080');
    });
    return {
      labels,
      datasets: [
        {
          data,
          backgroundColor,
        },
      ],
    };
  }, [response]);

  useEffect(() => {
    refetch();
  }, [params]);

  const isSmallType = type === 'small';

  const max = useMemo(() => {
    return Math.max(
      ...([...(dataSource?.datasets?.[0]?.data ?? []), 10] ?? [10]),
    );
  }, [dataSource]);

  return (
    <>
      <div className="rounded-[4px] border-[1px] bg-white flex flex-col h-full overflow-hidden">
        <Header
          title={'Thống kê theo loại thu chi'}
          onClickRefresh={() => refetch()}
          onClickSetting={() => setOpenSetting(true)}
          onZoomOut={() => setOpenModal(true)}
          onZoomIn={() => onClose()}
          isSmallType={isSmallType}
        />
        <Spin spinning={isRefetching}>
          <div className="p-[16px] pb-0 h-full">
            <VerticalBarChart
              containerClassName={
                (customTwMerge('h-auto'), !isSmallType && 'h-full')
              }
              showLegend={false}
              data={dataSource}
              type={type}
              chartClassName={customTwMerge('', chartClassName)}
              defaultLabelMaxLength={30}
              maxX={max}
            />
          </div>
        </Spin>
      </div>
      {openModal && (
        <ModalChart
          open={openModal}
          onClose={() => setOpenModal(false)}
          type="large"
        />
      )}
      {openSetting && (
        <SettingModal
          open={openSetting}
          onClose={() => setOpenSetting(false)}
          onSubmit={setParams}
          defaultValues={params}
        />
      )}
    </>
  );
};

export default Content;
