import React from 'react';

function Menu13(props) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 15.6279C12.0609 15.6279 13.0783 15.2065 13.8284 14.4564C14.5786 13.7062 15 12.6888 15 11.6279C15 10.5671 14.5786 9.54965 13.8284 8.7995C13.0783 8.04936 12.0609 7.62793 11 7.62793C9.93913 7.62793 8.92172 8.04936 8.17157 8.7995C7.42143 9.54965 7 10.5671 7 11.6279C7 12.6888 7.42143 13.7062 8.17157 14.4564C8.92172 15.2065 9.93913 15.6279 11 15.6279ZM16 11.6279C16 12.954 15.4732 14.2258 14.5355 15.1635C13.5979 16.1011 12.3261 16.6279 11 16.6279C9.67392 16.6279 8.40215 16.1011 7.46447 15.1635C6.52678 14.2258 6 12.954 6 11.6279C6 10.3018 6.52678 9.03008 7.46447 8.0924C8.40215 7.15471 9.67392 6.62793 11 6.62793C12.3261 6.62793 13.5979 7.15471 14.5355 8.0924C15.4732 9.03008 16 10.3018 16 11.6279Z"
        fill="#6B7280"
      />
      <path
        d="M9.4385 12.5719C9.4855 13.1679 9.9565 13.6319 10.8015 13.6879V14.1279H11.1765V13.6849C12.0515 13.6239 12.5625 13.1559 12.5625 12.4779C12.5625 11.8599 12.1725 11.5419 11.4725 11.3779L11.1765 11.3079V10.1079C11.5525 10.1509 11.7905 10.3559 11.8475 10.6399H12.5055C12.4585 10.0649 11.9655 9.61593 11.1765 9.56693V9.12793H10.8015V9.57793C10.0545 9.65093 9.5465 10.0999 9.5465 10.7359C9.5465 11.2979 9.9245 11.6559 10.5535 11.8019L10.8015 11.8629V13.1349C10.4175 13.0769 10.1625 12.8649 10.1055 12.5719H9.4385ZM10.7985 11.2179C10.4295 11.1329 10.2295 10.9579 10.2295 10.6959C10.2295 10.4019 10.4455 10.1819 10.8015 10.1179V11.2179H10.7985ZM11.2305 11.9639C11.6795 12.0679 11.8855 12.2359 11.8855 12.5329C11.8855 12.8719 11.6285 13.1039 11.1765 13.1469V11.9519L11.2305 11.9639Z"
        fill="#6B7280"
      />
      <path
        d="M1 0.62793C0.734784 0.62793 0.48043 0.733287 0.292893 0.920823C0.105357 1.10836 0 1.36271 0 1.62793V9.62793C0 9.89315 0.105357 10.1475 0.292893 10.335C0.48043 10.5226 0.734784 10.6279 1 10.6279H5.083C5.14167 10.2833 5.22767 9.94993 5.341 9.62793H3C3 9.0975 2.78929 8.58879 2.41421 8.21372C2.03914 7.83864 1.53043 7.62793 1 7.62793V3.62793C1.53043 3.62793 2.03914 3.41722 2.41421 3.04214C2.78929 2.66707 3 2.15836 3 1.62793H13C13 2.15836 13.2107 2.66707 13.5858 3.04214C13.9609 3.41722 14.4696 3.62793 15 3.62793V7.15593C15.38 7.49593 15.717 7.88393 16 8.30993V1.62793C16 1.36271 15.8946 1.10836 15.7071 0.920823C15.5196 0.733287 15.2652 0.62793 15 0.62793H1Z"
        fill="#6B7280"
      />
      <path
        d="M9.998 5.71072L10 5.62772C9.9998 5.28331 9.91066 4.94479 9.74121 4.64494C9.57177 4.3451 9.32777 4.09409 9.03284 3.91623C8.73791 3.73838 8.40205 3.63969 8.05778 3.62974C7.71352 3.61979 7.37252 3.69891 7.06781 3.85944C6.7631 4.01997 6.50501 4.25646 6.31853 4.54602C6.13205 4.83557 6.02351 5.16838 6.00341 5.5122C5.98331 5.85602 6.05234 6.19921 6.20381 6.50852C6.35529 6.81783 6.58407 7.08278 6.868 7.27772C7.73239 6.45589 8.82211 5.91034 9.998 5.71072Z"
        fill="#6B7280"
      />
    </svg>
  );
}

export default Menu13;
