import { useQuery } from '@tanstack/react-query';
import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  PaginationProps,
  filterField,
  getQueryParams,
  updateQueryParams,
} from 'tera-dls';

import { usePermission } from '_common/hooks/usePermission';
import ServicePackageApi from './_api';
import Searching from './containers/Searching';
import ServicePackageTable from './containers/Table';
import { PERMISSION_KEY } from './permission';
import ServicePackageFilter from './containers/Filter';
import { SERVICE_PACKAGE_URL } from './url';

const ServicePackage = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const { hasPage } = usePermission();
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);

  const { data: response, isFetching } = useQuery(
    ['get-service-package-list', queryParams],
    () =>
      ServicePackageApi.getList({
        params: filterField({
          ...queryParams,
          page: queryParams?.page ?? 1,
          limit: queryParams?.limit ?? 10,
          show_all: 1,
          show_default: 1,
        }),
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const list = response?.data;

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const handleSearch = ({ keyword }) => {
    handleUpdateQueryParams({ keyword, page: 1 });
  };

  const handleFilter = (values: any): void => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    handleUpdateQueryParams({ page: page, limit: pageSize });
  };

  return (
    <>
      <div className="tera-page">
        <HeaderViewListV2
          onClickFilter={() => setOpenFilterModal(true)}
          onClickButtonAdd={() => navigate(SERVICE_PACKAGE_URL.create.path)}
          buttonCreatingKey={PERMISSION_KEY.SERVICE_PACKAGE_CREATE_SERVICE}
          buttonFilterKey={PERMISSION_KEY.SERVICE_PACKAGE_SEARCH_SERVICE}
          title={'DANH SÁCH GÓI DỊCH VỤ'}
          actionLeftRender={
            hasPage(PERMISSION_KEY.SERVICE_PACKAGE_SEARCH_SERVICE) && (
              <Searching onSearch={handleSearch} />
            )
          }
        >
          <ServicePackageTable
            loading={isFetching}
            data={list ?? []}
            pagination={{
              onChange: handleChangePage,
              pageSize: response?.per_page,
              current: response?.current_page,
              total: response?.total,
              from: response?.from,
              to: response?.to,
            }}
          />
        </HeaderViewListV2>
        {openFilterModal && (
          <ServicePackageFilter
            open={openFilterModal}
            onClose={() => setOpenFilterModal(false)}
            onFilter={handleFilter}
            initialValue={queryParams}
          />
        )}
      </div>
    </>
  );
};

export default ServicePackage;
