import { useQuery } from '@tanstack/react-query';
import { useDebounce } from '_common/hooks/useDebounce';
import ConfigPermissionApi from 'pages/System/containers/ConfigPermission/_api';
import { forwardRef, useMemo, useState } from 'react';
import { OptionProps } from 'tera-dls';
import { SelectProps } from 'tera-dls/lib/components/Select';
import { TObjectType, TParamsApiDof } from '../../interfaces';
import SelectEntity from '../SelectEntity';

export interface SelectDataPermissionProps extends SelectProps {
  objectType?: TObjectType;
  paramsApi?: TParamsApiDof;
  idPrevent?: number;
  optionCustom?: OptionProps[];
  isCheckAll?: boolean;
}

const SelectDataPermission = forwardRef<
  HTMLSelectElement,
  SelectDataPermissionProps
>(
  (
    {
      objectType,
      isCheckAll = false,
      optionCustom = [],
      idPrevent,
      paramsApi,
      ...props
    },
    ref,
  ) => {
    const [search, setSearch] = useState<string>('');
    const searchDebounce = useDebounce(search, 300);

    const { data } = useQuery(
      ['get-config-permission', searchDebounce, paramsApi, objectType],
      () =>
        ConfigPermissionApi.getList({
          keyword: searchDebounce,
          limit: 15,
          page: 1,
          ...paramsApi,
          //   include_key: includesKey,
        }),
      {
        staleTime: 300000,
        cacheTime: 300000,
      },
    );

    const optionPermissionConfig: OptionProps[] = useMemo(() => {
      const options = data?.data
        ?.filter((item) => item?.id !== idPrevent)
        .map((permission) => ({
          label: `${permission?.code} - ${permission?.title}`,
          value: permission?.id,
        }));
      return options || [];
    }, [data, idPrevent]);

    return (
      <SelectEntity
        ref={ref}
        allowClear
        showSearch={data?.total_items > 15}
        labelInValue
        options={[...optionCustom, ...optionPermissionConfig]}
        searchValue={search}
        onSearch={setSearch}
        isCheckAll={isCheckAll}
        placeholder="Vui lòng chọn"
        {...props}
      />
    );
  },
);

export default SelectDataPermission;
