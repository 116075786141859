import { IRouteProps } from '_common/interface/router';
import ServicePackageDetail from './containers/Detail';
import { SERVICE_PACKAGE_URL } from './url';
import ServicePackage from '.';
import ServicePackageForm from './containers/Form';

export const ServicePackageRouter: IRouteProps[] = [
  {
    key: SERVICE_PACKAGE_URL.list.key,
    path: SERVICE_PACKAGE_URL.list.shortenUrl,
    component: <ServicePackage />,
  },
  {
    key: SERVICE_PACKAGE_URL.create.key,
    path: SERVICE_PACKAGE_URL.create.shortenUrl,
    component: <ServicePackageForm />,
  },
  {
    key: SERVICE_PACKAGE_URL.update.key,
    path: SERVICE_PACKAGE_URL.update.shortenUrl,
    component: <ServicePackageForm />,
  },
  {
    key: SERVICE_PACKAGE_URL.detail.key,
    path: SERVICE_PACKAGE_URL.detail.shortenUrl,
    component: <ServicePackageDetail />,
  },
];
