import { IRouteProps } from '_common/interface/router';
import { INVOICE_URL } from './url';
import Invoice from '.';
import InvoiceDetail from './containers/Detail';

export const InvoiceRouter: IRouteProps[] = [
  {
    key: INVOICE_URL.list.key,
    path: INVOICE_URL.list.shortenUrl,
    component: <Invoice />,
  },
  {
    key: INVOICE_URL.detail.key,
    path: INVOICE_URL.detail.shortenUrl,
    component: <InvoiceDetail />,
  },
];
