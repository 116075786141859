import { useMutation, useQuery } from '@tanstack/react-query';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { BUTTON_KEY } from '_common/constants/permission';
import { BUSINESS_URL } from '_common/constants/url';
import useConfirm from '_common/hooks/useConfirm';
import { usePermission } from '_common/hooks/usePermission';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Badge,
  DropdownItem,
  InformationCircleOutlined,
  PaginationProps,
  Tabs,
  Tag,
  Tooltip,
  formatDate,
  getQueryParams,
  notification,
  updateQueryParams,
} from 'tera-dls';
import BusinessApi from '../_api';
import {
  BUSINESS_STATUS,
  BUSINESS_STATUS_COLOR,
  BUSINESS_TABS,
} from '../constants';

import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import TableTera from '_common/dof/TableTera';
import { renderTotalCount } from '_common/utils';
import BusinessFilter from './Filter';
import BusinessHeader from './Header';

const BusinessPage = () => {
  const { hasPage } = usePermission();
  const navigate = useNavigate();
  const confirm = useConfirm();

  const [isFilter, setIsFilter] = useState<boolean>(false);

  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const {
    data: listPage,
    refetch,
    isLoading,
  } = useQuery(
    ['get-business-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        status: queryParams?.status === 'all' ? '' : queryParams?.status,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
      };
      return BusinessApi.getList(params);
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const { mutate: deleteBusiness, isLoading: loadingDelete } = useMutation(
    (id: string | number) => BusinessApi.delete(id),
    {
      onSuccess(res) {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          refetch();
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleQueryParams = (query) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...query,
    });
    navigate(location.pathname + paramString);
  };

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    handleQueryParams({
      page:
        queryParams?.limit && Number(pageSize) !== Number(queryParams?.limit)
          ? 1
          : page,
      limit: pageSize,
    });
  };

  const handleChangeTabs = (key) => {
    handleQueryParams({
      page: 1,
      status: key,
    });
  };

  const handleDeleteBusiness = (id: string | number, name: string) => {
    confirm.warning({
      title: 'Xác nhận xóa doanh nghiệp',
      content: (
        <>
          <p>Bạn có chắc chắn muốn xóa doanh nghiệp</p>
          <p>
            <b className="break-all">{name}</b> này không?
          </p>
        </>
      ),
      onOk: () => {
        deleteBusiness(id);
      },
    });
  };

  const handleFilter = (values) => {
    handleQueryParams({ ...values, page: 1 });
  };

  const renderDropdown = (record): DropdownItem[] => {
    const dropdownItems: DropdownItem[] = [];
    hasPage(BUTTON_KEY.BUSINESS_DETAIL) &&
      dropdownItems.push({
        key: 1,
        label: 'Xem',
        onClick: () => navigate(`${BUSINESS_URL.detail.path}/${record?.id}`),
      });
    hasPage(BUTTON_KEY.BUSINESS_UPDATE) &&
      dropdownItems.push({
        key: 2,
        label: 'Sửa',
        onClick: () => navigate(`${BUSINESS_URL.update.path}/${record?.id}`),
      });
    hasPage(BUTTON_KEY.BUSINESS_DELETE) &&
      dropdownItems.push({
        key: 3,
        label: <span className="text-red-500">Xóa</span>,
        onClick: () => handleDeleteBusiness(record?.id, record?.name),
      });
    return dropdownItems;
  };

  const columns: any = [
    {
      title: 'Buisness ID',
      dataIndex: 'id',
      width: 110,
    },
    {
      title: 'Tên công ty',
      dataIndex: 'name',
      width: 300,
      render: (text) => <div className="line-clamp-2">{text}</div>,
    },
    {
      title: 'Email công ty',
      dataIndex: 'email',
      width: 300,
      render: (text) => <div className="line-clamp-2">{text}</div>,
    },
    {
      title: 'Đại diện',
      dataIndex: 'owner_name',
      width: 300,
      render: (text) => <div className="line-clamp-2">{text}</div>,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 200,
      render: (status) => (
        <Tag color={BUSINESS_STATUS_COLOR[status]}>
          {BUSINESS_STATUS[status]}
        </Tag>
      ),
    },
    {
      title: 'Ngày đăng ký',
      dataIndex: 'register_time',
      width: 150,
      render: (register_time) => register_time && formatDate(register_time),
    },
    {
      title: (
        <div className="flex items-center">
          <span>SL gói dịch vụ</span>
          <Tooltip placement="top" title="Đang hoạt động">
            <div>
              <InformationCircleOutlined className="w-5 h-5" />
            </div>
          </Tooltip>
        </div>
      ),
      dataIndex: 'quantity_service',
      width: 200,
    },
    {
      title: (
        <div className="flex items-center">
          <span>SL gói sản phẩm</span>
          <Tooltip placement="top" title="Đang hoạt động">
            <div>
              <InformationCircleOutlined className="w-5 h-5" />
            </div>
          </Tooltip>
        </div>
      ),
      dataIndex: 'quantity_package_service',
      width: 200,
    },
    {
      title: (
        <div className="flex items-center">
          <span>SL người dùng</span>
          <Tooltip placement="top" title="Đã tạo">
            <div>
              <InformationCircleOutlined className="w-5 h-5" />
            </div>
          </Tooltip>
        </div>
      ),
      dataIndex: 'quantity_user',
      width: 200,
    },

    // {
    //   title: 'Thời gian dùng thử',
    //   dataIndex: 'trial_time',
    //   width: 200,
    //   render: (text) => <div className="line-clamp-2">{text}</div>,
    // },
    // {
    //   title: 'Ngày hết hạn',
    //   dataIndex: 'expiration_time',
    //   width: 150,
    //   render: (expiration_time) =>
    //     expiration_time && formatDate(expiration_time),
    // },

    {
      dataIndex: '',
      width: 60,
      fixed: 'right',
      render: (_, record) => {
        const dropdownItems = renderDropdown(record);
        return <ActionDropdown dropdownItems={dropdownItems} trigger="click" />;
      },
    },
  ];

  const tabItems = Object.entries(BUSINESS_TABS).map(([key, value]) => ({
    key: key,
    label: (
      <h3 className="tab-table">
        {value}
        <Badge showZero count={renderTotalCount(listPage?.summary, key)} />
      </h3>
    ),
  }));

  return (
    <div className="tera-page">
      <HeaderViewListV2
        title="DANH SÁCH DOANH NGHIỆP"
        onClickButtonAdd={() => navigate(BUSINESS_URL.create.path)}
        onClickFilter={() => setIsFilter(true)}
        buttonCreatingKey={BUTTON_KEY.BUSINESS_CREATE}
        // dropdownItems={[
        //   {
        //     key: 1,
        //     label: 'Export file excel',
        //   },
        // ]}
        actionLeftRender={<BusinessHeader />}
        bottomContent={<Tabs onChange={handleChangeTabs} items={tabItems} />}
      >
        <TableTera
          columns={columns}
          data={listPage?.data?.data || []}
          loading={isLoading || loadingDelete}
          rowKey={'id'}
          pagination={{
            onChange: handleChangePage,
            total: listPage?.data?.total || 0,
            current: listPage?.data?.current_page || 1,
            pageSize: listPage?.data?.per_page,
            to: listPage?.data?.to,
            from: listPage?.data?.from,
          }}
        />
      </HeaderViewListV2>
      {isFilter && (
        <BusinessFilter
          open={isFilter}
          initialValue={queryParams}
          onFilter={handleFilter}
          onClose={() => setIsFilter(false)}
        />
      )}
    </div>
  );
};

export default BusinessPage;
