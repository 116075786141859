import { useMutation, useQuery } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { REGEX } from '_common/constants/common';
import Input from '_common/dof/Control/Input';
import Select from '_common/dof/Control/Select';
import TextArea from '_common/dof/Control/TextArea';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';

import ImageBackground from '_common/component/ImageBackground';
import Switch from '_common/dof/Control/Switch';
import SelectDataPermission from '_common/dof/Select/SelectDataPermission';
import _ from 'lodash';
import ServicePackageApi from 'pages/ServicePackage/_api';
import { TYPE_SERVICE } from 'pages/ServicePackage/constants';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { Row, notification } from 'tera-dls';
import ServicePackageProduct, {
  IServicePackageTableRef,
  normalizeData,
} from '../Product';
import { usePrompt } from '_common/hooks/usePrompt';
import { customTwMerge } from 'tailwind-merge.config';
import InputNumber from '_common/dof/Control/InputNumber';

export interface IServicePackageFormContentRef {
  submit: () => void;
  getIsDirty: () => boolean;
  checkError: () => boolean;
}

interface IProps {
  onSuccess?: () => void;
  id?: number | string;
}

const ServicePackageContent = (props, ref) => {
  const { onSuccess, id } = props;
  const form = useForm({ mode: 'onChange' });
  // const queryClient = useQueryClient();
  const [packages, setSetPackages] = useState<any>();
  const [image, setImage] = useState<any>();
  const actionRef = useRef<IServicePackageTableRef>(null);

  const { data: detail, refetch } = useQuery(
    ['get-service-package-detail', id],
    () => ServicePackageApi.getDetail({ id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const isTrialDefault = Boolean(detail?.is_trial_default ?? 0);

  const handleDirty = (): any => {
    const isNotErrors = actionRef?.current?.checkDirty();
    if (detail) {
      return (
        form.formState.isDirty ||
        (!!image &&
          JSON.stringify(image) !==
            JSON.stringify({
              id: detail?.media_id,
              name: detail?.file_name,
              url: detail?.file_url,
            })) ||
        JSON.stringify(packages) !== JSON.stringify(detail?.packages) ||
        isNotErrors
      );
    }

    return (
      form.formState.isDirty || !!image || packages?.length > 0 || isNotErrors
    );
  };

  usePrompt(
    <p className="break-word">
      <p>Bạn có chắc muốn thoát bản ghi này không?</p>
      <p>Sau khi thoát dữ liệu sẽ không được lưu</p>
    </p>,
    handleDirty() ?? false,
  );

  const { mutate: mutateAction } = useMutation(
    (variables: any) =>
      id
        ? ServicePackageApi.update({ ...variables, id })
        : ServicePackageApi.create(variables),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          onSuccess && onSuccess();
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  useEffect(() => {
    if (!id) {
      form.setValue('status', true, { shouldDirty: false });
      form.setValue('start_up_fee', 0, { shouldDirty: false });
    }
  }, [id]);

  useEffect(() => {
    if (!detail) return;
    const data = _.pick(detail, [
      'code',
      'description',
      'name',
      'status',
      'type',
      'start_up_fee',
    ]);
    if (detail?.file_url) {
      setImage({
        id: detail?.media_id,
        name: detail?.file_name,
        url: detail?.file_url,
      });
    }
    form.reset(
      {
        ...data,
        ...(detail?.role && {
          role_id: detail?.role?.id,
        }),
      },
      { keepDirty: false },
    );
  }, [detail]);

  const handleSubmit = (values: any): void => {
    mutateAction({
      params: {
        ...values,
        status: !!values.status ? 1 : 0,
        ...(packages && !id && { packages: normalizeData(packages) }),
        ...(image && {
          file_upload: {
            id: image.id,
            name: image.name,
            url: image.url,
          },
        }),
      },
    });
  };

  useEffect(() => {
    id && refetch();
  }, [form, id]);

  useImperativeHandle(
    ref,
    () => ({
      checkError() {
        return actionRef?.current?.checkError();
      },
      async submit() {
        const isDirty = await actionRef?.current?.checkDirty();
        console.log('call', isDirty);

        !isDirty && form.handleSubmit(handleSubmit)();
      },
      getIsDirty() {
        return handleDirty();
      },
    }),
    [form, handleSubmit, actionRef],
  );

  const typeOptions = Object.entries(TYPE_SERVICE).map(([key, value]) => ({
    label: value,
    value: key,
  }));

  return (
    <FormTera form={form} onSubmit={handleSubmit}>
      <div className="p-[16px] gap-[16px]">
        <div className="col-span-1 grid gap-5 border-b border-dotted pb-[16px]">
          <div className="text-blue-500 text-[13px] leading-[15px] inline-block font-medium">
            Thông tin gói dịch vụ
          </div>
          <Row className="grid grid-cols-3 gap-5">
            <FormTeraItem
              name="code"
              label={'Mã gói dịch vụ'}
              className="col-span-1"
              rules={[
                { required: 'Vui lòng nhập' },
                {
                  pattern: {
                    value: REGEX.CODE,
                    message: `Mã gói dịch vụ không dấu, không khoảng cách, không kí tự đặc biệt ( ngoại trừ "_")`,
                  },
                },
              ]}
            >
              <Input
                maxLength={191}
                placeholder="Vui lòng nhập"
                disabled={isTrialDefault}
              />
            </FormTeraItem>
            <FormTeraItem
              name="name"
              label={'Tên gói dịch vụ'}
              className="col-span-1"
              rules={[{ required: 'Vui lòng nhập' }]}
            >
              <Input
                maxLength={100}
                placeholder="Vui lòng nhập"
                disabled={isTrialDefault}
              />
            </FormTeraItem>

            <FormTeraItem
              name="type"
              label={'Loại gói dịch vụ'}
              className="col-span-1"
              rules={[{ required: 'Vui lòng chọn' }]}
            >
              <Select
                placeholder="Vui lòng chọn"
                options={typeOptions}
                disabled={Boolean(detail?.is_used) || isTrialDefault}
              />
            </FormTeraItem>
            <FormTeraItem
              name="start_up_fee"
              label={'Phí khởi tạo'}
              className="col-span-1"
            >
              <InputNumber
                placeholder="Vui lòng chọn"
                min={0}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
              />
            </FormTeraItem>
            <FormTeraItem label="Icon" name="">
              <ImageBackground
                wrapperClassName="w-[70px] h-[70px]"
                onChange={setImage}
                value={image}
                isShowBtnDelete
                object_key={'service-package'}
                folder={'service-package'}
              />
            </FormTeraItem>
            <Row className="col-span-1 grid grid-cols-3">
              <FormTeraItem
                name="status"
                label={'Trạng thái'}
                className={customTwMerge(
                  'col-span-2',
                  isTrialDefault && 'pointer-events-none',
                )}
              >
                <Switch placeholder="Vui lòng chọn" disabled={isTrialDefault} />
              </FormTeraItem>
            </Row>

            <FormTeraItem
              label="Mô tả"
              name="description"
              className="flex-1 col-span-3"
            >
              <TextArea placeholder="Vui lòng nhập" maxLength={1000} rows={5} />
            </FormTeraItem>
          </Row>
        </div>
        <div className="col-span-1 grid gap-5 border-b border-dotted pb-[32px] mt-[32px]">
          <div className="text-blue-500 text-[13px] leading-[15px] inline-block font-medium">
            Gói sản phẩm
          </div>
          <ServicePackageProduct
            ref={actionRef}
            onChange={setSetPackages}
            objectType="object"
            mode={id ? 'default' : 'soft'}
            {...(isTrialDefault && { mode: 'view' })}
            id={id}
          />
        </div>
        <div className="col-span-1 grid gap-5 border-b border-dotted pb-[16px] mt-[32px]">
          <div className="text-blue-500 text-[13px] leading-[15px] inline-block font-medium">
            Phân quyền ứng dụng
          </div>
          <div className="grid grid-cols-4 gap-5">
            <FormTeraItem
              name="role_id"
              label={'Phân quyền'}
              className="col-span-1"
              rules={[{ required: 'Vui lòng chọn' }]}
            >
              <SelectDataPermission
                placeholder="Vui lòng chọn"
                disabled={isTrialDefault}
                paramsApi={{ type: 'business' }}
              />
            </FormTeraItem>
          </div>
        </div>
      </div>
    </FormTera>
  );
};

export default forwardRef<IServicePackageFormContentRef, IProps>(
  ServicePackageContent,
);
