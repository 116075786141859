import React, { useState } from 'react';
import Content from './containers/Content';
export const StatisticCashExpenseByPaymentMethodParamContext =
  React.createContext(null);

const StatisticCashExpenseByPaymentMethod = () => {
  const [contextParams, setContextParams] = useState<any>();
  return (
    <StatisticCashExpenseByPaymentMethodParamContext.Provider
      value={{ contextParams, setContextParams }}
    >
      <Content chartClassName="!h-[420px]" />
    </StatisticCashExpenseByPaymentMethodParamContext.Provider>
  );
};

export default StatisticCashExpenseByPaymentMethod;
