import { useMutation, useQueries, useQueryClient } from '@tanstack/react-query';
import { folderUpload } from '_common/constants/attachment';
import { REGEX } from '_common/constants/common';
import {
  messageError,
  messageValidate,
  messageValidateLength,
  messageWarning,
} from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import Select from '_common/dof/Control/Select';
import TextArea from '_common/dof/Control/TextArea';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectDataStatus from '_common/dof/Select/SelectDataStatus';
import SelectModule from '_common/dof/Select/SelectModule';
import SelectStatusType from '_common/dof/Select/SelectStatusType';
import UploadFiles from '_common/dof/UploadFiles';
import useConfirm from '_common/hooks/useConfirm';
import { convertListOrder } from '_common/utils';
import _ from 'lodash';
import { ReactNode, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Modal, OptionProps, Row, Spin, notification } from 'tera-dls';
import DataStatusApi from '../../_api';

function DataTypeForm({ open, onClose, onRefetch, id }) {
  const form = useForm();
  const { watch, setValue } = form;
  const objTypeKey = watch('objtype_key');
  const module = watch('module');
  const [fileList, setFileList] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const [
    { data: dataDetail, isLoading, isError, refetch },
    { data: listOrder },
  ] = useQueries({
    queries: [
      {
        queryKey: ['get-detail-data-status', id],
        queryFn: () => DataStatusApi.getDetail(id),
        enabled: !!id,
        cacheTime: 300000,
        staleTime: 300000,
      },
      {
        queryKey: ['get-list-order-data-status', id, objTypeKey],
        queryFn: () => {
          const params = {
            order_field: 'order',
            order_by: 'asc',
            status_id: id,
            object_status: objTypeKey,
          };
          return DataStatusApi.getList(params);
        },
        enabled: !!objTypeKey,
      },
    ],
  });

  const handleClose = () => {
    onClose();
    form.reset();
  };

  const { mutate: submitForm, isLoading: loadingSubmit } = useMutation(
    (variable) => {
      if (id) return DataStatusApi.update(id, variable);
      return DataStatusApi.create(variable);
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-list-data-status']);
          onRefetch();
          handleClose();
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) => {
        const errorMessage = error?.data?.errors || messageError.ERROR_API;
        const flattenMessage = Object.values(errorMessage).flat();
        notification.error({
          message: (
            <ul>
              {flattenMessage?.map((message: ReactNode) => (
                <li>{message}</li>
              ))}
            </ul>
          ),
        });
      },
    },
  );

  const handleCloseConfirm = () => {
    if (form?.formState?.isDirty) {
      confirm.warning({
        title: 'THOÁT BẢN GHI',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => onClose(),
      });
    } else onClose();
  };

  const handleSubmitForm = (values) => {
    const params = {
      ...values,
      file_upload: fileList?.[0],
      order: values.order ?? undefined,
    };
    submitForm(params);
  };

  const optionsOrder: OptionProps[] = convertListOrder(
    listOrder?.data,
    id,
    null,
  );

  useEffect(() => {
    if (id) refetch();
  }, [id]);

  useEffect(() => {
    if (dataDetail) {
      const data = _.pick(dataDetail, [
        'key',
        'objtype_key',
        'module',
        'title',
        'value',
        'color',
        'description',
        'parent_key',
      ]);
      setFileList(
        dataDetail?.media_id && [
          {
            name: dataDetail?.file_name,
            url: dataDetail?.file_url,
            id: dataDetail?.media_id,
          },
        ],
      );
      form.reset({
        ...data,
        module: data?.module?.id,
        order: dataDetail?.order ? Number(dataDetail?.order) - 1 : 0,
      });
    }
  }, [dataDetail]);

  if (isError) {
    onClose();
    notification.error({ message: messageError.DATA_NOT_FOUND });
  }

  return (
    <Modal
      closeIcon={false}
      open={open}
      okText="Đồng ý"
      cancelText="Hủy"
      title={id ? 'SỬA CẤU HÌNH TRẠNG THÁI' : 'THÊM CẤU HÌNH TRẠNG THÁI'}
      width={1000}
      onOk={() => form.handleSubmit(handleSubmitForm)()}
      onCancel={handleCloseConfirm}
      confirmLoading={loadingSubmit || (isLoading && !!id)}
    >
      <Spin spinning={isLoading && !!id}>
        <FormTera form={form} onSubmit={handleSubmitForm}>
          <Row className="grid grid-cols-2">
            <Col>
              <FormTeraItem
                label="Mã trạng thái"
                name="key"
                rules={[
                  {
                    required: messageValidate?.emptyText,
                  },
                  {
                    maxLength: {
                      value: 191,
                      message: messageValidateLength?.key,
                    },
                  },
                  {
                    pattern: {
                      value: REGEX.KEY,
                      message: messageValidate?.key,
                    },
                  },
                ]}
              >
                <Input maxLength={100} placeholder="{module}_{type}_{key}" />
              </FormTeraItem>
              <FormTeraItem name="module" label="Module" isRequired={false}>
                <SelectModule
                  onClear={() => {
                    setValue('objtype_key', null);
                    setValue('order', null);
                  }}
                />
              </FormTeraItem>
              <FormTeraItem
                label="Loại trạng thái"
                name="objtype_key"
                rules={[
                  {
                    required: messageValidate?.emptySelect,
                  },
                ]}
              >
                <SelectStatusType
                  labelInValue
                  paramsApi={{
                    module_id: module,
                  }}
                  disabled={!module}
                  onChangeCustom={() => {
                    setValue('order', null);
                  }}
                  onClear={() => setValue('order', null)}
                />
              </FormTeraItem>
              <FormTeraItem label="Parent key" name="parent_key">
                <SelectDataStatus
                  selectedValue={selectedStatus}
                  onChangeCustom={setSelectedStatus}
                  onClear={() => setSelectedStatus(null)}
                  paramsApi={{ status_id: id }}
                />
              </FormTeraItem>
              <FormTeraItem
                label="Tiêu đề"
                name="title"
                rules={[
                  {
                    maxLength: {
                      value: 100,
                      message: messageValidateLength?.text,
                    },
                  },
                ]}
              >
                <Input maxLength={100} />
              </FormTeraItem>
              <FormTeraItem label="Thứ tự đứng sau" name="order">
                <Select
                  options={optionsOrder}
                  disabled={!objTypeKey}
                  allowClear
                />
              </FormTeraItem>
              <FormTeraItem
                label="Giá trị"
                name="value"
                rules={[
                  {
                    maxLength: {
                      value: 100,
                      message: messageValidateLength?.text,
                    },
                  },
                ]}
              >
                <Input maxLength={100} />
              </FormTeraItem>
              <FormTeraItem
                label="Màu sắc"
                name="color"
                rules={[
                  {
                    maxLength: {
                      value: 100,
                      message: messageValidateLength?.text,
                    },
                  },
                ]}
              >
                <Input maxLength={100} />
              </FormTeraItem>
            </Col>
            <Col>
              <FormTeraItem
                label="Mô tả"
                name="description"
                rules={[
                  {
                    maxLength: {
                      value: 1000,
                      message: messageValidateLength?.textAria,
                    },
                  },
                ]}
              >
                <TextArea rows={10} maxLength={1000} />
              </FormTeraItem>
              <UploadFiles
                fileList={fileList}
                isSingle
                accept="image/*"
                object_id={id}
                object_key={folderUpload.OBJECT_TYPE}
                folder={folderUpload.OBJECT_TYPE}
                onReceiveFiles={(file) => setFileList([file])}
                onRemove={() => setFileList([])}
              />
            </Col>
          </Row>
        </FormTera>
      </Spin>
    </Modal>
  );
}

export default DataTypeForm;
