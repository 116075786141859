import useConfirm from '_common/hooks/useConfirm';
import { useState } from 'react';
import { ReactComponent as FileIcon } from 'styles/images/Icons/file-icon.svg';
import { ReactComponent as ImageIcon } from 'styles/images/Icons/img-icon.svg';
import { ReactComponent as VideoIcon } from 'styles/images/Icons/video-icon.svg';
import {
  Dropdown,
  EllipsisHorizontalOutlined,
  Image,
  downloadFile,
  notification,
} from 'tera-dls';
import FileModal from './FileModal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import PortalAPI from 'pages/FileManagement/_api';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';

const typeFileObject = {
  img: <ImageIcon className="w-40 h-40 shrink-0" />,
  file: <FileIcon className="w-40 h-40 shrink-0 " />,
  video: <VideoIcon className="w-40 h-40 shrink-0 " />,
};

export const getIconFile = (type: string) => {
  if (type.includes('video')) return typeFileObject['video'];
  return typeFileObject['file'];
};

interface IProps {
  value?: any;
}

const FileItem = (props: IProps) => {
  const { value } = props;
  const queryClient = useQueryClient();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const confirm = useConfirm();

  const { mutate: mutateDelete } = useMutation(
    (variable: any) => PortalAPI.delete(variable),
    {
      onSuccess: (res: any) => {
        if (res?.status === 200) {
          queryClient.invalidateQueries(['get-file-management-list']);
          notification.success({
            message: res?.data?.msg,
          });
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleDelete = () => {
    confirm.warning({
      title: 'Xác nhận xóa tệp',
      content: (
        <>
          Bạn có chắc muốn xóa tệp
          <span className="font-medium"> {value?.file_name} </span>
          này không?
        </>
      ),
      onOk: () => {
        mutateDelete({
          id: value.id,
          params: {
            object_type: value.object_type,
            object_id: value.object_id,
          },
        });
      },
    });
  };

  const items = [
    {
      key: '1',
      label: 'Tải xuống',
      onClick: () => {
        downloadFile(value.file_url, value.file_name);
      },
    },
    {
      key: '2',
      label: 'Đổi tên',
      onClick: () => {
        setOpenModal(true);
      },
    },
    {
      key: '3',
      label: <span className="text-red-500">Xóa</span>,
      onClick: () => {
        handleDelete();
      },
    },
  ];

  return (
    <>
      <div className="bg-gray-50 rounded-[0.625rem] items-center hover:bg-gray-200 p-4">
        <div className=" mb-2.5 flex gap-5 items-center">
          <span className="flex-1 break-all">{value?.file_name}</span>
          <Dropdown
            containerClassName="w-14"
            placement="bottom-end"
            menu={{
              items,
            }}
            trigger="click"
          >
            <span>
              <EllipsisHorizontalOutlined className="w-[1.5rem] h-[1.5rem] cursor-pointer" />
            </span>
          </Dropdown>
        </div>
        {value?.file_type?.includes('image') ? (
          <Image
            src={value?.file_url}
            alt={value?.name}
            // imageClassName="bg-center"
            // containerClassName="w-full h-[200px] "
          />
        ) : (
          <div className="w-full h-[200px] grid place-items-center bg-white rounded-md">
            {getIconFile(value?.file_type)}
          </div>
        )}
      </div>
      {openModal && (
        <FileModal
          open={openModal}
          value={value}
          onClose={() => setOpenModal(false)}
        />
      )}
    </>
  );
};

export default FileItem;
