import { PERMISSION_KEY } from './permission';

export const INVOICE_URL = {
  list: {
    key: PERMISSION_KEY.INVOICE_VIEW_INVOICE_LIST,
    path: '/invoice-management/invoice/list',
    shortenUrl: 'invoice/list',
  },
  detail: {
    key: PERMISSION_KEY.INVOICE_VIEW_INVOICE_DETAIL,
    path: '/invoice-management/invoice/detail',
    shortenUrl: 'invoice/detail/:id',
  },
};
