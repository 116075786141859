import HoverQuickView from '_common/component/HoverQuickView';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import { renderDescription } from '_common/shared/utils';
import moment from 'moment';
import {
  statusTypeColor,
  TYPE_ACCOUNT,
  USER_STATUS,
} from 'pages/User/constants/statusType';
import { Tag } from 'tera-dls';

const DetailMain = ({ detail }) => {
  const data = [
    {
      title: 'Họ và tên',
      value: detail?.full_name,
    },
    {
      title: 'Vai trò',
      value: TYPE_ACCOUNT[detail?.type],
    },
    {
      title: 'Người tạo',
      value: detail?.user_created && (
        <HoverQuickView
          avatarUrl={detail?.user_created?.avatar_url}
          email={detail?.user_created?.email}
          phone={detail?.user_created?.phone}
          name={detail?.user_created?.full_name}
          sub={detail?.user_created?.customer_type_text?.title}
          code={detail?.user_created?.code}
        >
          <span className="line-clamp-2">
            {detail?.user_created?.full_name}
          </span>
        </HoverQuickView>
      ),
    },
    {
      title: 'Số diện thoại',
      value: detail?.phone,
    },
    {
      title: 'Tên đăng nhập',
      value: detail?.username,
    },
    {
      title: 'Ngày tạo',
      value:
        detail?.created_at &&
        moment(detail?.created_at).format(DATE_TIME_FORMAT),
    },
    {
      title: 'Email',
      value: detail?.email,
    },
    {
      title: 'Tên công ty',
      value: detail?.business?.name,
    },
    {
      title: 'Người cập nhật',
      value: detail?.user_updated && (
        <HoverQuickView
          avatarUrl={detail?.user_updated?.avatar_url}
          email={detail?.user_updated?.email}
          phone={detail?.user_updated?.phone}
          name={detail?.user_updated?.full_name}
          sub={detail?.user_updated?.customer_type_text?.title}
          code={detail?.user_updated?.code}
        >
          <span className="line-clamp-2">
            {detail?.user_updated?.full_name}
          </span>
        </HoverQuickView>
      ),
    },
    {
      title: 'Trạng thái',
      value: USER_STATUS[detail?.status] && (
        <Tag color={statusTypeColor[detail?.status]}>
          {USER_STATUS[detail?.status]}
        </Tag>
      ),
    },
    {
      title: 'Phân quyền',
      value: detail?.role?.title,
    },
    {
      title: 'Ngày cập nhật',
      value:
        detail?.user_updated &&
        moment(detail?.updated_at).format(DATE_TIME_FORMAT),
    },
  ];

  return (
    <div className="grid grid-cols-3 gap-2">{renderDescription(data)}</div>
  );
};

export default DetailMain;
