import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const ServicePackageEndpoint = `${endpoint}/administrator/service`;
const ConfigPermissionEndpoint = `${endpoint}/administrator/role`;

const ServicePackageApi = {
  getListRoleHasPermission: async (role_id: number) =>
    await api
      .get(`${ConfigPermissionEndpoint}/role-has-permission-detail`, {
        role_id: role_id,
      })
      .then((result) => result?.data?.data),
  getList: async ({ params }) =>
    await api
      .get(`${ServicePackageEndpoint}/list`, params)
      .then((result) => result.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${ServicePackageEndpoint}/detail/${id}`)
      .then((result) => result.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${ServicePackageEndpoint}/create`, params)
      .then((result) => result.data),
  update: async ({ params, id }) =>
    await api
      .put(`${ServicePackageEndpoint}/update/${id}`, params)
      .then((result) => result.data),
  delete: async ({ id }) =>
    await api
      .delete(`${ServicePackageEndpoint}/delete/${id}`)
      .then((result) => result.data),
  getProduct: async ({ params }) =>
    await api
      .get(`${ServicePackageEndpoint}/package/list`, params)
      .then((data) => data?.data?.data),
  saveProduct: async ({ params }) =>
    await api
      .put(`${ServicePackageEndpoint}/package/update`, params)
      .then((result) => result?.data),
  deleteProduct: async ({ id }) =>
    await api
      .delete(`${ServicePackageEndpoint}/package/delete/${id}`)
      .then((result) => result?.data),
  getListOfBusiness: async ({ params }) =>
    await api
      .get(`${ServicePackageEndpoint}/get-in-business`, params)
      .then((result) => result.data?.data),
  getDetailOfBusiness: async ({ id }) =>
    await api
      .get(`${ServicePackageEndpoint}/get-detail-service-in-business/${id}`)
      .then((result) => result.data?.data),
  addToBusiness: async ({ params }) =>
    await api
      .post(`${ServicePackageEndpoint}/add-to-business`, params)
      .then((result) => result.data),
  active: async ({ params }) =>
    await api
      .post(`${ServicePackageEndpoint}/activated`, params)
      .then((result) => result.data),
  inActive: async ({ params }) =>
    await api
      .post(`${ServicePackageEndpoint}/activation-command`, params)
      .then((result) => result.data),
  reActive: async ({ params }) =>
    await api
      .post(`${ServicePackageEndpoint}/restart`, params)
      .then((result) => result.data),
  upgrade: async ({ params }) =>
    await api
      .post(`${ServicePackageEndpoint}/upgrade`, params)
      .then((result) => result.data),
  reNew: async ({ params }) =>
    await api
      .post(`${ServicePackageEndpoint}/extend`, params)
      .then((result) => result.data),
  removeOutBusiness: async ({ id }) =>
    await api
      .delete(`${ServicePackageEndpoint}/remove-out-business/${id}`)
      .then((result) => result?.data),
  getBusiness: async ({ params }) =>
    await api
      .get(`${ServicePackageEndpoint}/get-businesses`, params)
      .then((result) => result.data?.data),
  getSummaryDetail: async ({ params }) =>
    await api
      .get(`${ServicePackageEndpoint}/get-summary-detail`, params)
      .then((result) => result.data?.data),
};

export default ServicePackageApi;
