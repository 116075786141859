import { PERMISSION_KEY } from './permission';

export const EXPENSE_VOUCHER_URL = {
  list: {
    key: PERMISSION_KEY.EXPENSE_VOUCHER_VIEW_EXPENSE_VOUCHER_LIST,
    path: '/invoice-management/expense-voucher/list',
    shortenUrl: 'expense-voucher/list',
  },
  create: {
    key: PERMISSION_KEY.EXPENSE_VOUCHER_CREATE_EXPENSE_VOUCHER,
    path: '/invoice-management/expense-voucher/create',
    shortenUrl: 'expense-voucher/create',
  },
  update: {
    key: PERMISSION_KEY.EXPENSE_VOUCHER_UPDATE_EXPENSE_VOUCHER,
    path: '/invoice-management/expense-voucher/update',
    shortenUrl: 'expense-voucher/update/:id',
  },
};
