export enum EXPORTING_KEYS {
  product_item = 'product_item',
  brand_item = 'brand_item',
  unit_item = 'unit_item',
  category_item = 'category_item',
  supplier_item = 'supplier_item',
  customer_item = 'customer_item',
  sell_item = 'sell_item',
  purchase_item = 'purchase_item',
  employee_item = 'employee_item',
  email_marketing_item = 'email_marketing_item',
  price_quotation_item = 'price_quotation_item',
  consulting_ticket_item = 'consulting_ticket_item',
  appointment_item = 'appointment_item',
  task_item = 'task_item',
  call_item = 'call_item',
  lead_item = 'lead_item',
  opportunity_item = 'opportunity_item',
  campaign_item = 'campaign_item',
  sell_return_item = 'sell_return_item',
  purchase_return_item = 'purchase_return_item',
  receipt_item = 'receipt_item',
  expense_voucher_item = 'expense_voucher_item',
}
