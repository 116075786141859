import { PAGE_KEY } from './key';

export const MANAGE_PAGE_URL = {
  list: {
    key: PAGE_KEY.MANAGE_PAGE_LIST_VIEW,
    path: '/system/manage-page',
    shortenUrl: '',
  },
  detail: {
    key: PAGE_KEY.MANAGE_PAGE_LIST_VIEW_DETAIL,
    path: '/system/manage-page',
    shortenUrl: ':pageId',
  },
  columnConfig: {
    key: PAGE_KEY.COLUMN_CONFIG_LIST_VIEW,
    path: '/system/manage-page',
    shortenUrl: ':pageId/column-config/:tableId',
  },
  tableConfig: {
    key: PAGE_KEY.MANAGE_PAGE_TABLE_CONFIG,
    path: '/system/manage-page/table-config',
    shortenUrl: 'table-config/:id',
  },
  tableConfigDetail: {
    key: PAGE_KEY.MANAGE_PAGE_TABLE_CONFIG_DETAIL,
    path: '/system/manage-page',
    shortenUrl: ':pageId/table-config-detail/:tableId',
  },
  controlConfig: {
    key: PAGE_KEY.MANAGE_PAGE_CONTROL_CONFIG,
    path: '/system/manage-page/control-config',
    shortenUrl: 'control-config/:pageId',
  },
};

export const MANAGE_PAGE_CONFIG_FORM_URL = {
  list: {
    key: PAGE_KEY.MANAGE_PAGE_CONFIG_FORM,
    path: '/system/manage-page/config-form',
    shortenUrl: 'config-form/:pageId',
  },
  detail: {
    key: PAGE_KEY.MANAGE_PAGE_CONFIG_FORM_DETAIL,
    path: '/system/manage-page/config-form/detail',
    shortenUrl: 'config-form/:pageId/detail/:formId',
  },
  configField: {
    key: PAGE_KEY.MANAGE_PAGE_CONFIG_FIELD,
    path: '/system/manage-page/config-field',
    shortenUrl: ':pageId/config-field/:formId',
  },
};
