import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import { FILE_MANAGEMENT_URL } from '_common/constants/url';
import useConfirm from '_common/hooks/useConfirm';
import { convertBytes } from '_common/utils';
import moment from 'moment';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as FileIcon } from 'styles/images/Icons/file-icon.svg';
import { DropdownItem, FolderOpenSolid, Table } from 'tera-dls';
import FileModal from '../File/FileModal';
import FolderModal from '../Folder/FolderModal';
import { ReactComponent as ImageIcon } from 'styles/images/Icons/img-icon.svg';
import { ReactComponent as VideoIcon } from 'styles/images/Icons/video-icon.svg';
interface IProps {
  value?: any;
}

const typeFileObject = {
  img: <ImageIcon className="w-[1.5rem] h-[1.5rem]" />,
  file: <FileIcon className="w-[1.5rem] h-[1.5rem] " />,
  folder: <FolderOpenSolid className="w-[1.5rem] h-[1.5rem] fill-gray-500" />,
  video: <VideoIcon className="w-[1.5rem] h-[1.5rem] " />,
};

const getIconFile = (type: string) => {
  if (type.includes('video')) return typeFileObject['video'];
  if (type.includes('image')) return typeFileObject['img'];
  return typeFileObject['file'];
};

const FileTable = (props: IProps) => {
  const { value } = props;
  const [modal, setModal] = useState<any>({ open: false });
  const navigate = useNavigate();
  const { id: objectType } = useParams();
  const confirm = useConfirm();

  const handleDelete = (id, name) => {
    confirm.warning({
      title: `Xác nhận xóa ${objectType ? 'tệp' : 'thư mục'}`,
      content: (
        <>
          Bạn có chắc muốn xóa {objectType ? 'tệp' : 'thư mục'}
          <span className="font-medium"> {name} </span>
          này không?
        </>
      ),
      onOk: () => {
        id;
      },
    });
  };

  const generateDropDownItems = (record): DropdownItem[] => {
    return [
      {
        key: 2,
        label: <a onClick={() => 1}>Đổi tên</a>,
        onClick: () => {
          setModal({ open: true, value: record });
        },
      },
      {
        key: 3,
        label: (
          <a
            className="text-red-500"
            onClick={() =>
              handleDelete(
                record?.id,
                objectType ? record.file_name : record?.name,
              )
            }
          >
            Xóa
          </a>
        ),
      },
    ];
  };

  const columns = [
    {
      title: 'Tên',
      dataIndex: 'name',
      width: '30%',
      render: (val, record) => (
        <div className="flex items-center gap-2">
          {!objectType
            ? typeFileObject['folder']
            : getIconFile(record.file_type)}
          <span>{val ?? record?.file_name}</span>
        </div>
      ),
    },
    {
      title: 'Người đăng',
      dataIndex: 'created_by',
      width: '30%',
      render: (val) =>
        val && (
          <>
            <span className="text-green-500">[{val?.code}]</span> -{' '}
            {val?.full_name}
          </>
        ),
    },
    {
      title: 'Ngày đăng',
      dataIndex: 'created_at',
      width: '20%',
      render: (val) => val && moment(val).format('DD/MM/YYYY HH:mm:ss'),
    },
    {
      title: 'Dung lượng',
      dataIndex: 'size',
      width: '20%',
      render: (val, record) => convertBytes(val ?? record.file_size),
    },
    {
      title: '',
      width: 80,
      dataIndex: 'operation',
      render: (_, record) => (
        <ActionDropdown
          dropdownItems={generateDropDownItems(record)}
          trigger="click"
        />
      ),
    },
  ];
  return (
    <>
      <Table
        data={value}
        columns={columns}
        rowKey={'name'}
        onRow={(record) => ({
          onDoubleClick: () =>
            !objectType &&
            navigate(`${FILE_MANAGEMENT_URL.detail.path}/${record.name}`),
        })}
      />
      {modal?.open && objectType ? (
        <FileModal
          open={modal?.open}
          value={modal?.value}
          onClose={() => setModal({ open: false })}
        />
      ) : (
        <FolderModal
          open={modal?.open}
          value={modal?.value}
          onClose={() => setModal({ open: false })}
        />
      )}
    </>
  );
};

export default FileTable;
