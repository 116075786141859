import { SelectProps } from 'tera-dls/lib/components/Select';
import { useQuery } from '@tanstack/react-query';
import { forwardRef, useState } from 'react';
import { useDebounce } from '_common/hooks/useDebounce';
import SelectEntity from '../SelectEntity';
import { AdministratorApi } from '../../_api';
import { TParamsApiDof } from '../../interfaces';
import { EmployeeText } from '_common/component/EmployeeText';

interface IProps extends SelectProps {
  paramsApi?: TParamsApiDof;
  defaultOptions?: any;
  parentMenuId?: number;
  disableOptions?: number[] | string[];
}

const SelectPageConfig = forwardRef<HTMLInputElement, IProps>((props, ref) => {
  const {
    parentMenuId,
    defaultOptions = [],
    paramsApi,
    disableOptions,
    ...restProps
  } = props;
  const [searchPageConfig, setSearchPageConfig] = useState('');
  const searchPageConfigDebounce = useDebounce(searchPageConfig, 300);

  const paramsQuery = {
    limit: 15,
    page: 1,
    keyword: searchPageConfigDebounce,
    ...(parentMenuId && { epic_id: parentMenuId }),
    ...paramsApi,
  };

  const { data: pageConfigs, isLoading } = useQuery(
    ['get-page-list', paramsQuery],
    () => AdministratorApi.getListPage(paramsQuery),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const options =
    pageConfigs?.data?.map((item) => {
      return {
        label: item.title,
        value: item.id,
        labelDisplay: <EmployeeText code={item?.code} name={item?.title} />,
        ...(disableOptions &&
          disableOptions.includes(item.id as never) && { disabled: true }),
      };
    }) ?? [];

  return (
    <SelectEntity
      ref={ref}
      allowClear
      showSearch
      labelInValue
      options={[...defaultOptions, ...options]}
      onSearch={setSearchPageConfig}
      searchValue={searchPageConfigDebounce}
      loading={isLoading}
      {...restProps}
    />
  );
});

export default SelectPageConfig;
