import { useMutation, useQueries, useQueryClient } from '@tanstack/react-query';
import { folderUpload } from '_common/constants/attachment';
import { REGEX } from '_common/constants/common';
import {
  messageError,
  messageValidate,
  messageValidateLength,
  messageWarning,
} from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import Select from '_common/dof/Control/Select';
import TextArea from '_common/dof/Control/TextArea';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectDataType from '_common/dof/Select/SelectDataType';
import SelectModule from '_common/dof/Select/SelectModule';
import SelectObjectType from '_common/dof/Select/SelectObjectType';
import UploadFiles from '_common/dof/UploadFiles';
import useConfirm from '_common/hooks/useConfirm';
import { convertListOrder } from '_common/utils';
import _ from 'lodash';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Col,
  Modal,
  OptionProps,
  Row,
  Spin,
  ValueType,
  notification,
} from 'tera-dls';
import DataTypeApi from '../../_api';
import { useDebounce } from '_common/hooks/useDebounce';

interface IFormProp {
  open: boolean;
  onClose: () => void;
  onRefetch: () => void;
  id: string;
}

function DataTypeForm({ open, onClose, onRefetch, id }: IFormProp) {
  const form = useForm();
  const {
    watch,
    setValue,
    formState: { isDirty },
  } = form;
  const objTypeKey = watch('objtype_key');
  const module = watch('module');
  const [fileList, setFileList] = useState(null);
  const [selectedDataType, setSelectedDataType] = useState(null);
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const [search, setSearch] = useState<string>('');
  const searchDebounce = useDebounce(search, 300);
  const [
    { data: dataDetail, isLoading, isError, refetch },
    { data: listOrder },
  ] = useQueries({
    queries: [
      {
        queryKey: ['get-detail-data-type', id],
        queryFn: () => DataTypeApi.getDetail(id),
        enabled: !!id,
        cacheTime: 300000,
        staleTime: 300000,
      },
      {
        queryKey: ['get-list-order-data-type', id, objTypeKey, searchDebounce],
        queryFn: () => {
          const params = {
            order_field: 'order',
            order_by: 'asc',
            data_type_id: id,
            object_type: objTypeKey,
            keyword: searchDebounce,
            get_all: true,
          };
          return DataTypeApi.getList(params);
        },
        enabled: !!objTypeKey,
      },
    ],
  });

  const handleClose = () => {
    onClose();
    form.reset();
  };

  const { mutate: submitForm, isLoading: loadingSubmit } = useMutation(
    (variable) => {
      if (id) return DataTypeApi.update(id, variable);
      return DataTypeApi.create(variable);
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-list-data-type']);
          onRefetch();
          handleClose();
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) => {
        const errorMessage = error?.data?.errors || messageError.ERROR_API;
        const flattenMessage = Object.values(errorMessage).flat();
        notification.error({
          message: (
            <ul>
              {flattenMessage?.map((message: ReactNode) => (
                <li>{message}</li>
              ))}
            </ul>
          ),
        });
      },
    },
  );

  const handleCloseConfirm = () => {
    if (isDirty || fileList?.length > 0) {
      confirm.warning({
        title: 'THOÁT BẢN GHI',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => onClose(),
      });
    } else onClose();
  };

  const handleSubmitForm = (values) => {
    const params = {
      ...values,
      file_upload: fileList?.[0],
      order: values.order ?? undefined,
    };
    submitForm(params);
  };

  useEffect(() => {
    if (id) refetch();
  }, [id]);

  useEffect(() => {
    if (dataDetail) {
      const data = _.pick(dataDetail, [
        'key',
        'objtype_key',
        'module',
        'title',
        'value',
        'color',
        'description',
        'parent_key',
      ]);
      setFileList(
        dataDetail?.media_id && [
          {
            name: dataDetail?.file_name,
            url: dataDetail?.file_url,
            id: dataDetail?.media_id,
          },
        ],
      );
      // setSelectedObjectType({
      //   value: dataDetail?.object_type?.key,
      //   label: dataDetail?.object_type?.object_type,
      // });
      form.reset({
        ...data,
        module: data?.module?.id,
        order: dataDetail?.order ? Number(dataDetail?.order) - 1 : 0,
      });
    }
  }, [dataDetail]);

  const optionsOrder: OptionProps[] = useMemo(() => {
    return convertListOrder(listOrder?.data, id, null);
  }, [listOrder]);

  if (isError) {
    onClose();
    notification.error({ message: messageError.DATA_NOT_FOUND });
  }

  return (
    <Modal
      closeIcon={false}
      open={open}
      okText="Đồng ý"
      cancelText="Hủy"
      title={id ? 'SỬA LOẠI DỮ LIỆU' : 'THÊM LOẠI DỮ LIỆU'}
      width={1000}
      onOk={() => form.handleSubmit(handleSubmitForm)()}
      onCancel={handleCloseConfirm}
      confirmLoading={loadingSubmit || (isLoading && !!id)}
    >
      <Spin spinning={isLoading && !!id}>
        <FormTera form={form} onSubmit={handleSubmitForm}>
          <Row className="grid grid-cols-2">
            <Col>
              <FormTeraItem
                label="Mã dữ liệu"
                name="key"
                rules={[
                  {
                    required: messageValidate?.emptyText,
                  },
                  {
                    maxLength: {
                      value: 191,
                      message: messageValidateLength?.key,
                    },
                  },
                  {
                    pattern: {
                      value: REGEX.KEY,
                      message: messageValidate?.key,
                    },
                  },
                ]}
              >
                <Input
                  maxLength={100}
                  placeholder="{module}_{type}_{key}"
                  // onChange={(e) => setValue('key', e.target.value.trim())}
                />
              </FormTeraItem>
              <FormTeraItem
                name="module"
                label="Module"
                rules={[
                  {
                    required: messageValidate?.emptySelect,
                  },
                ]}
              >
                <SelectModule
                  onClear={() => {
                    setValue('objtype_key', null);
                    setValue('order', null);
                  }}
                />
              </FormTeraItem>
              <FormTeraItem
                label="Loại dữ liệu"
                name="objtype_key"
                rules={[
                  {
                    required: messageValidate?.emptySelect,
                  },
                ]}
              >
                <SelectObjectType
                  labelInValue
                  disabled={!module}
                  onChangeCustom={() => {
                    setValue('order', null);
                  }}
                  onClear={() => {
                    setValue('order', null);
                  }}
                  paramsApi={{
                    module_id: module,
                  }}
                />
              </FormTeraItem>
              <FormTeraItem label="Parent key" name="parent_key">
                <SelectDataType
                  selectedValue={selectedDataType}
                  onChangeCustom={(value: ValueType) => {
                    setSelectedDataType(value);
                  }}
                  onClear={() => setSelectedDataType(null)}
                  paramsApi={{
                    except_key: dataDetail?.concatenated_key,
                    module_id: module,
                  }}
                />
              </FormTeraItem>
              <FormTeraItem
                label="Tiêu đề"
                name="title"
                rules={[
                  {
                    maxLength: {
                      value: 100,
                      message: messageValidateLength?.text,
                    },
                  },
                ]}
              >
                <Input maxLength={100} />
              </FormTeraItem>
              <FormTeraItem label="Thứ tự đứng sau" name="order">
                <Select
                  options={optionsOrder}
                  disabled={!objTypeKey}
                  allowClear
                  showSearch={listOrder?.total_items > 15}
                  onSearch={setSearch}
                />
              </FormTeraItem>
              <FormTeraItem
                label="Giá trị"
                name="value"
                rules={[
                  {
                    maxLength: {
                      value: 100,
                      message: messageValidateLength?.text,
                    },
                  },
                ]}
              >
                <Input maxLength={100} />
              </FormTeraItem>
              <FormTeraItem
                label="Màu sắc"
                name="color"
                rules={[
                  {
                    maxLength: {
                      value: 100,
                      message: messageValidateLength?.text,
                    },
                  },
                ]}
              >
                <Input maxLength={100} />
              </FormTeraItem>
            </Col>
            <Col>
              <FormTeraItem
                label="Mô tả"
                name="description"
                rules={[
                  {
                    maxLength: {
                      value: 1000,
                      message: messageValidateLength?.textAria,
                    },
                  },
                ]}
              >
                <TextArea rows={10} maxLength={1000} />
              </FormTeraItem>
              <UploadFiles
                fileList={fileList}
                isSingle
                accept="image/*"
                object_id={id}
                object_key={folderUpload.OBJECT_TYPE}
                folder={folderUpload.OBJECT_TYPE}
                onReceiveFiles={(file) => setFileList([file])}
                onRemove={() => setFileList([])}
              />
            </Col>
          </Row>
        </FormTera>
      </Spin>
    </Modal>
  );
}

export default DataTypeForm;
