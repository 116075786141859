import useConfirm from '_common/hooks/useConfirm';
import { useState } from 'react';
import {
  Dropdown,
  EllipsisHorizontalOutlined,
  FolderOpenSolid,
} from 'tera-dls';
import FolderModal from './FolderModal';
import { useNavigate } from 'react-router-dom';
import { FILE_MANAGEMENT_URL } from '_common/constants/url';

interface IProps {
  value?: any;
}

const FolderItem = (props: IProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const confirm = useConfirm();
  const navigate = useNavigate();
  const { value } = props;

  const handleDelete = () => {
    confirm.warning({
      title: 'Xác nhận xóa thư mục',
      content: (
        <>
          Bạn có chắc muốn xóa thư mục
          <span className="font-medium"> {value.name} </span>
          này không?
        </>
      ),
      onOk: () => {
        1;
      },
    });
  };

  const items = [
    {
      key: '2',
      label: 'Đổi tên',
      onClick: () => {
        setOpenModal(true);
      },
    },
    {
      key: '3',
      label: <span className="text-red-500">Xóa</span>,
      onClick: () => {
        handleDelete();
      },
    },
  ];
  return (
    <>
      <div
        className="p-2.5 flex gap-5 bg-gray-50 rounded-[0.625rem] items-center hover:bg-gray-200 cursor-pointer"
        onDoubleClick={() =>
          navigate(`${FILE_MANAGEMENT_URL.detail.path}/${value.name}`)
        }
      >
        <div className="flex gap-2.5 flex-1 items-center">
          <FolderOpenSolid className="w-[2rem] h-[2rem] fill-gray-500" />
          <span className="break-all"> {value?.name}</span>
        </div>
        <Dropdown
          containerClassName="w-14"
          placement="bottom-end"
          menu={{
            items,
          }}
          trigger="click"
        >
          <span>
            <EllipsisHorizontalOutlined className="w-[1.5rem] h-[1.5rem] cursor-pointer" />
          </span>
        </Dropdown>
      </div>
      {openModal && (
        <FolderModal
          open={openModal}
          value={value}
          onClose={() => setOpenModal(false)}
        />
      )}
    </>
  );
};

export default FolderItem;
