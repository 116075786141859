export const CONFIG_PAYMENT_METHOD_URL = {
  list: {
    key: 'payment-method',
    path: '/system/payment-method/list',
    shortenUrl: 'list',
  },
  create: {
    key: 'payment-method-create',
    path: '/system/payment-method/create',
    shortenUrl: 'create',
  },
  update: {
    key: 'payment-method-update',
    path: '/system/payment-method/update',
    shortenUrl: 'update/:id',
  },
  transactionHistory: {
    key: 'transaction-history',
    path: '/system/payment-method/transaction-history',
    shortenUrl: 'transaction-history',
  },
};
