import React from 'react';
import Menu1 from './Menu1';
import Menu2 from './Menu2';
import Menu4 from './Menu4';
import Menu5 from './Menu5';
import Menu6 from './Menu6';
import Menu7 from './Menu7';
import Menu8 from './Menu8';
import Menu9 from './Menu9';
import Menu10 from './Menu10';
import Menu11 from './Menu11';
import Menu12 from './Menu12';
import Menu13 from './Menu13';
import Menu14 from './Menu14';
import { TypeIcon } from './interface';
import Menu16 from './Menu16';
import Menu15 from './Menu15';
import Menu3 from './Menu3';
import Menu17 from './Menu17';

interface IconsProps {
  icon: TypeIcon;
}

function Icons({ icon }: IconsProps) {
  const renderIcon = {
    menu1: <Menu1 />,
    menu2: <Menu2 />,
    menu3: <Menu3 />,
    menu4: <Menu4 />,
    menu5: <Menu5 />,
    menu6: <Menu6 />,
    menu7: <Menu7 />,
    menu8: <Menu8 />,
    menu9: <Menu9 />,
    menu10: <Menu10 />,
    menu11: <Menu11 />,
    menu12: <Menu12 />,
    menu13: <Menu13 />,
    menu14: <Menu14 />,
    menu15: <Menu15 />,
    menu16: <Menu16 />,
    menu17: <Menu17 />,
  };
  return <div className="tera-menu__item">{renderIcon[icon]}</div>;
}

export default Icons;
