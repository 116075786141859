import DailyTime from '_common/component/DailyTime';
import { messageWarning } from '_common/constants/message';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import moment from 'moment';

import { DATE_BACKEND_FORMAT, DATE_FORMAT } from '_common/constants/common';
import InputNumber from '_common/dof/Control/InputNumber';
import RangePicker from '_common/dof/Control/RangePicker';
import {
  CASH_RECEIPT_TYPE,
  EXPENSE_VOUCHER_TYPE,
  PAYMENT_METHOD_TYPE,
} from 'pages/Invoice/constants';
import { ACCOUNT_TYPE } from 'pages/User/constants/statusType';
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Row } from 'tera-dls';
import { StatisticCashExpenseByCustomerParamContext } from '..';

interface IProps {
  open: boolean;
  onClose?: () => void;
  onSubmit: (value: any) => void;
  defaultValues?: any;
}

const SettingModal = (props: IProps) => {
  const { onClose, open, onSubmit, defaultValues } = props;
  const form = useForm({ mode: 'onChange' });
  const confirm = useConfirm();

  const { setContextParams } = useContext(
    StatisticCashExpenseByCustomerParamContext,
  );

  useEffect(() => {
    if (defaultValues) {
      form.reset(
        {
          ...defaultValues,
          ...(defaultValues.accounting_date?.[0] && {
            accounting_date: [
              moment(defaultValues.accounting_date?.[0]),
              moment(defaultValues.accounting_date?.[1]),
            ],
          }),
          ...(defaultValues.date?.[0] && {
            date: [
              moment(defaultValues.date?.[0]),
              moment(defaultValues.date?.[1]),
            ],
          }),
        },
        { keepDirty: false },
      );
    }
  }, [defaultValues]);

  const isDirty = form?.formState?.isDirty;

  const handleSubmitForm = (value: any): void => {
    const data = {
      ...value,
      ...(!!value.accounting_date?.[0] && {
        accounting_date: [
          value.accounting_date[0]?.format(DATE_BACKEND_FORMAT),
          value.accounting_date[1]?.format(DATE_BACKEND_FORMAT),
        ],
      }),
      ...(!!value.date?.[0] && {
        date: [
          value.date[0]?.format(DATE_BACKEND_FORMAT),
          value.date[1]?.format(DATE_BACKEND_FORMAT),
        ],
      }),
    };

    setContextParams(data);
    onSubmit(data);
    onClose();
  };

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
    } else onClose();
  };

  const expenseVoucherTypeOptions = Object.entries(EXPENSE_VOUCHER_TYPE).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  const cashReceiptTypeOptions = Object.entries(CASH_RECEIPT_TYPE).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  const paymentMethod = Object.entries(PAYMENT_METHOD_TYPE).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  const accountType = Object.entries(ACCOUNT_TYPE).map(([key, value]) => ({
    label: value,
    value: key,
  }));

  const receipt_from = form?.watch('receipt_total_from');
  const receipt_to = form?.watch('receipt_total_to');

  useEffect(() => {
    if (receipt_from) {
      form?.trigger('receipt_total_from');
    }
    if (receipt_to) {
      form?.trigger('receipt_total_to');
    }
  }, [receipt_from, receipt_to]);

  const expense_from = form?.watch('expense_total_from');
  const expense_to = form?.watch('expense_total_to');

  useEffect(() => {
    if (receipt_from) {
      form?.trigger('expense_total_from');
    }
    if (expense_to) {
      form?.trigger('expense_total_to');
    }
  }, [expense_from, expense_to]);

  return (
    <Modal
      title={'Tham số báo cáo'}
      destroyOnClose
      closeIcon={false}
      width={500}
      cancelText="Hủy"
      okText="Lưu"
      onOk={form.handleSubmit(handleSubmitForm)}
      onCancel={handleCloseConfirm}
      open={open}
      centered={true}
    >
      <FormTera form={form} onSubmit={handleSubmitForm}>
        <Row className="grid grid-cols-1">
          <FormTeraItem
            name="type_customer"
            label="Loại người dùng"
            className="col-span-1"
          >
            <Select placeholder="Vui lòng chọn" options={accountType} />
          </FormTeraItem>
          <FormTeraItem
            className="col-span-1"
            label="Phương thức thanh toán"
            name="pay_method_type"
          >
            <Select
              placeholder="Vui lòng chọn"
              mode="multiple"
              options={paymentMethod}
            />
          </FormTeraItem>

          <FormTeraItem label="Ngày hạch toán" name="accounting_date">
            <RangePicker
              format={DATE_FORMAT}
              placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
              className="bg-white rounded-[3px]"
            />
          </FormTeraItem>
          <FormTeraItem
            className="col-span-1"
            label="Loại phiếu thu"
            name="receipt_type"
          >
            <Select
              allowClear
              placeholder="Vui lòng chọn"
              options={cashReceiptTypeOptions}
              mode="multiple"
            />
          </FormTeraItem>
          <FormTeraItem
            className="col-span-1"
            label="Loại phiếu chi"
            name="expense_type"
          >
            <Select
              placeholder="Vui lòng chọn"
              options={expenseVoucherTypeOptions}
              mode="multiple"
              allowClear
            />
          </FormTeraItem>
          <FormTeraItem className="col-span-1" label="Tổng giá trị thu" name="">
            <div className="grid grid-cols-2 gap-2.5 w-full">
              <FormTeraItem
                className="mb-0"
                label=""
                name="receipt_total_from"
                rules={[
                  {
                    validate: {
                      validate: (value, values) => {
                        if (
                          values.receipt_total_to &&
                          value &&
                          value > values.receipt_total_to
                        )
                          return 'Vui lòng nhập số nhỏ hơn';
                      },
                    },
                  },
                ]}
              >
                <InputNumber
                  placeholder="Vui lòng nhập"
                  min={0}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                />
              </FormTeraItem>
              <FormTeraItem
                className="mb-0"
                label=""
                name="receipt_total_to"
                rules={[
                  {
                    validate: {
                      validate: (value, values) => {
                        if (
                          values.receipt_total_from &&
                          value &&
                          value < values.receipt_total_from
                        )
                          return 'Vui lòng nhập số lớn hơn';
                      },
                    },
                  },
                ]}
              >
                <InputNumber
                  placeholder="Vui lòng nhập"
                  min={0}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                />
              </FormTeraItem>
            </div>
          </FormTeraItem>
          <FormTeraItem className="col-span-1" label="Tổng giá trị chi" name="">
            <div className="grid grid-cols-2 gap-2.5 w-full">
              <FormTeraItem
                className="mb-0"
                label=""
                name="expense_total_from"
                rules={[
                  {
                    validate: {
                      validate: (value, values) => {
                        if (
                          values.expense_total_to &&
                          value &&
                          value > values.expense_total_to
                        )
                          return 'Vui lòng nhập số nhỏ hơn';
                      },
                    },
                  },
                ]}
              >
                <InputNumber
                  placeholder="Vui lòng nhập"
                  min={0}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                />
              </FormTeraItem>
              <FormTeraItem
                className="mb-0"
                label=""
                name="expense_total_to"
                rules={[
                  {
                    validate: {
                      validate: (value, values) => {
                        if (
                          values.expense_total_from &&
                          value &&
                          value < values.expense_total_from
                        )
                          return 'Vui lòng nhập số lớn hơn';
                      },
                    },
                  },
                ]}
              >
                <InputNumber
                  placeholder="Vui lòng nhập"
                  min={0}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                />
              </FormTeraItem>
            </div>
          </FormTeraItem>
          <DailyTime
            form={form}
            timeKey="time"
            timeFormProps={{
              label: 'Ngày thu chi',
            }}
            dateKey="date"
            dateFormProps={{
              label: 'Từ ngày - đến ngày',
              rules: [{ required: 'Vui lòng chọn' }],
            }}
          />
        </Row>
      </FormTera>
    </Modal>
  );
};

export default SettingModal;
