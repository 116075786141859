import { useMutation, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { messageWarning } from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import PortalAPI from 'pages/FileManagement/_api';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, notification } from 'tera-dls';

interface IProps {
  open: boolean;
  value: any;
  onClose?: () => void;
}

const FileModal = (props: IProps) => {
  const { open, onClose, value } = props;
  const confirm = useConfirm();
  const form = useForm();
  const queryClient = useQueryClient();

  const {
    setValue,
    reset,
    formState: { isDirty },
  } = form;

  useEffect(() => {
    value && setValue('name', value?.file_name);
  }, [value]);

  const handleClose = () => {
    onClose();
    reset();
  };

  const handleOnCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          handleClose();
        },
      });
    } else {
      handleClose();
    }
  };
  const { mutate: mutateUpdate } = useMutation(
    (variable: any) => PortalAPI.update(variable),
    {
      onSuccess: (res: any) => {
        if (res?.status === 200) {
          queryClient.invalidateQueries(['get-file-management-list']);
          handleClose();
          notification.success({
            message: res?.data?.msg,
          });
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleSubmitFrom = (data) => {
    mutateUpdate({
      id: value.id,
      params: {
        object_id: value.id,
        object_type: value.object_type,
        file_name: data.name,
      },
    });
  };

  return (
    <Modal
      title={'Đổi tên'}
      destroyOnClose
      okText="Lưu"
      cancelText="Hủy"
      closeIcon={false}
      open={open}
      onOk={() => form.handleSubmit(handleSubmitFrom)()}
      onCancel={handleOnCloseConfirm}
      width={500}
    >
      <FormTera form={form} isLoading={false}>
        <FormTeraItem
          rules={[{ required: 'Vui lòng nhập' }]}
          label="Tên tệp"
          name="name"
        >
          <Input placeholder="Vui lòng nhập" />
        </FormTeraItem>
      </FormTera>
    </Modal>
  );
};

export default FileModal;
