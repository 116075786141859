export const EPIC_KEY = {
  DASHBOARD: 'dashboard',
  USER_LIST: 'user_list',
  BUSINESS_LIST: 'business_list',
  MODULE_LIST: 'module_list',
  CONFIG_DATA: 'config_data',
  CONFIG_STATUS: 'config_status',
  CONFIG_PERMISSION: 'config_permission',
  MANAGE_EPIC: 'manage_epic',
  MANAGE_MODULE: 'manage_module',
  MANAGE_GROUP_CONTROL: 'manage_group_control',
};

export const PAGE_KEY = {
  DASHBOARD_LIST_VIEW: 'admin_dashboard_list_view',
  DASHBOARD_LIST_CREATE: 'admin_dashboard_list_create',
  USER_VIEW: 'admin_user_list_view',
  USER_DETAIL: 'admin_user_list_view_detail',
  FILE_MANAGEMENT_LIST_VIEW: 'file_management_list_view',
  FILE_MANAGEMENT_LIST_DETAIL: 'file_management_list_detail',

  CONFIG_DATA_LIST_VIEW: 'admin_config_data_list_view',
  BUSINESS_LIST_VIEW: 'admin_business_list_view',
  BUSINESS_CREATE: 'admin_business_create',
  BUSINESS_UPDATE: 'admin_business_update',
  BUSINESS_DETAIL: 'admin_business_detail',
  CONFIG_STATUS_LIST_VIEW: 'admin_config_status_list_view',
  CONFIG_PERMISSION_LIST_VIEW: 'admin_config_permission_list',
  CONFIG_PERMISSION_LIST_SETTING: 'admin_config_permission_list_setting',
  EPIC_LIST_VIEW: 'admin_manage_epic_list_view',
  MODULE_LIST_VIEW: 'admin_manage_module_list_view',
  GROUP_CONTROL_LIST_VIEW: 'admin_manage_group_control_list_view',
};

export const TABLE_KEY = {
  USER_VIEW_USER: 'user_list_view_user',
};

export const BUTTON_KEY = {
  USER_CREATE: 'user_list_create',
  USER_UPDATE: 'user_list_update',
  USER_DETAIL: 'user_list_detail',
  USER_DELETE: 'user_list_delete',
  USER_ACTIVATION: 'user_list_activation',
  USER_RESET_PASSWORD: 'user_list_reset_password',
  MODULE_CREATE: 'module_create',
  MODULE_UPDATE: 'module_update',
  MODULE_DELETE: 'module_delete',
  MODULE_DETAIL: 'module_detail',
  GROUP_CONTROL_CREATE: 'module_create',
  GROUP_CONTROL_UPDATE: 'module_update',
  GROUP_CONTROL_DELETE: 'module_delete',
  GROUP_CONTROL_DETAIL: 'module_detail',
  BUSINESS_CREATE: 'module_create',
  BUSINESS_UPDATE: 'module_update',
  BUSINESS_DELETE: 'module_delete',
  BUSINESS_DETAIL: 'module_detail',
  FILE_MANAGEMENT_LIST_CREATE: 'file_management_list_create',
  CONFIG_PERMISSION_LIST_CREATE: 'config_permission_list_create',
  CONFIG_PERMISSION_LIST_COPY: 'config_permission_list_copy',
  CONFIG_PERMISSION_LIST_SETTING: 'config_permission_list_setting',
  CONFIG_PERMISSION_LIST_UPDATE: 'config_permission_list_update',
  CONFIG_PERMISSION_LIST_DELETE: 'config_permission_list_delete',
  CONFIG_PERMISSION_LIST_DETAIL: 'config_permission_list_detail',
  PRICE_QUOTE_LIST_DETAIL: 'contact_management_employee_list_detail',
  PRICE_QUOTE_LIST_APPROVE: 'contact_management_employee_list_approve',
  PRICE_QUOTE_LIST_REJECT: 'contact_management_employee_list_reject',
};

export const PAGE_TABLE_OBJECT_TYPE = {
  USER_LIST: 'user_list',
};
