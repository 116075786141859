import { useMutation, useQuery } from '@tanstack/react-query';
import HoverQuickView from '_common/component/HoverQuickView';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { timeFormat } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import useConfirm from '_common/hooks/useConfirm';
import InvoiceApi from 'pages/Invoice/_api';
import { TRANSACTION_METHOD, TRANSACTION_TYPE } from 'pages/Invoice/constants';
import ConfigPaymentMethodApi from 'pages/System/containers/ConfigPaymentMethod/_api';
import ModalConfirmPayment from 'pages/System/containers/ConfigPaymentMethod/components/ModalConfirmPayment';
import { statusTransactionRender } from 'pages/System/containers/ConfigPaymentMethod/constants';
import { useEffect, useMemo, useState } from 'react';
import {
  DropdownItem,
  formatCurrency,
  formatDate,
  notification,
  PaginationProps,
} from 'tera-dls';

const Transaction = ({ id }) => {
  const [params, setParams] = useState<any>({});
  const confirm = useConfirm();
  const [idValue, setIdValue] = useState(null);
  const [openDetail, setOpenDetail] = useState(false);
  const {
    data: response,
    refetch,
    isLoading,
  } = useQuery(
    ['get-invoice-transaction', id, params],
    () =>
      InvoiceApi.getTransaction({
        params: { bill_id: id, page: 1, limit: 10, ...params },
      }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { data: dataDetail, isLoading: isLoadingDetail } = useQuery(
    ['get-transaction-find', idValue],
    () => ConfigPaymentMethodApi.getTransactionFind(idValue),
    {
      cacheTime: 300000,
      staleTime: 300000,
      enabled: !!idValue,
    },
  );

  useEffect(() => {
    id && refetch();
  }, [id]);

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    setParams({
      page: page,
      limit: pageSize,
    });
  };
  const convertData = useMemo(
    () =>
      response?.data?.map((item) => ({
        ...item,
        ...(item.transaction ?? {}),
      })),
    [response?.data],
  );

  const { mutate: updateStatus } = useMutation(
    (variable: any) => {
      return ConfigPaymentMethodApi.getTransactionUpdate(idValue, variable);
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          refetch();
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) => ErrorToast({ errorProp: error?.data }),
    },
  );

  const confirmUpdateStatus = (status: number) => {
    const statusText = {
      1: 'Hoàn thành',
      2: 'Thất bại',
      4: 'Báo lỗi',
    };
    confirm.warning({
      title: 'THAY ĐỔI TRẠNG THÁI',
      content: (
        <>
          <p>Bạn có chắc muốn thay đổi trạng thái sang</p>
          <p>
            <b>{statusText[status]}</b> không?
          </p>
        </>
      ),
      onOk: () => {
        updateStatus({
          status: status,
        });
      },
    });
  };

  const renderDropdown = (record): DropdownItem[] => {
    // const isFinished = record?.status === 1;
    // const isFailed = record?.status === 2;
    const isProcessing = record?.status === 3;
    const isError = record?.status === 4;
    const result = [];

    result.push({
      key: 1,
      label: 'Xem',
      onClick: () => {
        setIdValue(record?.id);
        setOpenDetail(true);
      },
    });
    (isError || isProcessing) &&
      result.push({
        key: 2,
        label: 'Hoàn thành',
        onClick: () => {
          setIdValue(record?.id);
          confirmUpdateStatus(1);
        },
      });

    (isError || isProcessing) &&
      result.push({
        key: 3,
        label: 'Thất bại',
        onClick: () => {
          setIdValue(record?.id);
          confirmUpdateStatus(2);
        },
      });

    isProcessing &&
      result.push({
        key: 4,
        label: 'Báo lỗi',
        onClick: () => {
          setIdValue(record?.id);
          confirmUpdateStatus(4);
        },
      });
    return result;
  };

  const columns: any = [
    {
      title: 'Mã giao dịch',
      dataIndex: 'transaction_code',
      render: (data) => (
        <span className="text-blue-600 line-clamp-1">{data}</span>
      ),
      width: 150,
    },
    {
      title: 'Khách hàng',
      dataIndex: 'bill',
      render: (value) => (
        <HoverQuickView
          avatarUrl={value?.avatar_url}
          email={value?.customer_email}
          name={value?.customer_name}
          phone={value?.customer_phone}
          className="line-clamp-2"
        >
          {value?.customer_name}
        </HoverQuickView>
      ),
      width: 150,
    },

    {
      title: 'Loại giao dịch',
      dataIndex: 'transaction_type',
      width: 150,
      render: (data) => (
        <div className="flex items-center gap-1">{TRANSACTION_TYPE[data]}</div>
      ),
    },
    {
      title: 'Số tiền',
      dataIndex: 'amount',
      render: (amount) => formatCurrency(amount),
      width: 150,
    },
    {
      title: 'Phương thức',
      dataIndex: 'methods',
      width: 150,
      render: (method) => TRANSACTION_METHOD[method],
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 150,
      render: (status) => statusTransactionRender[status],
    },
    {
      title: 'Thời gian tạo',
      dataIndex: 'created_at',
      render: (data) => formatDate(data, timeFormat.date_time),
      width: 150,
    },
    {
      dataIndex: 'action',
      width: 80,
      fixed: 'right',
      render: (_, record) => {
        const dropdownItems = renderDropdown && renderDropdown(record);
        return <ActionDropdown dropdownItems={dropdownItems} trigger="click" />;
      },
    },
  ];
  return (
    <>
      <TableTera
        loading={isLoading}
        data={convertData}
        columns={columns}
        pagination={{
          onChange: handleChangePage,
          total: response?.total || 0,
          current: response?.current_page || 1,
          pageSize: response?.per_page,
          to: response?.to,
          from: response?.from,
        }}
      />
      {openDetail && (
        <ModalConfirmPayment
          data={dataDetail}
          isLoading={isLoadingDetail}
          open={openDetail}
          onClose={() => setOpenDetail(false)}
        />
      )}
    </>
  );
};

export default Transaction;
