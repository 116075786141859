import Filter from '_common/component/Filter';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectModule from '_common/dof/Select/SelectModule';
import SelectObjectType from '_common/dof/Select/SelectObjectType';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Row } from 'tera-dls';

interface DataTypeFilterProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue: any;
}

function DataTypeFilter({
  open,
  onClose,
  onFilter,
  initialValue,
}: DataTypeFilterProps) {
  const default_module = useMemo(() => {
    return initialValue?.module ? Number(initialValue?.module) : null;
  }, [initialValue?.module]);
  const form = useForm({
    defaultValues: {
      module: default_module,
      object_type: initialValue?.object_type || null,
    },
  });
  const { handleSubmit, watch, setValue } = form;
  const module = watch('module');

  const handleSubmitForm = (value) => {
    onFilter(value);
    onClose();
  };

  const handleReset = () => {
    const values: any = {
      module: null,
      object_type: null,
    };
    onFilter(values);
    onClose();
  };

  return (
    <Filter
      onReset={handleReset}
      open={open}
      onClose={onClose}
      onCancel={onClose}
      onFilter={() => handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={handleSubmit(handleSubmitForm)}>
        <Row className="grid gap-y-0 pb-2.5">
          <FormTeraItem name="module" label="Module">
            <SelectModule
              onChangeCustom={() => setValue('object_type', null)}
            />
          </FormTeraItem>
          <FormTeraItem label="Data Type" className="!mb-0" name="object_type">
            <SelectObjectType
              disabled={!module}
              paramsApi={{ module_id: module }}
            />
          </FormTeraItem>
        </Row>
      </FormTera>
    </Filter>
  );
}

export default DataTypeFilter;
