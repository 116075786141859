import { PresetColors } from 'tera-dls/lib/components/Tag/style';

export const statusWorkText = {
  1: 'Đang làm việc',
  2: 'Nghỉ việc',
  3: 'Nghỉ chờ việc',
  4: 'Nghỉ tự túc',
  5: 'Tất cả',
};

export type StatusUser = 'active' | 'inactive';
export const statusWorkColor: Record<StatusUser, PresetColors> = {
  active: 'green03',
  inactive: 'red03',
};
export const laborContractTypeText = {
  4: 'Xác định thời điểm',
  5: 'Không xác định',
};

export const genderText = {
  1: 'Nam',
  2: 'Nữ',
};

export const statusText = {
  active: 'Đang hoạt động',
  inactive: 'Đang nghỉ việc',
};

export const statusColor = {
  0: '#cc0000',
  1: '#33cc33',
};

export const permissionText = {
  1: 'Quản trị viên',
  2: 'Người dùng',
};

export const educationTypeText = {
  1: 'Đại học',
  2: 'Cao đẳng',
  3: 'Chứng chỉ',
  4: 'Trung cấp',
  5: 'Trung học',
  6: 'Sơ cấp',
  7: 'Cao học',
  8: 'Giấy phép lái xe',
  9: 'Đào tạo nghề',
  10: 'Huấn luyện ATLĐ',
};

export const typeTrainingText = {
  CHINH_QUY: 'Chính quy',
  TAI_CHUC: 'Tại chức',
  LIEN_THONG: 'Liên thông',
};

export const rankText = {
  GIOI: 'Giỏi',
  KHA: 'Khá',
  TRUNG_BINH: 'Trung bình',
  TRUNG_BINH_KHA: 'Trung bình khá',
};

export const statusMarried = {
  1: 'Độc thân',
  2: 'Đã có gia đình',
  3: 'Ly dị',
};

export const typeProfile = {
  1: 'Bằng cấp',
  2: 'CMND/Passport',
  3: 'Giấy khám sức khỏe',
  4: 'Bản sao hộ khẩu',
  5: 'Bảo sao khai sinh',
  6: 'Bảo sao KT3',
  7: 'Sơ yêu lý lịch',
};

export const statusType = {
  0: 'Ngừng hoạt động',
  1: 'Đang hoạt động',
};

export const statusTypeColor = {
  0: 'gray02',
  1: 'green03',
};

export const USER_STATUS = {
  all: 'Tất cả',
  0: 'Ngừng hoạt động',
  1: 'Đang hoạt động',
};

export const ACCOUNT_STATUS = {
  is_active: 'Hoạt động',
  expired: 'Hết hạn',
  cancelled: 'Đã hủy',
};

export const ACCOUNT_STATUS_COLOR = {
  is_active: 'green03',
  expired: 'red03',
  cancelled: 'gray01',
};

export const ACCOUNT_TYPE = {
  individual: 'Cá nhân',
  owner: 'Chủ sở hữu',
  member: 'Thành viên',
};

export const MODULE_TYPE = {
  individual: 'Cá nhân',
  business: 'Doanh nghiệp',
};

export const ACTIVITY_STATUS = {
  is_active: 'Hoạt động',
  expired: 'Hết hạn',
  cancelled: 'Đã hủy',
};

export const TYPE_ACCOUNT = {
  individual: 'Cá nhân',
  owner: 'Chủ sở hữu',
  member: 'Thành viên',
};
