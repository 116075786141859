import { useQueryClient } from '@tanstack/react-query';
import { messageWarning } from '_common/constants/message';
import useConfirm from '_common/hooks/useConfirm';
import { useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftSolid,
  BookmarkOutlined,
  Breadcrumb,
  Button,
  Row,
} from 'tera-dls';
import ServicePackageContent, {
  IServicePackageFormContentRef,
} from './ServicePackageContent';

const ServicePackageForm = () => {
  const navigate = useNavigate();
  const actionRef = useRef<IServicePackageFormContentRef>(null);
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const { id } = useParams();

  const handleCloseConfirm = () => {
    if (actionRef.current?.getIsDirty()) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          navigate(-1);
        },
      });
    } else navigate(-1);
  };

  return (
    <>
      <div className="tera-page-form !gap-0 relative">
        <div className="sticky top-[45px] z-10 bg-[#F3F3F9]">
          <div className="page-header-v2">
            <div className="page-header-v2__breadcrumb">
              <div
                className="page-header__breadcrumb-back cursor-pointer"
                onClick={handleCloseConfirm}
              >
                <ArrowSmallLeftSolid className="h-6 w-6" />
              </div>
              <Breadcrumb
                separator={'>'}
                items={[
                  {
                    title: (
                      <a onClick={handleCloseConfirm}>
                        <span className="!text-blue-400 hover:!text-blue-600">
                          Danh sách gói dịch vụ
                        </span>
                      </a>
                    ),
                  },
                  {
                    title: id ? 'Sửa gói dịch vụ' : 'Thêm gói dịch vụ',
                  },
                ]}
              />
            </div>
            <div className="page-header-v2__function">
              <Button
                htmlType="submit"
                type="success"
                onClick={async () => {
                  actionRef?.current?.submit();
                }}
                className="page-header-btn px-[12px]"
              >
                <BookmarkOutlined className="w-4 h-4 stroke-2" />
                <span className="font-normal text-[16px] leading-[18px] ">
                  Lưu
                </span>{' '}
              </Button>
            </div>
          </div>
        </div>
        <Row className="bg-white rounded-[5px]">
          <ServicePackageContent
            ref={actionRef}
            id={id}
            onSuccess={() => {
              navigate(-1);
              queryClient.invalidateQueries(['get-service-package-detail']);
              queryClient.invalidateQueries(['get-service-package-list']);
            }}
          />
        </Row>
      </div>
    </>
  );
};

export default ServicePackageForm;
