import { IRouteProps } from '_common/interface/router';
import { MODULE_LIST_URL } from '_common/constants/url';
import ModulePage from './containers';

export const ModuleListRouter: IRouteProps[] = [
  {
    key: MODULE_LIST_URL.list.key,
    path: MODULE_LIST_URL.list.shortenUrl,
    component: <ModulePage />,
  },
];
