import { PERMISSION_KEY } from './permission';

export const CASH_RECEIPT_URL = {
  list: {
    key: PERMISSION_KEY.CASH_RECEIPT_VIEW_CASH_RECEIPT_LIST,
    path: '/invoice-management/cash-receipt/list',
    shortenUrl: 'cash-receipt/list',
  },
  create: {
    key: PERMISSION_KEY.CASH_RECEIPT_CREATE_CASH_RECEIPT,
    path: '/invoice-management/cash-receipt/create',
    shortenUrl: 'cash-receipt/create',
  },
  update: {
    key: PERMISSION_KEY.CASH_RECEIPT_UPDATE_CASH_RECEIPT,
    path: '/invoice-management/cash-receipt/update',
    shortenUrl: 'cash-receipt/update/:id',
  },
};
