import { useEffect, useState } from 'react';
import { formatCurrency, InputNumber } from 'tera-dls';

type InputAmountProps = {
  record: any;
  onChangeAmount: (value: number) => void;
};
const InputAmount = ({ record, onChangeAmount }: InputAmountProps) => {
  const [amount, setAmount] = useState(0);
  const lengthAmount = String(amount).length;

  useEffect(() => onChangeAmount(amount), [amount]);

  return (
    <div className="space-y-4">
      <h3 className="font-semibold text-base">Tổng tiền</h3>
      <p>Bạn muốn chuyển bao nhiêu?</p>
      <div className="h-[300px] flex items-center justify-center flex-col">
        <div className="flex items-center gap-x-2.5">
          <InputNumber
            min={0}
            value={amount}
            className="border-none focus:ring-0 p-0 text-5xl"
            style={{
              width: amount && lengthAmount > 0 ? 35 * lengthAmount : 35,
            }}
            allowStepHandler={false}
            onChange={(value: number) => setAmount(value)}
            formatter={(value) =>
              String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
          />
          <span>đ</span>
        </div>
        <p className="mt-2.5">
          Còn lại: {formatCurrency(record?.available_balance)}
        </p>
        <p className="mt-2.5 text-red-500">Tối thiếu: 10,000 đ</p>
      </div>
    </div>
  );
};

export default InputAmount;
