import Filter from '_common/component/Filter';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectBusinessId from '_common/dof/Select/SelectBusinessId';
import SelectEpic from '_common/dof/Select/SelectEpic';
import SelectModule from '_common/dof/Select/SelectModule';
import { useForm } from 'react-hook-form';
import { Row } from 'tera-dls';
import { statusOnOffString } from '../../../constants';
import { IFilter } from '../../interfaces';

interface ManagePageFilterProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue: any;
}

function ManagePageFilter({
  open,
  onClose,
  onFilter,
  initialValue,
}: ManagePageFilterProps) {
  const form = useForm<IFilter>({
    defaultValues: {
      module: initialValue?.module,
      epic: initialValue?.epic,
      status: initialValue?.status,
      business_id: initialValue?.business_id,
    },
  });

  const { handleSubmit, watch, setValue } = form;

  const handleSubmitForm = (value) => {
    onFilter(value);
    onClose();
  };

  const handleReset = () => {
    const values: IFilter = {
      module: null,
      epic: null,
      status: null,
      business_id: null,
    };
    onFilter(values);
    onClose();
  };

  const optionsStatus = Object.keys(statusOnOffString).map((key) => ({
    label: statusOnOffString[key]?.name,
    value: key,
  }));

  return (
    <Filter
      open={open}
      onClose={onClose}
      onCancel={onClose}
      onFilter={() => handleSubmit(handleSubmitForm)()}
      onReset={handleReset}
    >
      <FormTera form={form}>
        <Row className="grid gap-y-0">
          <FormTeraItem label="Module" name="module">
            <SelectModule
              paramsApi={{
                include_id: watch('module'),
              }}
              onChangeCustom={() => setValue('epic', null)}
            />
          </FormTeraItem>
          <FormTeraItem label="Epic" name="epic">
            <SelectEpic
              paramsApi={{
                include_id: watch('epic'),
                module_id: watch('module'),
              }}
            />
          </FormTeraItem>
          <FormTeraItem label="Trạng thái" name="status">
            <Select options={optionsStatus} />
          </FormTeraItem>
          <FormTeraItem label="business_id" name="business_id">
            <SelectBusinessId
              paramsApi={{ include_id: initialValue?.business_id }}
            />
          </FormTeraItem>
        </Row>
      </FormTera>
    </Filter>
  );
}

export default ManagePageFilter;
