import { useQuery } from '@tanstack/react-query';
import InvoiceTable from 'pages/Invoice/containers/Table';
import UserApi from 'pages/User/_api/userList';
import React, { useEffect, useState } from 'react';
import { filterField, PaginationProps } from 'tera-dls';

const Invoice = ({ id }) => {
  const [params, setParams] = useState<any>({ limit: 10, page: 1 });
  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery(
    ['get-user-invoice', params, id],
    () =>
      UserApi.getInvoice({
        params: filterField({
          ...params,
          user_id: id,
        }),
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
  }, []);

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    setParams({ page: page, limit: pageSize });
  };
  return (
    <InvoiceTable
      hiddenColumns={['action']}
      loading={isLoading}
      data={response?.data ?? []}
      hid
      pagination={{
        onChange: handleChangePage,
        pageSize: response?.per_page,
        current: response?.current_page,
        total: response?.total,
        from: response?.from,
        to: response?.to,
      }}
    />
  );
};

export default Invoice;
