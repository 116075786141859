import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import BlockHeader from '_common/component/UIV2/Detail/BlockHeader';
import useConfirm from '_common/hooks/useConfirm';
import UserListApi from 'pages/User/_api/userList';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthApi } from 'states/api';
import {
  ArrowSmallLeftSolid,
  Badge,
  Breadcrumb,
  Button,
  Dropdown,
  DropdownItem,
  EllipsisHorizontalOutlined,
  EnvelopeOutlined,
  notification,
  PhoneOutlined,
  Spin,
  StarOutlined,
  Tabs,
  Tag,
  UserOutlined,
} from 'tera-dls';
import UserForm, { IFormType } from '../Form';
import UserConfigPermission from '../Form/PermissionConfig';
import UserFormResetPassword from '../ResetPassword';
import {
  statusTypeColor,
  TYPE_ACCOUNT,
  USER_STATUS,
} from 'pages/User/constants/statusType';
import DetailMain from './DetailMain';
import Permission from './Permission';
import TransactionHistory from './TransactionHistory/TransactionHistory';
import Bank from './Bank/Bank';
import UpdateStatus from '../UpdateStatus';
import Invoice from './Invoice';
import { USER_URL } from '_common/constants/url';

function UserDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isOpenConfigPermission, setIsOpenConfigPermission] = useState(null);
  const [isResetPasswordForm, setIsResetPasswordForm] = useState(null);
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const [formModel, setFormModel] = useState<any>({ open: false });
  const [tab, setTab] = useState<any>('information');
  const [openUpdateStatus, setOpenUpdateStatus] = useState<any>({
    open: false,
  });
  const [params, setParams] = useState<any>({});

  const handleChangeTabs = (key) => {
    setTab(key);
  };

  const { data, refetch, isLoading } = useQuery(
    ['get-user-detail', id],
    () => UserListApi.getDetail({ id }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { data: summary, refetch: refetchSummary } = useQuery(
    ['get-user-summary-detail', id, params],
    () =>
      UserListApi.getSummaryDetail({
        params: { ...params, user_id: id, get_created_by: 2, get_at_admin: 1 },
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (id) {
      refetchSummary();
      refetch();
    }
  }, [id]);

  const sendMailResetPassword = useMutation(
    (params: any) => AuthApi.forgotPassword(params),
    {
      onSuccess: (res) => {
        if (res.code === 200) {
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleResetPassword = (record) => {
    confirm.warning({
      title: 'Xác nhận đặt lại mật khẩu',
      content: (
        <p>
          Bạn có chắc chán muốn đặt lại mật khẩu nhân viên{' '}
          <strong>{record?.full_name}</strong>. Việc này sẽ gửi thông báo cho
          Admin và thông tin đặt lại mật khẩu sẽ được gửi về mail đã đăng ký.
        </p>
      ),
      onOk: () => sendMailResetPassword.mutate({ email: record?.email }),
    });
  };

  const mutateDelete = useMutation(
    (id: string | number) => UserListApi.delete({ id }),
    {
      onSuccess: (res) => {
        if (res.code === 200) {
          queryClient.invalidateQueries(['get-list-user']);
          queryClient.invalidateQueries(['get-config-permission-summary']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleDeleteUser = (id: string | number, name: string) => {
    confirm.warning({
      title: 'Xác nhận xóa người dùng',
      content: (
        <>
          <p>Bạn có chắc chắn muốn xóa người dùng</p>
          <p>
            <b className="break-all">{name}</b> này không?
          </p>
        </>
      ),
      onOk: () => {
        mutateDelete.mutate(id);
      },
    });
  };

  const renderDropdown = (record): DropdownItem[] => {
    const dropdownItems: DropdownItem[] = [];
    dropdownItems.push({
      key: 11,
      label: 'Cập nhật trạng thái',
      onClick: () => setOpenUpdateStatus({ open: true, value: record?.id }),
    });
    dropdownItems.push({
      key: 2,
      label: 'Gửi email đặt lại mật khẩu',
      onClick: () => handleResetPassword(record),
    });
    dropdownItems.push({
      key: 3,
      label: 'Đặt lại mật khẩu',
      onClick: () => setIsResetPasswordForm({ open: true, id: record?.id }),
    });

    record?.type !== 'individual' &&
      dropdownItems.push({
        key: 6,
        label: <span>Phân quyền</span>,
        onClick: () => {
          setIsOpenConfigPermission({ open: true, dataDetail: record });
        },
      });
    dropdownItems.push({
      key: 5,
      label: <span className="text-red-500">Xóa</span>,
      onClick: () => handleDeleteUser(record?.id, record?.full_name),
    });

    return dropdownItems;
  };

  const tabs = {
    information: <DetailMain detail={data} />,
    permission: <Permission id={data?.id} />,
    transaction_list: (
      <TransactionHistory id={data?.id} onUpdateParams={setParams} />
    ),
    card_list: <Bank id={data?.id} onUpdateParams={setParams} />,
    bill_list: <Invoice id={data?.id} />,
  };

  const tabObject = {
    information: 'Chi tiết người dùng',
    permission: 'Danh sách quyền',
    transaction_list: 'Lịch sử giao dịch',
    card_list: 'Ngân hàng',
    bill_list: 'DS hóa đơn',
  };

  const tabItems = Object.entries(tabObject).map(([key, value]) => {
    const item = summary?.find((i) => i?.key === key);
    return {
      key: key,
      label: (
        <h3 className="tab-table">
          {value}
          {!['information', 'permission'].includes(key) && (
            <Badge showZero count={item?.total_count ?? 0} />
          )}
        </h3>
      ),
    };
  });

  return (
    <>
      <Spin spinning={isLoading}>
        <div className="tera-page-form">
          <div className="page-header-sticky">
            <div className="page-header-v2">
              <div className="page-header-v2__breadcrumb">
                <div
                  className="page-header-v2__breadcrumb cursor-pointer"
                  onClick={() => navigate(-1)}
                >
                  <ArrowSmallLeftSolid className="h-6 w-6" />
                </div>
                <Breadcrumb
                  separator={'>'}
                  items={[
                    {
                      title: (
                        <a onClick={() => navigate(USER_URL.list.path)}>
                          <span className="!text-blue-400 hover:!text-blue-600">
                            Danh sách người dùng
                          </span>
                        </a>
                      ),
                    },
                    {
                      title: 'Chi tiết người dùng',
                    },
                  ]}
                />
              </div>
              <div className="page-header-v2__function">
                <Button
                  className="page-header-btn"
                  onClick={() =>
                    setFormModel({ open: true, id: data?.id, type: data?.type })
                  }
                >
                  Sửa
                </Button>
                <Button
                  type="danger"
                  className="page-header-btn"
                  onClick={() => handleDeleteUser(data?.id, data?.full_name)}
                >
                  Xóa
                </Button>
                <Dropdown
                  menu={{ items: renderDropdown(data) }}
                  trigger="click"
                >
                  <Button
                    type="alternative"
                    className="rounded-xsm py-1 px-1 "
                    icon={
                      <EllipsisHorizontalOutlined className="w-5 h-5 text-blue-500" />
                    }
                  />
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="page-content-v2 gap-y-2.5">
            <div className="page-content-block-heading-v2">
              <div className="flex justify-between gap-2.5">
                <div className="page-content-block-title flex gap-2 items-center">
                  <img
                    src={data?.avatar_url}
                    className="w-[30px] h-[30px] rounded-full"
                  />
                  <span>{data?.full_name} </span>
                </div>
                <Tag color={statusTypeColor[data?.is_active]}>
                  {USER_STATUS[data?.is_active]}
                </Tag>
              </div>
              <div className="grid sm:grid-cols-2 xl:grid-cols-4 gap-4">
                <BlockHeader
                  title="Số điện thoại"
                  content={data?.phone}
                  icon={<PhoneOutlined className="w-5 h-5" />}
                />
                <BlockHeader
                  title="Email"
                  content={data?.email}
                  icon={<EnvelopeOutlined className="w-5 h-5" />}
                />
                <BlockHeader
                  title="Tên đăng nhập"
                  content={data?.username}
                  icon={<StarOutlined className="w-5 h-5" />}
                />
                <BlockHeader
                  title="Vai trò"
                  content={TYPE_ACCOUNT[data?.type]}
                  icon={<UserOutlined className="w-5 h-5" />}
                />
              </div>
            </div>
            <div className="page-content-block-heading-v2">
              <Tabs
                onChange={handleChangeTabs}
                items={tabItems}
                activeKey={tab}
              />
              {tabs[tab]}
            </div>
          </div>
        </div>
      </Spin>
      {isResetPasswordForm && (
        <UserFormResetPassword
          open={isResetPasswordForm?.open}
          onClose={() => setIsResetPasswordForm({ open: false })}
          id={isResetPasswordForm?.id}
        />
      )}
      {isOpenConfigPermission?.open && (
        <UserConfigPermission
          open={isOpenConfigPermission?.open}
          onClose={() => setIsOpenConfigPermission({ open: false })}
          id={isOpenConfigPermission?.dataDetail?.id}
        />
      )}
      {formModel.open && (
        <UserForm
          open={formModel.open}
          id={formModel?.id}
          type={formModel?.type as IFormType}
          onClose={() => {
            setFormModel({ open: false });
          }}
          onChangeDataSuccess={() => {
            queryClient.invalidateQueries(['get-list-user']);
            queryClient.invalidateQueries(['get-user-detail']);
          }}
        />
      )}
      {openUpdateStatus?.open && (
        <UpdateStatus
          open={openUpdateStatus?.open}
          onClose={() => {
            setOpenUpdateStatus({ open: false });
          }}
          value={openUpdateStatus?.value}
        />
      )}
    </>
  );
}

export default UserDetail;
