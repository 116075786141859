import { useQueryClient } from '@tanstack/react-query';
import ServicePackageProduct from '../../Product';
const ServicePackageTab = ({ id, isDefault }) => {
  const queryClient = useQueryClient();
  isDefault;
  return (
    <div>
      <ServicePackageProduct
        objectType="object"
        mode={'default'}
        {...(isDefault && { mode: 'view' })}
        id={id}
        onSuccess={() => {
          queryClient.invalidateQueries(['get-service-package-summary']);
        }}
      />
    </div>
  );
};

export default ServicePackageTab;
