import { useQuery } from '@tanstack/react-query';
import InvoiceApi from 'pages/Invoice/_api';
import { useEffect, useState } from 'react';
import { filterField, PaginationProps } from 'tera-dls';
import CashReceiptTable from '../CashReceipt/containers/Table';
import { TABLE_KEY } from '../CashReceipt/keys';

const CashReceipt = ({ id }) => {
  const [params, setParams] = useState<any>({ limit: 10, page: 1 });
  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery(
    ['get-invoice-cash-receipt', params, id],
    () =>
      InvoiceApi.getReceipt({
        params: filterField({
          ...params,
          bill_id: id,
        }),
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
  }, []);

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    setParams({ page: page, limit: pageSize });
  };

  return (
    <CashReceiptTable
      objectType={TABLE_KEY.CASH_RECEIPT_VIEW_CASH_RECEIPT_LIST_TABLE}
      data={response?.data?.map((item) => item?.voucher)}
      loading={isLoading}
      hiddenColumns={['operation']}
      pagination={{
        onChange: handleChangePage,
        pageSize: response?.per_page,
        current: response?.current_page,
        total: response?.total,
        from: response?.from,
        to: response?.to,
      }}
    />
  );
};

export default CashReceipt;
