import Filter from '_common/component/Filter';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectModule from '_common/dof/Select/SelectModule';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Row, updateQueryParams } from 'tera-dls';
import { IFilter } from '../../interfaces';

interface EpicFilterProps {
  open: boolean;
  onClose: () => void;
  initialValue: any;
}

function EpicFilter({ open, onClose, initialValue }: EpicFilterProps) {
  const navigate = useNavigate();
  const default_module = useMemo(() => {
    return initialValue?.module ? Number(initialValue?.module) : null;
  }, [initialValue?.module]);
  const form = useForm({
    defaultValues: {
      status: initialValue?.status,
      module: default_module,
    },
  });

  const { handleSubmit, setValue } = form;
  useEffect(() => {
    setValue('module', default_module);
    setValue('status', initialValue?.status);
  }, [initialValue]);

  const handleQueryParams = (query: IFilter) => {
    const paramString = updateQueryParams({
      page: 1,
      ...query,
    });
    navigate(paramString);
  };

  const handleSubmitForm = (value) => {
    handleQueryParams(value);
    onClose();
  };

  const handleReset = () => {
    const values: IFilter = {
      keyword: '',
      status: '',
      module_id: null,
    };
    handleQueryParams(values);
    onClose();
  };

  return (
    <Filter
      open={open}
      onClose={onClose}
      onCancel={onClose}
      onFilter={() => handleSubmit(handleSubmitForm)()}
      onReset={handleReset}
    >
      <FormTera form={form}>
        <Row className="grid gap-y-0">
          <FormTeraItem label="Module" className="flex-auto" name="module">
            <SelectModule placeholder="Chọn Module" />
          </FormTeraItem>
          <FormTeraItem label="Trạng thái" name="status">
            <Select
              options={[
                {
                  label: 'Bật',
                  value: 'active',
                },
                {
                  label: 'Tắt',
                  value: 'inactive',
                },
              ]}
            />
          </FormTeraItem>
        </Row>
      </FormTera>
    </Filter>
  );
}

export default EpicFilter;
