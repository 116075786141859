export const INVOICE_STATUS = {
  unpaid: 'Chưa thanh toán',
  paid: 'Đã thanh toán',
  in_process: 'Đang tiến hành',
  complete: 'Hoàn thành',
  fail: 'Thất bại',
};

export const INVOICE_STATUS_COLOR = {
  unpaid: 'yellow03',
  paid: 'blue03',
  in_process: 'indigo03',
  complete: 'green03',
  fail: 'red03',
};

export const INVOICE_TYPE = {
  active: 'Kích hoạt',
  upgrade: 'Nâng cấp',
  extend: 'Gia hạn',
  restart: 'Kích hoạt',
};

export const TRANSACTION_METHOD = {
  1: 'Chuyển khoản',
  2: 'Ví điện tử',
  3: 'Ví Tera',
};

export const TRANSACTION_TYPE = {
  1: 'Nạp tiền',
  2: 'Rút tiền',
  3: 'Thanh toán',
};

export const INVOICE_TABS = {
  information: 'Thông tin chi tiết',
  transaction: 'LS giao dịch',
  receipt: 'Phiếu thu',
};

export const CASH_RECEIPT_TYPE = {
  collect_money_customer: 'Thu tiền khách hàng',
  pay_fund: 'Nộp quỹ',
  other_revenue: 'Thu khác',
};

export const EXPENSE_VOUCHER_TYPE = {
  spend_money_customer: 'Trả tiền khách hàng',
  release_fund: 'Xuất quỹ',
  other_pay: 'Chi khác',
};

export const PAYMENT_METHOD_TYPE = {
  cash: 'Tiền mặt',
  transfer: 'Chuyển khoản',
};

export const STATISTIC_VALUE = {
  saleOrder: 'Đơn bán hàng',
  saleOrderReturn: 'Đơn trả hàng bán',
  // purchaseOrder: 'Đơn mua hàng',
  // purchaseOrderReturn: 'Đơn trả hàng mua',
  // priceOrder: 'Đơn báo giá',
  // purchaseRequest: 'Đơn yêu cầu mua hàng',
};

export const ORDER_STATUS = {
  request: 'Yêu cầu duyệt',
  reject: 'Từ chối',
  pending: 'Đang yêu cầu',
  approve: 'Hoàn thành',
  all: 'Tất cả',
};
