import Dashboard from 'pages/Dashboard';
import { IRouteProps } from '_common/interface/router';
import { DASHBOARD_URL } from '_common/constants/url';

export const DashboardRouter: IRouteProps[] = [
  {
    key: DASHBOARD_URL.list.key,
    path: DASHBOARD_URL.list.shortenUrl,
    component: <Dashboard />,
  },
  {
    key: DASHBOARD_URL.create.key,
    path: DASHBOARD_URL.create.shortenUrl,
    component: <Dashboard />,
  },
];
