import React, { useState } from 'react';
import Content from './containers/Content';
export const StatisticCashExpenseByCustomerParamContext =
  React.createContext(null);

const StatisticCashExpenseByCustomer = () => {
  const [contextParams, setContextParams] = useState<any>();
  return (
    <StatisticCashExpenseByCustomerParamContext.Provider
      value={{ contextParams, setContextParams }}
    >
      <Content />
    </StatisticCashExpenseByCustomerParamContext.Provider>
  );
};

export default StatisticCashExpenseByCustomer;
