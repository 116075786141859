import Search from '_common/dof/Control/Search';
import FormTera, { FormTeraItem, useFormTera } from '_common/dof/FormTera';
import { useEffect } from 'react';
import { MagnifyingGlassOutlined } from 'tera-dls';

function DataTypeHeader({ onSearch, initialValue }) {
  const [formRef] = useFormTera();

  const handleSearch = (value) => {
    onSearch(value);
    formRef?.current?.reset({ ...value }, { keepValues: true });
  };

  useEffect(() => {
    initialValue && formRef.current.reset(initialValue);
  }, [initialValue]);

  return (
    <FormTera
      ref={formRef}
      object_type="crm"
      onSubmit={handleSearch}
      isLoading={false}
      className="sm:w-full md:w-full lg:w-[400px]"
    >
      <FormTeraItem className="mb-0" name="keyword">
        <Search
          placeholder="Tìm kiếm theo mã dữ liệu, tiêu đề"
          icon={<MagnifyingGlassOutlined />}
          className="w-full"
        />
      </FormTeraItem>
    </FormTera>
  );
}

export default DataTypeHeader;
