import {
  Button,
  ChevronDownOutlined,
  Dropdown,
  DropdownProps,
  PlusOutlined,
} from 'tera-dls';

interface IButtonDropdown extends DropdownProps {
  title?: string;
}

function ButtonDropdown({ title = 'Thêm mới', ...props }: IButtonDropdown) {
  return (
    <Dropdown
      containerClassName="w-[50px] !top-1"
      placement="bottom-end"
      trigger="click"
      {...props}
    >
      <Button
        htmlType="button"
        className="px-[12px] py-[5px] rounded-[4px] flex gap-2.5 bg-blue-500"
      >
        <div className="flex items-center gap-[5px] shrink-0  ">
          <PlusOutlined className="w-[16px] text-white" />
          <span className="leading-[13px] text-[13px]">{title}</span>
        </div>
        <ChevronDownOutlined className="w-[16px] text-white" />
      </Button>
    </Dropdown>
  );
}

export default ButtonDropdown;
