import CardFormV2 from '_common/component/CardFormV2';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import classNames from 'classnames';
import {
  BUSINESS_STATUS,
  BUSINESS_STATUS_COLOR,
} from 'pages/Business/constants';
import { Tag, formatDate } from 'tera-dls';

interface ContentProps {
  dataDetail?: any;
}

function Content({ dataDetail }: ContentProps) {
  const renderDetail1 = () => {
    return [
      {
        title: 'Tên công ty',
        value: dataDetail?.name,
      },
      {
        title: 'Tên đăng ký GPKD của doanh nghiệp',
        value: dataDetail?.name_registration,
      },
      {
        title: 'Ngày đăng ký',
        value:
          dataDetail?.register_time && formatDate(dataDetail?.register_time),
      },
      {
        title: 'Trạng thái',
        value: (
          <Tag color={BUSINESS_STATUS_COLOR[dataDetail?.status]}>
            {BUSINESS_STATUS[dataDetail?.status]}
          </Tag>
        ),
        className: 'col-span-3',
      },
    ];
  };
  const renderDetail2 = () => {
    return [
      {
        title: 'Email công ty',
        value: dataDetail?.email,
      },

      {
        title: 'Quy mô nhân sự',
        value: dataDetail?.employee_size_text?.title,
      },

      {
        title: 'Phương thức thanh toán',
        value: dataDetail?.payment_methods_text?.title,
      },
    ];
  };
  const renderDetail3 = () => {
    return [
      {
        title: 'Người tạo',
        value: dataDetail?.user_created?.full_name,
      },

      {
        title: 'Ngày tạo',
        value:
          dataDetail?.created_at &&
          formatDate(dataDetail?.created_at, DATE_TIME_FORMAT),
      },

      {
        title: 'Người cập nhật',
        value: dataDetail?.user_updated?.full_name,
      },

      {
        title: 'Ngày cập nhật',
        value:
          dataDetail?.updated_at &&
          formatDate(dataDetail?.updated_at, DATE_TIME_FORMAT),
      },
    ];
  };
  const renderDetail4 = () => {
    return [
      {
        title: 'Địa chỉ công ty',
        value: dataDetail?.address,
        className: 'col-span-3',
      },
      {
        title: 'Giới thiệu công ty',
        value: dataDetail?.intro,
        className: 'col-span-3',
      },
    ];
  };

  const renderRepresentative = () => {
    return [
      {
        title: 'Họ và tên',
        value: dataDetail?.owner_name,
      },
      {
        title: 'Chức danh',
        value: dataDetail?.owner_job_title_text?.title,
      },
      {
        title: 'Email',
        value: dataDetail?.owner_email,
      },
      {
        title: 'Số điện thoại',
        value: dataDetail?.owner_phone,
      },
      {
        title: 'Phòng ban/Bộ phận',
        value: dataDetail?.owner_department_text?.title,
      },
    ];
  };

  const renderOther = () => {
    return [
      {
        title: 'Website',
        value: dataDetail?.website,
      },
      {
        title: 'Instagram',
        value: dataDetail?.instagram,
      },
      {
        title: 'Tiktok',
        value: dataDetail?.tiktok,
      },
      {
        title: 'Facebook',
        value: dataDetail?.facebook,
      },
      {
        title: 'LinkedIn',
        value: dataDetail?.linkedin,
      },
    ];
  };
  return (
    <div className="flex flex-col gap-y-5">
      <CardFormV2 title="Thông tin doanh nghiệp">
        <div className="grid grid-cols-3 gap-2.5">
          <div className="flex flex-col gap-y-2.5">
            {renderDetail1().map((item, index) => (
              <div
                className={classNames('flex gap-x-2', item?.className)}
                key={index}
              >
                <h6 className="detail-key">{item?.title}</h6>
                <span className="detail-value">{item?.value}</span>
              </div>
            ))}
          </div>
          <div className="flex flex-col gap-y-2.5">
            {renderDetail2().map((item, index) => (
              <div className={classNames('flex gap-x-2')} key={index}>
                <h6 className="detail-key">{item?.title}</h6>
                <span className="detail-value">{item?.value}</span>
              </div>
            ))}
          </div>
          <div className="flex flex-col gap-y-2.5">
            {renderDetail3().map((item, index) => (
              <div className={classNames('flex gap-x-2')} key={index}>
                <h6 className="detail-key">{item?.title}</h6>
                <span className="detail-value">{item?.value}</span>
              </div>
            ))}
          </div>
          <div className="flex flex-col gap-y-2.5 col-span-3">
            {renderDetail4().map((item, index) => (
              <div
                className={classNames('flex gap-x-2', item?.className)}
                key={index}
              >
                <h6 className="detail-key">{item?.title}</h6>
                <span className="detail-value">{item?.value}</span>
              </div>
            ))}
          </div>
        </div>
      </CardFormV2>
      <CardFormV2 title="Thông tin người đại diện">
        <div className="grid grid-cols-3 gap-2.5">
          {renderRepresentative().map((item, index) => (
            <div className="flex gap-x-2" key={index}>
              <h6 className="detail-key">{item?.title}</h6>
              <span className="detail-value">{item?.value}</span>
            </div>
          ))}
        </div>
      </CardFormV2>
      <CardFormV2 title="Thông tin khác">
        <div className="grid grid-cols-3 gap-2.5">
          {renderOther().map((item, index) => (
            <div className="flex gap-x-2" key={index}>
              <h6 className="detail-key">{item?.title}</h6>
              <span className="detail-value">{item?.value}</span>
            </div>
          ))}
        </div>
      </CardFormV2>
    </div>
  );
}

export default Content;
