import { useQuery } from '@tanstack/react-query';
import PaginationCustom from '_common/component/PaginationCustom';
import Search from '_common/dof/Control/Search';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  MagnifyingGlassOutlined,
  Modal,
  PaginationProps,
  Spin,
  Table,
} from 'tera-dls';
import { StatusTypeApi } from '../../_api';

function ObjectTypeList({ open, onClose }) {
  const [keyword, setKeyWord] = useState<string>('');
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });

  const form = useForm();

  const { data: listObjectType, isLoading } = useQuery(
    ['get-list-status-type', pagination, keyword],
    () => {
      const params = {
        keyword,
        ...pagination,
      };
      return StatusTypeApi.getList(params);
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const columns = [
    {
      title: 'Mã loại trạng thái',
      dataIndex: 'key',
    },
    {
      title: 'Tên loại trạng thái',
      dataIndex: 'object_type',
    },
  ];

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize =
      pagination?.limit && Number(pageSize) !== Number(pagination?.limit);
    setPagination({
      page: isDiffPageSize ? 1 : page,
      limit: pageSize,
    });
  };

  const handleSearch = (values) => {
    setKeyWord(values?.keyword);
    setPagination({
      page: 1,
      limit: pagination?.limit,
    });
  };

  return (
    <Modal
      closeIcon={false}
      open={open}
      title="DANH SÁCH LOẠI TRẠNG THÁI"
      width={1800}
      onOk={onClose}
      okText="Đóng"
      cancelButtonProps={{ className: 'hidden' }}
    >
      <Spin spinning={isLoading}>
        <div className="flex flex-col gap-y-6">
          <FormTera form={form} onSubmit={handleSearch} isLoading={false}>
            <FormTeraItem className="!mb-0" name="keyword">
              <Search
                placeholder="Tìm kiếm theo mã và tên loại trạng thái"
                icon={<MagnifyingGlassOutlined />}
                className="w-[400px]"
              />
            </FormTeraItem>
          </FormTera>
          <div className="bg-white shadow-xsm rounded-[5px] overflow_hidden">
            <Table columns={columns} data={listObjectType?.data || []} />
            <PaginationCustom
              onChange={handleChangePage}
              to={listObjectType?.to}
              from={listObjectType?.from}
              pageSize={Number(listObjectType?.per_page)}
              total={Number(listObjectType?.total)}
              current={Number(listObjectType?.current_page)}
            />
          </div>
        </div>
      </Spin>
    </Modal>
  );
}

export default ObjectTypeList;
