import React from 'react';

function Menu1(props) {
  return (
    <svg
      width="17"
      height="13"
      viewBox="0 0 17 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.42308 0C0.637133 0 0 0.637133 0 1.42308V4.5C0 5.28594 0.637133 5.92308 1.42308 5.92308H6.34615C7.1321 5.92308 7.76923 5.28594 7.76923 4.5V1.42308C7.76923 0.637134 7.1321 0 6.34615 0H1.42308ZM1 1.42308C1 1.18942 1.18942 1 1.42308 1H6.34615C6.57981 1 6.76923 1.18942 6.76923 1.42308V4.5C6.76923 4.73366 6.57981 4.92308 6.34615 4.92308H1.42308C1.18942 4.92308 1 4.73366 1 4.5V1.42308ZM11.2692 1.23077C10.4833 1.23077 9.84615 1.8679 9.84615 2.65385V9.42308C9.84615 10.209 10.4833 10.8462 11.2692 10.8462H15.5769C16.3629 10.8462 17 10.209 17 9.42308V2.65385C17 1.8679 16.3629 1.23077 15.5769 1.23077H11.2692ZM10.8462 2.65385C10.8462 2.42019 11.0356 2.23077 11.2692 2.23077H15.5769C15.8106 2.23077 16 2.42019 16 2.65385V9.42308C16 9.65674 15.8106 9.84615 15.5769 9.84615H11.2692C11.0356 9.84615 10.8462 9.65674 10.8462 9.42308V2.65385ZM2.65385 7.38462C1.8679 7.38462 1.23077 8.02175 1.23077 8.80769V10.6538C1.23077 11.4398 1.8679 12.0769 2.65385 12.0769H6.96154C7.74748 12.0769 8.38462 11.4398 8.38462 10.6538V8.80769C8.38462 8.02175 7.74748 7.38462 6.96154 7.38462H2.65385ZM2.23077 8.80769C2.23077 8.57403 2.42019 8.38462 2.65385 8.38462H6.96154C7.1952 8.38462 7.38462 8.57403 7.38462 8.80769V10.6538C7.38462 10.8875 7.1952 11.0769 6.96154 11.0769H2.65385C2.42019 11.0769 2.23077 10.8875 2.23077 10.6538V8.80769Z"
        fill="#6B7280"
      />
    </svg>
  );
}

export default Menu1;
