import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import TableTera from '_common/dof/TableTera';
import useInfiniteScrollTable from '_common/dof/TableTera/Hooks/useInfiniteScrollTable';
import { ITableRowActionRef } from '_common/dof/TableTera/_interfaces';
import { OPERATION_KEY } from '_common/dof/TableTera/constants';
import _ from 'lodash';
import ServicePackageApi from 'pages/ServicePackage/_api';
import {
  PRODUCT_AMOUNT_USER,
  PRODUCT_MEMORY_CAPACITY,
  PRODUCT_ORDER_AMOUNT,
  PRODUCT_TIME,
} from 'pages/ServicePackage/constants';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  formatCurrency,
  notification,
  PlusCircleOutlined,
  Tag,
} from 'tera-dls';

type Mode = 'default' | 'soft' | 'view';
interface IProps {
  tableProps?: any;
  mode?: Mode;
  onChange?: (value: any) => void;
  onTotalChange?: (value: any) => void;
  id?: number;
  limit?: number;
  objectType: string;
  onSuccess?: () => void;
}

export interface IServicePackageTableRef {
  checkError: () => boolean;
  getTotal: () => number;
  checkDirty: () => number;
}

export const normalizeData = (values) =>
  values.map((item) => ({
    id: item.id,
    name: item.name,
    price: item.price,
    quantity_capacity: item.quantity_capacity,
    quantity_order: item.quantity_order,
    quantity_user: item.quantity_user,
    extension_allowed: item.extension_allowed ? 1 : 0,
    status: item.status ? 1 : 0,
    time: item.time,
  }));

const ServicePackageProduct = (props: IProps, ref: any) => {
  const {
    tableProps = {},
    mode = 'default',
    onChange,
    onTotalChange,
    id,
    limit = 15,
    objectType,
    onSuccess,
  } = props;

  const actionRef = useRef<ITableRowActionRef>(null);
  const [dataSource, setDataSource] = useState<any>([]);
  const queryClient = useQueryClient();
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
    refetch,
  } = useInfiniteQuery({
    queryKey: ['get-service-package-product-list'],
    staleTime: 30000,
    cacheTime: 30000,
    queryFn: ({ pageParam }) =>
      ServicePackageApi.getProduct({
        params: {
          service_id: id,
          page: pageParam ?? 1,
          limit,
          show_all: 1,
        },
      }),

    enabled: mode !== 'soft' && !!id,
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.data?.data?.length > 0 ? allPages.length + 1 : undefined;
    },
  });

  useInfiniteScrollTable({
    objectType,
    callback: () => {
      if (hasNextPage && !isFetching && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
  });

  useEffect(() => {
    if (mode !== 'soft' && !!id) {
      queryClient.setQueryData(
        ['get-service-package-product-list'],
        (oldData: any) => {
          return {
            pages: [oldData?.pages?.[0] ?? []],
            pageParams: [1],
          };
        },
      );
      refetch();

      return () => {
        queryClient.invalidateQueries(['get-service-package-product-list']);
      };
    }
  }, [mode, id]);

  const { mutateAsync: mutateSave, isLoading: isSaving } = useMutation(
    (variables: any) => ServicePackageApi.saveProduct(variables),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          onSuccess && onSuccess();
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const { mutateAsync: mutateDelete } = useMutation(
    (variables: any) => ServicePackageApi.deleteProduct(variables),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          onSuccess && onSuccess();
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        console.log(error);

        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const value = useMemo(() => {
    return data?.pages?.reduce((acc, page) => {
      return _.unionBy(acc, page?.data ?? [], 'id');
    }, []);
  }, [data?.pages]);

  useEffect(() => {
    mode !== 'soft' &&
      id &&
      setDataSource(
        value?.map((item) => ({
          ...item,
          status: Boolean(item?.status),
          extension_allowed: Boolean(item?.extension_allowed),
        })),
      );
  }, [value, mode, id]);

  useEffect(() => {
    if (data?.pages?.length > 0 && mode !== 'soft') {
      const page = data?.pages?.[data?.pages?.length - 1];
      onTotalChange && onTotalChange(page?.data?.total);
    }
  }, [data?.pages, mode]);

  useImperativeHandle(
    ref,
    () => ({
      checkError() {
        return actionRef.current?.trigger();
      },
      checkDirty() {
        return actionRef.current?.isEditing;
      },
      getTotal() {
        // return response?.total ?? 0;
      },
    }),
    [actionRef, ref],
  );

  const isDisable = (isSaving || isFetching || isFetchingNextPage) && !!id;

  const handleAdd = async (record) => {
    try {
      const response = await mutateSave({
        params: { service_id: id, packages: normalizeData([record]) },
      });
      if (response?.code !== 200) return;
      queryClient.setQueryData(
        ['get-service-package-product-list'],
        (oldData: any) => {
          return {
            pages: [oldData?.pages?.[0]],
            pageParams: [oldData?.pageParams?.[0]],
          };
        },
      );
      refetch({
        refetchPage: (page, index) => {
          return index === 0;
        },
      });
    } catch (e) {}
  };

  const handleDelete = async (record, index) => {
    try {
      const response = await mutateDelete({ id: record.id });
      const currentPage = Math.floor(index / limit) + 1;
      if (!currentPage || currentPage <= 0 || response?.code !== 200) return;
      queryClient.setQueryData(
        ['get-service-package-product-list'],
        (oldData: any) => {
          return {
            pages: oldData?.pages?.filter(
              (_, index) => index <= currentPage - 1,
            ),
            pageParams: oldData?.pageParams.filter(
              (_, index) => index <= currentPage - 1,
            ),
          };
        },
      );

      refetch({
        refetchPage: (page, index) => {
          return index === currentPage - 1;
        },
      });
    } catch (e) {}
  };

  const handleUpdate = async (record) => {
    try {
      const response = await mutateSave({
        params: { service_id: id, packages: normalizeData([record]) },
      });
      const currentPage = Math.floor(record?.index / limit) + 1;
      if (!currentPage || currentPage <= 0 || response?.code !== 200) return;
      queryClient.setQueryData(
        ['get-service-package-product-list'],
        (oldData: any) => {
          return {
            pages: oldData?.pages?.filter(
              (_, index) => index <= currentPage - 1,
            ),
            pageParams: oldData?.pageParams.filter(
              (_, index) => index <= currentPage - 1,
            ),
          };
        },
      );

      refetch({
        refetchPage: (page, index) => {
          return index === currentPage - 1;
        },
      });
    } catch (e) {}
  };

  const timeOptions = Object.entries(PRODUCT_TIME).map(([key, value]) => ({
    label: value,
    value: String(key),
  }));
  const amountUserOptions = Object.entries(PRODUCT_AMOUNT_USER).map(
    ([key, value]) => ({
      label: value,
      value: String(key),
    }),
  );
  const orderAmountOptions = Object.entries(PRODUCT_ORDER_AMOUNT).map(
    ([key, value]) => ({
      label: value,
      value: String(key),
    }),
  );

  const memoryCapacityOptions = Object.entries(PRODUCT_MEMORY_CAPACITY).map(
    ([key, value]) => ({
      label: value,
      value: String(key),
    }),
  );

  const columns = [
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
      width: 150,
      editable: true,
      rules: [{ required: 'Vui lòng nhập' }],
      inputProps: {
        autoFocus: true,
        maxLength: 100,
      },
      render: (val) => <span className="break-word">{val}</span>,
    },
    {
      title: 'Thời gian',
      dataIndex: 'time',
      width: 150,
      editable: true,
      type: 'select',
      rules: [{ required: 'Vui lòng chọn' }],
      inputProps: {
        autoFocus: true,
        options: timeOptions,
      },
      render: (val) => (
        <span className="break-word">{PRODUCT_TIME?.[val] ?? ''}</span>
      ),
    },
    {
      title: 'SL người dùng',
      dataIndex: 'quantity_user',
      width: 150,
      editable: true,
      type: 'select',
      rules: [{ required: 'Vui lòng chọn' }],
      inputProps: {
        autoFocus: true,
        options: amountUserOptions,
      },
      render: (val) => (
        <span className="break-word">{PRODUCT_AMOUNT_USER?.[val] ?? ''}</span>
      ),
    },
    {
      title: 'SL đơn hàng',
      dataIndex: 'quantity_order',
      width: 150,
      editable: true,
      type: 'select',
      rules: [{ required: 'Vui lòng chọn' }],
      inputProps: {
        autoFocus: true,
        options: orderAmountOptions,
      },
      render: (val) => (
        <span className="break-word">{PRODUCT_ORDER_AMOUNT?.[val] ?? ''}</span>
      ),
    },
    {
      title: 'SL dung lượng',
      dataIndex: 'quantity_capacity',
      width: 150,
      editable: true,
      type: 'select',
      rules: [{ required: 'Vui lòng chọn' }],
      inputProps: {
        autoFocus: true,
        options: memoryCapacityOptions,
      },
      render: (val) => (
        <span className="break-word">
          {PRODUCT_MEMORY_CAPACITY?.[val] ?? ''}
        </span>
      ),
    },
    {
      title: 'Giá tiền',
      dataIndex: 'price',
      width: 150,
      type: 'int',
      editable: true,
      rules: [{ required: 'Vui lòng nhập' }],
      inputProps: {
        min: 0,
        formatter: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      },
      render: (val) => formatCurrency(val),
    },
    {
      title: 'Cho phép gia hạn',
      dataIndex: 'extension_allowed',
      width: 80,
      type: 'switch',
      editable: true,
      rules: [{ required: 'Vui lòng nhập' }],
      render: (val) =>
        val ? <Tag color="green05">Bật</Tag> : <Tag color="gray02">Tắt</Tag>,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 60,
      type: 'switch',
      editable: true,
      rules: [{ required: 'Vui lòng nhập' }],
      render: (val) =>
        val ? <Tag color="green05">Bật</Tag> : <Tag color="gray02">Tắt</Tag>,
    },
    ...(mode !== 'view'
      ? [
          {
            title: (
              <div className="flex justify-center">
                <PlusCircleOutlined
                  className={`w-5 cursor-pointer text-green-500 table-row-except-cope ${
                    isDisable ? '!text-gray-500' : ''
                  }`}
                  onClick={() => {
                    !isDisable && actionRef.current?.addRow();
                  }}
                />
              </div>
            ),
            dataIndex: OPERATION_KEY,
            width: 60,
          },
        ]
      : []),
  ];

  return (
    <TableTera
      objectType={objectType}
      loading={isLoading && !!id}
      loadingIndicator={{
        loading: isFetchingNextPage,
      }}
      scroll={{ y: 360 }}
      {...tableProps}
      data={dataSource}
      recordCreatorProps={{
        record: () => ({ status: true, extension_allowed: true }),
      }}
      columns={columns}
      rowKey={'id'}
      mode={mode === 'view' ? 'table' : 'editable-row'}
      actionRef={actionRef}
      editable={{
        ...(tableProps?.editable && tableProps?.editable),
        saveOnClickOut: true,
        onValuesChange(_, recordList) {
          if (mode !== 'default') {
            setDataSource(recordList);
            const value = [...(recordList ?? [])]?.map((item) => {
              const { id, ...rest } = item;
              id;
              return {
                ...rest,
                status: item.status ? 1 : 0,
              };
            });
            onChange && onChange(value);
          }
        },
        ...(mode == 'default' &&
          id && {
            onAdd: handleAdd,
            onDelete: handleDelete,
            onUpdate: handleUpdate,
          }),
      }}
    />
  );
};

export default forwardRef<IServicePackageTableRef, IProps>(
  ServicePackageProduct,
);
