import Search from '_common/dof/Control/Search';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  MagnifyingGlassOutlined,
  getQueryParams,
  updateQueryParams,
} from 'tera-dls';
import { IFilter } from '../../interfaces';

function EpicHeader() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const form = useForm({
    defaultValues: {
      keyword: queryParams?.keyword,
    },
  });
  const { handleSubmit } = form;

  const handleQueryParams = (query: IFilter) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...query,
      page: 1,
    });
    navigate(paramString);
  };

  return (
    <FormTera
      form={form}
      onSubmit={handleSubmit(handleQueryParams)}
      className="sm:w-full md:w-full lg:w-[500px] flex gap-3"
    >
      <FormTeraItem className="mb-0 flex-auto" name="keyword">
        <Search
          placeholder="Tìm kiếm theo mã, tên epic"
          icon={<MagnifyingGlassOutlined />}
        />
      </FormTeraItem>
    </FormTera>
  );
}

export default EpicHeader;
