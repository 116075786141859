import { yupResolver } from '@hookform/resolvers/yup';
import {
  Form,
  FormItem,
  MagnifyingGlassOutlined,
  Search,
  getQueryParams,
  updateQueryParams,
} from 'tera-dls';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { IFilter } from '../../interfaces';

const schema = yup.object().shape({
  keyword: yup.string().nullable(),
});

function ModuleHeader() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const { register, handleSubmit } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      keyword: queryParams?.keyword,
    },
  });

  const handleQueryParams = (query: IFilter) => {
    console.log(query);
    const paramString = updateQueryParams({
      ...queryParams,
      ...query,
      page: 1,
    });
    navigate(location.pathname + paramString);
  };

  return (
    <Form
      onSubmit={handleSubmit(handleQueryParams)}
      className="sm:w-full md:w-full lg:w-[500px] flex gap-3"
    >
      <FormItem className="!mb-0 flex-auto">
        <Search
          placeholder="Tìm kiếm theo mã, tên trang"
          icon={<MagnifyingGlassOutlined />}
          {...register('keyword')}
          className="w-full"
        />
      </FormItem>
    </Form>
  );
}

export default ModuleHeader;
