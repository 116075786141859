import { messageWarning } from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from 'tera-dls';

interface IProps {
  open: boolean;
  value: any;
  onClose?: () => void;
}

const FolderModal = (props: IProps) => {
  const { open, onClose, value } = props;
  const confirm = useConfirm();
  const form = useForm();

  const {
    setValue,
    reset,
    formState: { isDirty },
  } = form;

  useEffect(() => {
    value && setValue('name', value?.name);
  }, [value]);

  const handleClose = () => {
    onClose();
    reset();
  };

  const handleOnCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          handleClose();
        },
      });
    } else {
      handleClose();
    }
  };
  const handleSubmitFrom = (value) => {
    value;
  };

  return (
    <Modal
      title={'Đổi tên'}
      destroyOnClose
      okText="Lưu"
      cancelText="Hủy"
      closeIcon={false}
      open={open}
      onOk={() => form.handleSubmit(handleSubmitFrom)}
      onCancel={handleOnCloseConfirm}
      width={500}
    >
      <FormTera form={form} isLoading={false}>
        <FormTeraItem
          rules={[{ required: 'Vui lòng nhập' }]}
          label="Tên thư mục"
          name="name"
        >
          <Input placeholder="Vui lòng nhập" />
        </FormTeraItem>
      </FormTera>
    </Modal>
  );
};

export default FolderModal;
