import React from 'react';

function Menu9(props) {
  return (
    <svg
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.1882 0.853553C11.3835 0.658291 11.3835 0.341709 11.1882 0.146447C10.9929 -0.0488155 10.6763 -0.0488155 10.4811 0.146447L8.81441 1.81311C8.72065 1.90688 8.66797 2.03406 8.66797 2.16667C8.66797 2.29927 8.72065 2.42645 8.81441 2.52022L10.4811 4.18689C10.6763 4.38215 10.9929 4.38215 11.1882 4.18689C11.3835 3.99162 11.3835 3.67504 11.1882 3.47978L10.3752 2.66681L11.8346 2.66699C13.3994 2.66699 14.668 3.93547 14.668 5.50033V5.83366C14.668 6.1098 14.8918 6.33366 15.168 6.33366C15.4441 6.33366 15.668 6.1098 15.668 5.83366V5.50033C15.668 3.3832 13.9518 1.66703 11.8347 1.66699L10.3749 1.66681L11.1882 0.853553Z"
        fill="#6B7280"
      />
      <path
        d="M2.66797 4.50033C2.66797 2.93547 3.93644 1.66699 5.5013 1.66699H5.83464C6.11078 1.66699 6.33464 1.44313 6.33464 1.16699C6.33464 0.89085 6.11078 0.666992 5.83464 0.666992H5.5013C3.38416 0.666992 1.66797 2.38318 1.66797 4.50033V5.96119L0.853553 5.14677C0.658291 4.95151 0.341709 4.95151 0.146447 5.14677C-0.0488155 5.34203 -0.0488155 5.65862 0.146447 5.85388L1.81311 7.52055C1.91092 7.61835 2.03917 7.66717 2.16736 7.66699C2.31638 7.66699 2.45078 7.6018 2.54239 7.49838L4.18689 5.85388C4.38215 5.65862 4.38215 5.34203 4.18689 5.14677C3.99162 4.95151 3.67504 4.95151 3.47978 5.14677L2.66797 5.95858V4.50033Z"
        fill="#6B7280"
      />
      <path
        d="M11.2378 7.03569C11.3939 6.80795 11.3359 6.49673 11.1082 6.34057C10.8804 6.1844 10.5692 6.24242 10.413 6.47017L7.87441 10.1723L6.3837 8.72303C6.1857 8.53054 5.86915 8.53499 5.67666 8.73299C5.48417 8.93098 5.48862 9.24753 5.68662 9.44002L7.60272 11.3029C7.70842 11.4057 7.85408 11.4565 8.00078 11.4419C8.14747 11.4273 8.28025 11.3487 8.36362 11.2272L11.2378 7.03569Z"
        fill="#6B7280"
      />
      <path
        d="M10.8346 15.668C12.3995 15.668 13.668 14.3995 13.668 12.8346V11.3764L12.8562 12.1882C12.6609 12.3835 12.3443 12.3835 12.1491 12.1882C11.9538 11.9929 11.9538 11.6763 12.1491 11.4811L13.7936 9.83658C13.8852 9.73316 14.019 9.66797 14.168 9.66797H14.1693C14.3019 9.66797 14.4291 9.72065 14.5228 9.81441L16.1895 11.4811C16.3848 11.6763 16.3848 11.9929 16.1895 12.1882C15.9942 12.3835 15.6776 12.3835 15.4824 12.1882L14.668 11.3738V12.8346C14.668 14.9518 12.9518 16.668 10.8346 16.668H10.5013C10.2252 16.668 10.0013 16.4441 10.0013 16.168C10.0013 15.8918 10.2252 15.668 10.5013 15.668H10.8346Z"
        fill="#6B7280"
      />
      <path
        d="M2.66797 12.5003C2.66797 12.2242 2.44411 12.0003 2.16797 12.0003C1.89183 12.0003 1.66797 12.2242 1.66797 12.5003V12.8337C1.66797 14.9508 3.38413 16.667 5.50124 16.667L6.96101 16.6672L6.14775 17.4804C5.95249 17.6757 5.95249 17.9923 6.14775 18.1875C6.34301 18.3828 6.65959 18.3828 6.85486 18.1875L8.52152 16.5209C8.61529 16.4271 8.66797 16.2999 8.66797 16.1673C8.66797 16.0347 8.61529 15.9075 8.52152 15.8138L6.85486 14.1471C6.65959 13.9518 6.34301 13.9518 6.14775 14.1471C5.95249 14.3424 5.95249 14.6589 6.14775 14.8542L6.96071 15.6672L5.50136 15.667C3.93651 15.667 2.66797 14.3985 2.66797 12.8337V12.5003Z"
        fill="#6B7280"
      />
    </svg>
  );
}

export default Menu9;
