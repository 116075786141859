import { Route, Routes } from 'react-router-dom';
import { IRouteProps } from '_common/interface/router';

import CheckAuthPage from 'pages/Auth/CheckAuth';
import CheckAuth from 'routers/CheckAuth';
import MiddlewareRouter from 'routers/MiddlewareRouter';

import BasicLayout from '_common/component/Layout/BasicLayout';
import EpicLayout from '_common/component/Layout/EpicLayout';
import PageLayout from '_common/component/Layout/PageLayout';
import UnAuthLayout from '_common/component/Layout/UnAuthLayout';
import PageNotfound from '_common/component/PageNotfound';

//
import { EPIC_KEY } from '_common/constants/permission';
import { EPIC_KEY_URL } from '_common/constants/url';

// page
import Dashboard from 'pages/Dashboard';

// dashboard router
import { DashboardRouter } from 'pages/Dashboard/router';
// login_new
import { BusinessRouter } from 'pages/Business/router';
import { FileManagementRouter } from 'pages/FileManagement/router';
import { ModuleListRouter } from 'pages/Module/router';
import { ServicePackageRouter } from 'pages/ServicePackage/router';
import { MANAGE_EPIC_URL } from 'pages/System/containers/ListEpic/constants/url';
import { MANAGE_PAGE_URL } from 'pages/System/containers/ManagePage/constants/url';
import {
  ConfigAffiliates,
  ConfigDataTypeRouter,
  ConfigPaymentMethod,
  ConfigPermissionRouter,
  ConfigStatusRouter,
  ManageEpic,
  ManageGroupControlRouter,
  ManageModuleRouter,
  ManagePageRouter,
} from 'pages/System/router';
import { UserListRouter } from 'pages/User/router';
import { InvoiceRouter } from 'pages/Invoice/router';
import { CashReceiptRouter } from 'pages/Invoice/containers/CashReceipt/router';
import { ExpenseVoucherRouter } from 'pages/Invoice/containers/ExpenseVoucher/router';
import { StatisticRouter } from 'pages/Invoice/containers/Statistic/router';

const renderRouter = (objectRouter: IRouteProps[]) => {
  return objectRouter.map((route: IRouteProps) => {
    const { key, component, path } = route;
    return (
      <Route
        key={key}
        path={path}
        element={<PageLayout page_key={key}>{component}</PageLayout>}
      />
    );
  });
};

export const Routers = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <MiddlewareRouter>
            <BasicLayout />
          </MiddlewareRouter>
        }
      >
        <Route index element={<Dashboard />} />
        <Route
          path={EPIC_KEY_URL.DASHBOARD}
          element={<EpicLayout epic_key={EPIC_KEY.DASHBOARD} />}
        >
          {renderRouter(DashboardRouter)}
        </Route>
        <Route path={'invoice-management'}>
          {renderRouter(InvoiceRouter)}
          {renderRouter(CashReceiptRouter)}
          {renderRouter(ExpenseVoucherRouter)}
          {renderRouter(StatisticRouter)}
        </Route>
        <Route path="/user">
          {/* User List */}
          <Route
            path={EPIC_KEY_URL.USER_LIST}
            // element={<EpicLayout epic_key={EPIC_KEY.USER_LIST} />}
          >
            {renderRouter(UserListRouter)}
          </Route>
        </Route>
        <Route path="/module">
          <Route
            path={EPIC_KEY_URL.MODULE_LIST}
            // element={<EpicLayout epic_key={EPIC_KEY.MODULE_LIST} />}
          >
            {renderRouter(ModuleListRouter)}
          </Route>
        </Route>
        <Route path="business-management">{renderRouter(BusinessRouter)}</Route>
        <Route path="/file-management">
          {/* operation List */}
          {renderRouter(FileManagementRouter)}
        </Route>
        <Route path="/service-package">
          {renderRouter(ServicePackageRouter)}
        </Route>

        <Route path="system">
          <Route
            path={EPIC_KEY_URL.CONFIG_DATA}
            element={<EpicLayout epic_key={EPIC_KEY.CONFIG_DATA} />}
          >
            {renderRouter(ConfigDataTypeRouter)}
          </Route>
          <Route
            path={EPIC_KEY_URL.CONFIG_STATUS}
            element={<EpicLayout epic_key={EPIC_KEY.CONFIG_STATUS} />}
          >
            {renderRouter(ConfigStatusRouter)}
          </Route>
          <Route
            path={EPIC_KEY_URL.CONFIG_PERMISSION}
            element={<EpicLayout epic_key={EPIC_KEY.CONFIG_PERMISSION} />}
          >
            {renderRouter(ConfigPermissionRouter)}
          </Route>
          <Route path={EPIC_KEY_URL.MANAGE_MODULE}>
            {renderRouter(ManageModuleRouter)}
          </Route>
          <Route path={EPIC_KEY_URL.MANAGE_GROUP_CONTROL}>
            {renderRouter(ManageGroupControlRouter)}
          </Route>
          <Route path={MANAGE_PAGE_URL.list.path}>
            {renderRouter(ManagePageRouter)}
          </Route>
          <Route path={MANAGE_EPIC_URL.list.path}>
            {renderRouter(ManageEpic)}
          </Route>

          <Route path={'payment-method'}>
            {renderRouter(ConfigPaymentMethod)}
          </Route>
          <Route path={'config-affiliates'}>
            {renderRouter(ConfigAffiliates)}
          </Route>
        </Route>
      </Route>
      <Route
        path="auth"
        element={
          <CheckAuth>
            <UnAuthLayout />
          </CheckAuth>
        }
      >
        <Route path="check-auth" element={<CheckAuthPage />} />
      </Route>
      <Route path="*" element={<PageNotfound />} />
    </Routes>
  );
};
