import { REGEX } from '_common/constants/common';
import { messageValidate } from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import TextArea from '_common/dof/Control/TextArea';
import { FormTeraItem } from '_common/dof/FormTera';
import SelectBank from '_common/dof/Select/SelectBank';
const EWalletForm = () => {
  return (
    <>
      <FormTeraItem
        name="card_type_id"
        label="Ví điện tử"
        rules={[{ required: messageValidate.emptySelect }]}
      >
        <SelectBank paramsApi={{ object_type: 2 }} />
      </FormTeraItem>
      <FormTeraItem
        name="account_phone"
        label="Số điện thoại"
        rules={[
          {
            required: messageValidate.emptyText,
            pattern: {
              value: REGEX.PHONE_NUMBER,
              message: messageValidate.phone,
            },
          },
        ]}
      >
        <Input />
      </FormTeraItem>
      <FormTeraItem
        name="cardholder"
        label="Người thụ hưởng"
        rules={[{ required: messageValidate.emptyText }]}
      >
        <Input className="uppercase placeholder-shown:normal-case" />
      </FormTeraItem>
      <FormTeraItem name="description" label="Format nội dụng">
        <TextArea rows={5} />
      </FormTeraItem>
    </>
  );
};

export default EWalletForm;
