import { useMutation, useQueries } from '@tanstack/react-query';
import CardForm from '_common/component/CardForm';
import { EmployeeText } from '_common/component/EmployeeText';
import HeaderViewList from '_common/component/HeaderViewList';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import useConfirm from '_common/hooks/useConfirm';
import { statusConfigColor } from 'pages/System/constants';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftSolid,
  Breadcrumb,
  Button,
  Col,
  Description,
  DropdownItem,
  ItemType,
  PencilSquareOutlined,
  Row,
  Spin,
  Tag,
  XMarkOutlined,
  notification,
} from 'tera-dls';
import { BUTTON_KEY } from '../../../constants/key';
import {
  MANAGE_PAGE_CONFIG_FORM_URL,
  MANAGE_PAGE_URL,
} from '../../../constants/url';
import FormConfigApi from '../../_api';
import TableFieldConfig from '../FieldConfig/Table';
import CopyForm from '../Modal/CopyForm';
import CreateConfigForm from '../Modal/CreateConfigForm';

const FormConfigDetail = () => {
  const [isOpenCopyModal, setIsOpenCopyModal] = useState<boolean>(false);
  const [isOpenEdit, setIsOpenEdit] = useState<boolean>(false);
  const confirm = useConfirm();
  const navigate = useNavigate();
  const { formId, pageId } = useParams();

  const [{ data: detailFormConfig, isLoading, refetch }] = useQueries({
    queries: [
      {
        queryKey: ['detail_form_config'],
        queryFn: () => {
          return FormConfigApi.getDetail(formId);
        },
      },
    ],
  });

  const { mutate: mutateDelete } = useMutation(
    (variables: any) => {
      return FormConfigApi.delete(variables);
    },
    {
      onSuccess: ({ code, msg }) => {
        if (code === 200) {
          navigate(`${MANAGE_PAGE_CONFIG_FORM_URL.list.path}/${pageId}`);
          notification.success({
            message: msg,
          });
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const details = [
    {
      key: 'code',
      value: detailFormConfig?.code,
      title: 'Mã form dữ liệu',
    },
    {
      key: 'layout',
      value: detailFormConfig?.layout,
      title: 'Layout',
    },
    {
      key: 'created_by',
      value: (
        <EmployeeText
          code={detailFormConfig?.created_by?.code}
          name={detailFormConfig?.created_by?.full_name}
        />
      ),
      title: 'Người tạo',
    },
    {
      key: 'title',
      value: detailFormConfig?.title,
      title: 'Tiêu đề',
    },
    {
      key: 'className',
      value: detailFormConfig?.class_name,
      title: 'ClassName',
    },
    {
      key: 'created_at',
      value: detailFormConfig?.created_at,
      title: 'Ngày tạo',
    },
    {
      key: 'group',
      value: detailFormConfig?.group_form_control?.title,
      title: 'Nhóm form dữ liệu',
    },
    {
      key: 'priority',
      value: detailFormConfig?.item_prev_order?.title,
      title: 'Thứ tự đứng sau',
    },
    {
      key: 'updated_by',
      value: (
        <EmployeeText
          code={detailFormConfig?.updated_by?.code}
          name={detailFormConfig?.updated_by?.full_name}
        />
      ),
      title: 'Người cập nhật',
    },
    {
      key: 'status',
      value: (
        <Tag color={statusConfigColor[detailFormConfig?.status]}>
          {detailFormConfig?.status_text}
        </Tag>
      ),
      title: 'Trạng thái',
    },
    {
      key: 'numOfColumn',
      value: detailFormConfig?.column,
      title: 'Số lượng cột',
    },
    {
      key: 'updated_at',
      value: detailFormConfig?.updated_by && detailFormConfig?.updated_at,
      title: 'Ngày cập nhật',
    },
  ];

  const dropdownConfig: DropdownItem[] = [
    {
      key: 1,
      label: <a>Export dữ liệu excel</a>,
    },
    {
      key: 2,
      label: <a>Import dữ liệu excel</a>,
    },
  ];

  const BreadcrumbItem: ItemType[] = [
    {
      title: (
        <a
          className="cursor-pointer"
          onClick={() => navigate(MANAGE_PAGE_URL.list.path)}
        >
          Danh sách trang
        </a>
      ),
    },
    {
      title: (
        <a
          className="cursor-pointer"
          onClick={() =>
            navigate(`${MANAGE_PAGE_CONFIG_FORM_URL.list.path}/${pageId}`)
          }
        >
          Cấu hình form dữ liệu
        </a>
      ),
    },
    {
      title: 'Chi tiết form dữ liệu',
    },
  ];

  const handleDeleteForm = () => {
    confirm.warning({
      title: 'Xác nhận xoá cấu hình form dữ liệu',
      onOk: () => {
        mutateDelete(formId);
      },
      content: (
        <div className="break-all">
          <p>Bạn có chắc muốn xoá cấu hình form dữ liệu </p>
          <p>
            <span className="font-bold">{detailFormConfig?.title}</span> này
            không?
          </p>
        </div>
      ),
    });
  };

  const onEdit = () => {
    setIsOpenEdit(true);
  };

  const closeModalCopy = () => {
    setIsOpenCopyModal(false);
  };
  const closeModalEdit = () => {
    refetch();
    setIsOpenEdit(false);
  };

  return (
    <>
      <Spin spinning={isLoading}>
        <HeaderViewList
          title={
            <>
              <div className="page-breadcrumb">
                <div className="cursor-pointer" onClick={() => navigate(-1)}>
                  <ArrowSmallLeftSolid width={24} height={24} />
                </div>
                <Breadcrumb separator={'>'} items={BreadcrumbItem} />
              </div>
            </>
          }
          isDetail
          buttonCreatingKey={BUTTON_KEY.MANAGE_PAGE_CONFIG_FORM_CREATE}
          dropdownConfig={dropdownConfig}
        >
          <Button
            type="danger"
            className="page-header-btn"
            onClick={handleDeleteForm}
          >
            <div className="flex items-center gap-1">
              <span>Xoá</span>
              <XMarkOutlined className="w-5 h-5" />
            </div>
          </Button>
          <Button onClick={() => onEdit()} className="page-header-btn">
            <span>Sửa</span>
            <PencilSquareOutlined className="w-5 h-5" />
          </Button>
        </HeaderViewList>
        <div className="bg-white shadow-xsm rounded-[5px] p-5">
          <CardForm title="THÔNG TIN FORM DỮ LIỆU">
            <Row className="lg:grid-cols-3 md:grid-cols-2 gap-0">
              {details.map((info) => (
                <Col key={info.key}>
                  <Description
                    className="lg:grid-cols-3 md:grid-cols-2"
                    label={info.title}
                  >
                    {info.value}
                  </Description>
                </Col>
              ))}
            </Row>
          </CardForm>
          <CardForm title="CẤU HÌNH TRƯỜNG DỮ LIỆU" className="pt-10">
            <TableFieldConfig isAdd />
          </CardForm>
        </div>
      </Spin>
      {isOpenEdit && (
        <CreateConfigForm
          isOpen={isOpenEdit}
          id={detailFormConfig?.id}
          handleClose={closeModalEdit}
        />
      )}
      {isOpenCopyModal && (
        <CopyForm isOpen={isOpenCopyModal} handleClose={closeModalCopy} />
      )}
    </>
  );
};

export default FormConfigDetail;
