import React from 'react';

function Menu4(props) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.3154 6.94405C14.3154 6.83239 14.271 6.7253 14.1921 6.64634C14.1131 6.56738 14.006 6.52303 13.8943 6.52303C13.7827 6.52303 13.6756 6.56738 13.5966 6.64634C13.5177 6.7253 13.4733 6.83239 13.4733 6.94405H14.3154ZM2.52668 6.94405C2.52668 6.83239 2.48232 6.7253 2.40336 6.64634C2.32441 6.56738 2.21732 6.52303 2.10565 6.52303C1.99399 6.52303 1.8869 6.56738 1.80795 6.64634C1.72899 6.7253 1.68463 6.83239 1.68463 6.94405H2.52668ZM15.2804 8.92624C15.3594 9.00529 15.4666 9.04971 15.5784 9.04971C15.6902 9.04971 15.7975 9.00529 15.8765 8.92624C15.9556 8.84718 16 8.73995 16 8.62815C16 8.51635 15.9556 8.40912 15.8765 8.33006L15.2804 8.92624ZM8 1.04971L8.29809 0.751621C8.25898 0.712413 8.21251 0.681305 8.16136 0.66008C8.11021 0.638855 8.05538 0.62793 8 0.62793C7.94462 0.62793 7.88979 0.638855 7.83864 0.66008C7.78749 0.681305 7.74102 0.712413 7.70191 0.751621L8 1.04971ZM0.123471 8.33006C0.0444138 8.40912 0 8.51635 0 8.62815C0 8.73995 0.0444138 8.84718 0.123471 8.92624C0.202528 9.00529 0.309753 9.04971 0.421556 9.04971C0.53336 9.04971 0.640585 9.00529 0.719642 8.92624L0.123471 8.33006ZM3.78975 16.6276H12.2102V15.7856H3.78975V16.6276ZM14.3154 14.5225V6.94405H13.4733V14.5225H14.3154ZM2.52668 14.5225V6.94405H1.68463V14.5225H2.52668ZM15.8765 8.33006L8.29809 0.751621L7.70191 1.34779L15.2804 8.92624L15.8765 8.33006ZM7.70191 0.751621L0.123471 8.33006L0.719642 8.92624L8.29809 1.34779L7.70191 0.751621ZM12.2102 16.6276C12.7686 16.6276 13.304 16.4058 13.6988 16.011C14.0936 15.6163 14.3154 15.0808 14.3154 14.5225H13.4733C13.4733 14.8575 13.3402 15.1788 13.1034 15.4156C12.8665 15.6525 12.5452 15.7856 12.2102 15.7856V16.6276ZM3.78975 15.7856C3.45477 15.7856 3.1335 15.6525 2.89663 15.4156C2.65975 15.1788 2.52668 14.8575 2.52668 14.5225H1.68463C1.68463 15.0808 1.90642 15.6163 2.30121 16.011C2.69599 16.4058 3.23144 16.6276 3.78975 16.6276V15.7856Z"
        fill="#6B7280"
      />
      <path
        d="M7.02734 5.46224C7.02734 5.27814 7.17658 5.12891 7.36068 5.12891H8.69401C8.87811 5.12891 9.02734 5.27814 9.02734 5.46224C9.02734 5.64633 8.87811 5.79557 8.69401 5.79557H7.36068C7.17658 5.79557 7.02734 5.64633 7.02734 5.46224Z"
        fill="#6B7280"
      />
      <path
        d="M3.69922 8.13965C3.69922 7.9498 3.85312 7.7959 4.04297 7.7959H12.0088C12.1986 7.7959 12.3526 7.9498 12.3526 8.13965C12.3526 8.3295 12.1986 8.4834 12.0088 8.4834H4.04297C3.85312 8.4834 3.69922 8.3295 3.69922 8.13965Z"
        fill="#6B7280"
      />
      <path
        d="M3.69922 10.8271C3.69922 10.6373 3.85312 10.4834 4.04297 10.4834H12.0088C12.1986 10.4834 12.3526 10.6373 12.3526 10.8271C12.3526 11.017 12.1986 11.1709 12.0088 11.1709H4.04297C3.85312 11.1709 3.69922 11.017 3.69922 10.8271Z"
        fill="#6B7280"
      />
      <path
        d="M3.69922 13.5146C3.69922 13.3248 3.85312 13.1709 4.04297 13.1709H12.0088C12.1986 13.1709 12.3526 13.3248 12.3526 13.5146C12.3526 13.7045 12.1986 13.8584 12.0088 13.8584H4.04297C3.85312 13.8584 3.69922 13.7045 3.69922 13.5146Z"
        fill="#6B7280"
      />
    </svg>
  );
}

export default Menu4;
