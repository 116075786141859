import { useQuery } from '@tanstack/react-query';
import { Modal, Spin } from 'tera-dls';
import ConfigPaymentMethodApi from '../../_api';

const ModalQrCode = ({ id, open, onClose }) => {
  const { data, isFetching } = useQuery(['get-payment-method-qr-code'], () =>
    ConfigPaymentMethodApi.getQrCode({ card_id: id }),
  );

  return (
    <Modal
      width={'25%'}
      open={open}
      title="QR Code"
      footerClassName="!border-none !justify-center"
      bodyClassName="flex items-center justify-center"
      cancelButtonProps={{ className: 'hidden' }}
      okText="Đóng"
      onOk={onClose}
    >
      <Spin
        spinning={isFetching}
        wrapperClassName="flex items-center justify-center"
      >
        <img src={data?.qrDataURL} alt={data?.qrCode} className="w-80 h-80" />
      </Spin>
    </Modal>
  );
};

export default ModalQrCode;
