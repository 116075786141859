import { yupResolver } from '@hookform/resolvers/yup';
import {
  Form,
  FormItem,
  MagnifyingGlassOutlined,
  getQueryParams,
  updateQueryParams,
} from 'tera-dls';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import Search from '_common/component/UIV2/Search';

const schema = yup.object().shape({
  keyword: yup.string().nullable(),
});

function BusinessHeader() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const { register, handleSubmit } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      keyword: queryParams?.keyword,
    },
  });

  const handleQueryParams = (query) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...query,
      page: 1,
    });
    navigate(location.pathname + paramString);
  };

  return (
    <Form onSubmit={handleSubmit(handleQueryParams)}>
      <FormItem className="!mb-0 flex-auto">
        <Search
          placeholder="Tìm kiếm theo business ID, tên doanh nghiệp"
          icon={<MagnifyingGlassOutlined />}
          {...register('keyword')}
        />
      </FormItem>
    </Form>
  );
}

export default BusinessHeader;
