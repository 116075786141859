import React from 'react';
import { Button, Modal } from 'tera-dls';
import CashExplainContent from './CashExplainContent';

interface IProps {
  open: boolean;
  onClose?: () => void;
  value: number | string;
}
const CashExplainDetail = (props: IProps) => {
  const { open, onClose, value } = props;
  return (
    <Modal
      title={'Chi tiết phiếu thu'}
      destroyOnClose
      closeIcon={false}
      width={800}
      cancelText="Hủy"
      okText="Đồng ý"
      onCancel={onClose}
      open={open}
      centered={true}
      footer={<Button onClick={onClose}>Đóng</Button>}
    >
      <CashExplainContent id={value} />
    </Modal>
  );
};

export default CashExplainDetail;
