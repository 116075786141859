import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import { messageError } from '_common/constants/message';
import TableTera from '_common/dof/TableTera';
import useConfirm from '_common/hooks/useConfirm';
import { useEffect, useState } from 'react';
import {
  DropdownItem,
  formatDate,
  notification,
  PaginationProps,
  PlusCircleOutlined,
} from 'tera-dls';
import PermissionGroupApi from './_api';
import Config from './containers/Config';
import DetailPermission from './containers/Detail';
import FormPermissionGroup from './containers/Form';
import MemberList from './containers/MemberList';

function PermissionGroup({ businessId }) {
  const queryClient = useQueryClient();
  const [isOpenForm, setIsOpenForm] = useState({
    open: false,
    id: null,
  });
  const [isOpenConfig, setIsOpenConfig] = useState({
    open: false,
    id: null,
  });
  const [isOpenDetail, setIsOpenDetail] = useState({
    open: false,
    id: null,
  });
  const [isOpenMember, setIsOpenMember] = useState({
    open: false,
    id: null,
  });

  const confirm = useConfirm();
  const [params, setParams] = useState<any>({});

  const permissionGroupList = useQuery(
    ['get-permission-group-list', params],
    () => {
      return PermissionGroupApi.getList({
        params: {
          page: 1,
          limit: 10,
          business_id: businessId,
          ...params,
        },
      });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const { refetch } = permissionGroupList;

  useEffect(() => {
    refetch();
  }, []);

  const deletePermission = useMutation(
    (id: number) => PermissionGroupApi.delete({ id }),
    {
      onSuccess(res) {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-permission-group-list']);
          queryClient.invalidateQueries(['get-business-summary']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        const errorMessage = error?.data?.msg || messageError.ERROR_API;
        notification.error({
          message: errorMessage,
        });
      },
    },
  );

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    setParams({
      page: page,
      limit: pageSize,
    });
  };

  const handleDelete = (item) => {
    confirm.warning({
      title: 'Xác nhận xóa nhóm quyền',
      content: (
        <>
          <p>Bạn có chắc muốn xóa nhóm quyền</p>
          <p>
            <b className="break-word">{item?.name}</b> này không?
          </p>
        </>
      ),
      onOk: async () => deletePermission.mutate(item?.id),
    });
  };

  const renderItemActions = (item: any): DropdownItem[] => {
    const dropdownItems: DropdownItem[] = [];
    dropdownItems.push({
      key: 1,
      label: 'Xem',
      onClick: () => {
        setIsOpenDetail({
          open: true,
          id: item?.id,
        });
      },
    });
    dropdownItems.push({
      key: 2,
      label: 'Cấu hình quyền',
      onClick: () => {
        setIsOpenConfig({
          open: true,
          id: item?.id,
        });
      },
    });
    dropdownItems.push({
      key: 3,
      label: 'DS thành viên',
      onClick: () => {
        setIsOpenMember({ open: true, id: item?.id });
      },
    });
    dropdownItems.push({
      key: 4,
      label: 'Sửa',
      onClick: () => {
        setIsOpenForm({ open: true, id: item?.id });
      },
    });
    dropdownItems.push({
      key: 5,
      label: <span className="text-red-500">Xóa</span>,
      onClick: () => handleDelete(item),
    });
    return dropdownItems;
  };

  const columns: any = [
    {
      title: 'Mã quyền',
      dataIndex: 'code',
      width: 150,
    },
    {
      title: 'Tên quyền',
      dataIndex: 'title',
      width: 200,
      render: (name) => <p className="line-clamp-2">{name}</p>,
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      width: 200,
      render: (created_at) =>
        created_at && formatDate(created_at, 'DD/MM/YYYY - HH:mm'),
    },
    {
      title: 'Ngày cập nhật',
      dataIndex: 'updated_at',
      width: 150,
      render: (updated_at) =>
        updated_at && formatDate(updated_at, 'DD/MM/YYYY - HH:mm'),
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      width: 150,
      render: (note) => <p className="line-clamp-2">{note}</p>,
    },
    {
      title: (
        <div className="flex justify-center">
          <PlusCircleOutlined
            className="w-5 text-green-500 cursor-pointer"
            onClick={() => setIsOpenForm({ open: true, id: null })}
          />
        </div>
      ),
      width: 60,
      render: (record) => {
        const items = renderItemActions(record);
        return <ActionDropdown dropdownItems={items} trigger="click" />;
      },
    },
  ];

  return (
    <>
      <TableTera
        rowKey={'id'}
        columns={columns}
        loading={permissionGroupList.isLoading}
        data={permissionGroupList.data?.data || []}
        pagination={{
          onChange: handleChangePage,
          to: permissionGroupList.data?.to,
          from: permissionGroupList.data?.from,
          current: permissionGroupList.data?.current_page,
          pageSize: permissionGroupList.data?.per_page,
          total: permissionGroupList.data?.total,
        }}
      />

      {isOpenForm.open && (
        <FormPermissionGroup
          open={isOpenForm.open}
          id={isOpenForm.id}
          businessId={businessId}
          onCancel={() => {
            setIsOpenForm({
              open: false,
              id: null,
            });
          }}
          onRefetch={() => {
            queryClient.invalidateQueries(['get-permission-group-list']);
          }}
        />
      )}
      {isOpenConfig.open && (
        <Config
          open={isOpenConfig.open}
          id={isOpenConfig.id}
          businessId={businessId}
          onCancel={() => {
            setIsOpenConfig({
              open: false,
              id: null,
            });
          }}
          onRefetch={() => {
            queryClient.invalidateQueries(['get-permission-group-list']);
          }}
        />
      )}
      {isOpenDetail.open && (
        <DetailPermission
          open={isOpenDetail.open}
          id={isOpenDetail.id}
          onCancel={() => {
            setIsOpenDetail({
              open: false,
              id: null,
            });
          }}
        />
      )}
      {isOpenMember.open && (
        <MemberList
          open={isOpenMember.open}
          id={isOpenMember.id}
          businessId={businessId}
          onCancel={() => {
            setIsOpenMember({
              open: false,
              id: null,
            });
          }}
        />
      )}
    </>
  );
}

export default PermissionGroup;
