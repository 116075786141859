import { IRouteProps } from '_common/interface/router';
import Statistic from '.';
import { STATISTIC_URL } from './url';

export const StatisticRouter: IRouteProps[] = [
  {
    key: STATISTIC_URL.list.key,
    path: STATISTIC_URL.list.shortenUrl,
    component: <Statistic />,
  },
];
