import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectModule from '_common/dof/Select/SelectModule';
import _, { difference, isEqual, uniqWith } from 'lodash';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as IconNodata } from 'styles/images/uiNew/NoData.svg';
import {
  ArrowSmallLeftSolid,
  BookmarkOutlined,
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  ItemType,
  Row,
  Spin,
  TabItemType,
  Tabs,
  notification,
  updateToggleValueInArr,
} from 'tera-dls';
import ConfigPermissionApi from '../../_api';
import { RequestSetting } from '../../interfaces';

const SettingConfigPermission = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { id } = useParams();
  const convertId = Number(id);
  const { state } = useLocation();

  const [module_id, setModuleId] = useState<string | number>(null);
  const [activeKey, setActiveKey] = useState<string>('');

  const { data: detail, refetch } = useQuery(
    ['get-detail-config-permission', id],
    () => ConfigPermissionApi.getDetail(id),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    id && refetch();
  }, [id]);

  const [
    { data: listDefaultConfigControl, isFetching },
    { data: listRoleHasPermission, isFetching: fetching },
  ] = useQueries({
    queries: [
      {
        queryKey: ['get-role-config-control', module_id],
        queryFn: () =>
          ConfigPermissionApi.getListConfigControl({
            module_id,
          }),
        refetchOnWindowFocus: false,
        enabled: !!module_id,
      },
      {
        queryKey: ['get-role-has-permission', convertId],
        queryFn: () => ConfigPermissionApi.getListRoleHasPermission(convertId),
        refetchOnWindowFocus: false,
        enabled: !!module_id,
      },
    ],
  });

  // const [listPage, setListPage] = useState([]);
  const [listPermission, setListPermission] = useState([]);
  const [selectedControlId, setSelectedControlId] = useState<number[]>([]);

  const { mutate: mutateSettingPermission, isLoading } = useMutation(
    (variables: RequestSetting) => {
      return ConfigPermissionApi.setting(variables);
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-role-config-control']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const convertDataPermission = (listDataBefore) => {
    const newList = listDataBefore?.reduce((acc, item) => {
      const checkEmpty = item || [];
      if (acc.length === 0) return [checkEmpty];
      return [...acc, checkEmpty];
    }, []);
    return newList;
  };

  const setDefaultListPage = () => {
    if (listDefaultConfigControl) {
      const dataConvert = convertDataPermission(
        listDefaultConfigControl?.[0]?.controls,
      );
      setListPermission(dataConvert);
      // setListPage(listDefaultConfigControl?.[0]?.pages);
      setActiveKey(listDefaultConfigControl?.[0]?.id);
    }
  };

  const setDefaultSelectedControlId = () => {
    if (listRoleHasPermission)
      setSelectedControlId(
        listRoleHasPermission.map((item) => item.permission_id),
      );
  };

  useEffect(() => setDefaultSelectedControlId(), [listRoleHasPermission]);

  useEffect(() => {
    setDefaultListPage();
  }, [listDefaultConfigControl]);

  const listTab: TabItemType[] = useMemo(() => {
    if (!listDefaultConfigControl) return [];
    return (
      listDefaultConfigControl?.map((item) => ({
        key: item.id,
        label: item.name,
      })) || []
    );
  }, [listDefaultConfigControl]);

  const handleChangeTab = (activeKey: string) => {
    const findDataTab = listDefaultConfigControl?.find(
      (item) => item.id === activeKey,
    );
    const dataConvert = convertDataPermission(findDataTab?.controls);
    setListPermission(dataConvert);
    setActiveKey(findDataTab?.id);
  };

  const BreadcrumbItem: ItemType[] = [
    {
      title: <a onClick={() => navigate(-1)}>Danh sách quyền</a>,
    },
    {
      title: `Cấu hình quyền: ${state?.titlePermission}`,
    },
  ];

  const listCheckboxNode = useMemo(
    () => (listControl: any[]) => {
      const listPermissionId = listControl
        ? listControl?.map((item) => item.id)
        : [];
      const checkedCurrentAll = listPermissionId.every((id) =>
        selectedControlId.includes(id),
      );

      const triggerSingleCheckbox = (item) => {
        const indexItem = selectedControlId.findIndex((id) => id === item.id);
        setSelectedControlId(
          updateToggleValueInArr(indexItem, item.id, selectedControlId),
        );
      };

      const onCheckAll = (
        e: ChangeEvent<HTMLInputElement>,
        listControl: number[],
      ) => {
        e.target.checked
          ? setSelectedControlId(
              uniqWith([...selectedControlId, ...listControl], isEqual),
            )
          : setSelectedControlId(
              [...selectedControlId].filter(
                (item) => !listControl.includes(item),
              ),
            );
      };

      if (!listControl?.length) return null;
      return (
        <Row className="grid-cols-12 gap-0 gap-y-5">
          <Col className="col-span-2">
            <Checkbox
              labelClassName="truncate"
              checked={!!listControl?.length && checkedCurrentAll}
              onChange={(e) =>
                onCheckAll(
                  e,
                  listControl?.map((item) => item.id),
                )
              }
            >
              Tất cả
            </Checkbox>
          </Col>
          <Col className="col-span-10">
            <Row className="grid-cols-4 gap-5">
              {listControl?.map((item) => {
                if (item.status === 'inactive') return null;
                return (
                  <Col key={item.id}>
                    <Checkbox
                      checked={selectedControlId.includes(item.id)}
                      labelClassName="truncate"
                      onChange={() => triggerSingleCheckbox(item)}
                    >
                      {item.title}
                    </Checkbox>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      );
    },
    [selectedControlId, listRoleHasPermission, activeKey],
  );

  // const extraCollapse = (listControl: any[]) => {
  //   const filterControlHasCheck = listControl?.filter((item) =>
  //     selectedControlId.includes(item.id),
  //   );
  //   return (
  //     <div className="flex items-center w-auto gap-2 shrink-0">
  //       <span>
  //         {filterControlHasCheck?.length} / {listControl?.length}
  //       </span>
  //       <i className="w-5 h-5 shrink-0">
  //         <ChevronDownOutlined />
  //       </i>
  //     </div>
  //   );
  // };

  const handleConfigPermission = () => {
    mutateSettingPermission({
      role_id: convertId,
      permission_id: selectedControlId,
    });
  };

  // const listCollapse: ItemCollapseType[] = listPage?.map((item) => {
  //   return {
  //     key: item.id,
  //     label: <span className="truncate">{item?.name}</span>,
  //     extra: extraCollapse(item?.config_controls),
  //     children: listCheckboxNode(item?.config_controls),
  //   };
  // });

  const listIdPermissionModule = useMemo(() => {
    if (!listDefaultConfigControl) return [];
    const listIdPermission = _.flatMapDeep(listDefaultConfigControl, (obj) =>
      obj?.controls?.map((item) => item.id),
    );
    return listIdPermission;
  }, [listDefaultConfigControl]);

  const onCheckAllModule = (e, listIdPermissionModule) => {
    e.target.checked
      ? setSelectedControlId(
          uniqWith([...selectedControlId, ...listIdPermissionModule], isEqual),
        )
      : setSelectedControlId(
          difference(selectedControlId, listIdPermissionModule),
        );
  };

  function containsAll(array1, array2) {
    const set = new Set(array1);
    for (const item of array2) {
      if (!set.has(item)) {
        return false;
      }
    }
    return true;
  }

  return (
    <div className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <div
              className="cursor-pointer page-header__breadcrumb-back"
              onClick={() => navigate(-1)}
            >
              <ArrowSmallLeftSolid width={24} height={24} />
            </div>
            <Breadcrumb separator={'>'} items={BreadcrumbItem} />
          </div>

          <div className="page-header__function">
            <Button
              type="success"
              htmlType="button"
              onClick={handleConfigPermission}
              icon={<BookmarkOutlined />}
              disabled={isFetching}
              loading={isLoading}
            >
              Lưu
            </Button>
          </div>
        </div>
      </div>

      <div className="page-content-v2">
        <div className="p-5 bg-white shadow-xsm rounded-2xl">
          <div className="flex items-center gap-x-2.5">
            <FormTera>
              <FormTeraItem name="module" className="mb-0">
                <SelectModule
                  key={detail?.type}
                  placeholder="Chọn Module"
                  value={module_id}
                  onChange={(id) => setModuleId(id as number)}
                  paramsApi={{
                    ...(detail?.type !== 'system' && { type: detail?.type }),
                    ...(detail?.type === 'user' && { type: 'business' }),
                  }}
                  className="w-[200px]"
                />
              </FormTeraItem>
            </FormTera>
            {!!module_id && (
              <Checkbox
                checked={containsAll(selectedControlId, listIdPermissionModule)}
                onChange={(e) => onCheckAllModule(e, listIdPermissionModule)}
              >
                Cho phép tất cả quyền
              </Checkbox>
            )}
          </div>
          <Spin spinning={isFetching || fetching}>
            {listTab && (
              <Tabs
                activeKey={activeKey}
                items={listTab}
                onChange={handleChangeTab}
              />
            )}
            {!!listPermission?.length && module_id ? (
              listCheckboxNode(listPermission)
            ) : (
              // <Collapse
              //   defaultActiveKey={[listCollapse[0].key]}
              //   containerClassName="flex flex-col gap-5"
              //   headingClassName="border-b"
              //   contentClassName="border-b"
              //   items={listCollapse}
              // />
              <div className="flex justify-center">
                {module_id ? (
                  <div className="flex items-center flex-col gap-2.5">
                    <IconNodata />
                    <span className="text-lg">Chưa có dữ liệu!</span>
                  </div>
                ) : (
                  <span className="font-medium h-10 flex items-center">
                    Vui lòng chọn Module!
                  </span>
                )}
              </div>
            )}
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default SettingConfigPermission;
