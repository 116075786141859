export const PAGE_KEY = {
  MANAGE_EPIC_LIST_VIEW: 'manage_epic_list_view',
};

export const BUTTON_KEY = {
  EPIC_CREATE: 'epic_create',
  EPIC_UPDATE: 'epic_update',
  EPIC_DELETE: 'epic_delete',
  EPIC_DETAIL: 'epic_detail',
};
