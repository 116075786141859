import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ImageBackground from '_common/component/ImageBackground';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { REGEX } from '_common/constants/common';
import { messageValidate, messageWarning } from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectDepartment from '_common/dof/Select/ObjectType/SelectDepartment';
import SelectJobTitle from '_common/dof/Select/ObjectType/SelectJobTitle';
import SelectBusinessId from '_common/dof/Select/SelectBusinessId';

import InputPassword from '_common/dof/Control/InputPassword';
import useConfirm from '_common/hooks/useConfirm';
import UserApi from 'pages/User/_api/userList';
import { statusType, TYPE_ACCOUNT } from 'pages/User/constants/statusType';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Modal, notification, OptionProps } from 'tera-dls';
import PermissionRole from './PermissionRole/PermissionRole';

export type IFormType = 'individual' | 'member' | 'owner';
type Field = 'role_id' | 'business_id';
interface IProps {
  open: boolean;
  id?: number;
  onClose: () => void;
  onChangeDataSuccess?: () => void;
  type: IFormType;
  defaultValues?: any;
  disableFields?: Array<Field>;
  disableToggle?: boolean;
}

const titleType = {
  individual: 'Thêm cá nhân',
  member: 'Thêm thành viên',
  owner: 'Thêm chủ sở hữu',
};

const UserForm = (props: IProps) => {
  const {
    open = false,
    id,
    onClose,
    onChangeDataSuccess,
    type: defaultType,
    defaultValues,
    disableFields = [],
    disableToggle,
  } = props;
  const [type, setType] = useState<any>(defaultType);
  const [roleValue, setRoleValue] = useState<any>({});
  const [image, setImage] = useState<any>();
  const [openPermissionRole, setOpenPermissionRole] = useState<boolean>(false);
  const form = useForm({
    mode: 'onChange',
  });
  const {
    formState: { isDirty },
  } = form;

  const confirm = useConfirm();

  const queryClient = useQueryClient();

  const handleClose = (): void => {
    form.reset();
    onClose();
  };

  useEffect(() => {
    if (defaultValues) {
      form.reset(defaultValues);
      defaultValues?.role && setRoleValue(defaultValues?.role);
    }
  }, [defaultValues]);

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        className: 'modal-button-center',
        onOk: () => {
          handleClose();
        },
      });
    } else {
      handleClose();
    }
  };

  const updateData = (data) => {
    const values = {
      full_name: data?.full_name,
      email: data?.email,
      phone: data?.phone,
      username: data?.username,
      role_id: data?.role_id,
      job_title: data?.job_title,
      department: data?.department,
      business_id: data?.business_id,
      type: data?.type,
      is_active: String(data?.is_active),
    };
    data?.type && setType(data?.type);
    data?.avatar_url && setImage({ url: data?.avatar_url });
    data?.role && setRoleValue(data?.role);
    form.reset(values, { keepDirty: false });
  };

  const { data, refetch } = useQuery(
    ['get-user-detail', id],
    () => UserApi.getDetail({ id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    id && refetch();
  }, [id]);

  useEffect(() => {
    if (data) {
      updateData(data);
    }
  }, [data]);

  const { mutate: mutateForm } = useMutation(
    (variables: any) => {
      return id
        ? UserApi.update({ id, params: variables })
        : UserApi.create({ params: variables });
    },
    {
      onSuccess: ({ code, msg }) => {
        if (code === 200) {
          onChangeDataSuccess && onChangeDataSuccess();
          queryClient.invalidateQueries(['get-user-detail']);
          onClose();
          notification.success({
            message: msg,
          });
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleSubmitForm = (values): void => {
    const data = {
      ...values,
      file_upload: {
        url: image?.url,
      },
      type,
    };
    mutateForm(data);
  };

  const activityOptions: OptionProps[] = Object.entries(statusType).map(
    ([value, label]) => ({ label, value }),
  );

  const typeOptions: OptionProps[] = Object.entries(TYPE_ACCOUNT).map(
    ([value, label]) => ({ label, value }),
  );

  return (
    <>
      <Modal
        centered={true}
        title={id ? 'Cập nhật người dùng' : titleType[type]}
        open={open}
        width={800}
        closeIcon={false}
        okText="Lưu"
        cancelText="Huỷ"
        onOk={() => form.handleSubmit(handleSubmitForm)()}
        onCancel={handleCloseConfirm}
        destroyOnClose={true}
      >
        <FormTera
          form={form}
          className="h-full"
          onSubmit={form.handleSubmit(handleSubmitForm)}
        >
          <div className="grid grid-cols-2 gap-5">
            <div className="col-span-1">
              <FormTeraItem label="Ảnh đại diện" name="" className="mb-[18px]">
                <div className="flex justify-center">
                  <ImageBackground
                    wrapperClassName="w-[70px] h-[70px]"
                    onChange={setImage}
                    value={image}
                    isShowBtnDelete
                    object_key={'service-package'}
                    folder={'service-package'}
                  />
                </div>
              </FormTeraItem>
              {id && (
                <FormTeraItem
                  label="Vai trò"
                  name="type"
                  rules={[
                    {
                      required: messageValidate.emptyText,
                    },
                  ]}
                >
                  <Select
                    placeholder="Vui lòng chọn"
                    options={typeOptions}
                    onChangeCustom={setType}
                  />
                </FormTeraItem>
              )}
              <FormTeraItem
                label="Tên đăng nhập"
                name="username"
                rules={[
                  {
                    required: messageValidate.emptyText,
                    pattern: {
                      value: new RegExp(REGEX.USERNAME),
                      message: messageValidate.username,
                    },
                    minLength: {
                      value: 1,
                      message: 'Tên đăng nhập tối thiểu 1 ký tự',
                    },
                    maxLength: {
                      value: 50,
                      message: 'Tên đăng nhập không vượt quá 50 ký tự',
                    },
                  },
                ]}
              >
                <Input disabled={!!id} />
              </FormTeraItem>
              {!id && (
                <FormTeraItem
                  label="Mật khẩu"
                  name="password"
                  rules={[
                    {
                      required: messageValidate.emptyText,
                      pattern: {
                        value: new RegExp(REGEX.PASSWORD),
                        message: messageValidate.password,
                      },
                      minLength: {
                        value: 8,
                        message: 'Mật khẩu tối thiểu 8 ký tự',
                      },
                      maxLength: {
                        value: 16,
                        message: 'Mật khẩu không vượt quá 16 ký tự',
                      },
                    },
                  ]}
                >
                  <InputPassword placeholder="Vui lòng nhập mật khẩu" />
                </FormTeraItem>
              )}
              <FormTeraItem
                label="Trạng thái"
                name="is_active"
                rules={[
                  {
                    required: messageValidate.emptyText,
                  },
                ]}
              >
                <Select options={activityOptions} placeholder="Vui lòng chọn" />
              </FormTeraItem>
              {type !== 'individual' && (
                <FormTeraItem
                  label="Công ty"
                  name="business_id"
                  rules={[
                    {
                      required: messageValidate.emptyText,
                    },
                  ]}
                >
                  <SelectBusinessId
                    disabled={disableFields?.includes('business_id')}
                    placeholder="Vui lòng chọn"
                    onChangeCustom={() => {
                      form.setValue('role_id', '', { shouldDirty: true });
                      setRoleValue({});
                    }}
                  />
                </FormTeraItem>
              )}
            </div>
            <div className="col-span-1">
              <FormTeraItem
                label="Họ và tên"
                name="full_name"
                rules={[
                  {
                    required: messageValidate.emptyText,
                  },
                ]}
              >
                <Input maxLength={100} />
              </FormTeraItem>
              <FormTeraItem
                label="Email"
                name="email"
                rules={[
                  {
                    required: messageValidate.emptyText,
                  },
                  {
                    pattern: {
                      value: REGEX.EMAIL,
                      message: messageValidate.email,
                    },
                  },
                ]}
              >
                <Input maxLength={320} />
              </FormTeraItem>
              <FormTeraItem
                label="Số điện thoại"
                name="phone"
                rules={[
                  {
                    required: messageValidate.emptyText,
                  },
                  {
                    pattern: {
                      value: REGEX.PHONE_NUMBER,
                      message: 'Số điện thoại không hợp lệ',
                    },
                  },
                  {
                    minLength: {
                      value: 8,
                      message: 'Nhập tối thiểu 8 kí tự',
                    },
                  },
                ]}
              >
                <Input maxLength={20} />
              </FormTeraItem>
              <FormTeraItem label="Chức danh" name="job_title">
                <SelectJobTitle />
              </FormTeraItem>
              <FormTeraItem label="Phòng ban/ Bộ phận" name="department">
                <SelectDepartment />
              </FormTeraItem>
              {type !== 'individual' && (
                <FormTeraItem
                  name="role_id"
                  label="Phân quyền"
                  className="col-span-1"
                  rules={[{ required: 'Vui lòng điền thông tin' }]}
                >
                  <div className="flex gap-2.5">
                    <Input readOnly={true} placeholder="" disabled />
                    <Button
                      htmlType="button"
                      onClick={() => {
                        setOpenPermissionRole(true);
                      }}
                      disabled={
                        !form.watch('business_id') ||
                        disableFields?.includes('role_id')
                      }
                    >
                      Chọn
                    </Button>
                  </div>
                </FormTeraItem>
              )}
            </div>
          </div>
        </FormTera>
      </Modal>
      {openPermissionRole && (
        <PermissionRole
          disableToggle={disableToggle}
          open={openPermissionRole}
          onClose={() => setOpenPermissionRole(false)}
          businessId={form.watch('business_id')}
          value={roleValue}
          onSubmit={(value) => {
            form.setValue('role_id', value?.id, { shouldDirty: true });
          }}
        />
      )}
    </>
  );
};

export default UserForm;
