import { useQueryClient } from '@tanstack/react-query';
import { useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowSmallLeftSolid, Breadcrumb, Button, ItemType } from 'tera-dls';
import DetailForm, { DetailFormRef } from './DetailForm';
import { BUSINESS_URL } from '_common/constants/url';

function BusinessForm() {
  const navigate = useNavigate();
  const { id } = useParams();
  const detailRef = useRef<DetailFormRef>(null);
  const queryClient = useQueryClient();

  const BreadcrumbItem: ItemType[] = [
    {
      title: <a className="cursor-pointer">Danh sách doanh nghiệp</a>,
      onClick: () => navigate(BUSINESS_URL.list.path),
    },
    {
      title: id ? 'Sửa doanh nghiệp' : 'Thêm doanh nghiệp',
    },
  ];

  return (
    <div className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <div
              className="page-header__breadcrumb-back cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <ArrowSmallLeftSolid className="h-6 w-6" />
            </div>
            <Breadcrumb separator={'>'} items={BreadcrumbItem} />
          </div>

          <div className="page-header-v2__function">
            <Button
              htmlType="submit"
              className="page-header-btn"
              onClick={() => detailRef?.current?.submit()}
            >
              Lưu
            </Button>
          </div>
        </div>
      </div>
      <div className="page-content-v2">
        <div className="page-content-v2_main">
          <DetailForm
            id={Number(id)}
            ref={detailRef}
            onSuccess={() => {
              queryClient.invalidateQueries(['get-business-list']);
              navigate(-1);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default BusinessForm;
