import React, { createContext } from 'react';
import { observer } from 'mobx-react-lite';
import { usePermission } from '_common/hooks/usePermission';
import NoPermission from '_common/component/NoPermission';

interface PageLayoutProp {
  page_key: string;
  children?: any;
}

export const PageContext = createContext({});

const PageLayout = observer(({ page_key, children }: PageLayoutProp) => {
  const { hasPage } = usePermission();

  if (!hasPage(page_key)) return <NoPermission />;

  return (
    <PageContext.Provider value={{}}>
      <div data-object_type={page_key}>{children}</div>
    </PageContext.Provider>
  );
});

export default PageLayout;
