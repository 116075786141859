import { useQuery } from '@tanstack/react-query';
import { TParamsApiDof } from '_common/dof/interfaces';
import { forwardRef, useState } from 'react';
import { OptionProps, SelectProps } from 'tera-dls';
import { AdministratorApi } from '../../_api';
import SelectEntity from '../SelectEntity';
import { useDebounce } from '_common/hooks/useDebounce';
import { useTeraForm } from '_common/dof/FormTera/TeraFormContext';
import { useTeraFormItem } from '_common/dof/FormTera/TeraItemContext';

interface SelectObjectTypeProps extends SelectProps {
  placeholder?: string;
  paramsApi?: TParamsApiDof;
}

const SelectObjectType = forwardRef<HTMLInputElement, SelectObjectTypeProps>(
  ({ placeholder = 'Vui lòng chọn', paramsApi, ...props }, ref) => {
    const [search, setSearch] = useState<string>('');
    const searchDebounce = useDebounce(search, 300);
    const { form } = useTeraForm();
    const { item } = useTeraFormItem();
    const includeKey = form?.watch(`${item?.name}`);
    const paramsQuery = {
      keyword: searchDebounce,
      include_key: includeKey,
      ...paramsApi,
    };

    const { data: listObjectType } = useQuery(
      ['get-list-object-type', paramsQuery],
      () => AdministratorApi.getListObjectType(paramsQuery),
      {
        staleTime: 300000,
        cacheTime: 300000,
      },
    );

    const optionsObjectType: OptionProps[] =
      listObjectType?.data?.map((type) => ({
        label: type?.object_type,
        value: type?.key,
      })) ?? [];

    return (
      <SelectEntity
        allowClear
        showSearch
        searchValue={search}
        onSearch={setSearch}
        placeholder={placeholder}
        options={optionsObjectType}
        {...props}
        ref={ref}
      />
    );
  },
);

export default SelectObjectType;
