import { useMutation, useQuery } from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import PaginationCustom from '_common/component/PaginationCustom';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import useConfirm from '_common/hooks/useConfirm';
import { usePermission } from '_common/hooks/usePermission';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  DropdownItem,
  Icon,
  PaginationProps,
  Table,
  Tag,
  getQueryParams,
  notification,
  updateQueryParams,
  useDetectDevice,
} from 'tera-dls';
import EpicApi from './_api';
import DetailPageModal from './containers/Detail';
import EpicFilter from './containers/Filter';
import EpicForm from './containers/Form';
import EpicHeader from './containers/Header';
import { IFilter, IFormModel } from './interfaces';
import { BUTTON_KEY } from './constants/key';
import { statusConfigColor } from 'pages/System/constants';

const EpicPage = () => {
  const { hasPage } = usePermission();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const { isMobile } = useDetectDevice();

  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [formModel, setFormModel] = useState<IFormModel>({ open: false });
  const [detailModal, setDetailModal] = useState<IFormModel>({ open: false });

  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const {
    data: listPage,
    refetch,
    isLoading,
  } = useQuery(
    ['get-epic-list', queryParams],
    () => EpicApi.getList({ page: 1, limit: 10, ...queryParams }),
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const { mutate: deletePage, isLoading: loadingDelete } = useMutation(
    (id: string | number) => EpicApi.delete(id),
    {
      onSuccess(res) {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          refetch();
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleQueryParams = (query: IFilter) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...query,
    });
    navigate(paramString);
  };

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize =
      queryParams?.limit && Number(pageSize) !== Number(queryParams?.limit);
    handleQueryParams({
      page: isDiffPageSize ? 1 : page,
      limit: pageSize,
    });
  };

  const handleDeletePage = (id: string | number, name: string) => {
    confirm.warning({
      title: 'XÁC NHẬN XÓA EPIC',
      content: (
        <>
          <p>Bạn có chắc chắn muốn xóa epic</p>
          <p>{name} này không?</p>
        </>
      ),
      onOk: () => {
        deletePage(id);
      },
    });
  };

  const renderDropdown = (record): DropdownItem[] => {
    const dropdownItems: DropdownItem[] = [];
    hasPage(BUTTON_KEY.EPIC_UPDATE) &&
      dropdownItems.push({
        key: 1,
        label: 'Sửa',
        onClick: () => setFormModel({ open: true, id: record?.id }),
      });
    hasPage(BUTTON_KEY.EPIC_DELETE) &&
      dropdownItems.push({
        key: 2,
        label: <span className="text-red-500">Xóa</span>,
        onClick: () => handleDeletePage(record?.id, record?.name),
      });
    return dropdownItems;
  };

  const columns: any = [
    {
      title: 'STT',
      dataIndex: 'record_number',
      width: 60,
      align: 'center',
    },
    {
      title: 'Mã epic',
      dataIndex: 'concatenated_code',
      width: 200,
      render: (text) => <div className="line-clamp-2">{text}</div>,
    },
    {
      title: 'Tên epic',
      dataIndex: 'name',
      width: 200,
      render: (text) => <div className="line-clamp-2">{text}</div>,
    },
    {
      title: 'Module',
      dataIndex: 'module',
      width: 200,
      render: (module) => module?.title,
    },
    {
      title: 'Icon',
      dataIndex: 'icon',
      width: 200,
      render: (icon) => <Icon type={icon} />,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 200,
      render: (status, record) =>
        status && (
          <Tag color={statusConfigColor[status]}>{record?.status_text}</Tag>
        ),
    },
    {
      dataIndex: '',
      width: 88,
      fixed: 'right',
      render: (_, record) => {
        const dropdownItems = renderDropdown(record);
        return (
          <ActionDropdown
            onClickDetail={() => setDetailModal({ open: true, id: record?.id })}
            dropdownItems={dropdownItems}
            trigger="click"
            buttonDetailKey={BUTTON_KEY.EPIC_DETAIL}
          />
        );
      },
    },
  ];

  return (
    <>
      <HeaderViewList
        title="DANH SÁCH EPIC"
        onClickButtonAdd={() => setFormModel({ open: true })}
        onClickFilter={() => setIsFilter(true)}
        buttonCreatingKey={BUTTON_KEY.EPIC_CREATE}
      >
        <EpicHeader />
      </HeaderViewList>
      <div className="bg-white shadow-xsm rounded-2xl">
        <Table
          columns={columns}
          data={listPage?.data || []}
          rowKey={(record: any) => record?.id}
          loading={isLoading || loadingDelete}
          hiddenColumns={
            isMobile
              ? [
                  'title',
                  'parent_menu',
                  'path',
                  'icon',
                  'business_id',
                  'status',
                ]
              : []
          }
        />
        {listPage?.total > 0 && (
          <PaginationCustom
            onChange={handleChangePage}
            total={listPage?.total || 0}
            current={listPage?.current_page || 1}
            pageSize={listPage?.per_page}
            to={listPage?.to}
            from={listPage?.from}
          />
        )}
      </div>
      {isFilter && (
        <EpicFilter
          open={isFilter}
          onClose={() => setIsFilter(false)}
          initialValue={queryParams}
        />
      )}
      {formModel.open && (
        <EpicForm
          open={formModel.open}
          id={formModel?.id}
          onClose={() => {
            setFormModel({ open: false });
          }}
          onChangeDataSuccess={() => {
            refetch();
          }}
        />
      )}
      {detailModal.open && (
        <DetailPageModal
          open={detailModal.open}
          onCloseModal={() => setDetailModal({ open: false })}
          id={detailModal.id}
        />
      )}
    </>
  );
};

export default EpicPage;
