import { useQuery } from '@tanstack/react-query';
import Search from '_common/dof/Control/Search';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import TableTera from '_common/dof/TableTera';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  MagnifyingGlassOutlined,
  Modal,
  PaginationProps,
  Spin,
} from 'tera-dls';
import { ObjectTypeApi } from '../../_api';

function ObjectTypeList({ open, onClose }) {
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });
  const [keyword, setKeyword] = useState('');

  const form = useForm();

  const { data: listObjectType, isLoading } = useQuery(
    ['get-list-object-type', pagination, keyword],
    () => {
      const params = {
        keyword,
        ...pagination,
      };
      return ObjectTypeApi.getList(params);
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const columns = [
    {
      title: 'Mã loại dữ liệu',
      dataIndex: 'key',
    },
    {
      title: 'Tên loại dữ liệu',
      dataIndex: 'object_type',
    },
  ];

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize = pagination?.limit && pageSize !== pagination?.limit;
    setPagination({
      page: isDiffPageSize ? 1 : page,
      limit: pageSize,
    });
  };

  const handleSearch = (value) => {
    setKeyword(value?.keyword);
    setPagination({
      page: 1,
      limit: pagination?.limit,
    });
  };

  return (
    <Modal
      closeIcon={false}
      open={open}
      title="DANH SÁCH LOẠI DỮ LIỆU"
      width={1800}
      onOk={onClose}
      okText="Đóng"
      cancelButtonProps={{ className: 'hidden' }}
    >
      <Spin spinning={isLoading}>
        <div className="flex flex-col gap-y-6">
          <FormTera form={form} onSubmit={handleSearch} isLoading={false}>
            <FormTeraItem className="!mb-0" name="keyword">
              <Search
                placeholder="Tìm kiếm theo mã và tên loại dữ liệu"
                icon={<MagnifyingGlassOutlined />}
                className="w-[400px]"
              />
            </FormTeraItem>
          </FormTera>
          <div className="bg-white shadow-xsm rounded-[5px]">
            <TableTera
              columns={columns}
              data={listObjectType?.data || []}
              pagination={{
                onChange: handleChangePage,
                total: Number(listObjectType?.total) || 0,
                current: Number(listObjectType?.current_page),
                pageSize: Number(listObjectType?.per_page),
                to: listObjectType?.to,
                from: listObjectType?.from,
              }}
            />
          </div>
        </div>
      </Spin>
    </Modal>
  );
}

export default ObjectTypeList;
