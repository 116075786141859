import { adminEndpoint } from '_common/constants/common';
import api from 'states/drivers';

const ObjectTypeEndpoint = `${adminEndpoint}/administrator/object-type`;

const ObjectTypeApi = {
  getList: async (param?) =>
    await api
      .get(`${ObjectTypeEndpoint}/list`, param)
      .then((result) => result?.data?.data),
};

export default ObjectTypeApi;
