import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const AffiliatesEndpoint = `${endpoint}/administrator/affiliates`;

const AffiliatesAPI = {
  getListPublisher: async (param?) =>
    await api
      .get(`${AffiliatesEndpoint}/list`, param)
      .then((result) => result?.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${AffiliatesEndpoint}/detail/${id}`)
      .then((result) => result?.data?.data),
  getReportPublisher: async (param?) =>
    await api
      .get(`${AffiliatesEndpoint}/report-publisher`, param)
      .then((result) => result?.data),
  update: async ({ params, id }) =>
    await api
      .put(`${AffiliatesEndpoint}/update/${id}`, params)
      .then((result) => result.data),
};

export default AffiliatesAPI;
