import Filter from '_common/component/Filter';
import { DATE_BACKEND_FORMAT, DATE_FORMAT } from '_common/constants/common';
import RangePicker from '_common/dof/Control/RangePicker';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface BusinessFilterProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue: any;
}

function BusinessFilter({
  open,
  onClose,
  onFilter,
  initialValue,
}: BusinessFilterProps) {
  const form = useForm();

  const handleSubmitForm = (values) => {
    const data = {
      register_date:
        values?.register_date &&
        values?.register_date
          ?.map((date) => moment(date).format(DATE_BACKEND_FORMAT))
          ?.join(','),
      // expiration_time:
      //   values?.expiration_time &&
      //   values?.expiration_time
      //     ?.map((date) => moment(date).format(DATE_BACKEND_FORMAT))
      //     ?.join(','),
    };
    onFilter(data);
    onClose();
  };

  const handleReset = () => {
    const values = {
      register_date: null,
      // expiration_time: null,
    };
    onFilter(values);
    onClose();
  };

  useEffect(() => {
    const data = {
      register_date:
        initialValue?.register_date &&
        initialValue?.register_date?.split(',')?.map((date) => moment(date)),
      // expiration_time:
      //   initialValue?.expiration_time &&
      //   initialValue?.expiration_time?.split(',')?.map((date) => moment(date)),
    };

    form.reset(data);
  }, [initialValue]);

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={handleSubmitForm} isLoading={false}>
        <FormTeraItem label="Ngày đăng ký" name="register_date">
          <RangePicker
            format={DATE_FORMAT}
            placeholder={['Từ ngày', 'Đến ngày']}
          />
        </FormTeraItem>
        {/* <FormTeraItem label="Ngày hết hạn" name="expiration_time">
          <RangePicker format={DATE_FORMAT} />
        </FormTeraItem> */}

        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default BusinessFilter;
