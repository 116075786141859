import React from 'react';

function Question() {
  return (
    <div>
      <h1>Hướng dẫn</h1>
      <p>
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptate,
        eveniet veritatis pariatur animi rerum impedit dolor accusantium
        repellendus sint nobis nam, ut suscipit quia excepturi, natus odit
        nostrum officiis magni?
      </p>
      <p>
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptate,
        eveniet veritatis pariatur animi rerum impedit dolor accusantium
        repellendus sint nobis nam, ut suscipit quia excepturi, natus odit
        nostrum officiis magni?
      </p>
    </div>
  );
}

export default Question;
