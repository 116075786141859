import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import ColumnSetting from '_common/component/ColumnSetting';
import DrawerCustom from '_common/component/DrawerCustom';
import ModalConfirm from '_common/component/ModalConfirm2';
import { useStores } from '_common/hooks';

// import ModalContact from '_common/component/ModalQuickView/ModalContact';
import { getQueryParams } from 'tera-dls';
import MenuComponent from '../Menu';
import InlineMenu from '../Menu/InlineMenu';

const closedKeys = ['detail', 'update', 'create', 'set-the-stage'];

function BasicLayout() {
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const location = useLocation();
  const [isExpand, setIsExpand] = useState<boolean>(true);
  const {
    drawerStore: { open, object_id, object_type, setDrawer },
    columnSettingStore,
  } = useStores();

  useEffect(() => {
    if (queryParams?.mode) {
      queryParams?.mode === 'kanban' ? setIsExpand(false) : setIsExpand(true);
      return;
    }
    const isClose = closedKeys.some((key) => location.pathname.includes(key));

    isClose ? setIsExpand(false) : setIsExpand(true);
  }, [location.pathname, JSON.stringify(queryParams?.mode)]);

  return (
    <>
      <div className="w-full">
        <InlineMenu isExpand={isExpand} onChangeSize={setIsExpand} />
        <div
          className={`flex flex-col flex-1 w-full h-full transition-all min-h-screen ${
            isExpand ? 'pl-[225px]' : 'pl-[50px]'
          }`}
        >
          <MenuComponent isExpand={isExpand} />

          <div className={`pt-[45px] bg-[#F3F3F9] h-full flex-1`}>
            <Outlet />
          </div>
        </div>
      </div>
      <DrawerCustom
        open={open}
        object_id={object_id}
        object_type={object_type}
        onClose={() => {
          setDrawer({
            object_id,
            object_type,
            open: false,
          });
        }}
      />
      <ColumnSetting
        modalProps={columnSettingStore.modalProps}
        open={columnSettingStore.open}
        object_type={columnSettingStore.object_type}
        onClose={() => {
          columnSettingStore.setColumnSetting({
            object_type: null,
            open: false,
          });
        }}
      />
      <ModalConfirm />
    </>
  );
}

export default observer(BasicLayout);
