import Filter from '_common/component/Filter';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectBank from '_common/dof/Select/SelectBank';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface IProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue: any;
}

function PaymentMethodFilter({
  open,
  onClose,
  onFilter,
  initialValue,
}: IProps) {
  const form = useForm();

  useEffect(() => {
    if (initialValue) {
      form.reset({
        ...(initialValue?.data && { data: initialValue.data }),
        ...(initialValue?.card_type_id && {
          card_type_id: Number(initialValue.card_type_id),
        }),
      });
    }
  }, [initialValue]);
  console.log(form.watch());

  const handleSubmitForm = (values) => {
    onFilter(values);
    onClose();
  };

  const handleReset = () => {
    const values = {
      data: null,
      card_type_id: null,
    };
    onFilter(values);
    onClose();
  };

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={handleSubmitForm} isLoading={false}>
        <FormTeraItem label="Phương thức thanh toán" name="data">
          <Select
            placeholder="Vui lòng chọn"
            options={[
              { value: '1', label: 'Ngân hàng' },
              { value: '2', label: 'Ví điện tử' },
            ]}
            onChangeCustom={() => form.setValue('card_type_id', null)}
            onClear={() => form.setValue('card_type_id', null)}
          />
        </FormTeraItem>

        <FormTeraItem name="card_type_id" label="Ngân hàng">
          <SelectBank
            disabled={!form.watch('data')}
            paramsApi={{ object_type: form?.watch('data') }}
          />
        </FormTeraItem>
        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default PaymentMethodFilter;
