import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const UserEndpoint = `${endpoint}/administrator/user`;
interface ParamType<T> {
  params?: T;
}
const UserApi = {
  getList: async <T>({ params }: ParamType<T>) =>
    await api
      .get(`${UserEndpoint}/list`, params)
      .then((result) => result.data?.data),
  getWallet: async <T>({ params }: ParamType<T>) =>
    await api
      .get(`${UserEndpoint}/get-wallet`, params)
      .then((result) => result.data?.data),
  getInvoice: async <T>({ params }: ParamType<T>) =>
    await api
      .get(`${UserEndpoint}/list-bill`, params)
      .then((result) => result.data?.data),
  getSummary: async <T>({ params }: ParamType<T>) =>
    await api
      .get(`${UserEndpoint}/summary-list`, params)
      .then((result) => result.data?.data),
  getSummaryDetail: async <T>({ params }: ParamType<T>) =>
    await api
      .get(`${UserEndpoint}/summary-detail`, params)
      .then((result) => result.data?.data),
  getDetail: async ({ id }: any) =>
    await api
      .get(`${UserEndpoint}/detail/${id}`)
      .then((result) => result.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${UserEndpoint}/create`, params)
      .then((result) => result.data),
  update: async ({ id, params }) =>
    await api
      .put(`${UserEndpoint}/update/${id}`, params)
      .then((result) => result.data),
  updateStatus: async ({ id, params }) =>
    await api
      .put(`${UserEndpoint}/update-status/${id}`, params)
      .then((result) => result.data),
  configRole: async ({ params }) =>
    await api
      .put(`${UserEndpoint}/config-role`, params)
      .then((result) => result.data),
  delete: async ({ id }) =>
    await api
      .delete(`${UserEndpoint}/delete/${id}`)
      .then((result) => result?.data),
  cancelActivate: async (params?) =>
    await api
      .post(`${UserEndpoint}/cancel-activation`, params)
      .then((result) => result.data),
  expiredAccount: async (params?) =>
    await api
      .post(`${UserEndpoint}/expired-account`, params)
      .then((result) => result.data),
  renewalAccount: async (params?) =>
    await api
      .post(`${UserEndpoint}/renewal-account`, params)
      .then((result) => result.data),
  restart: async (params?) =>
    await api
      .post(`${UserEndpoint}/restart`, params)
      .then((result) => result.data),
  getListPermission: async ({ params }) =>
    await api
      .get(`${UserEndpoint}/list-permission`, params)
      .then((result) => result.data?.data),
};

export default UserApi;
