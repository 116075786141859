import { useQueries } from '@tanstack/react-query';
import {
  DATE_BACKEND_FORMAT,
  DATE_FORMAT,
  timeFormat,
} from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import { filterField } from '_common/utils';
import moment from 'moment';
import { useMemo, useState } from 'react';
import {
  ArrowsPointingOuOutlined,
  Col,
  formatCurrency,
  formatDate,
  Modal,
  PaginationProps,
  RangePicker,
  RangePickerProps,
  Row,
  Tooltip,
} from 'tera-dls';
import ConfigPaymentMethodApi from '../../_api';
import {
  methodsText,
  RenderTransactionType,
  statusTransactionRender,
} from '../../constants';
import Withdraw from '../Withdraw';
import Content from './Content';
import ModalChart from './Modal';

export const ModalStatement = ({ onClose, open, id }) => {
  const [openWithdraw, setOpenWithdraw] = useState(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const [params, setParams] = useState({
    card_id: id,
    page: 1,
    limit: 10,
    fromDate: moment().subtract(7, 'days').format(DATE_BACKEND_FORMAT),
    toDate: moment().format(DATE_BACKEND_FORMAT),
  });

  const [filter, setFilter] = useState<any>({
    fromDate: moment().subtract(7, 'days').format(DATE_BACKEND_FORMAT),
    toDate: moment().format(DATE_BACKEND_FORMAT),
  });

  const [
    { data: response, isLoading: loadingTable },
    { data: responseChart },
    { data: dataDetail },
  ] = useQueries({
    queries: [
      {
        queryKey: ['get-transaction-detail', params],
        queryFn: () =>
          ConfigPaymentMethodApi.getTransactionDetail(filterField(params)),
        cacheTime: 300000,
        staleTime: 300000,
      },
      ,
      {
        queryKey: ['get-transaction-detail-in-week', id, filter],
        queryFn: () =>
          ConfigPaymentMethodApi.getChart({
            ...filter,
            card_id: id,
          }),
      },
      {
        queryKey: ['get-payment-method-detail', id],
        queryFn: () => ConfigPaymentMethodApi.getDetail(id),
        enabled: !!id,
      },
    ],
  });

  const detailCard = dataDetail?.[0];
  const dataTransaction = response?.data || [];

  const chartData = useMemo(() => {
    return {
      labels: responseChart
        ? Object.keys(responseChart)?.map((item) =>
            moment(item).format(DATE_FORMAT),
          )
        : [],
      datasets: [
        {
          data: responseChart
            ? Object.values(responseChart)?.map((item) => {
                if (!item) return 0;
                return item?.available_balance_at_time ?? 0;
              })
            : [],
          borderColor: '#F3722C',
          backgroundColor: '#F3722C',
          pointBorderWidth: 8,
          pointHoverBorderWidth: 10,
        },
      ],
    };
  }, [responseChart]);

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    setParams({
      ...params,
      page: page,
      limit: pageSize,
    });
  };

  const onChangeDates: RangePickerProps['onChange'] = (values) => {
    setParams({
      ...params,
      fromDate: values?.[0] ? values[0].format(DATE_BACKEND_FORMAT) : null,
      toDate: values?.[1] ? values[1].format(DATE_BACKEND_FORMAT) : null,
    });
  };

  const columns = [
    {
      title: 'Mã giao dịch',
      dataIndex: 'transaction_code',
      render: (data) => <span className="line-clamp-1">{data}</span>,
      width: '20%',
    },
    {
      title: 'Người dùng',
      dataIndex: 'user',
      render: (data) => <span className="line-clamp-1">{data?.username}</span>,
      width: '20%',
    },
    {
      title: 'Loại giao dịch',
      dataIndex: 'transaction_type',
      width: '20%',
      render: (data) => <RenderTransactionType type={data} />,
    },
    {
      title: 'Số tiền',
      dataIndex: 'amount',
      render: (amount) => formatCurrency(amount),
      width: '20%',
    },
    {
      title: 'Phương thức',
      dataIndex: 'methods',
      render: (value) => methodsText[value],
      width: '20%',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: '20%',
      render: (status) => statusTransactionRender[status],
    },
    {
      title: 'Thời gian tạo',
      dataIndex: 'created_at',
      render: (data) => formatDate(data, timeFormat.date_time),
      width: '20%',
    },
  ];

  return (
    <>
      <Modal
        width="70%"
        open={open}
        title="Sao kê"
        okText="Đóng"
        onOk={onClose}
        cancelButtonProps={{ className: 'hidden' }}
      >
        <Row className="grid-cols-12 gap-4">
          <Col className="col-span-3 bg-gray-100 rounded-[30px] p-4 space-y-4">
            <div className="flex items-center gap-5">
              <div className="bg-blue-200 p-2 rounded">
                <img
                  src={detailCard?.card_type?.image_bank}
                  className="w-[26px] h-[26px] bg-cover object-cover"
                />
              </div>
              <div className="space-y-2.5 font-semibold">
                <h3 className="font-medium text-base tracking-[0.15em]">
                  {detailCard?.card_type?.card_name}
                </h3>
                <p className="uppercase">{detailCard?.cardholder}</p>
                <p className="tracking-[0.3em]">
                  {detailCard?.account_number || detailCard?.account_phone}
                </p>
              </div>
            </div>

            <div className="space-y-1 px-9">
              <p className="font-medium">
                <span className="text-gray-500">Số dư khả dụng: </span>
                <span className="text-base text-green-600">
                  {formatCurrency(detailCard?.available_balance)}
                </span>
              </p>
              <p className="font-medium">
                <span className="text-gray-500">Số lần giao dịch: </span>
                <span className="text-base text-yellow-600">
                  {detailCard?.transaction_count}
                </span>
              </p>
              <p className="font-medium">
                <span className="text-gray-500">Tổng thu: </span>
                <span className="text-base">
                  {formatCurrency(detailCard?.total_deposit)}
                </span>
              </p>
              <p className="font-medium">
                <span className="text-gray-500">Tổng rút: </span>
                <span className="text-base">
                  {formatCurrency(detailCard?.total_withdrawal)}
                </span>
              </p>
            </div>
          </Col>
          <Col className="col-span-9 border border-gray-200 rounded p-2">
            <div className="flex justify-between items-center w-full px-2.5 py-1.5 shadow-[0_2px_2px_0_#0000000D]">
              <h4 className="font-medium">Biến động số dư</h4>

              <div className="flex gap-2.5">
                <RangePicker
                  value={[moment(filter?.fromDate), moment(filter?.toDate)]}
                  placeholder={['dd/mm/yyyy', ' dd/mm/yyyy']}
                  onChange={(value) =>
                    setFilter({
                      fromDate: value?.[0]?.format(DATE_BACKEND_FORMAT),
                      toDate: value?.[1].format(DATE_BACKEND_FORMAT),
                    })
                  }
                  allowClear={false}
                  maxDate={moment()}
                />
                <Tooltip title="Mở rộng">
                  <span className="flex items-center">
                    <ArrowsPointingOuOutlined
                      className="w-4 h-4 cursor-pointer"
                      onClick={() => setOpenModal(true)}
                    />
                  </span>
                </Tooltip>
              </div>
            </div>
            <div className="mt-4 h-3/4">
              <Content data={chartData} />
            </div>
          </Col>
        </Row>
        <div>
          <div className="flex justify-between items-center my-4">
            <h4 className="text-blue-500 font-medium">Lịch sử giao dịch</h4>
            <RangePicker
              placeholder={['dd/mm/yyyy', 'dd/mm/yyyy']}
              className="w-[300px]"
              value={[moment(params?.fromDate), moment(params?.toDate)]}
              onChange={onChangeDates}
              allowClear={false}
              maxDate={moment()}
            />
          </div>
          <TableTera
            loading={loadingTable}
            columns={columns}
            data={dataTransaction}
            pagination={{
              onChange: handleChangePage,
              total: response?.total || 0,
              current: response?.current_page || 1,
              pageSize: response?.per_page,
              to: response?.to,
              from: response?.from,
            }}
          />
        </div>
        {openWithdraw && (
          <Withdraw
            record={detailCard}
            open={openWithdraw}
            onClose={() => {
              setOpenWithdraw(false);
            }}
          />
        )}
      </Modal>
      {openModal && (
        <ModalChart
          open={openModal}
          onClose={() => setOpenModal(false)}
          data={chartData}
        />
      )}
    </>
  );
};
