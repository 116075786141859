import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  Badge,
  Dropdown,
  DropdownItem,
  PaginationProps,
  PlusCircleOutlined,
  Tabs,
  Tag,
  getQueryParams,
  notification,
  updateQueryParams,
} from 'tera-dls';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { BUTTON_KEY } from '_common/constants/permission';
import { USER_URL } from '_common/constants/url';
import TableTera from '_common/dof/TableTera';
import useConfirm from '_common/hooks/useConfirm';
import { usePermission } from '_common/hooks/usePermission';
import { renderTotalCount } from '_common/utils';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import UserListApi from 'pages/User/_api/userList';
import {
  ACCOUNT_TYPE,
  USER_STATUS,
  statusTypeColor,
} from 'pages/User/constants/statusType';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthApi } from 'states/api';
import UserForm, { IFormType } from '../Form';
import UserConfigPermission from '../Form/PermissionConfig';
import UserFormResetPassword from '../ResetPassword';
import UpdateStatus from '../UpdateStatus';

interface QuerySearch {
  page?: number;
  limit?: number;
  full_name?: string;
  status?: null | number;
}
interface IProps {
  businessId?: number;
  enableTab?: boolean;
  createProps?: any;
  createOnTitle?: boolean;
}

export interface IUserTableRef {
  onCreate: (type: IFormType) => void;
}
const ListUserTable = (
  {
    businessId,
    enableTab = true,
    createProps = {},
    createOnTitle = false,
  }: IProps,
  ref,
) => {
  const confirm = useConfirm();
  const navigate = useNavigate();
  const { hasPage } = usePermission();
  const [isOpenConfigPermission, setIsOpenConfigPermission] = useState(null);
  const [isResetPasswordForm, setIsResetPasswordForm] = useState(null);
  const [openUpdateStatus, setOpenUpdateStatus] = useState<any>({
    open: false,
  });
  const [formModel, setFormModel] = useState<any>({ open: false });
  const queryClient = useQueryClient();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const handleQueryParams = (query) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...query,
    });
    navigate(location.pathname + paramString);
  };
  const params = _.omit(queryParams, ['is_active']);

  const { data: listData, refetch: getListUser } = useQuery(
    ['get-list-user', queryParams, businessId],
    () =>
      UserListApi.getList<QuerySearch>({
        params: {
          page: 1,
          limit: 10,
          ...params,
          ...(queryParams?.is_active !== 'all' && {
            is_active: queryParams?.is_active,
          }),
          ...(businessId && { business_id: businessId }),
        },
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { data: summary, refetch: refetchSummary } = useQuery(
    ['get-config-permission-summary', queryParams],
    () =>
      UserListApi.getSummary({
        params,
      }),
    {
      enabled: enableTab,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    refetchSummary();
    getListUser();
  }, []);

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    handleQueryParams({
      page,
      limit: pageSize,
    });
  };

  const handleChangeTabs = (key) => {
    handleQueryParams({
      page: 1,
      is_active: key,
    });
  };

  const mutateDelete = useMutation(
    (id: string | number) => UserListApi.delete({ id }),
    {
      onSuccess: (res) => {
        if (res.code === 200) {
          getListUser();
          queryClient.invalidateQueries(['get-business-summary']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  // const activeAccount = useMutation(
  //   (params: any) => AuthApi.activeAccount(params),
  //   {
  //     onSuccess: (res) => {
  //       if (res.code === 200) {
  //         notification.success({
  //           message: res?.msg,
  //         });
  //       }
  //     },
  //     onError: (error: any) => {
  //       const errorMessage = error?.data?.msg || messageError.ERROR_API;
  //       notification.error({
  //         message: errorMessage,
  //       });
  //     },
  //   },
  // );

  const sendMailResetPassword = useMutation(
    (params: any) => AuthApi.forgotPassword(params),
    {
      onSuccess: (res) => {
        if (res.code === 200) {
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleDeleteUser = (id: string | number, name: string) => {
    confirm.warning({
      title: 'Xác nhận xóa người dùng',
      content: (
        <>
          <p>Bạn có chắc chắn muốn xóa người dùng</p>
          <p>
            <b className="break-all">{name}</b> này không?
          </p>
        </>
      ),
      onOk: () => {
        mutateDelete.mutate(id);
      },
    });
  };

  const handleResetPassword = (record) => {
    confirm.warning({
      title: 'Xác nhận đặt lại mật khẩu',
      content: (
        <p>
          Bạn có chắc chán muốn đặt lại mật khẩu nhân viên{' '}
          <strong>{record?.full_name}</strong>. Việc này sẽ gửi thông báo cho
          Admin và thông tin đặt lại mật khẩu sẽ được gửi về mail đã đăng ký.
        </p>
      ),
      onOk: () => sendMailResetPassword.mutate({ email: record?.email }),
    });
  };

  const renderDropdown = (record): DropdownItem[] => {
    const dropdownItems: DropdownItem[] = [];

    hasPage(BUTTON_KEY.USER_DETAIL) &&
      dropdownItems.push({
        key: 1,
        label: 'Xem',
        onClick: () => navigate(`${USER_URL.detail.path}/${record.id}`),
      });
    dropdownItems.push({
      key: 11,
      label: 'Cập nhật trạng thái',
      onClick: () => setOpenUpdateStatus({ open: true, value: record?.id }),
    });
    hasPage(BUTTON_KEY.USER_RESET_PASSWORD) &&
      dropdownItems.push({
        key: 2,
        label: 'Gửi email đặt lại mật khẩu',
        onClick: () => handleResetPassword(record),
      });
    hasPage(BUTTON_KEY.USER_RESET_PASSWORD) &&
      dropdownItems.push({
        key: 3,
        label: 'Đặt lại mật khẩu',
        onClick: () => setIsResetPasswordForm({ open: true, id: record?.id }),
      });

    hasPage(BUTTON_KEY.USER_UPDATE) &&
      dropdownItems.push({
        key: 4,
        label: 'Sửa',
        onClick: () =>
          setFormModel({ open: true, id: record?.id, type: record?.type }),
      });
    record?.type !== 'individual' &&
      dropdownItems.push({
        key: 6,
        label: <span>Phân quyền</span>,
        onClick: () => {
          setIsOpenConfigPermission({ open: true, dataDetail: record });
        },
      });
    hasPage(BUTTON_KEY.USER_DELETE) &&
      dropdownItems.push({
        key: 5,
        label: <span className="text-red-500">Xóa</span>,
        onClick: () => handleDeleteUser(record?.id, record?.full_name),
      });
    // hasPage(BUTTON_KEY.USER_DELETE) &&
    //   record?.type !== 'owner' &&

    return dropdownItems;
  };

  const items = [
    {
      key: '2',
      label: 'Thêm thành viên',
      onClick: () => {
        setFormModel({ open: true, type: 'member' });
      },
    },
    {
      key: '2',
      label: 'Thêm chủ sở hữu',
      onClick: () => {
        setFormModel({ open: true, type: 'owner' });
      },
    },
  ];

  const columnsEmployList: any = [
    {
      title: 'Họ và tên',
      dataIndex: 'full_name',
      key: 'full_name',
      width: 200,
      render: (text, record) => (
        <p
          className="line-clamp-2 text-blue-500 cursor-pointer"
          onClick={() => navigate(`${USER_URL.detail.path}/${record.id}`)}
        >
          {text}
        </p>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 200,
      render: (text) => <p className="line-clamp-2">{text}</p>,
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      key: 'phone',
      width: 200,
    },
    {
      title: 'Tên đăng nhập',
      dataIndex: 'username',
      key: 'username',
      width: 200,
      render: (text) => <p className="line-clamp-2">{text}</p>,
    },
    {
      title: 'Vai trò',
      dataIndex: 'type',
      key: 'type',
      width: 200,
      render: (type) => type && ACCOUNT_TYPE[type],
    },
    {
      title: 'Công ty',
      dataIndex: 'business',
      key: 'business',
      width: 200,
      render: (business) => business?.name,
    },
    {
      title: 'Phân quyền',
      dataIndex: 'role',
      width: 200,
      render: (val) => val?.title,
    },
    {
      title: 'Trạng thái ',
      dataIndex: 'is_active',
      key: 'is_active',
      width: 200,
      render: (status) =>
        USER_STATUS[status] && (
          <Tag color={statusTypeColor[status]}>{USER_STATUS[status]}</Tag>
        ),
    },
    {
      title: createOnTitle ? (
        <div className="flex justify-center">
          <Dropdown
            containerClassName="w-[50px] !top-1"
            placement="bottom-end"
            trigger="click"
            menu={{ items }}
          >
            <span>
              <PlusCircleOutlined className="w-5 cursor-pointer text-green-500" />
            </span>
          </Dropdown>
        </div>
      ) : (
        ''
      ),
      dataIndex: '',
      width: 80,
      fixed: 'right',
      render: (_, record) => {
        const dropdownItems = renderDropdown(record);
        return <ActionDropdown dropdownItems={dropdownItems} trigger="click" />;
      },
    },
  ];

  const tabItems = Object.entries(USER_STATUS)
    .reverse()
    .map(([key, value]) => ({
      key: key,
      label: (
        <h3 className="tab-table">
          {value}
          <Badge showZero count={renderTotalCount(summary, key)} />
        </h3>
      ),
    }));

  useImperativeHandle(
    ref,
    () => {
      return {
        onCreate: (type) => setFormModel({ open: true, type }),
      };
    },
    [],
  );
  return (
    <>
      {enableTab && (
        <Tabs
          onChange={handleChangeTabs}
          items={tabItems}
          activeKey={queryParams?.is_active}
          defaultActiveKey="all"
          className="mb-0 bg-white"
        />
      )}
      <TableTera
        rowKey={'id'}
        columns={columnsEmployList}
        data={listData?.data}
        pagination={{
          onChange: handleChangePage,
          from: listData?.from,
          to: listData?.to,
          current: listData?.current_page,
          pageSize: listData?.limit,
          total: listData?.total,
        }}
      />
      {isResetPasswordForm && (
        <UserFormResetPassword
          open={isResetPasswordForm?.open}
          onClose={() => setIsResetPasswordForm({ open: false })}
          id={isResetPasswordForm?.id}
        />
      )}
      {isOpenConfigPermission?.open && (
        <UserConfigPermission
          open={isOpenConfigPermission?.open}
          onClose={() => setIsOpenConfigPermission({ open: false })}
          id={isOpenConfigPermission?.dataDetail?.id}
        />
      )}
      {openUpdateStatus?.open && (
        <UpdateStatus
          open={openUpdateStatus?.open}
          onClose={() => {
            setOpenUpdateStatus({ open: false });
          }}
          value={openUpdateStatus?.value}
        />
      )}
      {formModel.open && (
        <UserForm
          {...createProps}
          open={formModel.open}
          id={formModel?.id}
          type={formModel?.type as IFormType}
          onClose={() => {
            setFormModel({ open: false });
          }}
          onChangeDataSuccess={() => {
            queryClient.invalidateQueries(['get-list-user']);
            queryClient.invalidateQueries(['get-business-summary']);
          }}
        />
      )}
    </>
  );
};

export default observer(forwardRef<IUserTableRef, IProps>(ListUserTable));
