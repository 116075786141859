import { PortalEndpoint, adminEndpoint } from '_common/constants/common';
import api from 'states/drivers';

const Attachment = `${PortalEndpoint}/portal/attachment`;
const Admin = `${adminEndpoint}/administrator`;
interface ParamType<T> {
  params?: T;
}
const PortalAPI = {
  getAll: async <T,>({ params }: ParamType<T>) =>
    await api
      .get(`${Attachment}/list`, params)
      .then((result) => result.data?.data),
  getUsers: async <T,>({ params }: ParamType<T>) =>
    await api
      .get(`${Admin}/user/list`, params)
      .then((result) => result.data?.data),
  update: async ({ id, params }) =>
    await api.put(`${Attachment}/update/${id}`, params),
  delete: async ({ id }) => await api.delete(`${Attachment}/delete/${id}`),
};

export default PortalAPI;
