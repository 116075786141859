import { useMutation, useQueries } from '@tanstack/react-query';
import {
  messageValidate,
  messageValidateLength,
  messageWarning,
} from '_common/constants/message';

import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import Input from '_common/dof/Control/Input';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectModule from '_common/dof/Select/SelectModule';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { Modal, Spin, notification } from 'tera-dls';
import GroupControlApi from '../../_api';
import { REGEX } from '_common/constants/common';
import SelectEpic from '_common/dof/Select/SelectEpic';

interface IProps {
  open: boolean;
  id?: number;
  onClose: () => void;
  onChangeDataSuccess?: () => void;
}

interface IForm {
  code: string;
  title: string;
  epic_id: number;
  module_id: number;
}

const GroupControlForm = (props: IProps) => {
  const { open = false, id, onClose, onChangeDataSuccess } = props;

  const form = useForm<IForm>();
  const {
    reset,
    handleSubmit,
    watch,
    formState: { isDirty },
  } = form;
  const confirm = useConfirm();

  const handleClose = (): void => {
    reset();
    onClose();
  };

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        className: 'modal-button-center',
        onOk: () => {
          handleClose();
        },
      });
    } else {
      handleClose();
    }
  };

  const moduleId = watch('module_id');

  const updateData = (data) => {
    const values = _.pick(data, ['code', 'title', 'epic_id', 'module_id']);
    reset({
      ...values,
    });
  };

  const [{ data: detail, isLoading }] = useQueries({
    queries: [
      {
        queryKey: ['get-group-id-detail', id],
        queryFn: () => GroupControlApi.getDetail(id),
        enabled: !!open && !!id,
        onSuccess: (data) => {
          updateData(data);
        },
      },
    ],
  });

  const { mutate: mutateForm, isLoading: isLoadingSubmit } = useMutation(
    (variables: any) => {
      return id
        ? GroupControlApi.update(id, variables)
        : GroupControlApi.create(variables);
    },
    {
      onSuccess: ({ code, msg }) => {
        if (code === 200) {
          onChangeDataSuccess && onChangeDataSuccess();
          onClose();
          notification.success({
            message: msg,
          });
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const trimData = (code: string): string => {
    return code.split(' ').join('');
  };

  const handleSubmitForm = (values: { [key: string]: any }): void => {
    const code = trimData(values.code);
    mutateForm({
      ...values,
      code,
      status: values?.status ? 'active' : 'inactive',
    });
  };

  return (
    <Modal
      centered={true}
      title={id ? ' SỬA NHÓM CONTROL' : 'THÊM NHÓM CONTROL'}
      open={open}
      width={500}
      closeIcon={false}
      okText="Lưu"
      cancelText="Huỷ"
      onOk={() => handleSubmit(handleSubmitForm)()}
      onCancel={handleCloseConfirm}
      destroyOnClose={true}
      confirmLoading={(isLoading && !!id) || isLoadingSubmit}
    >
      <Spin spinning={(isLoading && !!id) || isLoadingSubmit}>
        <FormTera form={form} className="h-full">
          <FormTeraItem
            label="Mã epic"
            name="code"
            rules={[
              {
                required: 'Vui lòng chọn',
                maxLength: {
                  value: 191,
                  message: messageValidateLength.key,
                },
                pattern: {
                  message: messageValidate.code,
                  value: REGEX.CODE,
                },
              },
            ]}
          >
            <Input placeholder="Vui lòng nhập" className="w-full" />
            <p className="text-gray-400 text-[0.625rem] italic mt-1">
              Nhập thông tin{' '}
              <span className="text-gray-800">[group_control]</span>, hệ thống
              sẽ tự gán theo Format:
              <span className="text-gray-800">
                [module]_[epic]_[group_control]
              </span>{' '}
            </p>
          </FormTeraItem>
          <FormTeraItem
            label="Tiêu đề"
            name="title"
            rules={[
              {
                required: 'Vui lòng chọn',
                maxLength: {
                  value: 255,
                  message: messageValidateLength.textLong,
                },
              },
            ]}
          >
            <Input placeholder="Vui lòng nhập" className="w-full" />
          </FormTeraItem>
          <FormTeraItem
            label="Module"
            name="module_id"
            className="flex-auto"
            rules={[
              {
                required: 'Vui lòng chọn',
              },
            ]}
          >
            <SelectModule placeholder="Chọn Module" />
          </FormTeraItem>
          <FormTeraItem
            label="Epic"
            name="epic_id"
            rules={[
              {
                required: 'Vui lòng chọn',
              },
            ]}
          >
            <SelectEpic
              disabled={!moduleId}
              paramsApi={{
                include_id: detail?.epic_id,
                module_id: moduleId,
              }}
              placeholder="Chọn Epic"
            />
          </FormTeraItem>
        </FormTera>
      </Spin>
    </Modal>
  );
};

export default GroupControlForm;
