import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const ImportExportEndpoint = `${endpoint}/administrator/import-export`;

const ImportExportApi = {
  getList: async ({ params }) =>
    await api
      .get(`${ImportExportEndpoint}/list-item`, params)
      .then((res) => res?.data?.data),
  getColumnList: async ({ params }) =>
    await api
      .get(`${ImportExportEndpoint}/list-column`, params)
      .then((res) => res?.data?.data),
  import: async ({ params }) =>
    await api
      .post(`${ImportExportEndpoint}/import-system`, params)
      .then((res) => res?.data),
  export: async ({ params }) =>
    await api
      .post(`${ImportExportEndpoint}/export-system`, params)
      .then((res) => res?.data),
  selectExportingFields: async ({ params }) =>
    await api
      .post(`${ImportExportEndpoint}/select-field-export`, params)
      .then((res) => res?.data),
  getTemplate: async ({ params }) =>
    await api
      .get(`${ImportExportEndpoint}/download-template-sample`, params)
      .then((res) => res?.data),
};

export default ImportExportApi;
