import { useQuery } from '@tanstack/react-query';
import { useDebounce } from '_common/hooks/useDebounce';
import { forwardRef, useState } from 'react';
import { OptionProps, SelectProps } from 'tera-dls';
import SelectEntity from '../SelectEntity';
import { AdministratorApi } from '../../_api';
import { TParamsApiDof } from '_common/dof/interfaces';
import { EmployeeText } from '_common/component/EmployeeText';

interface SelectEpicProps extends SelectProps {
  paramsApi?: TParamsApiDof;
}

const SelectEpic = forwardRef(
  (
    { placeholder = 'Chọn Epic', paramsApi, ...props }: SelectEpicProps,
    ref,
  ) => {
    const [search, setSearch] = useState('');
    const searchDebounce = useDebounce(search, 300);
    const paramsQuery = {
      limit: 15,
      page: 1,
      keyword: searchDebounce,
      ...paramsApi,
    };
    const { data: listEpic, isLoading } = useQuery(
      ['get-epic-list', paramsQuery],
      () => AdministratorApi.getListEpic(paramsQuery),
      {
        staleTime: 300000,
        cacheTime: 300000,
      },
    );
    const options: OptionProps[] =
      listEpic?.data?.map((item) => ({
        labelDisplay: <EmployeeText code={item?.code} name={item?.name} />,
        label: item?.name,
        value: item?.id,
      })) ?? [];

    return (
      <SelectEntity
        allowClear
        showSearch
        onSearch={setSearch}
        placeholder={placeholder}
        options={options}
        loading={isLoading}
        {...props}
        ref={ref}
      />
    );
  },
);

export default SelectEpic;
