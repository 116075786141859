import Filter from '_common/component/Filter';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { TYPE_SERVICE } from 'pages/ServicePackage/constants';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface IProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue: any;
}

function ServicePackageFilter({
  open,
  onClose,
  onFilter,
  initialValue,
}: IProps) {
  const form = useForm();

  useEffect(() => {
    if (initialValue) {
      form.reset({
        ...initialValue,
        ...(initialValue?.status && { status: Number(initialValue?.status) }),
      });
    }
  }, [initialValue]);

  const handleSubmitForm = (values) => {
    onFilter(values);
    onClose();
  };

  const handleReset = () => {
    const values = {
      type: null,
      status: null,
    };
    onFilter(values);
    onClose();
  };

  const typeOptions = Object.entries(TYPE_SERVICE).map(([key, value]) => ({
    label: value,
    value: key,
  }));

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={handleSubmitForm} isLoading={false}>
        <FormTeraItem label="Loại gói dịch vụ" name="type">
          <Select placeholder="Vui lòng chọn" options={typeOptions} />
        </FormTeraItem>
        <FormTeraItem label="Trạng thái" name="status">
          <Select
            placeholder="Vui lòng chọn"
            options={[
              { label: 'Bật', value: 1 },
              { label: 'Tắt', value: 0 },
            ]}
          />
        </FormTeraItem>

        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default ServicePackageFilter;
