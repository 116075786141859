import Search from '_common/dof/Control/Search';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { MagnifyingGlassOutlined } from 'tera-dls';

interface IProps {
  onSearch: (value: any) => void;
  value?: string;
}
const SearchForm = (props: IProps) => {
  const { onSearch, value } = props;

  const form = useForm();
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = form;
  const handleSearch = (value) => {
    if (isDirty) {
      onSearch(value);
      reset({ ...value }, { keepValues: true });
    }
  };

  useEffect(() => {
    value && reset({ keyword: value });
  }, [value]);

  return (
    <FormTera
      form={form}
      onSubmit={handleSubmit(handleSearch)}
      className="sm:w-full md:w-full lg:w-[400px]"
    >
      <FormTeraItem className="mb-0" name="keyword">
        <Search
          placeholder="Tìm kiếm theo mã bảng DL, tiêu đề"
          icon={<MagnifyingGlassOutlined />}
          className="h-10"
        />
      </FormTeraItem>
    </FormTera>
  );
};

export default SearchForm;
