import {
  CircleStackOutlined,
  CogOutlined,
  DocumentDuplicateOutlined,
  EllipsisHorizontalOutlined,
  HomeModernOutlined,
  HomeOutlined,
  ListBulletOutlined,
  WrenchScrewdriverOutlined,
  CreditCardOutlined,
  StarOutlined,
} from 'tera-dls';

import {
  CONFIG_DATA_URL,
  CONFIG_PERMISSION_URL,
  CONFIG_STATUS_URL,
  GROUP_CONTROL_URL,
  MODULE_URL,
  EPIC_URL,
} from '_common/constants/url';
import { IMenu, TypeMoreMenu } from './interface';
import { MANAGE_PAGE_URL } from 'pages/System/containers/ManagePage/constants/url';
import { CONFIG_PAYMENT_METHOD_URL } from 'pages/System/containers/ConfigPaymentMethod/url';
import { CONFIG_AFFILIATES_URL } from 'pages/System/containers/ConfigAffiliates/url';

export const dashboardMenu: IMenu = {
  iconNode: <HomeOutlined />,
  title: 'Trang chủ',
  path: '/dashboard',
  key: 'dashboard',
  id: 'home',
};

export const systemMenu: IMenu = {
  iconNode: <CogOutlined />,
  title: 'Hệ thống',
  path: '/system/config-data',
  key: 'system',
  id: 'system',
};

export const systemSubMenu: IMenu[] = [
  {
    iconNode: <CircleStackOutlined />,
    title: 'Cấu hình dữ liệu',
    path: CONFIG_DATA_URL.list.path,
    id: CONFIG_DATA_URL.list.key,
    key: 'config-data',
  },
  {
    iconNode: <ListBulletOutlined />,
    title: 'Cấu hình trạng thái',
    path: CONFIG_STATUS_URL.list.path,
    id: CONFIG_STATUS_URL.list.key,
    key: 'config-status',
  },
  {
    iconNode: <WrenchScrewdriverOutlined />,
    title: 'Cấu hình quyền',
    path: CONFIG_PERMISSION_URL.list.path,
    id: CONFIG_PERMISSION_URL.list.key,
    key: 'config-permission',
  },
  {
    iconNode: <DocumentDuplicateOutlined />,
    title: 'Quản lý module',
    path: MODULE_URL.list.path,
    id: MODULE_URL.list.key,
    key: 'manage-module',
  },
  {
    iconNode: <DocumentDuplicateOutlined />,
    title: 'Quản lý epic',
    path: EPIC_URL.list.path,
    id: EPIC_URL.list.key,
    key: 'manage-epic',
  },
  {
    iconNode: <DocumentDuplicateOutlined />,
    title: 'Quản lý nhóm control',
    path: GROUP_CONTROL_URL.list.path,
    id: GROUP_CONTROL_URL.list.key,
    key: 'manage-group-control',
  },
  {
    iconNode: <DocumentDuplicateOutlined />,
    title: 'Quản lý trang',
    path: MANAGE_PAGE_URL.list.path,
    id: MANAGE_PAGE_URL.list.key,
    key: 'manage-page',
  },
  {
    iconNode: <CreditCardOutlined />,
    title: 'Cấu hình thanh toán',
    key: CONFIG_PAYMENT_METHOD_URL.list.key,
    id: CONFIG_PAYMENT_METHOD_URL.list.key,
    path: CONFIG_PAYMENT_METHOD_URL.list.path,
    children: [
      {
        name: 'Phương thức thanh toán',
        path: CONFIG_PAYMENT_METHOD_URL.list.path,
        id: CONFIG_PAYMENT_METHOD_URL.list.key,
      },
      {
        name: 'Lịch sử giao dịch',
        path: CONFIG_PAYMENT_METHOD_URL.transactionHistory.path,
        id: CONFIG_PAYMENT_METHOD_URL.transactionHistory.key,
      },
    ],
  },
  {
    iconNode: <StarOutlined />,
    title: 'Affiliates',
    key: 'config-affiliates',
    id: CONFIG_AFFILIATES_URL.summary_report.key,
    path: CONFIG_AFFILIATES_URL.summary_report.path,
    children: [
      {
        name: 'Báo cáo chung',
        path: CONFIG_AFFILIATES_URL.summary_report.path,
        id: CONFIG_AFFILIATES_URL.summary_report.key,
      },
      {
        name: 'DS người giới thiệu',
        path: CONFIG_AFFILIATES_URL.list_referer.path,
        id: CONFIG_AFFILIATES_URL.list_referer.key,
      },
    ],
  },
];

export const moreMenu: TypeMoreMenu[] = [
  {
    icon: <HomeModernOutlined />,
    title: 'Dashboard',
    key: 'dashboard1',
    path: '/',
    img: { src: 'https://via.placeholder.com/1280x720' },
  },
  {
    icon: <EllipsisHorizontalOutlined />,
    title: 'More',
    key: 'home',
    path: '/',
    img: { src: 'https://via.placeholder.com/1280x720' },
  },
];
