export const TYPE_ROLE = {
  system: 'Hệ thống',
  user: 'Người dùng',
  business: 'Gói dịch vụ',
};

export const ROLE_TAB = {
  all: 'Tất cả',
  system: 'Hệ thống',
  user: 'Người dùng',
  business: 'Gói dịch vụ',
};
