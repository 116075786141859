import { IRouteProps } from '_common/interface/router';
import BusinessPage from './containers';
import { BUSINESS_URL } from '_common/constants/url';
import BusinessForm from './containers/Form';
import BusinessDetail from './containers/Detail';

export const BusinessRouter: IRouteProps[] = [
  {
    key: BUSINESS_URL.list.key,
    path: BUSINESS_URL.list.shortenUrl,
    component: <BusinessPage />,
  },
  {
    key: BUSINESS_URL.create.key,
    path: BUSINESS_URL.create.shortenUrl,
    component: <BusinessForm />,
  },
  {
    key: BUSINESS_URL.update.key,
    path: BUSINESS_URL.update.shortenUrl,
    component: <BusinessForm />,
  },
  {
    key: BUSINESS_URL.detail.key,
    path: BUSINESS_URL.detail.shortenUrl,
    component: <BusinessDetail />,
  },
];
