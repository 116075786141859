export const PERMISSION_KEY = {
  EXPENSE_VOUCHER_VIEW_EXPENSE_VOUCHER_LIST:
    'fin_cashbook_view_expense_voucher_list',
  EXPENSE_VOUCHER_VIEW_EXPENSE_VOUCHER_DETAIL:
    'fin_cashbook_view_expense_voucher_detail',
  EXPENSE_VOUCHER_CREATE_EXPENSE_VOUCHER: 'fin_cashbook_create_expense_voucher',
  EXPENSE_VOUCHER_UPDATE_EXPENSE_VOUCHER: 'fin_cashbook_update_expense_voucher',
  EXPENSE_VOUCHER_DELETE_EXPENSE_VOUCHER: 'fin_cashbook_delete_expense_voucher',
  EXPENSE_VOUCHER_SEARCH_EXPENSE_VOUCHER: 'fin_cashbook_search_expense_voucher',

  EXPENSE_VOUCHER_IMPORT_EXPENSE_VOUCHER: 'fin_cashbook_import_expense_voucher',
  EXPENSE_VOUCHER_EXPORT_EXPENSE_VOUCHER: 'fin_cashbook_export_expense_voucher',
  EXPENSE_VOUCHER_CONFIG_COLUMN_EXPENSE_VOUCHER:
    'fin_cashbook_config_column_expense_voucher',
};
