import {
  ArrowPathOutlined,
  ArrowsPointingInOutlined,
  ArrowsPointingOuOutlined,
  Cog6ToothOutlined,
  Tooltip,
} from 'tera-dls';

const Header = ({
  isSmallType,
  onClickRefresh,
  onClickSetting,
  onZoomIn,
  onZoomOut,
  title,
}) => {
  return (
    <div
      className="flex flex-col gap-2.5 "
      style={{
        boxShadow: '0px 1px 1px rgba(0,0,0,0.1)',
      }}
    >
      <div className="flex flex-col gap-[5px] pt-[16px]">
        <div className="flex px-[16px] pb-2.5 item-center">
          <div className="uppercase font-medium flex-1 text-base">{title}</div>
          <div className="flex gap-2.5">
            <Tooltip title="Làm mới">
              <span className="flex items-center">
                <ArrowPathOutlined
                  className="cursor-pointer w-4 h-4"
                  onClick={onClickRefresh}
                />
              </span>
            </Tooltip>
            <Tooltip title="Cấu hình tham số">
              <span className="flex items-center">
                <Cog6ToothOutlined
                  className="w-4 h-4 cursor-pointer"
                  onClick={() => onClickSetting(true)}
                />
              </span>
            </Tooltip>
            {isSmallType ? (
              <Tooltip title="Mở rộng">
                <span className="flex items-center">
                  <ArrowsPointingOuOutlined
                    className="w-4 h-4 cursor-pointer"
                    onClick={() => onZoomOut(true)}
                  />
                </span>
              </Tooltip>
            ) : (
              <Tooltip title="Thu gọn">
                <span className="flex items-center">
                  <ArrowsPointingInOutlined
                    className="w-4 h-4 cursor-pointer"
                    onClick={() => onZoomIn()}
                  />
                </span>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
