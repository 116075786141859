import { customTwMerge } from 'tailwind-merge.config';
import { ChartBar, ChartBarProps } from 'tera-dls';

export type VerticalBarChartType = 'small' | 'large';
interface IData {
  labels: Array<string>;
  datasets: any;
}

interface IProps {
  data: IData;
  type?: VerticalBarChartType;
  options?: ChartBarProps['options'];
  chartClassName?: string;
  defaultShowTickLength?: number;
  defaultLabelMaxLength?: number;
  wrapperClassName?: string;
  containerClassName?: string;
  minNumber?: number;
  maxNumber?: number;
  maxX?: number;
  showLegend?: boolean;
}

const VerticalBarChart = (props: IProps) => {
  const {
    type = 'small',
    data,
    chartClassName,
    options,
    defaultShowTickLength = 8,
    defaultLabelMaxLength = 15,
    wrapperClassName = '',
    containerClassName = '',
    minNumber,
    maxNumber,
    maxX,
    showLegend = true,
  } = props;
  const { labels } = data ?? {};

  const isSmallType = type === 'small';

  const displayTick = isSmallType
    ? labels?.length <= defaultShowTickLength
    : true;

  const max =
    (maxNumber ?? labels?.length) - 1 < 0
      ? maxNumber ?? labels?.length
      : (maxNumber ?? labels?.length) - 1;

  const optionsChart: any = {
    responsive: true,
    indexAxis: 'y',
    plugins: {
      legend: {
        display: showLegend,
        position: 'bottom',
      },
      // datalabels: {
      //   display: displayTick,
      //   color: 'black',
      //   formatter: (val) => formatNumber(val),
      //   anchor: 'end',
      //   align: 'end',
      // },
    },
    scales: {
      y: {
        ticks: {
          display: displayTick,
          maxRotation: 45,
          minRotation: 0,
          callback: (_, index) => {
            const label = labels[index];
            return label.length > defaultLabelMaxLength
              ? label.substring(0, defaultLabelMaxLength) + '...'
              : label;
          },
        },
        beginAtZero: true,
        min: minNumber ?? 0,
        max,
      },
      x: {
        max: maxX ?? 10,
      },
    },
    maintainAspectRatio: false,
    ...options,
  };

  const renderChart = () => (
    <ChartBar
      className={chartClassName}
      data={data}
      options={optionsChart}
      // plugins={['data-labels']}
    />
  );
  return (
    <div
      className={customTwMerge(
        'w-full h-full overflow-x-auto',
        containerClassName,
      )}
    >
      {isSmallType ? (
        <div className={wrapperClassName}>
          {renderChart()}
          {!displayTick && <div className="invisible">label</div>}
        </div>
      ) : (
        <div className={customTwMerge('h-full', wrapperClassName)}>
          {renderChart()}
        </div>
      )}
    </div>
  );
};

export default VerticalBarChart;
