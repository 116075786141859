export const PAGE_KEY = {
  MANAGE_PAGE_LIST_VIEW: 'manage_page_list_view',
  MANAGE_PAGE_CONFIG_FORM: 'manage_page_config_form',
  MANAGE_PAGE_CONFIG_FORM_DETAIL: 'manage_page_config_form_detail',
  MANAGE_PAGE_CONFIG_FIELD: 'manage_page_config_field',
  MANAGE_PAGE_TABLE_CONFIG: 'manage_page_table_config',
  MANAGE_PAGE_TABLE_CONFIG_DETAIL: 'manage_page_table_config_detail',
  MANAGE_PAGE_LIST_VIEW_DETAIL: 'manage_page_list_view_detail',
  COLUMN_CONFIG_LIST_VIEW: 'column_config_list_view',
  MANAGE_PAGE_CONTROL_CONFIG: 'manage_page_control_config',
};

export const BUTTON_KEY = {
  MANAGE_PAGE_CONFIG_FORM_CREATE: 'manage_page_config_form_create',
  MANAGE_PAGE_CONFIG_FORM_UPDATE: 'manage_page_config_form_update',
  MANAGE_PAGE_CONFIG_FORM_DETAIL: 'manage_page_config_form_detail',
  MANAGE_PAGE_CONFIG_FORM_DELETE: 'manage_page_config_form_delete',
  MANAGE_PAGE_CONFIG_FIELD_CREATE: 'manage_page_config_field_create',
  MANAGE_PAGE_CONFIG_FIELD_UPDATE: 'manage_page_config_field_update',
  MANAGE_PAGE_CONFIG_FIELD_DETAIL: 'manage_page_config_field_detail',
  MANAGE_PAGE_CONFIG_FIELD_DELETE: 'manage_page_config_field_delete',
  MANAGE_PAGE_CONFIG_FIELD: 'manage_page_config_field',

  MANAGE_PAGE_LIST_DETAIL: 'manage_page_list_detail',
  MANAGE_PAGE_LIST_CREATE: 'manage_page_list_create',
  MANAGE_PAGE_LIST_UPDATE: 'manage_page_list_update',
  MANAGE_PAGE_LIST_DELETE: 'manage_page_list_delete',
  MANAGE_PAGE_LIST_COPY_PAGE: 'manage_page_list_copy_page',
  MANAGE_PAGE_LIST_CONTROL: 'manage_page_list_control',
  MANAGE_PAGE_LIST_FORM: 'manage_page_list_form',
  MANAGE_PAGE_LIST_TABLE: 'manage_page_list_table',
  MANAGE_PAGE_LIST_SEARCH_FILTER: 'manage_page_list_search_filter',

  SYSTEM_PAGE_TABLE_CONFIG_DETAIL: 'system_page_table_config_detail',
  SYSTEM_PAGE_TABLE_CONFIG_UPDATE: 'system_page_table_config_update',
  SYSTEM_PAGE_TABLE_CONFIG_DELETE: 'system_page_table_config_delete',
  SYSTEM_PAGE_TABLE_CONFIG_CREATE: 'system_page_table_config_create',
  SYSTEM_PAGE_TABLE_FIELD_CONFIG: 'system_page_table_field_config',

  CONTROL_CONFIG_LIST_CREATE: 'control_config_list_create',
  CONTROL_CONFIG_LIST_UPDATE: 'control_config_list_update',
  CONTROL_CONFIG_LIST_COPY: 'control_config_list_copy',
  CONTROL_CONFIG_LIST_DETAIL: 'control_config_list_detail',
  CONTROL_CONFIG_LIST_DELETE: 'control_config_list_delete',

  COLUMN_CONFIG_LIST_CREATE: 'column_config_list_create',
  COLUMN_CONFIG_LIST_DETAIL: 'column_config_list_detail',
  COLUMN_CONFIG_LIST_UPDATE: 'column_config_list_update',
  COLUMN_CONFIG_LIST_DELETE: 'column_config_list_delete',
};
