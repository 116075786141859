import { PERMISSION_KEY } from './permission';

export const SERVICE_PACKAGE_URL = {
  create: {
    key: PERMISSION_KEY.SERVICE_PACKAGE_CREATE_SERVICE,
    path: '/service-package/service/create',
    shortenUrl: 'service/create',
  },
  update: {
    key: PERMISSION_KEY.SERVICE_PACKAGE_UPDATE_SERVICE,
    path: '/service-package/service/update',
    shortenUrl: 'service/update/:id',
  },
  list: {
    key: PERMISSION_KEY.SERVICE_PACKAGE_VIEW_SERVICE_LIST,
    path: '/service-package/service/list',
    shortenUrl: 'service/list',
  },
  detail: {
    key: PERMISSION_KEY.SERVICE_PACKAGE_VIEW_SERVICE_DETAIL,
    path: '/service-package/service/detail',
    shortenUrl: 'service/detail/:id',
  },
};
