import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { DATE_FORMAT } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import useConfirm from '_common/hooks/useConfirm';
import { filterField } from '_common/utils';
import ServicePackageApi from 'pages/ServicePackage/_api';
import {
  STATUS_SERVICE_BUSINESS,
  STATUS_SERVICE_BUSINESS_COLOR,
} from 'pages/ServicePackage/constants';
import { useEffect, useState } from 'react';
import {
  DropdownItem,
  formatDate,
  notification,
  PaginationProps,
  PlusCircleOutlined,
  Tag,
  Tooltip,
} from 'tera-dls';
import ServiceCreation from './ServiceCreation';
import ServiceRenew from './ServiceRenew';
import ServiceUpgrade from './ServiceUpgrade';
import { DefaultTag } from '_common/shared/utils';

function Service({ id }) {
  const [openForm, setOpenForm] = useState<boolean>(false);
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const [params, setParams] = useState<any>({ page: 1, limit: 10 });
  const [openUpgrade, setOpenUpgrade] = useState<any>({ open: false });
  const [openRenew, setOpenRenew] = useState<any>({ open: false });

  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery(
    ['get-service-package-business-list', params, id],
    () =>
      ServicePackageApi.getListOfBusiness({
        params: filterField({
          ...params,
          business_id: id,
        }),
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );
  const list = response?.data ?? [];

  useEffect(() => {
    id && refetch();
  }, [id]);

  const actionApi = {
    active: ServicePackageApi.active,
    inActive: ServicePackageApi.inActive,
    reActive: ServicePackageApi.reActive,
    upgrade: ServicePackageApi.upgrade,
    reNew: ServicePackageApi.reNew,
  };
  const { mutate: mutateAction } = useMutation(
    ({ actionType, ...rest }: any) => actionApi[actionType]?.(rest),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-service-package-business-list']);
          queryClient.invalidateQueries(['get-business-summary']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const { mutate: mutateDelete } = useMutation(
    (variables: any) => ServicePackageApi.removeOutBusiness(variables),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-service-package-product-list']);
          queryClient.invalidateQueries(['get-service-package-business-list']);
          queryClient.invalidateQueries(['get-business-summary']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleDelete = (id: number, name: string): void => {
    confirm.warning({
      title: 'Xác nhận xóa gói dịch vụ',
      content: (
        <p className="break-word">
          <p> Bạn có chắc muốn xóa gói dịch vụ</p>
          <p>
            <span className="font-bold break-word">{` ${name ?? ''} `} </span>
            ra khỏi doanh nghiệp này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateDelete({ id });
      },
    });
  };

  const handleActive = (id: number, name: string): void => {
    confirm.warning({
      title: 'Xác nhận kích hoạt gói dịch vụ',
      content: (
        <p className="break-word">
          <p> Bạn có chắc chắn muốn kích hoạt gói dịch vụ</p>
          <p>
            <span className="font-bold">{` ${name ?? ''} `} </span>
            này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateAction({
          actionType: 'active',
          params: {
            current_id: id,
          },
        });
      },
    });
  };
  const handleReActive = (id: number, name: string): void => {
    confirm.warning({
      title: 'Xác nhận kích hoạt lại gói dịch vụ',
      content: (
        <p className="break-word">
          Bạn có chắc chắn muốn kích hoạt lại gói dịch vụ{' '}
          <p>
            <span className="font-bold">{` ${name ?? ''} `} </span>
            này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateAction({
          actionType: 'reActive',
          params: {
            current_id: id,
          },
        });
      },
    });
  };

  const handleInActive = (id: number, name: string): void => {
    confirm.warning({
      title: 'Xác nhận hũy kích hoạt gói dịch vụ',
      content: (
        <p className="break-word">
          Bạn có chắc muốn hũy kích hoạt gói dịch vụ
          <p>
            <span className="font-bold">{` ${name ?? ''} `} </span>
            này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateAction({
          actionType: 'inActive',
          params: {
            current_id: id,
          },
        });
      },
    });
  };

  const renderDropdown = (record): DropdownItem[] => {
    const isDefault = record?.service?.is_trial_default;
    const dropdownItems: DropdownItem[] = [];

    dropdownItems.push({
      key: 1,
      label: <span className="text-red-500">Xóa</span>,
      onClick: () => handleDelete(record?.id, record?.service?.name),
    });
    ['not_activated'].includes(record?.status) &&
      dropdownItems.push({
        key: 2,
        label: 'Kích hoạt',
        onClick: () => handleActive(record?.id, record?.service?.name),
      });
    ['finished'].includes(record?.status) &&
      dropdownItems.push({
        key: 3,
        label: 'Kích hoạt lại',
        onClick: () => handleReActive(record?.id, record?.service?.name),
      });
    ['is_active'].includes(record?.status) &&
      dropdownItems.push({
        key: 4,
        label: 'Hủy kích hoạt',
        onClick: () => handleInActive(record?.id, record?.service?.name),
      });
    ['is_active', 'expired'].includes(record?.status) &&
      !isDefault &&
      dropdownItems.push({
        key: 5,
        label: 'Nâng cấp',
        onClick: () => {
          setOpenUpgrade({ open: true, value: record?.id });
        },
      });
    ['is_active', 'expired'].includes(record?.status) &&
      dropdownItems.push({
        key: 6,
        label: 'Gia hạn',
        onClick: () => {
          setOpenRenew({ open: true, value: record?.id });
        },
      });
    return dropdownItems;
  };

  const columns: any = [
    {
      title: 'Loại gói dịch vụ',
      dataIndex: 'service_type_text',
    },
    {
      title: 'Gói dịch vụ',
      dataIndex: 'service',
      render: (val, record) => (
        <div className="flex gap-2 items-center line-clamp-1">
          <span className="text-blue-500">{val?.name}</span>
          {!!record?.service?.is_trial_default && (
            <DefaultTag title={'Mặc định'} />
          )}
        </div>
      ),
    },
    {
      key: 'package_name',
      title: 'Gói sản phẩm',
      dataIndex: 'package',
      render: (val) => <span className="line-clamp-3">{val?.name}</span>,
    },
    {
      title: 'Ngày kích hoạt',
      dataIndex: 'date_active',
      render: (val) => val && formatDate(val, DATE_FORMAT),
    },
    {
      title: 'Thời gian',
      dataIndex: 'time',
      render: (val) => <span>{val ?? '' + ' '} tháng</span>,
    },
    {
      title: 'Ngày hết hạn',
      dataIndex: 'date_expired',
      render: (val) => val && formatDate(val, DATE_FORMAT),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 200,
      render: (val) => (
        <Tag color={STATUS_SERVICE_BUSINESS_COLOR[val]}>
          {STATUS_SERVICE_BUSINESS[val]}
        </Tag>
      ),
    },
    {
      title: (
        <div className="flex justify-center">
          <Tooltip title="Thêm">
            <div>
              <PlusCircleOutlined
                className="w-6 h-6 text-green-600 cursor-pointer"
                onClick={() => setOpenForm(true)}
              />
            </div>
          </Tooltip>
        </div>
      ),
      width: 60,
      fixed: 'right',
      render: (_, record) => {
        const dropdownItems = renderDropdown(record);
        return <ActionDropdown dropdownItems={dropdownItems} trigger="click" />;
      },
    },
  ];
  const handleChangePage: PaginationProps['onChange'] = (
    page: any,
    pageSize: any,
  ) => {
    setParams({ page: page, limit: pageSize });
  };

  return (
    <>
      <TableTera
        rowKey={'id'}
        loading={isLoading}
        columns={columns}
        data={list}
        pagination={{
          onChange: handleChangePage,
          pageSize: response?.per_page,
          current: response?.current_page,
          total: response?.total,
          from: response?.from,
          to: response?.to,
        }}
      />
      {openForm && (
        <ServiceCreation
          value={id}
          open={openForm}
          onClose={() => setOpenForm(false)}
        />
      )}
      {openUpgrade?.open && (
        <ServiceUpgrade
          open={openUpgrade?.open}
          value={openUpgrade?.value}
          onClose={() => setOpenUpgrade({ open: false })}
          businessId={id}
        />
      )}
      {openRenew?.open && (
        <ServiceRenew
          open={openRenew?.open}
          value={openRenew?.value}
          onClose={() => setOpenRenew({ open: false })}
        />
      )}
    </>
  );
}

export default Service;
