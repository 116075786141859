import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { ReactNode } from 'react';
import { Description, Modal, Spin, Tag } from 'tera-dls';

import { EmployeeText } from '_common/component/EmployeeText';
import { timeFormat } from '_common/constants/common';
import { statusConfigColor } from 'pages/System/constants';
import EpicApi from '../../_api';
interface DetailConfigPermissionProps {
  open: boolean;
  onCloseModal: () => void;
  id: string | number;
}
interface GridDescriptionProps {
  label?: string;
  children?: ReactNode;
}
const DetailPageModal = ({
  id,
  onCloseModal,
  open,
}: DetailConfigPermissionProps) => {
  const { data, isLoading } = useQuery<any>(['get-epic-detail', id], () =>
    EpicApi.getDetail(id),
  );

  const GridDescription = ({ label, children }: GridDescriptionProps) => {
    return (
      <Description
        label={label}
        className="grid-cols-4 gap-5"
        childrenClassName="col-span-3"
      >
        {children}
      </Description>
    );
  };
  return (
    <Modal
      centered
      title="Chi tiết epic"
      okText="Đóng"
      className="sm:w-3/5 lg:w-1/3"
      open={open}
      onOk={onCloseModal}
      onCancel={onCloseModal}
      cancelButtonProps={{ className: 'hidden' }}
      closeIcon={false}
      destroyOnClose
    >
      <Spin spinning={isLoading && !!id}>
        <GridDescription label="Mã Epic">
          {data?.concatenated_code}
        </GridDescription>
        <GridDescription label="Tên Epic">{data?.name}</GridDescription>
        <GridDescription label="Module">{data?.module?.title}</GridDescription>
        <GridDescription label="Icon">{data?.icon}</GridDescription>
        <GridDescription label="Trạng thái">
          <Tag color={statusConfigColor[data?.status]}>{data?.status_text}</Tag>
        </GridDescription>
        <GridDescription label="Người tạo">
          {data?.created_by && (
            <EmployeeText
              code={data?.created_by?.code}
              name={data?.created_by?.username}
            />
          )}
        </GridDescription>
        <GridDescription label="Ngày tạo">
          {data?.created_at &&
            moment(data?.created_at).format(timeFormat.date_time_second)}
        </GridDescription>
        <GridDescription label="Người cập nhật">
          {data?.updated_by && (
            <EmployeeText
              code={data?.updated_by?.code}
              name={data?.updated_by?.username}
            />
          )}
        </GridDescription>
        <GridDescription label="Ngày cập nhật">
          {data?.updated_at &&
            moment(data?.updated_at).format(timeFormat.date_time_second)}
        </GridDescription>
      </Spin>
    </Modal>
  );
};

export default DetailPageModal;
