import FolderItem from './FolderItem';
import File from '../File';

interface IProps {
  value?: any;
}
const Folder = (props: IProps) => {
  const { value } = props;
  return (
    <>
      <div>
        <h3 className="pb-5">Thư mục</h3>
        <div className="grid gap-5 grid-cols-5">
          {value?.map((item) => (
            <FolderItem key={item.name} value={item} />
          ))}
        </div>
      </div>
      <div className="mt-5">
        <File />
      </div>
    </>
  );
};

export default Folder;
