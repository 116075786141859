import { PAGE_KEY } from './permission';

export const EPIC_KEY_URL = {
  DASHBOARD: '/dashboard',
  USER_LIST: '/user',
  BUSINESS_LIST: 'business',
  MODULE_LIST: '/module',
  CONFIG_DATA: '/system/config-data',
  CONFIG_STATUS: '/system/config-status',
  CONFIG_APPLICATION: '/system/config-application',
  CONFIG_DEPARTMENT: '/system/config-department',
  CONFIG_POSITION: '/system/config-position',
  CONFIG_JOB_TITLE: '/system/config-job-title',
  CONFIG_PERMISSION: '/system/config-permission',
  MANAGE_EPIC: '/system/manage-epic',
  MANAGE_MODULE: '/system/manage-module',
  MANAGE_GROUP_CONTROL: '/system/manage-group-control',
};

export const DASHBOARD_URL = {
  list: {
    key: PAGE_KEY.DASHBOARD_LIST_VIEW,
    path: '/dashboard',
    shortenUrl: '',
  },
  create: {
    key: PAGE_KEY.DASHBOARD_LIST_CREATE,
    path: '/dashboard/create',
    shortenUrl: 'create',
  },
};

export const USER_URL = {
  list: {
    key: PAGE_KEY.USER_VIEW,
    path: '/user/list',
    shortenUrl: 'list',
  },
  detail: {
    key: PAGE_KEY.USER_DETAIL,
    path: '/user/detail',
    shortenUrl: 'detail/:id',
  },
};

export const MODULE_LIST_URL = {
  list: {
    key: PAGE_KEY.MODULE_LIST_VIEW,
    path: '/module/list',
    shortenUrl: 'list',
  },
};

export const BUSINESS_URL = {
  list: {
    key: PAGE_KEY.BUSINESS_LIST_VIEW,
    path: '/business-management/business/list',
    shortenUrl: 'business/list',
  },
  create: {
    key: PAGE_KEY.BUSINESS_CREATE,
    path: '/business-management/business/create',
    shortenUrl: 'business/create',
  },
  update: {
    key: PAGE_KEY.BUSINESS_UPDATE,
    path: '/business-management/business/update',
    shortenUrl: 'business/update/:id',
  },
  detail: {
    key: PAGE_KEY.BUSINESS_DETAIL,
    path: '/business-management/business/detail',
    shortenUrl: 'business/detail/:id',
  },
};

export const CONFIG_DATA_URL = {
  list: {
    key: PAGE_KEY.CONFIG_DATA_LIST_VIEW,
    path: '/system/config-data',
    shortenUrl: '',
  },
};

export const CONFIG_STATUS_URL = {
  list: {
    key: PAGE_KEY.CONFIG_STATUS_LIST_VIEW,
    path: '/system/config-status',
    shortenUrl: '',
  },
};

export const CONFIG_PERMISSION_URL = {
  list: {
    key: PAGE_KEY.CONFIG_PERMISSION_LIST_VIEW,
    path: '/system/config-permission',
    shortenUrl: '',
  },
  setting: {
    key: PAGE_KEY.CONFIG_PERMISSION_LIST_SETTING,
    path: '/system/config-permission/setting',
    shortenUrl: 'setting/:id',
  },
};

export const EPIC_URL = {
  list: {
    key: PAGE_KEY.EPIC_LIST_VIEW,
    path: '/system/manage-epic',
    shortenUrl: '',
  },
};

export const MODULE_URL = {
  list: {
    key: PAGE_KEY.MODULE_LIST_VIEW,
    path: '/system/manage-module',
    shortenUrl: '',
  },
};

export const GROUP_CONTROL_URL = {
  list: {
    key: PAGE_KEY.GROUP_CONTROL_LIST_VIEW,
    path: '/system/manage-group-control',
    shortenUrl: '',
  },
};

export const FILE_MANAGEMENT_URL = {
  list: {
    key: PAGE_KEY.FILE_MANAGEMENT_LIST_VIEW,
    path: '/file-management',
    shortenUrl: '',
  },
  detail: {
    key: PAGE_KEY.FILE_MANAGEMENT_LIST_DETAIL,
    path: '/file-management/detail',
    shortenUrl: 'detail/:id',
  },
};
