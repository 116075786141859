import { endpoint } from '_common/constants/common';
import { filterField } from '_common/utils';
import api from 'states/drivers';

const ModuleEndpoint = `${endpoint}/administrator/module`;

const ModuleApi = {
  getList: async (param?) =>
    await api
      .get(`${ModuleEndpoint}/list`, filterField(param))
      .then((result) => result.data?.data),
  getDetail: async (id: string | number) =>
    await api
      .get(`${ModuleEndpoint}/detail/${id}`)
      .then((result) => result.data?.data),
  create: async (param) =>
    await api
      .post(`${ModuleEndpoint}/create`, param)
      .then((result) => result.data),
  update: async (id: string | number, param) =>
    await api
      .put(`${ModuleEndpoint}/update/${id}`, param)
      .then((result) => result.data),
  delete: async (id: string | number) =>
    await api
      .delete(`${ModuleEndpoint}/delete/${id}`)
      .then((result) => result.data),
  import: async (params?) =>
    await api
      .post(`${ModuleEndpoint}/import`, params)
      .then((result) => result.data),
  export: async (params?) =>
    await api
      .post(`${ModuleEndpoint}/export`, params)
      .then((result) => result.data),
};

export default ModuleApi;
