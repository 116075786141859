import useConfirm from '_common/hooks/useConfirm';

import { useMutation, useQueries, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { REGEX } from '_common/constants/common';
import {
  messageValidate,
  messageValidateLength,
} from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import Select from '_common/dof/Control/Select';
import Toggle from '_common/dof/Control/Switch';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { convertListOrder } from '_common/utils';
import { isArray } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Col, Modal, OptionProps, Row, Spin, notification } from 'tera-dls';
import { FieldConfigApi } from '../../../_api';

interface IDetailEmployeeGroup {
  id?: number;
  isOpen?: boolean;
  handleClose?: () => void;
}
interface IForm {
  code: string;
  title: string;
  type: string;
  keyData: string;
  className: string;
  placeholder: string;
  priority: string | number;
  allowEdit: boolean | string;
  disableWhenEdit: boolean | string;
  allowAdd: boolean | string;
  isRequire: boolean | string;
}

const CreateConfigField = ({
  id,
  isOpen,
  handleClose,
}: IDetailEmployeeGroup) => {
  const defaultValues = {
    code: '',
    title: '',
    type: '',
    keyData: '',
    className: '',
    placeholder: '',
    priority: '',
    allowEdit: false,
    disableWhenEdit: false,
    allowAdd: false,
    isRequire: false,
  };
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const { formId } = useParams();

  const form = useForm<IForm>({
    mode: 'onChange',
    defaultValues,
  });
  const {
    reset,
    handleSubmit,
    setValue,
    formState: { isDirty },
  } = form;
  const [
    { data: fieldDetail, isLoading: loadingDetail },
    { data: listFieldType },
    { data: listFieldOrder },
  ] = useQueries({
    queries: [
      {
        queryKey: ['edit_field_config'],
        queryFn: () => {
          return FieldConfigApi.getDetail(id);
        },
        enabled: !!id,
      },
      {
        queryKey: ['list_type_field'],
        queryFn: () => {
          return FieldConfigApi.getFieldType();
        },
      },
      {
        queryKey: ['list_order_field'],
        queryFn: () => {
          return FieldConfigApi.getFieldOrder({
            form_id: id ? fieldDetail?.form_id : Number(formId),
          });
        },
      },
    ],
  });

  const optionsFieldType: OptionProps[] = useMemo(() => {
    return isArray(listFieldType?.data)
      ? listFieldType?.data.map((type) => ({
          value: type?.id,
          label: type?.title,
        }))
      : [];
  }, [listFieldType?.data]);

  const optionsFormOrder: OptionProps[] = useMemo(() => {
    return convertListOrder(listFieldOrder, id, fieldDetail);
  }, [listFieldOrder, fieldDetail]);

  const handleResetForm = () => {
    reset();
    queryClient.setQueryData(['list_order_field'], null);
  };

  const handleCancel = () => {
    handleClose();
    handleResetForm();
  };

  const { mutate: handleMutation, isLoading } = useMutation(
    (variables: any) => {
      if (id) {
        return FieldConfigApi.update(id, variables);
      }

      return FieldConfigApi.create(variables);
    },
    {
      onSuccess: ({ code, msg }) => {
        if (code === 200) {
          handleCancel();
          notification.success({
            message: msg,
          });
          queryClient.invalidateQueries(['list-field-config']);
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const checkTypeStatus = (status: boolean | string, type: string) => {
    if (type === 'submit') {
      return status ? 'active' : 'inactive';
    }
    return status === 'active' ? true : false;
  };

  const handleSubmitForm = (valueForm: IForm) => {
    const convertData = {
      form_id: id ? fieldDetail?.form_id : Number(formId),
      code: valueForm?.code,
      title: valueForm?.title,
      order: valueForm?.priority ? Number(valueForm?.priority) : null,
      type_id: Number(valueForm?.type),
      key_data: valueForm?.keyData,
      class_name: valueForm?.className,
      place_holder: valueForm?.placeholder,
      is_create: checkTypeStatus(valueForm?.allowAdd, 'submit'),
      is_required: checkTypeStatus(valueForm?.isRequire, 'submit'),
      disable_edit: checkTypeStatus(valueForm?.disableWhenEdit, 'submit'),
      is_edit: checkTypeStatus(valueForm?.allowEdit, 'submit'),
    };
    handleMutation(convertData);
  };

  useEffect(() => {
    if (!!id && fieldDetail) {
      const values: IForm = {
        code: fieldDetail?.code_guard,
        title: fieldDetail?.title,
        type: fieldDetail?.type_field?.id,
        keyData: fieldDetail?.key_data,
        className: fieldDetail?.class_name,
        placeholder: fieldDetail?.place_holder,
        priority: fieldDetail?.item_prev_order?.order || 0,
        allowEdit: checkTypeStatus(fieldDetail?.is_edit, 'edit'),
        disableWhenEdit: checkTypeStatus(fieldDetail?.disable_edit, 'edit'),
        allowAdd: checkTypeStatus(fieldDetail?.is_create, 'edit'),
        isRequire: checkTypeStatus(fieldDetail?.is_required, 'edit'),
      };
      Object.entries(values).forEach(([fieldName, fieldValue]: [any, any]) => {
        setValue(fieldName, fieldValue);
      });
    }
  }, [fieldDetail, id]);

  const onExitModal = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        onOk: () => {
          handleCancel();
        },
        content: (
          <>
            <p>Bạn có chắc muốn thoát bản ghi này không?</p>
            <p>Sau khi thoát dữ liệu sẽ không được lưu.</p>
          </>
        ),
      });
      return;
    }
    handleCancel();
  };

  return (
    <>
      <Modal
        centered={true}
        title={`${id ? 'Sửa trường dữ liệu' : 'Thêm trường dữ liệu'}`}
        open={isOpen}
        onCancel={onExitModal}
        onOk={handleSubmit(handleSubmitForm)}
        closeIcon={false}
        okText="Lưu"
        cancelText="Huỷ"
        className="sm:w-[65%] md:w-[65%] lg:w-[50%]"
        confirmLoading={isLoading}
        destroyOnClose
      >
        <Spin spinning={!!id && loadingDetail}>
          <FormTera className="overflow-auto h-full" form={form}>
            <Row className="grid-cols-2 gap-x-10">
              <Col>
                <Col>
                  <FormTeraItem
                    name="code"
                    label="Mã trường dữ liệu"
                    help={
                      <span className="text-gray-400 lg:text-xs md:text-[10px] sm:text-[10px]">
                        Nhập thông tin{' '}
                        <span className="text-gray-800">[field]</span>, hệ thống
                        sẽ tự gán theo Format:{' '}
                        <span className="text-gray-800">
                          [module]_[page]_[form]_[field]
                        </span>
                      </span>
                    }
                    rules={[
                      {
                        required: messageValidate.emptyText,
                        maxLength: {
                          value: 191,
                          message: messageValidateLength?.key,
                        },
                        pattern: {
                          value: REGEX.CODE,
                          message: messageValidate.code,
                        },
                      },
                    ]}
                  >
                    <Input className="mb-2" />
                  </FormTeraItem>
                </Col>
                <Col>
                  <FormTeraItem
                    name="title"
                    label="Tiêu đề"
                    rules={[
                      {
                        required: messageValidate.emptyText,
                        maxLength: {
                          value: 191,
                          message: messageValidateLength?.key,
                        },
                      },
                    ]}
                  >
                    <Input />
                  </FormTeraItem>
                </Col>
                <Col>
                  <FormTeraItem
                    name="type"
                    label="Loại dữ liệu"
                    rules={[
                      {
                        required: messageValidate.emptySelect,
                        maxLength: {
                          value: 191,
                          message: messageValidateLength?.key,
                        },
                      },
                    ]}
                  >
                    <Select options={optionsFieldType} />
                  </FormTeraItem>
                </Col>
                <Col>
                  <FormTeraItem
                    label="Key dữ liệu"
                    name="keyData"
                    rules={[
                      {
                        maxLength: {
                          value: 255,
                          message: messageValidateLength?.textLong,
                        },
                      },
                    ]}
                  >
                    <Input />
                  </FormTeraItem>
                </Col>
                <Col>
                  <FormTeraItem
                    label="ClassName"
                    name="className"
                    rules={[
                      {
                        maxLength: {
                          value: 255,
                          message: messageValidateLength?.textLong,
                        },
                      },
                    ]}
                  >
                    <Input />
                  </FormTeraItem>
                </Col>
              </Col>
              <Col>
                <Col>
                  <FormTeraItem
                    name="placeholder"
                    label="Placeholder"
                    rules={[
                      {
                        maxLength: {
                          value: 255,
                          message: messageValidateLength?.textLong,
                        },
                      },
                    ]}
                  >
                    <Input />
                  </FormTeraItem>
                </Col>
                <Col>
                  <FormTeraItem name="priority" label="Thứ tự đứng sau">
                    <Select options={optionsFormOrder} />
                  </FormTeraItem>
                </Col>
                <Col>
                  <FormTeraItem name="allowEdit" label="Cho phép chỉnh sửa">
                    <Toggle />
                  </FormTeraItem>
                </Col>
                <Col>
                  <FormTeraItem
                    name="disableWhenEdit"
                    label="Disable khi chỉnh sửa"
                  >
                    <Toggle />
                  </FormTeraItem>
                </Col>
                <Col>
                  <FormTeraItem name="allowAdd" label="Cho phép thêm mới">
                    <Toggle />
                  </FormTeraItem>
                </Col>
                <Col>
                  <FormTeraItem name="isRequire" label="Bắt buộc">
                    <Toggle />
                  </FormTeraItem>
                </Col>
              </Col>
            </Row>
          </FormTera>
        </Spin>
      </Modal>
    </>
  );
};

export default CreateConfigField;
