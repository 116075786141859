import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { messageWarning } from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Modal, notification } from 'tera-dls';
import PermissionRole from '../Form/PermissionRole/PermissionRole';
import UserApi from 'pages/User/_api/userList';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
interface IProps {
  open: boolean;
  id?: number;
  onClose: () => void;
}

const UserConfigPermission = ({ open, onClose, id }: IProps) => {
  const form = useForm({ mode: 'onChange' });
  const confirm = useConfirm();
  const [openPermissionRole, setOpenPermissionRole] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const { data, refetch } = useQuery(
    ['get-user-detail', id],
    () => {
      return UserApi.getDetail({ id });
    },
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    id && refetch();
  }, [id]);

  useEffect(() => {
    if (data) {
      form.setValue('role_id', data?.role?.id, { shouldDirty: false });
    }
  }, [data]);

  const { mutate: mutateFormPermission } = useMutation(
    (variables: any) => {
      return UserApi.configRole(variables);
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-list-user']);
          queryClient.invalidateQueries(['get-user-detail']);
          onClose();
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );
  const handleSubmitForm = (values) => {
    mutateFormPermission({
      params: { ...values, user_id: data?.id },
    });
  };

  const handleCloseConfirm = () => {
    if (form?.formState?.isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        className: 'modal-button-center',
        onOk: () => {
          onClose();
        },
      });
    } else {
      onClose();
    }
  };

  return (
    <>
      <Modal
        centered={true}
        title={'Phân quyền'}
        open={open}
        width={500}
        closeIcon={false}
        okText="Lưu"
        cancelText="Huỷ"
        onOk={() => form.handleSubmit(handleSubmitForm)()}
        onCancel={handleCloseConfirm}
        destroyOnClose={true}
      >
        <FormTera form={form} onSubmit={handleSubmitForm}>
          <FormTeraItem
            name="role_id"
            label="Phân quyền"
            className="col-span-1"
            rules={[{ required: 'Vui lòng điền thông tin' }]}
          >
            <div className="flex gap-2.5">
              <Input readOnly={true} placeholder="" disabled />
              <Button
                htmlType="button"
                onClick={() => {
                  setOpenPermissionRole(true);
                }}
              >
                Chọn
              </Button>
            </div>
          </FormTeraItem>
        </FormTera>
      </Modal>
      {openPermissionRole && (
        <PermissionRole
          open={openPermissionRole}
          onClose={() => setOpenPermissionRole(false)}
          businessId={data?.business_id}
          value={data?.role}
          onSubmit={(value) => {
            form.setValue('role_id', value?.id, { shouldDirty: true });
          }}
        />
      )}
    </>
  );
};

export default UserConfigPermission;
