import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { messageValidate, messageWarning } from '_common/constants/message';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectColumnConfig from '_common/dof/Select/SelectColumnConfig';
import SelectControlConfig from '_common/dof/Select/SelectControlConfig';
import SelectFieldConfig from '_common/dof/Select/SelectFieldConfig';
import SelectFormConfig from '_common/dof/Select/SelectFormConfig';
import SelectPageConfig from '_common/dof/Select/SelectPageConfig';
import SelectTableConfig from '_common/dof/Select/SelectTableConfig';
import useConfirm from '_common/hooks/useConfirm';
import { getFieldObjToArr } from '_common/utils';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, OptionProps, notification } from 'tera-dls';
import * as yup from 'yup';
import ManagePageApi from '../../_api';
import { IFormCopy } from '../../interfaces';

interface ManagePageCopyProps {
  pageId: string | number;
  open: boolean;
  onClose: () => void;
}

const schema = yup.object().shape({
  page_root_id: yup.string().required(messageValidate.emptySelect),
  page_current_id: yup.string().nullable(),
  config_controls: yup.array().nullable(),
  config_tables: yup.array().nullable(),
  config_columns: yup.array().nullable(),
  config_forms: yup.array().nullable(),
  config_fields: yup.array().nullable(),
});

function ManagePageCopy({ open, onClose, pageId }: ManagePageCopyProps) {
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const [selectedPage, setSelectedPage] = useState<OptionProps>(null);
  const [selectedForm, setSelectedForm] = useState<OptionProps[]>([]);
  const [selectedTable, setSelectedTable] = useState<OptionProps[]>([]);
  const [selectedColumn, setSelectedColumn] = useState<OptionProps[]>([]);
  const [selectedControl, setSelectedControl] = useState<OptionProps[]>([]);
  const [selectedField, setSelectedField] = useState<OptionProps[]>([]);

  const form = useForm<IFormCopy>({
    resolver: yupResolver<IFormCopy>(schema),
  });
  const {
    reset,
    handleSubmit,
    watch,
    formState: { isDirty, isSubmitted, isValid },
  } = form;

  const isErrorMinimumConfig = useMemo(() => {
    const arrConfig = [
      selectedTable.length,
      selectedControl.length,
      selectedForm.length,
    ];
    const isError = arrConfig.some((item) => item > 0);
    return !isError;
  }, [selectedTable, selectedControl, selectedForm]);

  const handleClose = () => {
    onClose();
    reset();
  };

  const { mutate: submitCopy } = useMutation(
    (variable: IFormCopy) => ManagePageApi.copy(variable),
    {
      onSuccess: ({ code, msg }) => {
        if (code === 200) {
          handleClose();
          notification.success({
            message: msg,
          });

          if (selectedForm) queryClient.invalidateQueries(['list-form-config']);
          if (selectedTable)
            queryClient.invalidateQueries(['get-table-config-list']);
          if (selectedColumn)
            queryClient.invalidateQueries(['get-list-column-config']);
          if (selectedControl)
            queryClient.invalidateQueries(['get-list-control-config']);
          if (selectedField)
            queryClient.invalidateQueries(['list-field-config']);
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          handleClose();
        },
      });
    } else {
      handleClose();
    }
  };

  const handleSubmitForm = () => {
    if (isErrorMinimumConfig) return;
    const dataCopy: IFormCopy = {
      page_root_id: selectedPage.value,
      page_current_id: pageId,
      config_controls: getFieldObjToArr(selectedControl, 'value'),
      config_tables: getFieldObjToArr(selectedTable, 'value'),
      config_columns: getFieldObjToArr(selectedColumn, 'value'),
      config_forms: getFieldObjToArr(selectedForm, 'value'),
      config_fields: getFieldObjToArr(selectedField, 'value'),
    };
    submitCopy(dataCopy);
  };

  const resetField = (type: 'page' | 'table' | 'form') => {
    switch (type) {
      case 'table': {
        setSelectedColumn([]);
        break;
      }
      case 'page': {
        setSelectedColumn([]);
        setSelectedForm([]);
        setSelectedTable([]);
        setSelectedControl([]);
        setSelectedField([]);
        break;
      }
      case 'form': {
        setSelectedField([]);
        break;
      }
    }
  };

  return (
    <Modal
      centered
      closeIcon={false}
      open={open}
      okText="Lưu"
      cancelText="Hủy"
      title="SAO CHÉP TRANG"
      onOk={() => handleSubmit(handleSubmitForm)()}
      onCancel={handleCloseConfirm}
      className="md:w-[418px] w-[418px]"
    >
      <FormTera className="h-full" form={form}>
        <FormTeraItem label="Chọn trang nguồn" name="page_root_id">
          <SelectPageConfig
            selectedValue={selectedPage}
            onSelect={(option: OptionProps) => {
              setSelectedPage(option);
              resetField('page');
              reset({ page_root_id: option?.value }, { keepValues: true });
            }}
            onClear={() => setSelectedPage(null)}
            paramsApi={{ page_id: pageId }}
          />
        </FormTeraItem>
        <FormTeraItem label="Chọn cấu hình control" name="config_controls">
          <SelectControlConfig
            isCheckAll
            mode="multiple"
            selectedValue={selectedControl}
            disabled={!watch('page_root_id')}
            onClear={() => setSelectedControl([])}
            onSelect={(value: OptionProps[]) => {
              setSelectedControl(value);
            }}
            paramsApi={{ page_id: watch('page_root_id') }}
          />
        </FormTeraItem>
        <FormTeraItem label="Chọn cấu hình bảng dữ liệu" name="config_tables">
          <SelectTableConfig
            isCheckAll
            mode="multiple"
            selectedValue={selectedTable}
            disabled={!watch('page_root_id')}
            pageId={watch('page_root_id')}
            onClear={() => {
              setSelectedTable([]);
              resetField('table');
            }}
            onSelect={(value: OptionProps[]) => {
              setSelectedTable(value);
              resetField('table');
            }}
          />
        </FormTeraItem>
        <FormTeraItem label="Chọn cấu hình cột dữ liệu" name="config_columns">
          <SelectColumnConfig
            isCheckAll
            mode="multiple"
            typeLabel="table-column"
            disabled={!selectedTable.length}
            selectedValue={selectedColumn}
            paramsApi={{
              table_id: getFieldObjToArr(selectedTable, 'value'),
              page_id: watch('page_root_id'),
            }}
            onClear={() => setSelectedColumn([])}
            onSelect={(value: OptionProps[]) => {
              setSelectedColumn(value);
            }}
          />
        </FormTeraItem>
        <FormTeraItem label="Chọn cấu hình form" name="config_forms">
          <SelectFormConfig
            mode="multiple"
            disabled={!watch('page_root_id')}
            selectedValue={selectedForm}
            isCheckAll
            onSelect={(value: OptionProps[]) => {
              setSelectedForm(value);
              resetField('form');
            }}
            onClear={() => {
              setSelectedForm([]);
              resetField('form');
            }}
            paramsApi={{ page_id: watch('page_root_id') }}
          />
        </FormTeraItem>
        <FormTeraItem label="Chọn cấu hình trường dữ liệu" name="config_fields">
          <SelectFieldConfig
            disabled={!selectedForm.length}
            mode="multiple"
            typeLabel="form-field"
            selectedValue={selectedField}
            onSelect={(value: OptionProps[]) => {
              setSelectedField(value);
            }}
            isCheckAll
            onClear={() => setSelectedField([])}
            paramsApi={{
              form_ids: getFieldObjToArr(selectedForm, 'value'),
              page_id: watch('page_root_id'),
            }}
          />
        </FormTeraItem>
        {isErrorMinimumConfig && isSubmitted && isValid && (
          <span className="text-red-500">
            * Vui lòng chọn ít nhất 1 cấu hình
          </span>
        )}
      </FormTera>
    </Modal>
  );
}

export default ManagePageCopy;
