import { useQuery } from '@tanstack/react-query';
import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import { timeFormat } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import { convertOrderSort } from '_common/utils';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  DropdownItem,
  formatCurrency,
  formatDate,
  getQueryParams,
  updateQueryParams,
  PaginationProps,
} from 'tera-dls';
import AffiliatesAPI from '../../_api';
import { CONFIG_AFFILIATES_URL } from '../../url';
import ModalEditPercentageForm from '../Modal/ModalEditPercentage';
import Filter from './Filter';
import Searching from './Searching';

const ListReferer = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const queryParams = getQueryParams(search) as any;
  const [openUpdateModel, setOpenUpdateModel] = useState<any>({ open: false });
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);

  const { data: response, isLoading } = useQuery(
    ['get-publisher-list', queryParams],
    () => {
      const data = {
        page: 1,
        limit: 10,
        ...queryParams,
      };
      return AffiliatesAPI.getListPublisher(data);
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const itemsAction = (item): DropdownItem[] => {
    return [
      {
        key: 1,
        label: 'Xem',
        onClick: () => {
          navigate(
            `${CONFIG_AFFILIATES_URL.list_referer_detail.path}/${item?.id}`,
          );
        },
      },
      {
        key: 2,
        label: 'Sửa',
        onClick: () => {
          setOpenUpdateModel({ open: true, value: item?.id });
        },
      },
    ];
  };

  const handleUpdateFilter = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(paramString);
  };

  const handleSort = (record) => {
    const convertOrder = convertOrderSort(record?.order);

    handleUpdateFilter({
      order_field: record?.field,
      order_by: convertOrder,
      page: 1,
    });
  };

  const columns: any = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 80,
      render: (title) => <p className="line-clamp-2">{title}</p>,
      sorter: true,
    },
    {
      title: 'Tên người giới thiệu',
      dataIndex: 'full_name',
      render: (full_name) => <p className="line-clamp-2">{full_name}</p>,
      sorter: true,
      width: 200,
    },
    {
      title: 'Tỷ lệ chuyển đổi',
      dataIndex: 'conversion_rate',
      render: (conversion_rate) => (
        <p className="line-clamp-2">{conversion_rate} %</p>
      ),
      sorter: true,
      width: 150,
    },
    {
      title: 'Tổng tiền hoa hồng',
      dataIndex: 'commission_total',
      render: (commission_total) => (
        <p className="line-clamp-2">{formatCurrency(commission_total || 0)}</p>
      ),
      sorter: true,
      width: 200,
    },
    {
      title: '% Hoa hồng',
      dataIndex: 'commission_percentage',
      render: (commission_percentage) => (
        <p className="line-clamp-2">{commission_percentage} %</p>
      ),
      sorter: true,
      width: 100,
    },
    {
      title: 'Ngày đăng ký',
      dataIndex: 'date_registered',
      render: (date_registered) => (
        <p className="line-clamp-2">
          {formatDate(date_registered, timeFormat.date_time_second)}
        </p>
      ),
      width: 150,
      sorter: true,
    },
    {
      width: 80,
      fixed: 'right',
      render: (record) => {
        return (
          <ActionDropdown dropdownItems={itemsAction(record)} trigger="click" />
        );
      },
    },
  ];

  const handleSearch = ({ keyword }) => {
    handleUpdateFilter({ keyword, page: 1 });
  };

  const handleFilter = (values: any): void => {
    handleUpdateFilter({ ...values, page: 1 });
  };

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    handleUpdateFilter({ page: page, limit: pageSize });
  };
  return (
    <>
      <div className="tera-page">
        <HeaderViewListV2
          onClickFilter={() => setOpenFilterModal(true)}
          title={'DANH SÁCH NGƯỜI GIỚI THIỆU'}
          actionLeftRender={<Searching onSearch={handleSearch} />}
        >
          <div className="bg-white shadow-xsm rounded-[5px] overflow-hidden">
            <TableTera
              loading={isLoading}
              columns={columns}
              data={response?.data || []}
              onChange={(record) => handleSort(record)}
              pagination={{
                onChange: handleChangePage,
                total: response?.total || 0,
                current: response?.current_page || 1,
                pageSize: response?.per_page,
                to: response?.to,
                from: response?.from,
              }}
            />
          </div>
        </HeaderViewListV2>
      </div>
      {openUpdateModel?.open && (
        <ModalEditPercentageForm
          open={openUpdateModel?.open}
          value={openUpdateModel?.value}
          onClose={() => setOpenUpdateModel({ open: false })}
        />
      )}
      {openFilterModal && (
        <Filter
          open={openFilterModal}
          onClose={() => setOpenFilterModal(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
    </>
  );
};

export default ListReferer;
