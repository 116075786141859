import { useStores } from '_common/hooks';

function Dashboard() {
  const {
    authStore: { user },
  } = useStores();

  return (
    <>
      <h3>Chào mừng, {user?.full_name}</h3>
    </>
  );
}

export default Dashboard;
