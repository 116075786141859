import { StarOutlined } from 'tera-dls';

export const renderDescription = (data, width = 150) =>
  data?.map((item, index) => (
    <div className="flex gap-5" key={item?.key ?? index}>
      <h6
        className={` !text-gray-500 font-normal detail-key w-full`}
        style={{
          minWidth: width,
          maxWidth: width,
        }}
      >
        {item?.title}
      </h6>
      <span className=" w-full detail-value text-gray-700 font-normal">
        {item?.value}
      </span>
    </div>
  ));

export const getStatusSummaryCount = (summary, status: string): number => {
  const data = summary?.find((item) => item.status === status);
  return data?.total_count ?? 0;
};

interface ITruncateTextProps {
  value: any[];
  length?: number;
  onClick?: () => void;
}

export const TruncateText = (props: ITruncateTextProps) => {
  const { value, length = 3, onClick } = props;
  if (value?.length < length) return <>{value.join(', ')}</>;
  return (
    <>
      {`${value?.[0] ?? ''}, ${value?.[1] ?? ''},`}
      <span className="text-blue-500 cursor-pointer" onClick={onClick}>
        ...+{value?.length - 2}
      </span>
    </>
  );
};

export const DefaultTag = ({ title }) => {
  return (
    <span className="bg-pink-400 px-2.5 py-1.5 rounded-xsm text-white flex gap-1 whitespace-nowrap">
      <StarOutlined className="w-[10px] fill-yellow-300" />{' '}
      {title ?? 'Mặc định'}
    </span>
  );
};
