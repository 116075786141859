import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const StatisticEndpoint = `${endpoint}/administrator/accounting-voucher/report`;
const StatisticApi = {
  byType: async ({ params }) =>
    await api
      .get(`${StatisticEndpoint}/by-type`, params)
      .then((data) => data?.data?.data),
  byTime: async ({ params }) =>
    await api
      .get(`${StatisticEndpoint}/by-time`, params)
      .then((data) => data?.data?.data),
  byPaymentMethod: async ({ params }) =>
    await api
      .get(`${StatisticEndpoint}/by-pay-method`, params)
      .then((data) => data?.data?.data),
  byCustomer: async ({ params }) =>
    await api
      .get(`${StatisticEndpoint}/by-customer`, params)
      .then((data) => data?.data?.data),
};

export default StatisticApi;
