export const FILTER_TIME = {
  currentWeek: 'Tuần này',
  lastWeek: 'Tuần trước',
  currentMonth: 'Tháng này',
  lastMonth: 'Tháng trước',
  january: 'Tháng 1',
  february: 'Tháng 2',
  march: 'Tháng 3',
  april: 'Tháng 4',
  may: 'Tháng 5',
  june: 'Tháng 6',
  july: 'Tháng 7',
  august: 'Tháng 8',
  september: 'Tháng 9',
  october: 'Tháng 10',
  november: 'Tháng 11',
  december: 'Tháng 12',
  currentQuarter: 'Quý này',
  lastQuarter: 'Quý trước',
  quarterOne: 'Quý 1',
  quarterTwo: 'Quý 2',
  quarterThree: 'Quý 3',
  quarterFour: 'Quý 4',
  firstSixMonthOfYear: '6 tháng đầu năm',
  secondSixMonthOfYear: '6 tháng cuối năm',
  currentYear: 'Năm nay',
  lastYear: 'Năm trước',
};
