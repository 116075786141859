import { useMutation, useQueries, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { REGEX } from '_common/constants/common';
import { messageValidate, messageWarning } from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import ModuleApi from 'pages/Module/_api';
import { MODULE_TYPE } from 'pages/Module/constants';
import { useForm } from 'react-hook-form';
import { Modal, OptionProps, notification } from 'tera-dls';

interface IProps {
  open: boolean;
  id?: number;
  onClose: () => void;
  onChangeDataSuccess?: () => void;
}

interface IForm {
  code: string;
  title: string;
  type: string;
  sub_title: string;
  url: string;
  color: string;
}

const ModuleForm = (props: IProps) => {
  const { open = false, id, onClose, onChangeDataSuccess } = props;

  const form = useForm<IForm>({
    mode: 'onChange',
  });
  const confirm = useConfirm();

  const queryClient = useQueryClient();

  const handleClose = (): void => {
    form.reset();
    onClose();
  };

  const handleCloseConfirm = () => {
    if (form.formState.isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        className: 'modal-button-center',
        onOk: () => {
          handleClose();
        },
      });
    } else {
      handleClose();
    }
  };

  const updateData = (data) => {
    const values = _.pick(data, [
      'code',
      'title',
      'sub_title',
      'url',
      'color',
      'type',
    ]);
    form.reset({
      ...values,
    });
  };

  useQueries({
    queries: [
      {
        queryKey: ['get-page-detail', id],
        queryFn: () => ModuleApi.getDetail(id),
        enabled: !!open && !!id,
        onSuccess: (data) => {
          updateData(data);
        },
      },
    ],
  });

  const { mutate: mutateForm } = useMutation(
    (variables: any) => {
      return id ? ModuleApi.update(id, variables) : ModuleApi.create(variables);
    },
    {
      onSuccess: ({ code, msg }) => {
        if (code === 200) {
          onChangeDataSuccess && onChangeDataSuccess();
          queryClient.removeQueries({
            queryKey: ['get-page-by-menu-id'],
          });
          onClose();
          notification.success({
            message: msg,
          });
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const trimData = (code: string): string => {
    return code.split(' ').join('');
  };

  const handleSubmitForm = (values: { [key: string]: any }): void => {
    const code = trimData(values.code);
    mutateForm({
      ...values,
      code,
      status: values?.status ? 'active' : 'inactive',
    });
  };

  const moduleTypeOptions: OptionProps[] = Object.entries(MODULE_TYPE).map(
    ([value, label]) => ({
      label,
      value,
    }),
  );

  return (
    <Modal
      centered={true}
      title={id ? ' SỬA MODULE' : 'THÊM MODULE'}
      open={open}
      width={500}
      closeIcon={false}
      okText="Lưu"
      cancelText="Huỷ"
      onOk={() => form.handleSubmit(handleSubmitForm)()}
      onCancel={handleCloseConfirm}
      destroyOnClose={true}
    >
      <FormTera form={form} onSubmit={form.handleSubmit(handleSubmitForm)}>
        <FormTeraItem
          label="Mã trang"
          name="code"
          rules={[
            {
              required: messageValidate.emptyText,
            },
            {
              pattern: {
                message: messageValidate.code,
                value: REGEX.CODE,
              },
            },
          ]}
        >
          <Input maxLength={191} />
        </FormTeraItem>
        <FormTeraItem
          label="Tiêu đề"
          name="title"
          rules={[
            {
              required: messageValidate.emptyText,
            },
          ]}
        >
          <Input maxLength={255} />
        </FormTeraItem>
        <FormTeraItem label="Tiêu đề phụ" name="sub_title">
          <Input maxLength={255} />
        </FormTeraItem>
        <FormTeraItem
          label="Loại module"
          name="type"
          rules={[
            {
              required: messageValidate.emptySelect,
            },
          ]}
        >
          <Select mode="multiple" options={moduleTypeOptions} />
        </FormTeraItem>
        <FormTeraItem
          label="Đường dẫn"
          name="url"
          rules={[
            {
              required: messageValidate.emptyText,
            },
          ]}
        >
          <Input maxLength={255} />
        </FormTeraItem>
        <FormTeraItem label="Màu sắc" name="color">
          <Input maxLength={191} />
        </FormTeraItem>
      </FormTera>
    </Modal>
  );
};

export default ModuleForm;
