import { useForm } from 'react-hook-form';
import { Col, Modal, OptionProps, Row, Spin, notification } from 'tera-dls';

import { useMutation, useQuery } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { REGEX } from '_common/constants/common';
import {
  messageValidate,
  messageValidateLength,
  messageWarning,
} from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import InputNumber from '_common/dof/Control/InputNumber';
import Select from '_common/dof/Control/Select';
import Toggle from '_common/dof/Control/Switch';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectColumnConfig from '_common/dof/Select/SelectColumnConfig';
import useConfirm from '_common/hooks/useConfirm';
import { useEffect, useState } from 'react';
import { align, dataType, unit } from '../../../constants';
import ColumnConfigApi from '../../_api';
import { IForm } from '../../interfaces';

interface ColumnConfigFormProps {
  id: string | number;
  tableId: string | number;
  open: boolean;
  onRefetch: () => void;
  onClose: () => void;
}

// const schema = yup.object().shape({
//   code: yup
//     .string()
//     .required(messageValidate.emptyText)
//     .matches(REGEX.CODE, 'Mã cột không dấu và chỉ chứa kí tự "_"')
//     .max(191, messageValidateLength.key)
//     .trim(),
//   title: yup
//     .string()
//     .required(messageValidate.emptyText)
//     .max(255, messageValidateLength.textLong)
//     .trim(),
//   type: yup.string().required(messageValidate.emptySelect),
//   key: yup.string().nullable().trim().max(255, messageValidateLength.textLong),
//   class_name: yup
//     .string()
//     .nullable()
//     .trim()
//     .max(255, messageValidateLength.textLong),
//   width: yup
//     .number()
//     .nullable()
//     .when('unit', {
//       is: 'percent',
//       then: (schema) => schema.max(100, 'Không nhập quá 100'),
//       otherwise: (schema) => schema,
//     }),
//   unit: yup.string().nullable(),
//   align: yup.string().nullable(),
//   stand_behind: yup.number().nullable(),
//   status: yup.boolean().nullable(),
//   show_desktop: yup
//     .number()
//     .nullable()
//     .transform((_, value) => (value ? 1 : 0)),
//   hide_tablet_lg: yup
//     .number()
//     .nullable()
//     .transform((_, value) => (value ? 1 : 0)),
//   hide_tablet: yup
//     .number()
//     .nullable()
//     .transform((_, value) => (value ? 1 : 0)),
//   show_mobile: yup
//     .number()
//     .nullable()
//     .transform((_, value) => (value ? 1 : 0)),
// });

const optionsCustom: OptionProps = {
  label: 'Đứng đầu',
  value: 0,
};

function ColumnConfigForm({
  open,
  onClose,
  onRefetch,
  id,
  tableId,
}: ColumnConfigFormProps) {
  const confirm = useConfirm();

  const [standBehindSelected, setStandBehindSelected] =
    useState<OptionProps>(null);

  const form = useForm<IForm>({
    defaultValues: {
      unit: 'px',
    },
  });
  const {
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { isDirty },
  } = form;
  const {
    data: detailColumn,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ['get-detail-column-config', id],
    () => ColumnConfigApi.getDetail(id),
    {
      enabled: !!id,
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const handleClose = () => {
    onClose();
    reset();
  };

  const { mutate: submitForm, isLoading: loadingSubmit } = useMutation(
    (variable: IForm) => {
      const data = {
        ...variable,
        table_id: +tableId,
      };
      if (id) return ColumnConfigApi.update(id, data);
      return ColumnConfigApi.create(data);
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          onRefetch();
          handleClose();
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleCloseConfirm = () => {
    if (loadingSubmit) return;
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          handleClose();
        },
      });
    } else {
      handleClose();
    }
  };

  const handleSubmitForm = (values: IForm) => {
    if (loadingSubmit) return;
    const data: any = {
      ...values,
      status: values?.status ? 'active' : 'inactive',
    };
    submitForm(data);
  };

  const optionsDataType = Object.keys(dataType).map((key) => ({
    label: dataType[key],
    value: key,
  }));

  const optionsAlign = Object.keys(align).map((key) => ({
    label: align[key],
    value: key,
  }));

  const optionsUnit = Object.keys(unit).map((key) => ({
    label: unit[key],
    value: key,
  }));

  useEffect(() => {
    if (detailColumn && id) {
      console.log('detailColumn', detailColumn);
      const values: IForm = {
        code: detailColumn?.code,
        title: detailColumn?.title,
        type: detailColumn?.type,
        key: detailColumn?.key,
        class_name: detailColumn?.class_name,
        width: detailColumn?.width,
        unit: detailColumn?.unit,
        align: detailColumn?.align,
        stand_behind: !!detailColumn?.standing_behind
          ? detailColumn?.standing_behind?.id
          : detailColumn?.standing_behind,
        status: detailColumn?.status === 'active' ? true : false,
        show_desktop: detailColumn?.show_desktop,
        hide_tablet_lg: detailColumn?.hide_tablet_lg,
        hide_tablet: detailColumn?.hide_tablet,
        show_mobile: detailColumn?.show_mobile,
      };

      if (detailColumn?.standing_behind)
        setStandBehindSelected({
          label: detailColumn?.standing_behind?.title,
          value: detailColumn?.standing_behind?.id,
        });
      else setStandBehindSelected(optionsCustom);

      Object.entries(values).forEach(([fieldName, fieldValue]: [any, any]) => {
        setValue(fieldName, fieldValue);
      });
    }
  }, [detailColumn, id]);

  useEffect(() => {
    if (id) refetch();
  }, [id]);

  if (isError) {
    onClose();
  }

  return (
    <Modal
      centered
      closeIcon={false}
      open={open}
      okText="Lưu"
      cancelText="Hủy"
      title={id ? 'SỬA CỘT DỮ LIỆU' : 'THÊM CỘT DỮ LIỆU'}
      className="md:w-[700px]"
      onOk={() => handleSubmit(handleSubmitForm)()}
      onCancel={handleCloseConfirm}
      confirmLoading={(isLoading && !!id) || loadingSubmit}
    >
      <Spin spinning={isLoading && !!id}>
        <FormTera form={form} className="h-full">
          <Row className="grid grid-cols-1 md:grid-cols-2 gap-x-4 ">
            <Col>
              <FormTeraItem
                label="Mã cột dữ liệu"
                name="code"
                help={
                  <span className="text-gray-400 text-[10px]">
                    Nhập thông tin{' '}
                    <span className="text-gray-800">[column]</span>, hệ thống sẽ
                    tự gán theo Format:
                    <span className="text-gray-800">
                      [module]_[page]_[table]_[column]
                    </span>
                  </span>
                }
                rules={[
                  {
                    required: messageValidate.emptyText,
                    maxLength: {
                      message: messageValidateLength.key,
                      value: 191,
                    },
                    pattern: {
                      message: messageValidate.code,
                      value: REGEX.CODE,
                    },
                  },
                ]}
              >
                <Input />
              </FormTeraItem>
              <FormTeraItem
                label="Tiêu đề"
                name="title"
                rules={[
                  {
                    required: messageValidate.emptyText,
                    maxLength: {
                      message: messageValidateLength.textLong,
                      value: 255,
                    },
                  },
                ]}
              >
                <Input />
              </FormTeraItem>
              <FormTeraItem
                label="Loại dữ liệu"
                name="type"
                rules={[
                  {
                    required: messageValidate.emptySelect,
                  },
                ]}
              >
                <Select options={optionsDataType} />
              </FormTeraItem>
              <FormTeraItem
                label="Key dữ liệu"
                name="key"
                rules={[
                  {
                    maxLength: {
                      message: messageValidateLength.textLong,
                      value: 255,
                    },
                  },
                ]}
              >
                <Input />
              </FormTeraItem>
              <FormTeraItem
                label="className"
                name="class_name"
                rules={[
                  {
                    maxLength: {
                      message: messageValidateLength.textLong,
                      value: 255,
                    },
                  },
                ]}
              >
                <Input />
              </FormTeraItem>
              <div className="flex items-end gap-x-2.5">
                <FormTeraItem
                  label="Độ rộng"
                  name="width"
                  className="mb-0"
                  rules={[
                    {
                      maxLength: {
                        message: messageValidateLength.text,
                        value: 100,
                      },
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    max={watch('unit') === 'percent' ? 100 : undefined}
                    className="basis-4/5"
                  />
                </FormTeraItem>
                <FormTeraItem name="unit" className="mb-0">
                  <Select className="basis-1/5" options={optionsUnit} />
                </FormTeraItem>
              </div>
            </Col>
            <Col>
              <FormTeraItem label="Căn lề" name="align">
                <Select options={optionsAlign} />
              </FormTeraItem>
              <FormTeraItem label="Thứ tự đứng sau" name="stand_behind">
                <SelectColumnConfig
                  idPrevent={Number(id)}
                  optionCustom={[optionsCustom]}
                  selectedValue={standBehindSelected}
                  onSelect={(_, value) => setStandBehindSelected(value)}
                  paramsApi={{ table_id: tableId }}
                  isRefetch
                />
              </FormTeraItem>
              <FormTeraItem label="Trạng thái" name="status">
                <Toggle />
              </FormTeraItem>
              <FormTeraItem
                label="Hiển thị trên Desktop (>1600px)"
                name="show_desktop"
              >
                <Toggle />
              </FormTeraItem>
              <FormTeraItem
                label="Hiển thị trên Table lớn (>1200px)"
                name="hide_tablet_lg"
              >
                <Toggle />
              </FormTeraItem>
              <FormTeraItem
                label="Hiển thị trên Table nhỏ (>960px)"
                name="hide_tablet"
              >
                <Toggle />
              </FormTeraItem>
              <FormTeraItem
                label="Hiển thị trên mobile (<768px)"
                name="show_mobile"
              >
                <Toggle />
              </FormTeraItem>
            </Col>
          </Row>
        </FormTera>
      </Spin>
    </Modal>
  );
}

export default ColumnConfigForm;
