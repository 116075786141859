import { makeAutoObservable, toJS } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

export class CommonStore {
  activeMenu = '';

  openMenuMore = false;

  module = 'admin';
  openModalLocationId = false;

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'CommonStore',
      properties: [],
      storage: window.localStorage,
    });
  }
  pushEvent() {
    const event = new CustomEvent('CommonStore', {
      detail: toJS(this),
    });
    window.dispatchEvent(event);
  }

  setActiveMenu = (key: string) => {
    this.activeMenu = key;
  };

  setOpenMenuMore = (open: boolean) => {
    this.openMenuMore = open;
  };

  setOpenModalLocationId = (open: boolean) => {
    this.openModalLocationId = open;
    this.pushEvent();
  };
}
