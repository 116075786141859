import { useQuery } from '@tanstack/react-query';
import { systemSubMenu } from '_common/component/Layout/Menu/menus';
import ManagePageApi from 'pages/System/containers/ManagePage/ListPage/_api';
import { mergeArrayObjectByKeyDependOnNewArray } from 'tera-dls';
import menu from '../component/Layout/Menu/menu.json';
import { useStores } from './useStores';

const subMenuObject = {
  ['system']: systemSubMenu,
};

const useSubMenu = () => {
  const {
    commonStore: { activeMenu },
  } = useStores();

  const { data: subMenus } = useQuery(
    ['get-page-by-menu-id', activeMenu],
    () => ManagePageApi.getList({ parent_key: activeMenu }),
    {
      cacheTime: 300000,
      staleTime: 300000,
      enabled: !!activeMenu,
    },
  );

  const getSubMenu = (targetParentKey) =>
    menu.subMenu.filter((item) => item?.parentKey === targetParentKey);

  const configSubMenu = subMenus?.data
    ?.filter((item) => item.status === 'active')
    ?.map((item) => ({ ...item, code: item.concatenated_code }));

  const menus = menu.parentMenu
    ?.filter((item) => item?.parentKey === activeMenu)
    ?.map((parent) => {
      const defaultChildren = getSubMenu(parent.key);
      const children = mergeArrayObjectByKeyDependOnNewArray(
        configSubMenu,
        defaultChildren,
        'code',
      );
      return { ...parent, children: children ?? [] };
    });

  return subMenuObject[activeMenu] ?? menus;
};

export default useSubMenu;
