import React from 'react';

function Menu16(props) {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.929 3.91469L9.02706 0.135871C8.86569 0.0467471 8.68435 0 8.5 0C8.31565 0 8.13431 0.0467471 7.97294 0.135871L1.07098 3.91469C0.898279 4.00919 0.754157 4.14838 0.653705 4.31768C0.553253 4.48699 0.500165 4.68018 0.5 4.87705V12.3782C0.500165 12.5751 0.553253 12.7683 0.653705 12.9376C0.754157 13.1069 0.898279 13.2461 1.07098 13.3406L7.97294 17.1194C8.13436 17.2084 8.31568 17.255 8.5 17.255C8.68432 17.255 8.86564 17.2084 9.02706 17.1194L15.929 13.3406C16.1017 13.2461 16.2458 13.1069 16.3463 12.9376C16.4467 12.7683 16.4998 12.5751 16.5 12.3782V4.87705C16.4998 4.68018 16.4467 4.48699 16.3463 4.31768C16.2458 4.14838 16.1017 4.00919 15.929 3.91469ZM8.42157 0.960969C8.44465 0.948337 8.47055 0.941717 8.49686 0.941717C8.52318 0.941717 8.54907 0.948337 8.57216 0.960969L15.1282 4.5492L12.4663 6.00568L5.83726 2.37744L8.42157 0.960969ZM8.02941 16.0786L1.51961 12.5155C1.49555 12.5016 1.47562 12.4816 1.46184 12.4575C1.44806 12.4333 1.44093 12.406 1.44118 12.3782V5.38528L8.02941 8.99313V16.0786ZM1.87176 4.5492L4.85765 2.91391L11.4859 6.54215L8.5 8.17587L1.87176 4.5492ZM15.5588 12.3782C15.5591 12.406 15.5519 12.4333 15.5382 12.4575C15.5244 12.4816 15.5044 12.5016 15.4804 12.5155L8.97059 16.0786V8.99156L11.7941 7.44568V10.51C11.7941 10.6348 11.8437 10.7545 11.9319 10.8427C12.0202 10.931 12.1399 10.9806 12.2647 10.9806C12.3895 10.9806 12.5092 10.931 12.5975 10.8427C12.6857 10.7545 12.7353 10.6348 12.7353 10.51V6.93117L15.5588 5.38528V12.3782Z"
        fill="#6B7280"
      />
      <path
        d="M11.4992 7.12793L3.99924 2.62793L5.99924 1.62793L13.4992 6.12793V12.1279L12.4992 11.1279L11.4992 12.6279V7.12793Z"
        fill="#6B7280"
      />
    </svg>
  );
}

export default Menu16;
