import Filter from '_common/component/Filter';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectBusinessId from '_common/dof/Select/SelectBusinessId';
import { ACCOUNT_TYPE, USER_STATUS } from 'pages/User/constants/statusType';
import { useForm } from 'react-hook-form';
import { OptionProps, Row } from 'tera-dls';

interface UserFilterProps {
  open: boolean;
  initialValue: any;
  onClose: () => void;
  onFilter: (value) => void;
}

function UserFilter({
  open,
  onClose,
  onFilter,
  initialValue,
}: UserFilterProps) {
  const form = useForm({
    defaultValues: {
      ...initialValue,
      ...(initialValue?.business_id && {
        business_id: Number(initialValue?.business_id),
      }),
    },
  });

  const handleSubmitForm = (value) => {
    const data = {
      ...value,
    };

    onFilter(data);
    onClose();
  };

  const handleReset = () => {
    const values = {
      type: null,
      is_active: null,
      business_id: null,
    };
    onFilter(values);
    onClose();
  };

  const typeOptions: OptionProps[] = Object.entries(ACCOUNT_TYPE).map(
    ([value, label]) => ({ label, value }),
  );

  const activityOptions: OptionProps[] = Object.entries(USER_STATUS)
    .reverse()
    .filter(([value]) => value !== 'all')
    .map(([value, label]) => ({ label, value }));

  return (
    <Filter
      open={open}
      onClose={onClose}
      onCancel={onClose}
      onFilter={() => form?.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={form?.handleSubmit(handleSubmitForm)}>
        <Row className="grid gap-y-0">
          <FormTeraItem label="Vai trò" name="type">
            <Select options={typeOptions} />
          </FormTeraItem>
          <FormTeraItem label="Công ty" name="business_id">
            <SelectBusinessId
              placeholder="Vui lòng chọn"
              paramsApi={{ include_id: form.watch('business_id') }}
            />
          </FormTeraItem>

          <FormTeraItem label="Trạng thái" name="is_active">
            <Select options={activityOptions} />
          </FormTeraItem>
        </Row>
        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default UserFilter;
