import { useMutation, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import BusinessApi from 'pages/Business/_api';
import { TBusinessStatus } from 'pages/Business/interfaces';
import { useParams } from 'react-router-dom';
import { Button, notification } from 'tera-dls';

function ButtonAction({ status, dataDetail }) {
  const confirm = useConfirm();
  // const form = useForm({
  //   defaultValues: {
  //     trial_time: '7',
  //   },
  // });
  const { id } = useParams();
  const queryClient = useQueryClient();

  const refetch = () => {
    queryClient.invalidateQueries(['get-business-detail']);
    queryClient.invalidateQueries(['get-business-list']);
  };

  const activate = useMutation(
    (variables: any) => {
      const params = _.omit(variables, ['type']);
      if (variables?.type === 'active') return BusinessApi.activate(params);
      return BusinessApi.renewalAccount(params);
    },
    {
      onSuccess: ({ code, msg }) => {
        if (code === 200) {
          refetch();
          notification.success({
            message: msg,
          });
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const changeStatus = useMutation(
    (type: 'cancel' | 'expire' | 'restart') => {
      const data = {
        business_id: id,
      };
      switch (type) {
        case 'cancel':
          return BusinessApi.cancelActivate(data);
        case 'expire':
          return BusinessApi.expiredAccount(data);
        case 'restart':
          return BusinessApi.restart(data);
      }
    },
    {
      onSuccess: ({ code, msg }) => {
        if (code === 200) {
          refetch();
          queryClient.invalidateQueries(['get-service-package-business-list']);
          queryClient.invalidateQueries([
            'get-role-permission-business-detail',
          ]);
          notification.success({
            message: msg,
          });
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  // const trialOptions = Object.entries(TRIAL_TIME).map(([value, label]) => ({
  //   label,
  //   value,
  // }));

  // const handleSubmitActivate = (values, type) => {
  //   const data = {
  //     ...values,
  //     business_id: id,
  //     type,
  //   };

  //   activate.mutate(data);
  // };

  const handleActivate = (type: 'active' | 'renew') => {
    confirm.warning({
      title:
        type === 'active'
          ? 'Xác nhận kích hoạt tài khoản'
          : 'Xác nhận gia hạn tài khoản',
      content: (
        // <FormTera
        //   form={form}
        //   onSubmit={form?.handleSubmit((data) =>
        //     handleSubmitActivate(data, 'active'),
        //   )}
        // >
        //   <FormTeraItem
        //     label="Thời gian dùng thử"
        //     name="trial_time"
        //     className="text-left"
        //     rules={[
        //       {
        //         required: messageValidate.emptySelect,
        //       },
        //     ]}
        //   >
        //     <Select options={trialOptions} allowClear={false} />
        //   </FormTeraItem>
        // </FormTera>
        <p>Bạn có chắc muốn kích hoạt tài khoản này không</p>
      ),
      onOk: () => {
        const data = {
          business_id: id,
          type,
        };

        activate.mutate(data);
      },
      // form?.handleSubmit((data) => handleSubmitActivate(data, type))(),
    });
  };

  const handleInActive = () => {
    confirm.warning({
      title: 'Xác nhận hủy kích hoạt',
      content: (
        <>
          <p>Bạn có chắc muốn hủy kích hoạt tài khoản</p>
          <p>
            <b className="break-all">{dataDetail?.name}</b> này không?
          </p>
        </>
      ),
      onOk: () => {
        changeStatus.mutate('cancel');
      },
    });
  };

  // const handleExpire = () => {
  //   confirm.warning({
  //     title: 'Xác nhận hết hạn tài khoản',
  //     content: (
  //       <>
  //         <p>Bạn có chắc muốn hết hạn tài khoản</p>
  //         <p>
  //           <b className="break-all">{dataDetail?.name}</b> này không?
  //         </p>
  //       </>
  //     ),
  //     onOk: () => {
  //       changeStatus.mutate('expire');
  //     },
  //   });
  // };

  const handleReactive = () => {
    confirm.warning({
      title: 'Xác nhận kích hoạt lại tài khoản',
      content: (
        <>
          <p>Bạn có chắc muốn kích hoạt lại tài khoản</p>
          <p>
            <b className="break-all">{dataDetail?.name}</b> này không?
          </p>
        </>
      ),
      onOk: () => {
        changeStatus.mutate('restart');
      },
    });
  };

  const renderButton = (status: TBusinessStatus) => {
    switch (status) {
      case 'no_activated':
        return (
          <Button
            type="success"
            className="page-header-btn"
            onClick={() => handleActivate('active')}
          >
            Kích hoạt tài khoản
          </Button>
        );
      case 'cancelled':
        return (
          <Button
            className="page-header-btn bg-blue-400"
            onClick={handleReactive}
          >
            Kích hoạt lại
          </Button>
        );
      // case 'expired':
      //   return (
      //     <Button
      //       className="page-header-btn bg-blue-400"
      //       onClick={() => handleActivate('renew')}
      //     >
      //       Gia hạn tài khoản
      //     </Button>
      //   );
      case 'waiting_for_activation':
        return (
          <Button
            className="page-header-btn bg-blue-400"
            onClick={handleReactive}
          >
            Gửi kích hoạt lại
          </Button>
        );
      case 'is_active':
        return (
          <>
            <Button
              type="danger"
              className="page-header-btn bg-red-400"
              onClick={handleInActive}
            >
              Hủy kích hoạt
            </Button>
            {/* <Button
              className="page-header-btn bg-purple-400"
              onClick={handleExpire}
            >
              Hết hạn tài khoản
            </Button>
            <Button
              className="page-header-btn bg-blue-400"
              onClick={() => handleActivate('renew')}
            >
              Gia hạn tài khoản
            </Button> */}
          </>
        );
      default:
        return <></>;
    }
  };

  return renderButton(status);
}

export default ButtonAction;
