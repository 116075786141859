import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import useConfirm from '_common/hooks/useConfirm';
import ServicePackageApi from 'pages/ServicePackage/_api';
import { SERVICE_PACKAGE_URL } from 'pages/ServicePackage/url';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftSolid,
  Breadcrumb,
  Button,
  notification,
  PencilSquareOutlined,
  Spin,
  XMarkOutlined,
} from 'tera-dls';
import ServicePackageDetailContent from './ServicePackageDetailContent';

function ServicePackageDetail() {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const { id } = useParams();
  const queryClient = useQueryClient();

  const {
    data: detail,
    refetch,
    isLoading,
  } = useQuery(
    ['get-service-package-detail', id],
    () => ServicePackageApi.getDetail({ id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { data: summary, refetch: fetchSummary } = useQuery(
    ['get-service-package-summary', id],
    () => ServicePackageApi.getSummaryDetail({ params: { service_id: id } }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (id) {
      fetchSummary();
      refetch();
    }
  }, [id]);

  const isDefault = detail?.is_trial_default;

  const { mutate: mutateDelete } = useMutation(
    (variables: any) => ServicePackageApi.delete(variables),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          navigate(-1);
          queryClient.invalidateQueries(['get-service-package-detail']);
          queryClient.invalidateQueries(['get-service-package-list']);

          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleUpdate = () => {
    navigate(`${SERVICE_PACKAGE_URL.update.path}/${id}`);
  };

  const handleDelete = (): void => {
    confirm.warning({
      title: 'Xóa gói dịch vụ',
      content: (
        <p className="break-word">
          <p> Bạn có chắc muốn xóa gói dịch vụ</p>
          <p>
            <span className="font-bold break-word">
              {` ${detail?.name ?? ''} `}{' '}
            </span>
            này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateDelete({ id: detail?.id });
      },
    });
  };

  return (
    <Spin spinning={isLoading}>
      <div className="tera-page-form">
        <div className="page-header-sticky">
          <div className="page-header-v2">
            <div className="page-header-v2__breadcrumb">
              <div
                className="page-header-v2__breadcrumb cursor-pointer"
                onClick={() => navigate(-1)}
              >
                <ArrowSmallLeftSolid className="h-6 w-6" />
              </div>
              <Breadcrumb
                separator={'>'}
                items={[
                  {
                    title: (
                      <a onClick={() => navigate(-1)}>
                        <span className="!text-blue-400 hover:!text-blue-600">
                          Danh sách gói dịch vụ
                        </span>
                      </a>
                    ),
                  },
                  {
                    title: 'Chi tiết gói dịch vụ',
                  },
                ]}
              />
            </div>
            <div className="page-header-v2__function">
              <Button className="page-header-btn" onClick={handleUpdate}>
                <PencilSquareOutlined className="w-4" />
                Sửa
              </Button>
              {!isDefault && (
                <Button
                  type="danger"
                  className="page-header-btn"
                  onClick={handleDelete}
                >
                  <XMarkOutlined className="w-4" />
                  Xóa
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className="page-content-v2 gap-y-2.5">
          <ServicePackageDetailContent dataDetail={detail} summary={summary} />
        </div>
      </div>
    </Spin>
  );
}

export default ServicePackageDetail;
