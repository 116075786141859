import { useQuery } from '@tanstack/react-query';
import React, { ReactNode } from 'react';
import { Description, Icon, Modal, Spin } from 'tera-dls';
import moment from 'moment';

import ModuleApi from '../../_api';
interface DetailConfigPermissionProps {
  open: boolean;
  onCloseModal: () => void;
  id: string | number;
}
interface GridDescriptionProps {
  label?: string;
  children?: ReactNode;
}
const DetailPageModal = ({
  id,
  onCloseModal,
  open,
}: DetailConfigPermissionProps) => {
  const { data, isLoading } = useQuery<any>(['get-detail-page', id], () =>
    ModuleApi.getDetail(id),
  );

  const GridDescription = ({ label, children }: GridDescriptionProps) => {
    return (
      <Description
        label={label}
        className="grid-cols-4 gap-5"
        childrenClassName="col-span-3"
      >
        {children}
      </Description>
    );
  };
  return (
    <Modal
      centered
      title="Chi tiết module"
      okText="Đóng"
      className="sm:w-3/5 lg:w-1/2 "
      open={open}
      onOk={onCloseModal}
      onCancel={onCloseModal}
      cancelButtonProps={{ className: 'hidden' }}
      closeIcon={false}
      destroyOnClose
    >
      <Spin spinning={isLoading && !!id}>
        <GridDescription label="Mã quyền">
          {data?.code?.toUpperCase()}
        </GridDescription>
        <GridDescription label="Tiêu đề">{data?.title}</GridDescription>
        <GridDescription label="Mô tả ngắn">{data?.sub_title}</GridDescription>
        <GridDescription label="Đường dẫn">{data?.url}</GridDescription>
        <GridDescription label="Icon">
          <Icon type={data?.icon} />
        </GridDescription>
        <GridDescription label="Mô tả">{data?.description}</GridDescription>
        <GridDescription label="Người tạo">
          {data?.created_by &&
            `${data?.created_by?.code} - ${data?.created_by?.full_name}`}
        </GridDescription>
        <GridDescription label="Ngày tạo">
          {data?.created_at &&
            moment(data?.created_at).format('DD/MM/YYYY HH:mm:ss')}
        </GridDescription>
        <GridDescription label="Ngày cập nhật">
          {data?.updated_at &&
            moment(data?.updated_at).format('DD/MM/YYYY HH:mm:ss')}
        </GridDescription>
      </Spin>
    </Modal>
  );
};

export default DetailPageModal;
