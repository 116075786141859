import { adminEndpoint } from '_common/constants/common';
import api from 'states/drivers';

const StatusTypeEndpoint = `${adminEndpoint}/administrator/object-status`;

const StatusTypeApi = {
  getList: async (param?) =>
    await api
      .get(`${StatusTypeEndpoint}/list`, param)
      .then((result) => result?.data?.data),
};

export default StatusTypeApi;
