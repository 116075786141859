export const folderUpload = {
  HEALTH_STATUS: 'health-status',
  DEGREE: 'degree',
  CERTIFICATION: 'certification',
  EMPLOYEE_PROFILE: 'user-profile',
  CONTRACT: 'contract',
  RECRUITMENT_POSITION: 'recruitment-position',
  RECRUITMENT_CANDIDATE: 'recruitment-candidate',
  OVER_TIME_SHEET: 'over_time_sheet',
  ATTENDANCE_TIME_SHEET: 'attendance_time_sheet',
  OVERTIME_APPLICATION: 'overtime_application',
  LEAVE_APPLICATION: 'leave_application',
  BUSINESS_TRIP_APPLICATION: 'business_trip_application',
  EXTRA_ATTENDANCE_APPLICATION: 'extra_attendance_application',
  OBJECT_TYPE: 'object_type',
};
