import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { messageWarning } from '_common/constants/message';
import CheckBox from '_common/dof/Control/CheckBox';
import Input from '_common/dof/Control/Input';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectServicePackage from '_common/dof/Select/SelectServicePackage';
import SelectServicePackageProduct from '_common/dof/Select/SelectServicePackageProduct';
import useConfirm from '_common/hooks/useConfirm';
import ServicePackageApi from 'pages/ServicePackage/_api';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, notification } from 'tera-dls';

interface IProps {
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  value: number;
}

const ServiceRenew = (props: IProps) => {
  const { open, onClose, value: id } = props;
  const confirm = useConfirm();
  const form = useForm({});
  const queryClient = useQueryClient();

  const { data: detail } = useQuery(
    ['get-service-package-detail', id],
    () => ServicePackageApi.getDetailOfBusiness({ id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
      refetchOnMount: 'always',
    },
  );

  const { mutate: mutateAction } = useMutation(
    (variables: any) => ServicePackageApi.reNew({ ...variables }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-service-package-business-list']);
          onClose();
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleCloseConfirm = async () => {
    if (form.formState.isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
    } else onClose();
  };

  useEffect(() => {
    form.setValue('invoice', true, { shouldDirty: false });
  }, []);

  useEffect(() => {
    if (detail) {
      form.setValue('service_id', detail?.service?.id, { shouldDirty: false });
      form.setValue('package_id', detail?.package?.id, { shouldDirty: false });
      form.setValue('time', `${detail?.package?.time ?? 0 + ' '} tháng`, {
        shouldDirty: false,
      });
    }
  }, [detail]);

  const handleSubmitForm = () => {
    mutateAction({
      params: {
        current_id: id,
      },
    });
  };

  return (
    <Modal
      title={'Gia hạn gói dịch vụ'}
      destroyOnClose
      closeIcon={false}
      width={600}
      cancelText="Hủy"
      okText="Lưu"
      onOk={form.handleSubmit(handleSubmitForm)}
      onCancel={handleCloseConfirm}
      open={open}
      centered={true}
    >
      <FormTera form={form} onSubmit={handleSubmitForm}>
        <FormTeraItem
          name={'service_id'}
          label="Gói dịch vụ"
          rules={[{ required: 'Vui lòng chọn' }]}
        >
          <SelectServicePackage
            paramsApi={{
              include_id: form.watch('service_id'),
              type: 'official',
            }}
            disabled
          />
        </FormTeraItem>
        <FormTeraItem
          name={'package_id'}
          label="Gói sản phẩm"
          rules={[{ required: 'Vui lòng chọn' }]}
        >
          <SelectServicePackageProduct
            paramsApi={{
              include_id: form.watch('upgrade_id'),
              service_id: form.watch('service_id'),
            }}
            disabled
          />
        </FormTeraItem>
        <FormTeraItem
          name={'time'}
          label="Thời hạn gia hạn"
          rules={[{ required: 'Vui lòng chọn' }]}
        >
          <Input disabled />
        </FormTeraItem>
        <FormTeraItem name={'invoice'} label="">
          <CheckBox labelClassName="font-normal text-xxs">
            Xuất hóa đơn
          </CheckBox>
        </FormTeraItem>
      </FormTera>
    </Modal>
  );
};

export default ServiceRenew;
