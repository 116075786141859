import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import ModalImport from '_common/component/ModalImport';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { BUTTON_KEY } from '_common/constants/permission';
import { CONFIG_PERMISSION_URL } from '_common/constants/url';
import TableTera from '_common/dof/TableTera';
import useConfirm from '_common/hooks/useConfirm';
import { usePermission } from '_common/hooks/usePermission';
import { ResponseGetApi } from '_common/interface/api';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Badge,
  ColumnsType,
  DropdownItem,
  PaginationProps,
  Tabs,
  Tag,
  downloadFile,
  getQueryParams,
  notification,
  updateQueryParams,
  useDetectDevice,
} from 'tera-dls';
import { TableRowSelection } from 'tera-dls/lib/components/Table/interface';
import ConfigPermissionApi from './_api';
import { ROLE_TAB, TYPE_ROLE } from './constants';
import CopyConfigPermission from './containers/Copy';
import DetailConfigPermission from './containers/Detail';
import ConfigPermissionFilter from './containers/Filter';
import FormConfigPermission from './containers/Form';
import GroupList from './containers/GroupList';
import Searching from './containers/Searching';
import { ListRole, RequestImport } from './interfaces';

const ConfigPermission = () => {
  const { hasPage } = usePermission();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const { isTablet } = useDetectDevice();
  const [openForm, setOpenForm] = useState(false);
  const [openCopy, setOpenCopy] = useState(false);
  const [openGroupList, setOpenGroupList] = useState(false);
  const [openImport, setOpenImport] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [idPermission, setIdPermission] = useState<number>(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const params = {
    keyword: queryParams?.keyword,
    ...(queryParams?.type !== 'all' && { type: queryParams?.type }),
    ...(queryParams?.business_id && {
      business_id: queryParams?.business_id,
    }),
    ...(queryParams?.is_default && {
      is_default: queryParams?.is_default,
    }),
    limit: queryParams?.limit ?? 10,
    page: queryParams?.page ?? 1,
  };

  const { data, refetch, isFetching } = useQuery<ResponseGetApi<ListRole[]>>(
    ['get-config-permission', params],
    () =>
      ConfigPermissionApi.getList({
        ...params,
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { data: summary, refetch: refetchSummary } = useQuery(
    ['get-config-permission-summary', params],
    () =>
      ConfigPermissionApi.getSummary({
        ...params,
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    refetchSummary();
    refetch();
  }, []);

  const { mutate: deletePermission } = useMutation(
    (id: string | number) => ConfigPermissionApi.delete(id),
    {
      onSuccess(res) {
        if (res?.code === 200) {
          notification.success({ message: res?.msg });
          refetch();
          refetchSummary();
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const confirm = useConfirm();
  const buttonKey = {
    create: BUTTON_KEY.CONFIG_PERMISSION_LIST_CREATE,
    copyPermission: BUTTON_KEY.CONFIG_PERMISSION_LIST_COPY,
    settingPermission: BUTTON_KEY.CONFIG_PERMISSION_LIST_SETTING,
    update: BUTTON_KEY.CONFIG_PERMISSION_LIST_UPDATE,
    delete: BUTTON_KEY.CONFIG_PERMISSION_LIST_DELETE,
    detail: BUTTON_KEY.CONFIG_PERMISSION_LIST_DETAIL,
  };

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    handleUpdateQueryParams({ page: page, limit: pageSize });
  };

  const onDelete = (id: number, title: string) => {
    confirm.warning({
      title: 'Xác nhận xoá quyền',
      content: (
        <>
          <p>Bạn có chắc chắn muốn xóa quyền</p>
          <p>
            <span className="font-bold">{title}</span> này không?
          </p>
        </>
      ),
      onOk: () => {
        deletePermission(id);
      },
    });
  };

  const onCloseImport = () => {
    setOpenImport(false);
  };

  const { mutate: mutateImport } = useMutation(
    (variables: RequestImport) => {
      return ConfigPermissionApi.import(variables);
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          onCloseImport();
          queryClient.invalidateQueries(['get-config-permission']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) => {
        confirm.error({
          title: 'LỖI IMPORT DỮ LIỆU',
          content: (
            <>
              <p>Tổng số lỗi: {error?.data?.errors?.length}</p>
              <ul className="list-disc">
                {error?.data?.errors?.map((msgErr) => (
                  <li>{msgErr}</li>
                ))}
              </ul>
            </>
          ),
          cancelButtonProps: {
            className: 'hidden',
          },
          cancelText: 'Đóng',
          align: 'left',
        });
      },
    },
  );

  const { mutate: mutateExport } = useMutation(
    () => {
      return ConfigPermissionApi.export(selectedRowKeys);
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          const date = moment().format('DDMMYYYY_HHmmss');
          downloadFile(res?.data?.src, `export_permission_${date}`);
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleImport = (values) => {
    mutateImport({
      overwrite: values.overwrite,
      file: values?.file,
    });
  };
  const onEdit = (id: number) => {
    setOpenForm(true);
    setIdPermission(id);
  };
  const onCopy = (id: number) => {
    setOpenCopy(true);
    setIdPermission(id);
  };
  const onDetail = (id: number) => {
    setOpenDetail(true);
    setIdPermission(id);
  };
  const onCloseDetail = () => {
    setIdPermission(null);
    setOpenDetail(false);
  };
  const onCloseCopy = () => {
    setIdPermission(null);
    setOpenCopy(false);
  };
  const onCloseForm = () => {
    setOpenForm(false);
    setIdPermission(null);
  };
  const onCloseGroupList = () => {
    setOpenGroupList(false);
  };

  const dropdownItemsAction = (record): DropdownItem[] => {
    const disableUpdate = Boolean(record?.is_default) && !record?.business_id;

    const dropdownItems: DropdownItem[] = [
      ...(hasPage(buttonKey.detail)
        ? [
            {
              key: 1,
              label: <a onClick={() => onDetail(record.id)}>Xem</a>,
            },
          ]
        : []),
      ...(hasPage(buttonKey.update) && !disableUpdate
        ? [
            {
              key: 2,
              label: 'Sửa',
              onClick: () => onEdit(record.id),
            },
          ]
        : []),

      ...(hasPage(buttonKey.settingPermission)
        ? [
            {
              key: 3,
              label: 'Cấu hình quyền',
              onClick: () =>
                navigate(CONFIG_PERMISSION_URL.setting.path + '/' + record.id, {
                  state: { titlePermission: record.title, type: record.type },
                }),
            },
          ]
        : []),
      ...(hasPage(buttonKey.copyPermission)
        ? [
            {
              key: 4,
              label: <a onClick={() => onCopy(record.id)}>Sao chép quyền</a>,
            },
          ]
        : []),
      ...(hasPage(buttonKey.delete) && !disableUpdate
        ? [
            {
              key: 5,
              label: <span className="text-red-500">Xoá</span>,
              onClick: () => onDelete(record.id, record.title),
            },
          ]
        : []),
    ];
    return dropdownItems;
  };

  const columns: ColumnsType<ListRole> = [
    {
      title: 'Role ID',
      dataIndex: 'id',
      width: 100,
    },
    {
      title: 'Mã quyền',
      dataIndex: 'code',
      width: 100,
    },
    {
      title: 'Tiêu đề',
      dataIndex: 'title',
      width: 200,
      render: (val) => <span className="line-clamp-2">{val}</span>,
    },
    {
      title: 'Loại quyền',
      dataIndex: 'type',
      width: 100,
      render: (val) => (val ? TYPE_ROLE[val] : ''),
    },

    {
      title: 'Mặc định',
      dataIndex: 'is_default',
      width: 80,
      align: 'center',
      render: (value) =>
        Boolean(value) ? (
          <Tag color="green05">Có</Tag>
        ) : (
          <Tag color="gray01">Không</Tag>
        ),
    },
    {
      title: 'Business ID',
      dataIndex: 'business_id',
      width: 100,
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      width: 200,
      render: (note: string) => {
        return <span className="line-clamp-3">{note}</span>;
      },
    },
    {
      title: '',
      width: 50,
      fixed: 'right',
      render: (record: ListRole) => (
        <ActionDropdown
          dropdownItems={dropdownItemsAction(record)}
          trigger="click"
          buttonDetailKey={buttonKey.detail}
          containerClassName="w-[50px]"
          placement="bottom-end"
        />
      ),
    },
  ];

  const dropdownItemsConfig: DropdownItem[] = [
    {
      key: 'export',
      label: 'Export dữ liệu excel',
      onClick: () => mutateExport(),
    },
    {
      key: 'import',
      label: 'Import dữ liệu excel',
      onClick: () => setOpenImport(true),
    },
    {
      key: 'group-list',
      label: 'Danh sách nhóm',
      onClick: () => setOpenGroupList(true),
    },
  ];

  const dropdownMore: DropdownItem[] = [
    {
      key: 'delete',
      label: <p className="text-[red]">Xoá</p>,
      onClick: () => setOpenImport(true),
    },
  ];

  const rowSelections: TableRowSelection<ListRole> = {
    selectedRowKeys,
    onChange: (rowKeys) => setSelectedRowKeys(rowKeys),
  };

  const handleChangeTabs = (key) => {
    handleUpdateQueryParams({
      page: 1,
      type: key,
    });
  };

  const handleSearch = ({ keyword }) => {
    handleUpdateQueryParams({ keyword, page: 1 });
  };

  const tabItems = Object.entries(ROLE_TAB).map(([key, value]) => {
    const item = summary?.find((i) => i?.type === key);
    return {
      key: key,
      label: (
        <h3 className="tab-table">
          {value}
          <Badge showZero count={item?.total_count ?? 0} />
        </h3>
      ),
    };
  });

  const handleFilter = (values: any): void => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  return (
    <div className="tera-page">
      <HeaderViewListV2
        title="Danh sách quyền"
        onClickButtonAdd={() => setOpenForm(true)}
        buttonCreatingKey={buttonKey.create}
        onClickFilter={() => setOpenFilterModal(true)}
        dropdownItems={dropdownItemsConfig}
        dropdownMoreItems={isTablet ? dropdownMore : undefined}
        selectedNumber={selectedRowKeys?.length}
        actionLeftRender={<Searching onSearch={handleSearch} />}
        bottomContent={
          <Tabs
            onChange={handleChangeTabs}
            defaultActiveKey="all"
            items={tabItems}
          />
        }
      >
        <div className="bg-white shadow-xsm rounded-2xl">
          <TableTera
            rowKey={'id'}
            columns={columns}
            data={data?.data}
            loading={isFetching}
            rowSelection={rowSelections}
            pagination={{
              onChange: handleChangePage,
              total: data?.total || 0,
              current: data?.current_page,
              pageSize: data?.per_page,
              to: data?.to,
              from: data?.from,
            }}
          />
        </div>
      </HeaderViewListV2>
      {openForm && (
        <FormConfigPermission
          open={openForm}
          onClose={onCloseForm}
          id={idPermission}
        />
      )}
      {openDetail && (
        <DetailConfigPermission
          open={openDetail}
          onCloseModal={onCloseDetail}
          id={idPermission}
        />
      )}
      {openCopy && (
        <CopyConfigPermission
          open={openCopy}
          onClose={onCloseCopy}
          id={idPermission}
        />
      )}
      {openImport && (
        <ModalImport
          open={openImport}
          onCancel={onCloseImport}
          onOk={handleImport}
        />
      )}
      {openGroupList && (
        <GroupList open={openGroupList} onClose={onCloseGroupList} />
      )}
      {openFilterModal && (
        <ConfigPermissionFilter
          open={openFilterModal}
          onClose={() => setOpenFilterModal(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
    </div>
  );
};

export default ConfigPermission;
