import { useEffect, useState } from 'react';
import { Button, FunnelOutlined, Modal } from 'tera-dls';
import Searching from './Searching';
import EmployeeFilter from './EmployeeFilter';
import EmployeeTable from './EmployeeTable';
import { useQuery } from '@tanstack/react-query';
import PermissionGroupApi from '../../_api';

interface MemberListProps {
  id: string | number;
  open: boolean;
  onCancel: () => void;
  businessId: number;
}

function MemberList({ id, open, onCancel, businessId }: MemberListProps) {
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);
  const [params, setParams] = useState({});

  const { data: response, refetch } = useQuery(
    ['get-employee-list', params],
    () => {
      return PermissionGroupApi.getMembers({
        params: {
          page: 1,
          limit: 10,
          business_id: businessId,
          role_id: id,
          ...params,
        },
      });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );
  console.log(response);

  useEffect(() => {
    id && refetch();
  }, [id]);

  const handleSearch = ({ keyword }) => {
    setParams({ ...params, keyword, page: 1 });
  };

  const handleFilter = (values) => {
    setParams({ ...params, ...values, page: 1 });
  };

  id;
  return (
    <Modal
      title="Danh sách thành viên"
      open={open}
      width="80%"
      okText="Đóng"
      onCancel={onCancel}
      onOk={onCancel}
      cancelButtonProps={{ className: 'hidden' }}
      destroyOnClose
      bodyClassName="min-h-[80vh] overflow-auto"
    >
      <div className="flex flex-col gap-y-2.5">
        <div className="flex gap-x-2.5 items-center justify-end">
          <Searching onSearch={handleSearch} />
          <Button
            type="alternative"
            className="rounded-xsm p-2"
            icon={<FunnelOutlined className="w-5 h-5 text-gray-400 shrink-0" />}
            onClick={() => setOpenFilterModal(true)}
          />
        </div>
        <EmployeeTable data={response?.data} />
      </div>
      {openFilterModal && (
        <EmployeeFilter
          open={openFilterModal}
          onClose={() => setOpenFilterModal(false)}
          onFilter={handleFilter}
          initialValue={params}
        />
      )}
    </Modal>
  );
}

export default MemberList;
