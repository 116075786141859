import { useMutation, useQuery } from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import PaginationCustom from '_common/component/PaginationCustom';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { BUTTON_KEY } from '_common/constants/permission';
import useConfirm from '_common/hooks/useConfirm';
import { usePermission } from '_common/hooks/usePermission';
import { ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  DropdownItem,
  PaginationProps,
  Table,
  downloadFile,
  getQueryParams,
  notification,
  updateQueryParams,
  useDetectDevice,
} from 'tera-dls';
import GroupControlApi from './_api';
import GroupControlFilter from './containers/Filter';
import GroupControlForm from './containers/Form';
import GroupControlHeader from './containers/Header';
import { IFilter, IFormModel } from './interfaces';
import DetailPageModal from './containers/Detail';
import { messageError } from '_common/constants/message';
import ModalImport from '_common/component/ModalImport';
import moment from 'moment';

const GroupControlPage = () => {
  const { hasPage } = usePermission();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const { isMobile } = useDetectDevice();

  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [formModel, setFormModel] = useState<IFormModel>({ open: false });
  const [detailModal, setDetailModal] = useState<IFormModel>({ open: false });
  const [isOpenImport, setIsOpenImport] = useState<boolean>(false);

  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const {
    data: listPage,
    refetch,
    isLoading,
  } = useQuery(
    ['get-list-group-control', queryParams],
    () => GroupControlApi.getList({ ...queryParams }),
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
  }, []);

  const { mutate: deletePage, isLoading: loadingDelete } = useMutation(
    (id: string | number) => GroupControlApi.delete(id),
    {
      onSuccess(res) {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          refetch();
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleQueryParams = (query: IFilter) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...query,
    });
    navigate(location.pathname + paramString);
  };

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    handleQueryParams({
      page: page,
      limit: pageSize,
    });
  };

  const handleFilter = (values: any) => {
    handleQueryParams({
      page: 1,
      ...values,
    });
  };

  const handleDeletePage = (id: string | number, name: string) => {
    confirm.warning({
      title: 'XÁC NHẬN XÓA NHÓM CONTROL',
      content: (
        <>
          <p>Bạn có chắc chắn muốn xóa nhóm control</p>
          <p>{name} này không?</p>
        </>
      ),
      onOk: () => {
        deletePage(id);
      },
    });
  };

  const renderDropdown = (record): DropdownItem[] => {
    const dropdownItems: DropdownItem[] = [];
    hasPage(BUTTON_KEY.GROUP_CONTROL_UPDATE) &&
      dropdownItems.push({
        key: 6,
        label: 'Sửa',
        onClick: () => setFormModel({ open: true, id: record?.id }),
      });
    hasPage(BUTTON_KEY.GROUP_CONTROL_DELETE) &&
      dropdownItems.push({
        key: 7,
        label: <span className="text-red-500">Xóa</span>,
        onClick: () => handleDeletePage(record?.id, record?.name),
      });
    return dropdownItems;
  };

  const { mutate: importExcel } = useMutation(
    (params: any) => {
      return GroupControlApi.import(params);
    },
    {
      onSuccess(res) {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          refetch();
          setIsOpenImport(false);
        }
      },
      onError(error: any) {
        const errorMessage = error?.data?.errors || [messageError.ERROR_API];
        const flattenMessage = Object.values(errorMessage).flat();
        notification.error({
          message: (
            <ul>
              {flattenMessage?.map((message: ReactNode) => (
                <li>{message}</li>
              ))}
            </ul>
          ),
        });
      },
    },
  );

  const { mutate: exportExcel } = useMutation(
    () => {
      const data = {
        keyword: queryParams?.keyword || '',
        epic_id: queryParams?.epic_id ? Number(queryParams?.epic_id) : null,
        module: queryParams?.module ? Number(queryParams?.module) : null,
        selected: [],
      };
      return GroupControlApi.export(data);
    },
    {
      onSuccess(res) {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          const date = moment().format('DDMMYYYY_HHmmss');
          downloadFile(res?.data?.src, `export_data-type_${date}`);
        }
      },
      onError(error: any) {
        const errorMessage = error?.data?.errors || [messageError.ERROR_API];
        const flattenMessage = Object.values(errorMessage).flat();
        notification.error({
          message: (
            <ul>
              {flattenMessage?.map((message: ReactNode) => (
                <li>{message}</li>
              ))}
            </ul>
          ),
        });
      },
    },
  );

  const handleImport = (data) => {
    importExcel(data);
  };

  const columns: any = [
    {
      title: 'STT',
      dataIndex: 'id',
      width: 60,
      align: 'center',
    },
    {
      title: 'Mã nhóm',
      dataIndex: 'concatenated_code',
      width: 200,
      render: (text) => <div className="line-clamp-2">{text}</div>,
    },
    {
      title: 'Tên nhóm',
      dataIndex: 'title',
      width: 200,
      render: (text) => <div className="line-clamp-2">{text}</div>,
    },
    {
      title: 'Modue',
      dataIndex: 'module',
      width: 200,
      render: (module) => <div className="line-clamp-2">{module?.title}</div>,
    },
    {
      title: 'Epic',
      dataIndex: 'epic',
      width: 200,
      render: (epic) => <div className="line-clamp-2">{epic?.name}</div>,
    },
    {
      dataIndex: '',
      width: 88,
      fixed: 'right',
      render: (_, record) => {
        const dropdownItems = renderDropdown(record);
        return (
          <ActionDropdown
            onClickDetail={() => setDetailModal({ open: true, id: record?.id })}
            dropdownItems={dropdownItems}
            trigger="click"
            buttonDetailKey={BUTTON_KEY.GROUP_CONTROL_DETAIL}
          />
        );
      },
    },
  ];

  const itemsHeader: DropdownItem[] = [
    {
      key: 1,
      label: 'Export file excel',
      onClick: () => exportExcel(),
    },
    {
      key: 2,
      label: 'Import file excel',
      onClick: () => setIsOpenImport(true),
    },
  ];

  return (
    <>
      <HeaderViewList
        title="DANH SÁCH NHÓM CONTROL"
        onClickButtonAdd={() => setFormModel({ open: true })}
        onClickFilter={() => setIsFilter(true)}
        buttonCreatingKey={BUTTON_KEY.GROUP_CONTROL_CREATE}
        dropdownConfig={itemsHeader}
      >
        <GroupControlHeader />
      </HeaderViewList>
      <div className="bg-white shadow-xsm rounded-2xl">
        <Table
          columns={columns}
          data={listPage?.data || []}
          rowKey={(record: any) => record?.id}
          loading={isLoading || loadingDelete}
          hiddenColumns={
            isMobile
              ? [
                  'title',
                  'parent_menu',
                  'path',
                  'icon',
                  'business_id',
                  'status',
                ]
              : []
          }
        />
        {listPage?.total > 0 && (
          <PaginationCustom
            onChange={handleChangePage}
            total={listPage?.total || 0}
            current={listPage?.current_page || 1}
            pageSize={listPage?.per_page}
            to={listPage?.to}
            from={listPage?.from}
          />
        )}
      </div>
      {isFilter && (
        <GroupControlFilter
          open={isFilter}
          onClose={() => setIsFilter(false)}
          initialValue={queryParams}
          onFilter={handleFilter}
        />
      )}
      {formModel.open && (
        <GroupControlForm
          open={formModel.open}
          id={formModel?.id}
          onClose={() => {
            setFormModel({ open: false });
          }}
          onChangeDataSuccess={() => {
            refetch();
          }}
        />
      )}
      {detailModal.open && (
        <DetailPageModal
          open={detailModal.open}
          onCloseModal={() => setDetailModal({ open: false })}
          id={detailModal.id}
        />
      )}
      {isOpenImport && (
        <ModalImport
          open={isOpenImport}
          onCancel={() => setIsOpenImport(false)}
          title="IMPORT DỮ LIỆU"
          onOk={handleImport}
        />
      )}
    </>
  );
};

export default GroupControlPage;
