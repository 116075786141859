import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  messageError,
  messageValidate,
  messageWarning,
} from '_common/constants/message';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectControlConfig from '_common/dof/Select/SelectControlConfig';
import SelectPageConfig from '_common/dof/Select/SelectPageConfig';
import useConfirm from '_common/hooks/useConfirm';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, OptionProps, notification } from 'tera-dls';
import ControlConfigApi from '../../_api';
import { FormCopyControl, RequestCopyControl } from '../../interfaces';
// const schema = yup
//   .object()
//   .shape({
//     page_target_id: yup.number().required(messageValidate.emptySelect),
//     control_selected: yup.array().min(1, messageValidate.emptySelect),
//   })
//   .required();
interface CopyControlConfigProps {
  open: boolean;
  onClose: () => void;
  pageId: number;
}
const CopyControlConfig = ({
  onClose,
  open,
  pageId,
}: CopyControlConfigProps) => {
  const confirm = useConfirm();
  const form = useForm<FormCopyControl>({
    defaultValues: {
      page_target_id: null,
      control_selected: [],
    },
  });
  const {
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = form;
  const [selectedControl, setSelectedControl] = useState<OptionProps[]>([]);
  const queryClient = useQueryClient();

  const handleClose = () => {
    onClose();
    reset();
  };

  const { mutate: mutateCopyControl } = useMutation(
    (variables: RequestCopyControl) => {
      return ControlConfigApi.copy(variables);
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-list-control-config']);
          handleClose();
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) => {
        const errorMessage = error?.data?.msg || messageError.ERROR_API;
        notification.error({ message: errorMessage });
      },
    },
  );

  const onCloseModal = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          handleClose();
        },
      });
    } else handleClose();
  };

  const handleSubmitForm = (values: FormCopyControl) => {
    mutateCopyControl({
      ...values,
      control_selected: values.control_selected.includes('all')
        ? 'all'
        : values.control_selected,
      page_id: pageId,
    });
  };

  return (
    <Modal
      title="Sao chép control"
      okText="Lưu"
      cancelText="Hủy"
      width="20vw"
      open={open}
      onCancel={onCloseModal}
      onOk={() => handleSubmit(handleSubmitForm)()}
      closeIcon={false}
    >
      <FormTera form={form}>
        <FormTeraItem
          label="Chọn trang nguồn"
          name="page_target_id"
          rules={[{ required: messageValidate.emptySelect }]}
        >
          <SelectPageConfig
            onChangeCustom={() => setSelectedControl([])}
            paramsApi={{ page_id: pageId }}
          />
        </FormTeraItem>
        <FormTeraItem
          label="Chọn cấu hình control"
          name="control_selected"
          rules={[{ min: { message: messageValidate.emptySelect, value: 1 } }]}
        >
          <SelectControlConfig
            isCheckAll
            mode="multiple"
            selectedValue={selectedControl}
            disabled={!watch('page_target_id')}
            onClear={() => setSelectedControl([])}
            onChangeCustom={(value: OptionProps[]) => {
              setSelectedControl(value);
            }}
            paramsApi={{ page_id: watch('page_target_id') }}
          />
        </FormTeraItem>
      </FormTera>
    </Modal>
  );
};

export default CopyControlConfig;
