import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { messageError } from '_common/constants/message';
import { BUSINESS_URL } from '_common/constants/url';
import useConfirm from '_common/hooks/useConfirm';
import BusinessApi from 'pages/Business/_api';
import { BUSINESS_DETAIL_TABS } from 'pages/Business/constants';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftSolid,
  Badge,
  Breadcrumb,
  Button,
  ItemType,
  Spin,
  Tabs,
  notification,
  updateQueryParams,
} from 'tera-dls';
import ButtonAction from './ButtonAction';
import Content from './Content';
import Permission from './Permission';
import Service from './Service';
import User from './User';
import PermissionGroup from './PermissionGroup';

function BusinessDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const confirm = useConfirm();
  const { id } = useParams();
  const [selected, setSelected] = useState<string>('information');

  const queryClient = useQueryClient();

  const {
    data: dataDetail,
    isLoading,
    isError,
    refetch,
  } = useQuery(['get-business-detail', id], () => BusinessApi.getDetail(id), {
    enabled: !!id,
    cacheTime: 300000,
    staleTime: 300000,
  });

  const { data: summary, refetch: fetchSummary } = useQuery(
    ['get-business-summary', id],
    () => BusinessApi.getSummaryDetail({ business_id: id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { mutate: deleteBusiness, isLoading: loadingDelete } = useMutation(
    (id: number) => BusinessApi.delete(id),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          navigate(BUSINESS_URL.list.path);
          queryClient.invalidateQueries(['get-business-list']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleUpdate = () => {
    const paramString = updateQueryParams({
      redirect_url: location.pathname,
    });

    navigate(`${BUSINESS_URL.update.path}/${id}${paramString}`);
  };

  const handleChangeTabs = (key) => {
    setSelected(key);
  };

  const tabItems = Object.entries(BUSINESS_DETAIL_TABS).map(([key, value]) => {
    const item = summary?.find((i) => i?.key === key);
    return {
      key: key,
      label: (
        <h3 className="tab-table">
          {value}
          {!['information', 'permission'].includes(key) && (
            <Badge showZero count={item?.count ?? 0} />
          )}
        </h3>
      ),
    };
  });

  const renderTab = (key) => {
    const tabs = {
      information: <Content dataDetail={dataDetail} />,
      services: <Service id={dataDetail?.id} />,
      permission: <Permission businessId={dataDetail?.id} />,
      users: <User businessId={dataDetail?.id} />,
      group_role: <PermissionGroup businessId={dataDetail?.id} />,
    };
    return tabs[key] || <></>;
  };

  const handleDelete = () => {
    confirm.warning({
      title: 'Xác nhận xóa doanh nghiệp',
      content: (
        <>
          <p>Bạn có chắc muốn xóa doanh nghiệp</p>
          <p>
            <b className="break-word">{dataDetail?.name}</b> này không?
          </p>
        </>
      ),
      onOk: () => {
        deleteBusiness(dataDetail?.id);
      },
    });
  };

  const BreadcrumbItem: ItemType[] = [
    {
      title: (
        <a
          className="cursor-pointer text-blue-500"
          onClick={() => navigate(BUSINESS_URL.list.path)}
        >
          Danh sách doanh nghiệp
        </a>
      ),
    },
    {
      title: 'Chi tiết doanh nghiệp',
    },
  ];

  useEffect(() => {
    if (id) {
      refetch();
      fetchSummary();
    }
  }, [id]);

  if (isError) {
    notification.error({ message: messageError.DATA_NOT_FOUND });
    return <NoData />;
  }

  return (
    <div className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <div
              className="page-header__breadcrumb-back cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <ArrowSmallLeftSolid className="h-6 w-6" />
            </div>
            <Breadcrumb separator={'>'} items={BreadcrumbItem} />
          </div>
          <div className="page-header-v2__function">
            <ButtonAction status={dataDetail?.status} dataDetail={dataDetail} />
            <Button
              type="danger"
              className="page-header-btn"
              onClick={handleDelete}
            >
              Xóa
            </Button>
            <Button className="page-header-btn" onClick={handleUpdate}>
              Sửa
            </Button>
          </div>
        </div>
      </div>
      <div className="page-content-v2">
        <Spin spinning={isLoading || loadingDelete}>
          <div className="page-content-v2_main">
            <Tabs
              onChange={handleChangeTabs}
              items={tabItems}
              activeKey={selected}
            />
            {renderTab(selected)}
          </div>
        </Spin>
      </div>
    </div>
  );
}

export default BusinessDetail;
