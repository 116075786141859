import Filter from '_common/component/Filter';
import { DATE_BACKEND_FORMAT, DATE_FORMAT } from '_common/constants/common';
import DatePicker from '_common/dof/Control/DatePicker';
import InputNumber from '_common/dof/Control/InputNumber';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectUser from '_common/dof/Select/SelectUser';
import moment from 'moment';
import {
  EXPENSE_VOUCHER_TYPE,
  PAYMENT_METHOD_TYPE,
} from 'pages/Invoice/constants';

import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface IProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue?: any;
}

function ExpenseVoucherFilter({
  open,
  onClose,
  onFilter,
  initialValue,
}: IProps) {
  const newForm = useForm({
    mode: 'onChange',
  });
  const ballot_type = newForm.watch('ballot_type');

  const submitter_id = newForm.watch('submitter_id');

  const handleSubmitForm = (values: any) => {
    const data = {
      ...values,
      date: values.date ? moment(values.date).format(DATE_BACKEND_FORMAT) : '',
    };

    onFilter(data);
    onClose();
  };
  useEffect(() => {
    initialValue &&
      newForm.reset({
        ...initialValue,
        ...(initialValue.submitter_id && {
          submitter_id: Number(initialValue.submitter_id),
        }),
        ...(initialValue.date && {
          date: moment(initialValue.date),
        }),
      });
  }, [initialValue]);

  const handleReset = () => {
    const values: any = {
      ballot_type: null,
      submitter_id: null,
      pay_method_type: null,
      date: null,
      from_total_amount: null,
      to_total_amount: null,
    };
    onFilter(values);
    onClose();
  };

  const typeOptions = Object.entries(EXPENSE_VOUCHER_TYPE).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  const paymentMethod = Object.entries(PAYMENT_METHOD_TYPE).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  const from = newForm?.watch('from_total_amount');
  const to = newForm?.watch('to_total_amount');

  useEffect(() => {
    if (from) {
      newForm?.trigger('from_total_amount');
    }
    if (to) {
      newForm?.trigger('to_total_amount');
    }
  }, [from, to, newForm]);

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      containerClassName={'w-[400px]'}
      onFilter={() => newForm?.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={newForm} onSubmit={handleSubmitForm} isLoading={false}>
        <FormTeraItem label="Loại phiếu chi" name="ballot_type">
          <Select
            placeholder="Vui lòng chọn"
            options={typeOptions}
            allowClear
            onChangeCustom={() => newForm.setValue('submitter_id', null)}
          />
        </FormTeraItem>
        <FormTeraItem label="Người nộp" name="submitter_id">
          <SelectUser
            paramsApi={{ include_id: submitter_id }}
            allowClear
            disabled={!ballot_type}
          />
        </FormTeraItem>
        <FormTeraItem label="Phương thức thanh toán" name="pay_method_type">
          <Select
            placeholder="Vui lòng chọn"
            options={paymentMethod}
            allowClear
          />
        </FormTeraItem>
        <FormTeraItem label="Ngày phiếu chi" name="date">
          <DatePicker
            placeholder="Vui lòng chọn"
            allowClear
            format={DATE_FORMAT}
          />
        </FormTeraItem>

        <FormTeraItem label="Tổng giá trị" name="">
          <div className="flex gap-2 items-center">
            <FormTeraItem
              label=""
              name="from_total_amount"
              rules={[
                {
                  validate: {
                    validate: (value, values) => {
                      if (
                        values.to_total_amount &&
                        value > values.to_total_amount
                      )
                        return 'Vui lòng nhập số nhỏ hơn';
                    },
                  },
                },
              ]}
            >
              <InputNumber
                placeholder="Vui lòng nhập"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                min={0}
              />
            </FormTeraItem>
            <span className="mb-2.5">-</span>
            <FormTeraItem
              label=""
              name="to_total_amount"
              rules={[
                {
                  validate: {
                    validate: (value, values) => {
                      if (
                        values.from_total_amount &&
                        value < values.from_total_amount
                      )
                        return 'Vui lòng nhập số lớn hơn';
                    },
                  },
                },
              ]}
            >
              <InputNumber
                placeholder="Vui lòng nhập"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                min={0}
              />
            </FormTeraItem>
          </div>
        </FormTeraItem>
        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default ExpenseVoucherFilter;
