export const CONFIG_AFFILIATES_URL = {
  summary_report: {
    key: 'config-affiliates',
    path: '/system/config-affiliates/general',
    shortenUrl: 'general',
  },
  list_referer: {
    key: 'list-referer',
    path: '/system/config-affiliates/list-referer',
    shortenUrl: 'list-referer',
  },
  list_referer_detail: {
    key: 'list-referer-detail',
    path: '/system/config-affiliates/detail',
    shortenUrl: 'detail/:id',
  },
};
