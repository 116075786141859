import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import useConfirm from '_common/hooks/useConfirm';
import InvoiceApi from 'pages/Invoice/_api';
import { INVOICE_TABS } from 'pages/Invoice/constants';
import { INVOICE_URL } from 'pages/Invoice/url';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftSolid,
  Badge,
  Breadcrumb,
  Button,
  notification,
  Spin,
  Tabs,
} from 'tera-dls';
import Transaction from './Transaction';
import CashReceipt from './CashReceipt';
import InvoiceDetailContent from './InvoiceDetailContent';

function InvoiceDetail() {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [params, setParams] = useState<string>('information');

  const handleChangeTabs = (key) => {
    setParams(key);
  };

  const {
    data: detail,
    refetch,
    isLoading,
  } = useQuery(['get-invoice-detail', id], () => InvoiceApi.getDetail({ id }), {
    enabled: !!id,
    staleTime: 300000,
    cacheTime: 300000,
  });

  const { data: summary, refetch: fetchSummary } = useQuery(
    ['get-Invoice-summary', id],
    () => InvoiceApi.getSummaryTab({ params: { bill_id: id } }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (id) {
      fetchSummary();
      refetch();
    }
  }, [id]);

  const { mutate: mutateDelete } = useMutation(
    (variables: any) => InvoiceApi.delete(variables),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          navigate(INVOICE_URL.list.path);
          queryClient.invalidateQueries(['get-invoice-list']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleDelete = (): void => {
    confirm.warning({
      title: 'Xác nhận xóa hóa đơn',
      content: (
        <p className="break-word">
          <p> Bạn có chắc muốn xóa hóa đơn</p>
          <p>
            <span className="font-bold break-word">
              {` ${detail?.code ?? ''} `}{' '}
            </span>
            này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateDelete({ id: detail?.id });
      },
    });
  };

  const tabItems = Object.entries(INVOICE_TABS).map(([key, value]) => {
    const item = summary?.find((i) => i?.key === key);
    return {
      key: key,
      label: (
        <h3 className="tab-table">
          {value}
          {!['information'].includes(key) && (
            <Badge showZero count={item?.total_count ?? 0} />
          )}
        </h3>
      ),
    };
  });

  const renderTab = (key) => {
    const tabs = {
      information: <InvoiceDetailContent loading={isLoading} value={detail} />,
      transaction: <Transaction id={detail?.id} />,
      receipt: <CashReceipt id={detail?.id} />,
    };
    return tabs[key] || <></>;
  };

  const { mutate: mutatePayment } = useMutation(
    (variables: any) => InvoiceApi.payment(variables),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-invoice-list']);
          queryClient.invalidateQueries(['get-invoice-detail']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handlePayment = (id: number, name: string): void => {
    confirm.warning({
      title: 'Xác nhận thanh toán',
      content: (
        <p className="break-word">
          <p> Bạn có chắc muốn thanh toán hóa đơn</p>
          <p>
            <span className="font-bold break-word">{` ${name ?? ''} `} </span>
            này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutatePayment({ params: { bill_id: id } });
      },
    });
  };

  return (
    <Spin spinning={isLoading}>
      <div className="tera-page-form">
        <div className="page-header-sticky">
          <div className="page-header-v2">
            <div className="page-header-v2__breadcrumb">
              <div
                className="page-header-v2__breadcrumb cursor-pointer"
                onClick={() => navigate(-1)}
              >
                <ArrowSmallLeftSolid className="h-6 w-6" />
              </div>
              <Breadcrumb
                separator={'>'}
                items={[
                  {
                    title: (
                      <a onClick={() => navigate(-1)}>
                        <span className="!text-blue-400 hover:!text-blue-600">
                          Danh sách hóa đơn
                        </span>
                      </a>
                    ),
                  },
                  {
                    title: 'Chi tiết hóa đơn',
                  },
                ]}
              />
            </div>
            <div className="page-header-v2__function">
              <Button
                type="danger"
                className="page-header-btn"
                onClick={handleDelete}
              >
                Xóa
              </Button>

              {detail?.status === 'unpaid' &&
                detail.transaction_type === '3' && (
                  <Button
                    className="page-header-btn"
                    onClick={() => handlePayment(detail?.id, detail?.code)}
                  >
                    Xác nhận thanh toán
                  </Button>
                )}
            </div>
          </div>
        </div>
        <div className="page-content-v2_main !p-[16px]">
          <Tabs
            className="[&>*:first-child]:bg-white"
            onChange={handleChangeTabs}
            items={tabItems}
            activeKey={params}
          />
          {renderTab(params)}
        </div>
      </div>
    </Spin>
  );
}

export default InvoiceDetail;
