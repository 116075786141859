import { useQuery } from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import UploadFiles from '_common/component/UploadFiles';
import { BUTTON_KEY } from '_common/constants/permission';
import { chain } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftSolid,
  Breadcrumb,
  Button,
  Dropdown,
  PlusCircleOutlined,
  Spin,
  getQueryParams,
  updateQueryParams,
} from 'tera-dls';
import PortalAPI from './_api';
import File from './containers/File';
import Folder from './containers/Folder';
import Header from './containers/Header';
import Searching from './containers/Searching';
import FileTable from './containers/Table';

export const config = {
  google: {
    clientID: '',
    keyGMap: '',
  },
  fbConfig: {
    appId: '',
    version: 'v1.0',
  },
  hasHeader: false,
  hasMobile: true,
  templates: ['tera'],
  languages: ['vn'],
  app: {},
  uploadKey: '9074c259a7',
  appId: '2',
};

const FileManagement = () => {
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const { id: objectType } = useParams();

  const [keyword, setKeyword] = useState<string>('');
  const [filter, setFilter] = useState<any>({});

  const {
    data: response,
    refetch,
    isLoading,
  } = useQuery(
    ['get-file-management-list', queryParams, objectType, keyword, filter],
    () =>
      PortalAPI.getAll({
        params: {
          limit: 1000,
          keyword,
          ...filter,
          ...(objectType && { object_type: objectType }),
          ...(queryParams ?? {}),
        },
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );
  const list = response?.data;

  const folder = useMemo(
    () =>
      chain(list)
        .groupBy('object_type')
        .map((value, key) => {
          let total = 0;
          value.forEach((element) => {
            total += Number(element?.file_size);
          });
          return { name: key, files: value, size: total };
        })
        .value(),
    [list],
  );

  const navigate = useNavigate();

  const handleUpdateQueryParams = (data = {}) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  useEffect(() => {
    if (objectType) {
      setFilter((prev) => ({ ...prev, displayType: 'list' }));
      setKeyword('');
      refetch();
    } else {
      setFilter((prev) => ({ ...prev, displayType: 'list' }));
      refetch();
    }
  }, [objectType]);

  useEffect(() => {
    queryParams && setFilter((prev) => ({ ...prev, ...queryParams }));
  }, [
    queryParams?.uploaded_by,
    queryParams?.displayType,
    queryParams?.file_type,
  ]);

  useEffect(() => {
    queryParams?.keyword && setKeyword(queryParams.keyword);
  }, [queryParams?.keyword]);

  const handleSearch = ({ keyword }): void => {
    !objectType && handleUpdateQueryParams({ keyword });
    setKeyword(keyword);
  };

  const handleChangeHeader = (value = {}) => {
    !objectType && handleUpdateQueryParams({ ...value });
    setFilter((prev) => ({ ...prev, ...value }));
  };

  const items = [
    {
      key: '1',
      label: 'Thư mục',
      onClick: () => {
        1;
      },
    },
    {
      key: '2',
      label: (
        <>
          <UploadFiles isView={false} fileList={[]}>
            Tệp
          </UploadFiles>
        </>
      ),
    },
  ];

  return (
    <>
      <HeaderViewList
        title={
          objectType ? (
            <div className="flex gap-2">
              <div
                className="page-header__breadcrumb-back cursor-pointer"
                onClick={() => navigate(-1)}
              >
                <ArrowSmallLeftSolid className="h-6 w-6" />
              </div>
              <Breadcrumb
                separator={'>'}
                items={[
                  {
                    title: <a onClick={() => navigate(-1)}>Quản lý tệp</a>,
                  },
                  {
                    title: `Tên tệp`,
                  },
                ]}
              />
            </div>
          ) : (
            'DANH SÁCH FILE'
          )
        }
      >
        <Searching onSearch={handleSearch} value={keyword} />
        {BUTTON_KEY.FILE_MANAGEMENT_LIST_CREATE && (
          <Dropdown
            containerClassName="w-14"
            placement="bottom-end"
            menu={{
              items,
            }}
            trigger="click"
          >
            <Button type="primary" className="rounded-xsm">
              <PlusCircleOutlined className="w-[1rem] h-[1rem]" />
              Thêm mới
            </Button>
          </Dropdown>
        )}
      </HeaderViewList>
      <Spin spinning={isLoading}>
        <div className="bg-white shadow-xsm rounded-2xl ">
          <div className="p-5">
            <Header
              displayType={filter?.displayType}
              userId={filter?.uploaded_by}
              type={filter?.file_type}
              onChange={handleChangeHeader}
            />
            {(filter?.displayType ?? 'list') == 'list' ? (
              <>
                {!objectType && (
                  <div className="mt-5">
                    <Folder value={folder} />
                  </div>
                )}
                {objectType && (
                  <div className="mt-5">
                    <File value={list} />
                  </div>
                )}
              </>
            ) : (
              <div className="mt-5">
                <FileTable value={objectType ? list : folder} />
              </div>
            )}
          </div>
        </div>
      </Spin>
    </>
  );
};

export default FileManagement;
