import { useMutation, useQueryClient } from '@tanstack/react-query';
import { EmployeeText } from '_common/component/EmployeeText';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import useConfirm from '_common/hooks/useConfirm';
import { usePermission } from '_common/hooks/usePermission';
import { DefaultTag } from '_common/shared/utils';
import moment from 'moment';
import ServicePackageApi from 'pages/ServicePackage/_api';
import { TYPE_SERVICE } from 'pages/ServicePackage/constants';
import { PERMISSION_KEY } from 'pages/ServicePackage/permission';
import { SERVICE_PACKAGE_URL } from 'pages/ServicePackage/url';
import { useNavigate } from 'react-router-dom';
import { DropdownItem, TableProps, Tag, notification } from 'tera-dls';

interface IProps extends TableProps {
  [key: string]: any;
}

const ServicePackageTable = (props: IProps) => {
  const { data = [], ...restProps } = props;
  const { hasPage } = usePermission();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const permission = {
    detail: PERMISSION_KEY.SERVICE_PACKAGE_VIEW_SERVICE_DETAIL,
    update: PERMISSION_KEY.SERVICE_PACKAGE_UPDATE_SERVICE,
    delete: PERMISSION_KEY.SERVICE_PACKAGE_DELETE_SERVICE,
  };

  const { mutate: mutateDelete } = useMutation(
    (variables: any) => ServicePackageApi.delete(variables),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-service-package-list']);
          queryClient.invalidateQueries(['get-service-package-detail']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleDelete = (id: number, name: string): void => {
    confirm.warning({
      title: 'Xóa gói dịch vụ',
      content: (
        <p className="break-word">
          <p> Bạn có chắc muốn xóa gói dịch vụ</p>
          <p>
            <span className="font-bold break-word">{` ${name ?? ''} `} </span>
            này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateDelete({ id });
      },
    });
  };

  const generateDropDownItems = (record): DropdownItem[] => {
    const isDefault = record?.is_trial_default;
    const dropdownItems: DropdownItem[] = [];
    hasPage(permission.detail) &&
      dropdownItems.push({
        key: 1,
        label: <a onClick={() => record}>Xem</a>,
        onClick: () => {
          navigate(`${SERVICE_PACKAGE_URL.detail.path}/${record?.id}`);
        },
      });
    hasPage(permission.update) &&
      dropdownItems.push({
        key: 2,
        label: <a onClick={() => 1}>Sửa</a>,
        onClick: () => {
          navigate(`${SERVICE_PACKAGE_URL.update.path}/${record?.id}`);
        },
      });
    hasPage(permission.delete) &&
      !isDefault &&
      dropdownItems.push({
        key: 3,
        label: <a className="text-red-500">Xóa</a>,
        onClick: () => handleDelete(record?.id, record?.name),
      });

    return dropdownItems;
  };
  const columns: any = [
    {
      title: 'Mã gói dịch vụ',
      dataIndex: 'code',
      width: 150,
      render: (val, record) => (
        <div className="flex gap-2 items-center line-clamp-1">
          <span className="text-blue-500">{val}</span>
          {!!record.is_trial_default && <DefaultTag title={'Mặc định'} />}
        </div>
      ),
    },
    {
      title: 'Tên gói dịch vụ',
      dataIndex: 'name',
      width: 200,
      render: (val) => <span className="line-clamp-2">{val}</span>,
    },
    {
      title: 'Loại gói dịch vụ',
      dataIndex: 'type',
      width: 150,
      render: (value) => (value ? TYPE_SERVICE[value] : ''),
    },
    {
      title: 'Phân quyền',
      dataIndex: 'role',
      width: 150,
      render: (value) =>
        value && (
          <span className="line-clamp-2">{`${value.code} - ${value.title}`}</span>
        ),
    },
    {
      title: 'Trang thái',
      dataIndex: 'status',
      width: 100,
      render: (value) =>
        value === 1 ? (
          <Tag color="green05">Bật</Tag>
        ) : (
          <Tag color="gray02">Tắt</Tag>
        ),
    },
    {
      title: 'Người tạo',
      dataIndex: 'user_created',
      width: 150,
      render: (value) =>
        value && <EmployeeText code={value.code} name={value.full_name} />,
    },
    {
      title: 'Ngày tạo',
      width: 100,
      dataIndex: 'created_at',
      render: (value) => (value ? moment(value).format(DATE_TIME_FORMAT) : ''),
    },
    {
      title: '',
      dataIndex: 'operation',
      width: 46,
      fixed: 'right',
      render: (_, record) => (
        <ActionDropdown
          dropdownItems={generateDropDownItems(record)}
          trigger="click"
          containerClassName="w-[50px]"
          placement="bottom-end"
        />
      ),
    },
  ];

  return (
    <>
      <TableTera rowKey={'id'} {...restProps} data={data} columns={columns} />
    </>
  );
};

export default ServicePackageTable;
