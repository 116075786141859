import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { REGEX } from '_common/constants/common';
import {
  messageError,
  messageValidate,
  messageWarning,
} from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import Select from '_common/dof/Control/Select';
import TextArea from '_common/dof/Control/TextArea';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Spin, notification } from 'tera-dls';
import ConfigPermissionApi from '../../_api';
import { TYPE_ROLE } from '../../constants';
import SelectBusinessId from '_common/dof/Select/SelectBusinessId';

interface IProps {
  open: boolean;
  id: number;
  onClose: () => void;
}
const FormConfigPermission = ({ open, id, onClose }: IProps) => {
  const confirm = useConfirm();
  const form = useForm({
    mode: 'onChange',
  });
  const type = form.watch('type');

  const queryClient = useQueryClient();
  const {
    data: dataDetail,
    refetch: refetchDetail,
    isLoading: loadingDetail,
  } = useQuery(
    ['get-detail-config-permission', id],
    () => ConfigPermissionApi.getDetail(id),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const handleClose = () => {
    onClose();
    form.reset();
  };

  const { mutate: mutateFormPermission } = useMutation(
    (variables: any) => {
      if (id) return ConfigPermissionApi.update(id, variables);
      return ConfigPermissionApi.create(variables);
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-config-permission']);
          queryClient.invalidateQueries(['get-config-permission-summary']);
          handleClose();
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) => {
        const errorMessage = error?.data?.msg || messageError.ERROR_API;
        notification.error({ message: errorMessage });
      },
    },
  );

  const handleSubmitForm = (values) => {
    mutateFormPermission({
      ...values,
    });
  };

  const onCloseModal = () => {
    const { isDirty } = form.formState;
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          handleClose();
        },
      });
    } else handleClose();
  };

  useEffect(() => {
    if (dataDetail) {
      form.reset(
        {
          code_guard: dataDetail?.code_guard,
          note: dataDetail?.note,
          title: dataDetail?.title,
          type: dataDetail?.type,
          business_id: dataDetail?.business_id,
        },
        { keepDirty: false },
      );
    }
  }, [dataDetail]);

  useEffect(() => {
    id && refetchDetail();
  }, [id]);

  const typeOptions = Object.entries(TYPE_ROLE).map(([key, value]) => ({
    label: value,
    value: key,
  }));

  return (
    <Modal
      title={id ? 'Sửa quyền' : 'Thêm quyền'}
      okText="Lưu"
      cancelText="Hủy"
      className="lg:w-1/5"
      open={open}
      onCancel={onCloseModal}
      onOk={() => form.handleSubmit(handleSubmitForm)()}
      closeIcon={false}
    >
      <Spin spinning={loadingDetail && !!id}>
        <FormTera form={form} onSubmit={handleSubmitForm}>
          <FormTeraItem
            label="Mã quyền"
            name={'code_guard'}
            rules={[
              {
                required: messageValidate.emptyText,
              },
              {
                pattern: {
                  value: REGEX.CODE,
                  message: messageValidate.code,
                },
              },
            ]}
          >
            <Input maxLength={100} placeholder="Vui lòng nhập" />
          </FormTeraItem>
          <FormTeraItem
            label="Tiêu đề"
            name={'title'}
            rules={[
              {
                required: messageValidate.emptyText,
              },
            ]}
          >
            <Input placeholder="Vui lòng nhập" maxLength={100} />
          </FormTeraItem>
          <FormTeraItem
            label="Loại quyền"
            name={'type'}
            rules={[
              {
                required: messageValidate.emptyText,
              },
            ]}
          >
            <Select placeholder="Vui lòng chọn" options={typeOptions} />
          </FormTeraItem>
          {type === 'user' && (
            <FormTeraItem
              label="Business ID"
              name={'business_id'}
              rules={[
                {
                  required: messageValidate.emptyText,
                },
              ]}
            >
              <SelectBusinessId
                paramsApi={{ include_id: form.watch('business_id') }}
                placeholder="Vui lòng chọn"
              />
            </FormTeraItem>
          )}
          <FormTeraItem label="Ghi chú" name={'note'}>
            <TextArea placeholder="Vui lòng nhập" maxLength={1000} />
          </FormTeraItem>
        </FormTera>
      </Spin>
    </Modal>
  );
};

export default FormConfigPermission;
