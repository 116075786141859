import { useQuery } from '@tanstack/react-query';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectBank from '_common/dof/Select/SelectBank';
import TableTera from '_common/dof/TableTera';
import moment from 'moment';
import ConfigPaymentMethodApi from 'pages/System/containers/ConfigPaymentMethod/_api';
import { useEffect, useState } from 'react';
import { PaginationProps } from 'tera-dls';
import Searching from './Searching';

const Bank = ({ id, onUpdateParams }) => {
  const [params, setParams] = useState<any>({ page: 1, limit: 10 });

  const { data: response, refetch } = useQuery(
    ['get-payment-method-user-list', params, id],
    () =>
      ConfigPaymentMethodApi.getList({
        ...(params?.card_type_id && {
          card_type_id: params.card_type_id,
        }),
        keyword: params?.keyword,
        get_at_admin: 1,
        user_id: id,
        // object_type: 1,
      }),
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  useEffect(() => {
    id && refetch();
  }, [id]);

  const handleSearch = ({ keyword }) => {
    setParams((prev) => ({ ...prev, keyword, page: 1 }));
    onUpdateParams((prev) => ({
      ...prev,
      bank_keyword: keyword,
      get_at_admin: 1,
    }));
  };
  const handleSelect = (value) => {
    setParams((prev) => ({ ...prev, card_type_id: value, page: 1 }));
    onUpdateParams((prev) => ({
      ...prev,
      bank_card_type_id: value,
      get_at_admin: 1,
    }));
  };

  const columns = [
    {
      title: 'Tên ngân hàng',
      dataIndex: 'card_type',
      width: 150,
      render: (val) => <span className="line-clamp-2">{val?.card_name}</span>,
    },
    {
      title: 'Người thụ hưởng',
      dataIndex: 'cardholder',
      width: 150,
      render: (val) => <span className="line-clamp-1">{val}</span>,
    },
    {
      title: 'Số tài khoản',
      width: 150,
      dataIndex: 'account_number',
    },
    {
      title: 'Ngày tạo',
      width: 150,
      dataIndex: 'created_at',
      render: (val) => val && moment(val).format(DATE_TIME_FORMAT),
    },
    {
      title: 'Ngày cập nhật',
      width: 150,
      dataIndex: 'updated_at',
      render: (val) => val && moment(val).format(DATE_TIME_FORMAT),
    },
  ];

  const handleChangePage: PaginationProps['onChange'] = (
    page: any,
    pageSize: any,
  ) => {
    setParams({ page: page, limit: pageSize });
  };

  return (
    <div
      className="p-[9px] flex flex-col gap-[9px]"
      style={{
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
      }}
    >
      <div className="flex gap-2.5 justify-end">
        <FormTera>
          <FormTeraItem name="">
            <SelectBank
              className="w-[300px]"
              placeholder="Tất cả thẻ"
              onChange={handleSelect}
              allowClear
              value={params?.card_type_id}
            />
          </FormTeraItem>
        </FormTera>
        <Searching onSearch={handleSearch} />
      </div>
      <TableTera
        columns={columns}
        data={response?.data}
        pagination={{
          onChange: handleChangePage,
          pageSize: response?.per_page,
          current: response?.current_page,
          total: response?.total,
          from: response?.from,
          to: response?.to,
        }}
      />
    </div>
  );
};

export default Bank;
