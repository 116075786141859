import { ChartLine, ChartLineProps } from 'tera-dls';

const Content = ({ data }) => {
  const options: ChartLineProps['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    elements: {
      line: {
        tension: 0.3,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  return <ChartLine data={data} options={options} />;
};

export default Content;
