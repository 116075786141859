import { useMutation } from '@tanstack/react-query';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import { messageError } from '_common/constants/message';
import TableTera from '_common/dof/TableTera';
import { INDEX_NUMBER_KEY } from '_common/dof/TableTera/constants';
import useConfirm from '_common/hooks/useConfirm';
import { usePermission } from '_common/hooks/usePermission';
import ColumnConfigApi from 'pages/System/containers/ManagePage/ColumnConfig/_api';
import ColumnConfigDetail from 'pages/System/containers/ManagePage/ColumnConfig/containers/Detail';
import ColumnConfigForm from 'pages/System/containers/ManagePage/ColumnConfig/containers/Form';
import {
  dataType,
  statusOnOffString,
} from 'pages/System/containers/ManagePage/constants';
import { BUTTON_KEY } from 'pages/System/containers/ManagePage/constants/key';
import { useState } from 'react';
import {
  Button,
  DropdownItem,
  PlusCircleOutlined,
  Tag,
  notification,
} from 'tera-dls';

interface IProps {
  value: any;
  tableId: string | number;
  loading?: boolean;
  onUpdatedData?: (val: boolean) => void;
}

function TableColumConfig(props: IProps) {
  const { value, tableId, loading, onUpdatedData } = props;
  const confirm = useConfirm();
  const [isOpenDetail, setIsOpenDetail] = useState<boolean>(false);
  const [isOpenForm, setIsOpenForm] = useState<boolean>(false);
  const [idColumn, setIdColumn] = useState<number | string>(null);
  const { hasPage } = usePermission();
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);

  const { mutate: deleteColumn } = useMutation(
    (id: number) => ColumnConfigApi.delete(id),
    {
      onSuccess(res) {
        if (res?.code === 200) {
          onUpdatedData && onUpdatedData(true);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        const errorMessage = error?.data?.msg || messageError.ERROR_API;
        notification.error({
          message: errorMessage,
        });
      },
    },
  );

  const { mutate: deleteMultipleColumns } = useMutation(
    (variables: any) => ColumnConfigApi.deleteMultiple(variables),
    {
      onSuccess(res) {
        if (res?.code === 200) {
          onUpdatedData && onUpdatedData(true);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        const errorMessage = error?.data?.msg || messageError.ERROR_API;
        notification.error({
          message: errorMessage,
        });
      },
    },
  );

  const handleDelete = (id: number) => {
    confirm.warning({
      title: 'XÁC NHẬN XÓA CỘT DỮ LIỆU',
      content: (
        <>
          <p>Bạn có chắc chắn muốn xóa cột</p>
          <p>này không?</p>
        </>
      ),
      onOk: () => {
        deleteColumn(id);
      },
    });
  };

  const handleDetail = (id: number) => {
    setIsOpenDetail(true);
    setIdColumn(id);
  };

  const handleUpdate = (id: number) => {
    setIsOpenForm(true);
    setIdColumn(id);
  };

  const generateDropDownItems = (record): DropdownItem[] => {
    const dropdownItems: DropdownItem[] = [];
    hasPage(BUTTON_KEY.COLUMN_CONFIG_LIST_DETAIL) &&
      dropdownItems.push({
        key: 1,
        label: <a onClick={() => handleDetail(record?.id)}>Xem</a>,
      });

    hasPage(BUTTON_KEY.COLUMN_CONFIG_LIST_UPDATE) &&
      dropdownItems.push({
        key: 10,
        label: <a onClick={() => handleUpdate(record?.id)}>Sửa</a>,
      });

    hasPage(BUTTON_KEY.COLUMN_CONFIG_LIST_DELETE) &&
      dropdownItems.push({
        key: 2,
        label: (
          <a className="text-red-500" onClick={() => handleDelete(record?.id)}>
            Xóa
          </a>
        ),
      });

    return dropdownItems;
  };

  const handleDeleteMultipleRows = () => {
    confirm.warning({
      title: 'XÁC NHẬN XÓA CÁC CỘT DỮ LIỆU',
      content: <p>Bạn có chắc chắn muốn xóa các cột đã chọn này không?</p>,
      onOk: () => {
        deleteMultipleColumns({ ids: selectedRowKeys, table_id: tableId });
      },
    });
  };

  const columns: any = [
    {
      title: 'STT',
      dataIndex: INDEX_NUMBER_KEY,
      width: 80,
      align: 'center',
    },
    {
      title: 'Mã cột dữ liệu',
      dataIndex: 'concatenated_code',
      width: 250,

      render: (text) => <div className="line-clamp-2">{text}</div>,
    },
    {
      title: 'Tiêu đề',
      dataIndex: 'title',
      width: 200,
      render: (text) => <div className="line-clamp-2">{text}</div>,
    },
    {
      title: 'Loại dữ liệu',
      dataIndex: 'type',
      width: 200,
      render: (type) => dataType[type],
    },
    {
      title: 'Key dữ liệu',
      dataIndex: 'key',
      width: 150,
      render: (text) => <div className="line-clamp-2">{text}</div>,
    },
    {
      title: 'className',
      dataIndex: 'class_name',
      width: 150,
      render: (text) => <div className="line-clamp-2">{text}</div>,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      align: 'center',
      width: 100,
      render: (text) => (
        <Tag color={statusOnOffString[text]?.color}>
          {statusOnOffString[text]?.name}
        </Tag>
      ),
    },
    {
      title: '',
      width: 80,
      fixed: 'right',
      render: (_, record) => {
        return (
          <ActionDropdown
            dropdownItems={generateDropDownItems(record)}
            trigger="click"
            placement="bottom-end"
            containerClassName="w-12"
          />
        );
      },
    },
  ];

  return (
    <>
      <div className={`flex justify-between items-center my-5 mr-3.5`}>
        {selectedRowKeys?.length > 0 ? (
          <div className="flex gap-5">
            <span className="bg-green-300 rounded-full px-5 py-1.5 grid items-center text-white">
              Đã chọn ({selectedRowKeys?.length ?? 0})
            </span>

            <Button
              onClick={() =>
                selectedRowKeys?.length > 0 && handleDeleteMultipleRows()
              }
              type="danger"
            >
              Xóa
            </Button>
          </div>
        ) : (
          <div />
        )}
        {hasPage(BUTTON_KEY.COLUMN_CONFIG_LIST_CREATE) && (
          <Button
            onClick={() => setIsOpenForm(true)}
            disabled={!tableId}
            className="rounded-xsm"
          >
            <PlusCircleOutlined className="w-[1rem] h-[1rem]" />
            Thêm mới
          </Button>
        )}
      </div>

      <div className="bg-white shadow-xsm rounded-[5px] overflow-hidden">
        <TableTera
          loading={loading}
          columns={columns}
          data={value ?? []}
          rowKey="id"
          pagination={{}}
          rowSelection={{ selectedRowKeys, onChange: setSelectedRowKeys }}
        />
      </div>
      {isOpenDetail && idColumn && (
        <ColumnConfigDetail
          open={isOpenDetail}
          onClose={() => {
            setIdColumn(null);
            setIsOpenDetail(false);
          }}
          id={idColumn}
        />
      )}
      {isOpenForm && (
        <ColumnConfigForm
          open={isOpenForm}
          id={idColumn}
          onClose={() => {
            setIsOpenForm(false);
            setIdColumn(null);
          }}
          onRefetch={() => {
            onUpdatedData && onUpdatedData(true);
          }}
          tableId={tableId}
        />
      )}
    </>
  );
}

export default TableColumConfig;
