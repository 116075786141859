import React, { createContext } from 'react';
export const CartContext = createContext(null);

const EditableRow: React.FC<any> = ({ children, ...restProps }) => {
  return (
    <>
      <CartContext.Provider
        value={{
          index: 1,
        }}
      >
        <tr {...restProps} data-index={1}>
          {children}
        </tr>
      </CartContext.Provider>
      <CartContext.Provider
        value={{
          index: 2,
        }}
      >
        <tr {...restProps} data-index={2}>
          {children}
        </tr>
      </CartContext.Provider>
    </>
  );
};

export default React.memo(EditableRow);
