import { useMutation, useQuery } from '@tanstack/react-query';
import CardFormV2 from '_common/component/CardFormV2';
import NoData from '_common/component/NoData';
import { REGEX } from '_common/constants/common';
import { messageError, messageValidate } from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import TextArea from '_common/dof/Control/TextArea';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectDepartment from '_common/dof/Select/ObjectType/SelectDepartment';
import SelectJobTitle from '_common/dof/Select/ObjectType/SelectJobTitle';
import SelectPaymentType from '_common/dof/Select/ObjectType/SelectPaymentType';
import SelectScalesType from '_common/dof/Select/ObjectType/SelectScalesType';
import _ from 'lodash';
import BusinessApi from 'pages/Business/_api';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { Row, Spin, notification } from 'tera-dls';

interface IForm {
  name: string;
  name_registration: string;
  owner_name: string;
  owner_email: string;
  owner_job_title: string;
  owner_phone: string;
  owner_department: string;
  email: string;
  address: string;
  employee_size: string;
  payment_methods: string;
  intro: string;
  trial_time: string;
  website: string;
  instagram: string;
  linkedin: string;
  facebook: string;
  tiktok: string;
}

export interface DetailFormRef {
  submit?: () => void;
}

interface DetailFormProps {
  id?: number;
  onSuccess: () => void;
}

const DetailForm = forwardRef<DetailFormRef, DetailFormProps>(
  ({ id, onSuccess }, ref) => {
    const form = useForm<IForm>({
      mode: 'onChange',
    });

    const {
      data: dataDetail,
      isLoading,
      isError,
      refetch,
    } = useQuery(['get-business-detail', id], () => BusinessApi.getDetail(id), {
      enabled: !!id,
      cacheTime: 300000,
      staleTime: 300000,
    });

    const { mutate: mutateForm } = useMutation(
      (variables: IForm) => {
        return id
          ? BusinessApi.update(id, variables)
          : BusinessApi.create(variables);
      },
      {
        onSuccess: ({ code, msg }) => {
          if (code === 200) {
            if (onSuccess) onSuccess();
            notification.success({
              message: msg,
            });
          }
        },
        onError: (error: any) => {
          const errorMessage = error?.data?.msg || messageError.ERROR_API;
          notification.error({
            message: errorMessage,
          });
        },
      },
    );

    const handleSubmitForm = (values: IForm) => {
      if (isLoading && !!id) return;

      mutateForm(values);
    };

    useImperativeHandle(ref, () => ({
      submit() {
        form?.handleSubmit?.(handleSubmitForm)();
      },
    }));

    useEffect(() => {
      if (dataDetail && !!id) {
        const data = _.pick(dataDetail, [
          'name',
          'name_registration',
          'owner_name',
          'owner_email',
          'owner_job_title',
          'owner_phone',
          'owner_department',
          'email',
          'address',
          'employee_size',
          'payment_methods',
          'intro',
          'trial_time',
          'website',
          'instagram',
          'linkedin',
          'facebook',
          'tiktok',
        ]);

        form.reset(data);
      }
    }, [dataDetail, id]);

    useEffect(() => {
      if (id) refetch();
    }, [id]);

    if (isError) {
      notification.error({
        message: messageError.DATA_NOT_FOUND,
      });
      return <NoData />;
    }

    return (
      <Spin spinning={isLoading && !!id}>
        <FormTera form={form} onSubmit={form.handleSubmit(handleSubmitForm)}>
          <div className="flex flex-col gap-y-5">
            <CardFormV2 title="THÔNG TIN DOANH NGHIỆP">
              <Row className="grid grid-cols-3 gap-x-5 gap-y-0">
                <FormTeraItem
                  label="Tên công ty"
                  name="name"
                  rules={[
                    {
                      required: messageValidate.emptyText,
                    },
                  ]}
                >
                  <Input maxLength={255} />
                </FormTeraItem>

                <FormTeraItem
                  label="Email công ty"
                  name="email"
                  rules={[
                    {
                      pattern: {
                        value: REGEX.EMAIL,
                        message: messageValidate.email,
                      },
                      required: messageValidate.emptyText,
                    },
                  ]}
                >
                  <Input maxLength={320} />
                </FormTeraItem>

                <FormTeraItem
                  label="Phương thức thanh toán"
                  name="payment_methods"
                  rules={[
                    {
                      required: messageValidate.emptySelect,
                    },
                  ]}
                >
                  <SelectPaymentType />
                </FormTeraItem>

                <FormTeraItem
                  label="Tên đăng ký giấy phép kinh doanh của doanh nghiệp"
                  name="name_registration"
                >
                  <Input maxLength={255} />
                </FormTeraItem>

                <FormTeraItem
                  label="Quy mô nhân sự"
                  name="employee_size"
                  rules={[
                    {
                      required: messageValidate.emptySelect,
                    },
                  ]}
                >
                  <SelectScalesType />
                </FormTeraItem>

                <FormTeraItem
                  className="col-span-3"
                  label="Địa chỉ công ty"
                  name="address"
                >
                  <Input />
                </FormTeraItem>

                <FormTeraItem
                  className="col-span-3"
                  label="Giới thiệu công ty"
                  name="intro"
                >
                  <TextArea rows={5} maxLength={1000} />
                </FormTeraItem>
              </Row>
            </CardFormV2>
            <CardFormV2 title="THÔNG TIN ĐẠI DIỆN">
              <Row className="grid grid-cols-3 gap-x-5 gap-y-0">
                <FormTeraItem
                  label="Họ và tên"
                  name="owner_name"
                  rules={[
                    {
                      required: messageValidate.emptyText,
                    },
                  ]}
                >
                  <Input maxLength={255} />
                </FormTeraItem>

                <FormTeraItem
                  label="Email"
                  name="owner_email"
                  rules={[
                    {
                      pattern: {
                        value: REGEX.EMAIL,
                        message: messageValidate.email,
                      },
                    },
                    {
                      required: messageValidate.emptyText,
                    },
                  ]}
                >
                  <Input maxLength={320} />
                </FormTeraItem>

                <FormTeraItem
                  label="Số điện thoại"
                  name="owner_phone"
                  rules={[
                    {
                      required: messageValidate.emptyText,
                    },
                    {
                      pattern: {
                        value: new RegExp(REGEX.PHONE_NUMBER),
                        message: messageValidate.phone,
                      },
                    },
                  ]}
                >
                  <Input maxLength={20} />
                </FormTeraItem>

                <FormTeraItem
                  label="Phòng ban/ Bộ phận"
                  name="owner_department"
                >
                  <SelectDepartment />
                </FormTeraItem>

                <FormTeraItem label="Chức danh" name="owner_job_title">
                  <SelectJobTitle />
                </FormTeraItem>
              </Row>
            </CardFormV2>
            <CardFormV2 title="THÔNG TIN KHÁC">
              <Row className="grid grid-cols-3 gap-x-5 gap-y-0">
                <FormTeraItem label="Website" name="website">
                  <Input maxLength={255} />
                </FormTeraItem>

                <FormTeraItem label="Instagram" name="instagram">
                  <Input maxLength={255} />
                </FormTeraItem>

                <FormTeraItem label="LinkedIn" name="linkedin">
                  <Input maxLength={255} />
                </FormTeraItem>

                <FormTeraItem label="Facebook" name="facebook">
                  <Input maxLength={255} />
                </FormTeraItem>

                <FormTeraItem label="Tiktok" name="tiktok">
                  <Input maxLength={255} />
                </FormTeraItem>
              </Row>
            </CardFormV2>
          </div>
        </FormTera>
      </Spin>
    );
  },
);

export default DetailForm;
