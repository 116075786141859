import { useQuery } from '@tanstack/react-query';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import { DATE_FORMAT } from '_common/constants/common';
import { BUSINESS_URL } from '_common/constants/url';
import TableTera from '_common/dof/TableTera';
import moment from 'moment';
import ServicePackageApi from 'pages/ServicePackage/_api';
import {
  PRODUCT_TIME,
  STATUS_SERVICE_BUSINESS,
  STATUS_SERVICE_BUSINESS_COLOR,
} from 'pages/ServicePackage/constants';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DropdownItem, formatDate, PaginationProps, Tag } from 'tera-dls';

const Business = ({ id }) => {
  const [params, setParams] = useState<any>({ page: 1, limit: 10 });
  const navigate = useNavigate();

  const {
    data: response,
    refetch,
    isLoading,
  } = useQuery(
    ['get-service-package-business-list', id, params],
    () =>
      ServicePackageApi.getBusiness({ params: { ...params, service_id: id } }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    id && refetch();
  }, [id]);

  const list = response?.data;

  const renderDropdown = (record): DropdownItem[] => {
    const dropdownItems: DropdownItem[] = [];
    dropdownItems.push({
      key: 1,
      label: 'Xem',
      onClick: () =>
        navigate(`${BUSINESS_URL.detail.path}/${record?.business?.id}`),
    });

    return dropdownItems;
  };

  const columns: any = [
    {
      title: 'Buisness ID',
      dataIndex: 'id',
      width: 110,
    },
    {
      key: 'name',
      title: 'Tên công ty',
      dataIndex: 'business',
      width: 300,
      render: (val) => <div className="line-clamp-2">{val?.name}</div>,
    },

    {
      key: 'owner_name',
      title: 'Đại diện',
      dataIndex: 'business',
      width: 300,
      render: (val) => <div className="line-clamp-2">{val?.owner_name}</div>,
    },
    {
      title: 'Gói sản phẩm',
      dataIndex: 'package',
      width: 200,
      render: (val) => <div className="line-clamp-2">{val?.name}</div>,
    },
    {
      title: 'Ngày kích hoạt',
      dataIndex: 'date_active',
      width: 200,
      render: (val) => (val ? moment(val).format(DATE_FORMAT) : null),
    },
    {
      title: 'Thời gian',
      dataIndex: 'time',
      render: (val) => PRODUCT_TIME[val] ?? '',
    },
    {
      title: 'Ngày hết hạn',
      dataIndex: 'date_expired',
      render: (val) => val && formatDate(val, DATE_FORMAT),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 200,
      render: (status) => (
        <Tag color={STATUS_SERVICE_BUSINESS_COLOR[status]}>
          {STATUS_SERVICE_BUSINESS[status]}
        </Tag>
      ),
    },
    {
      dataIndex: '',
      width: 60,
      fixed: 'right',
      render: (_, record) => {
        const dropdownItems = renderDropdown(record);
        return <ActionDropdown dropdownItems={dropdownItems} trigger="click" />;
      },
    },
  ];
  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    setParams({ page, limit: pageSize });
  };

  return (
    <TableTera
      columns={columns}
      data={list || []}
      loading={isLoading}
      rowKey={'id'}
      pagination={{
        onChange: handleChangePage,
        total: response?.data?.total || 0,
        current: response?.data?.current_page || 1,
        pageSize: response?.data?.per_page,
        to: response?.data?.to,
        from: response?.data?.from,
      }}
    />
  );
};

export default Business;
