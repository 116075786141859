import Filter from '_common/component/Filter';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectEpic from '_common/dof/Select/SelectEpic';
import SelectModule from '_common/dof/Select/SelectModule';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Row } from 'tera-dls';
import { IFilter } from '../../interfaces';

interface GroupControlFilterProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue: any;
}

function GroupControlFilter({
  open,
  onClose,
  initialValue,
  onFilter,
}: GroupControlFilterProps) {
  const default_initialValue = useMemo(() => {
    return {
      module: initialValue?.module ? Number(initialValue?.module) : '',
      epic_id: initialValue?.epic_id ? Number(initialValue?.epic_id) : '',
    };
  }, [initialValue]);

  const form = useForm({
    defaultValues: {
      epic_id: default_initialValue?.epic_id,
      module: default_initialValue?.module,
    },
  });

  const { handleSubmit, watch } = form;

  const moduleId = watch('module');

  const handleSubmitForm = (value) => {
    onFilter(value);
    onClose();
  };

  const handleReset = () => {
    const values: IFilter = {
      module_id: null,
      epic_id: null,
    };
    onFilter(values);
    onClose();
  };

  return (
    <Filter
      open={open}
      onClose={onClose}
      onCancel={onClose}
      onFilter={() => handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={handleSubmit(handleSubmitForm)}>
        <Row className="grid gap-y-0">
          <FormTeraItem name="module" label="Module" className="flex-auto">
            <SelectModule />
          </FormTeraItem>
          <FormTeraItem name="epic_id" label="Epic">
            <SelectEpic
              disabled={!moduleId}
              paramsApi={{ module: moduleId }}
              placeholder="Chọn Epic"
            />
          </FormTeraItem>
        </Row>
        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default GroupControlFilter;
