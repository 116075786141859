import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  PaginationProps,
  downloadFile,
  filterField,
  getQueryParams,
  notification,
  updateQueryParams,
} from 'tera-dls';
import CashReceiptApi from './api';
import CashReceiptFilter from './containers/Filter';
import Searching from './containers/Searching';
import CashReceiptTable from './containers/Table';
import { CASH_RECEIPT_URL } from './url';

import useColumnSetting from '_common/component/ColumnSetting/_hooks/useColumnConfig';
import ModalImport from '_common/component/ModalImport';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { usePermission } from '_common/hooks/usePermission';
import moment from 'moment';
import ImportExportApi from 'pages/System/containers/ConfigImportExportData/api';
import { EXPORTING_KEYS } from 'pages/System/containers/ConfigImportExportData/constants';
import Statistic from './containers/Statistic';
import { TABLE_KEY } from './keys';
import { PERMISSION_KEY } from './permission';

const CashReceipt = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const { hasPage } = usePermission();
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);
  const [openImport, setOpenImport] = useState<boolean>(false);
  const [errorImport, setErrorImport] = useState<any | undefined>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const queryClient = useQueryClient();
  const { openModal } = useColumnSetting(
    TABLE_KEY.CASH_RECEIPT_VIEW_CASH_RECEIPT_LIST_TABLE,
  );

  const {
    data: response,
    isFetching,
    refetch,
  } = useQuery(
    ['get-cash-receipt-list', queryParams],
    () =>
      CashReceiptApi.getList({
        params: filterField({
          ...queryParams,
          page: queryParams?.page ?? 1,
          limit: queryParams?.limit ?? 10,
        }),
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
  }, []);

  const list = response?.data;

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const { mutate: mutateImport } = useMutation(
    (variables: any) => ImportExportApi.import(variables),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-cash-receipt-list']);
          queryClient.invalidateQueries(['get-cash-receipt-statistic']);
          setOpenImport(false);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        if (error?.data?.code === 501) {
          ErrorToast({ errorProp: error?.data });
          return;
        }
        setErrorImport(error?.data?.errors);
      },
    },
  );

  const { mutate: mutateExport } = useMutation(
    (variables: any) => ImportExportApi.export(variables),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          setSelectedRowKeys([]);
          const date = moment().format('DDMMYYYY_HHmmss');

          downloadFile(res?.data?.src, `export_cash_receipt_${date}`);
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const dropdownItems = [
    ...(hasPage(PERMISSION_KEY.CASH_RECEIPT_CONFIG_COLUMN_CASH_RECEIPT)
      ? [
          {
            key: 1,
            label: <a>Cấu hình cột</a>,
            onClick: () => openModal(),
          },
        ]
      : []),
    ...(hasPage(PERMISSION_KEY.CASH_RECEIPT_IMPORT_CASH_RECEIPT)
      ? [
          {
            key: 2,
            label: <a>Import file excel</a>,
            onClick: () => {
              setOpenImport(true);
            },
          },
        ]
      : []),
    ...(hasPage(PERMISSION_KEY.CASH_RECEIPT_EXPORT_CASH_RECEIPT)
      ? [
          {
            key: 3,
            label: <a>Export file excel</a>,
            onClick: () =>
              mutateExport({
                params: {
                  item_code: 'receipt_item',
                  selected_ids: selectedRowKeys ?? [],
                },
              }),
          },
        ]
      : []),
  ];

  const handleImport = (value) => {
    mutateImport({
      params: {
        file: value?.file,
        item_code: 'receipt_item',
        overwrite_all: Number(value?.overwrite),
      },
    });
  };

  const handleSearch = ({ keyword }) => {
    handleUpdateQueryParams({ keyword, page: 1 });
  };

  const handleFilter = (values: any): void => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    handleUpdateQueryParams({ page: page, limit: pageSize });
  };

  return (
    <>
      <div className="p-2.5">
        <HeaderViewListV2
          onClickFilter={() => setOpenFilterModal(true)}
          onClickButtonAdd={() => navigate(CASH_RECEIPT_URL.create.path)}
          buttonCreatingKey={PERMISSION_KEY.CASH_RECEIPT_CREATE_CASH_RECEIPT}
          buttonFilterKey={PERMISSION_KEY.CASH_RECEIPT_SEARCH_CASH_RECEIPT}
          dropdownItems={dropdownItems}
          title={'PHIẾU THU TIỀN'}
          actionLeftRender={
            hasPage(PERMISSION_KEY.CASH_RECEIPT_SEARCH_CASH_RECEIPT) && (
              <Searching onSearch={handleSearch} />
            )
          }
          selectedNumber={selectedRowKeys?.length}
          middleContent={<Statistic />}
        >
          <CashReceiptTable
            loading={isFetching}
            data={list ?? []}
            rowSelection={{
              selectedRowKeys,
              onChange: setSelectedRowKeys,
            }}
            pagination={{
              onChange: handleChangePage,
              pageSize: response?.per_page,
              current: response?.current_page,
              total: response?.total,
              from: response?.from,
              to: response?.to,
            }}
          />
        </HeaderViewListV2>
        {openFilterModal && (
          <CashReceiptFilter
            open={openFilterModal}
            onClose={() => setOpenFilterModal(false)}
            onFilter={handleFilter}
            initialValue={queryParams}
          />
        )}
      </div>
      {openImport && (
        <ModalImport
          // permission={{
          //   upload: PERMISSION_KEY.CASH_RECEIPT_VIEW_CASH_RECEIPT_LIST,
          //   delete: PERMISSION_KEY.CASH_RECEIPT_VIEW_CASH_RECEIPT_LIST,
          //   list: PERMISSION_KEY.CASH_RECEIPT_VIEW_CASH_RECEIPT_LIST,
          //   download: PERMISSION_KEY.CASH_RECEIPT_VIEW_CASH_RECEIPT_LIST,
          // }}
          open={openImport}
          onOk={handleImport}
          onCancel={() => {
            setOpenImport(false);
            setErrorImport(undefined);
          }}
          type={EXPORTING_KEYS.receipt_item}
          errorValue={errorImport}
        />
      )}
    </>
  );
};

export default CashReceipt;
