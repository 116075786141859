import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const BusinessEndpoint = `${endpoint}/administrator/business`;

const BusinessApi = {
  getList: async (param?) =>
    await api
      .get(`${BusinessEndpoint}/list`, param)
      .then((result) => result.data?.data),
  getListPermission: async ({ params }) =>
    await api
      .get(`${BusinessEndpoint}/list-role`, params)
      .then((result) => result.data?.data),
  getDetail: async (id: string | number) =>
    await api
      .get(`${BusinessEndpoint}/detail/${id}`)
      .then((result) => result.data?.data),
  create: async (param) =>
    await api
      .post(`${BusinessEndpoint}/create`, param)
      .then((result) => result.data),
  update: async (id: string | number, param) =>
    await api
      .put(`${BusinessEndpoint}/update/${id}`, param)
      .then((result) => result.data),
  delete: async (id: string | number) =>
    await api
      .delete(`${BusinessEndpoint}/delete/${id}`)
      .then((result) => result.data),
  import: async (params?) =>
    await api
      .post(`${BusinessEndpoint}/import`, params)
      .then((result) => result.data),
  export: async (params?) =>
    await api
      .post(`${BusinessEndpoint}/export`, params)
      .then((result) => result.data),
  activate: async (params?) =>
    await api
      .post(`${BusinessEndpoint}/activate`, params)
      .then((result) => result.data),
  cancelActivate: async (params?) =>
    await api
      .post(`${BusinessEndpoint}/cancel-activation`, params)
      .then((result) => result.data),
  expiredAccount: async (params?) =>
    await api
      .post(`${BusinessEndpoint}/expired-account`, params)
      .then((result) => result.data),
  renewalAccount: async (params?) =>
    await api
      .post(`${BusinessEndpoint}/renewal-account`, params)
      .then((result) => result.data),
  restart: async (params?) =>
    await api
      .post(`${BusinessEndpoint}/restart`, params)
      .then((result) => result.data),
  getSummaryDetail: async (param?) =>
    await api
      .get(`${BusinessEndpoint}/get-summary-detail`, param)
      .then((result) => result.data?.data),
};

export default BusinessApi;
