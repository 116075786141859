import { endpoint } from '_common/constants/common';
import { filterField } from '_common/utils';
import api from 'states/drivers';

const EpicEndpoint = `${endpoint}/administrator/epic`;

const EpicApi = {
  getList: async (param?) =>
    await api
      .get(`${EpicEndpoint}/list`, filterField(param))
      .then((result) => result.data?.data),
  getDetail: async (id: string | number) =>
    await api
      .get(`${EpicEndpoint}/detail/${id}`)
      .then((result) => result.data?.data),
  create: async (param) =>
    await api
      .post(`${EpicEndpoint}/create`, param)
      .then((result) => result.data),
  update: async (id: string | number, param) =>
    await api
      .put(`${EpicEndpoint}/update/${id}`, param)
      .then((result) => result.data),
  delete: async (id: string | number) =>
    await api
      .delete(`${EpicEndpoint}/delete/${id}`)
      .then((result) => result.data),
  import: async (params?) =>
    await api
      .post(`${EpicEndpoint}/import`, params)
      .then((result) => result.data),
  export: async (params?) =>
    await api
      .post(`${EpicEndpoint}/export`, params)
      .then((result) => result.data),
};

export default EpicApi;
