import { useQuery } from '@tanstack/react-query';
import PortalAPI from 'pages/FileManagement/_api';
import {
  InformationCircleOutlined,
  ListBulletOutlined,
  Select,
  Squares2x2Outlined,
} from 'tera-dls';

interface IProps {
  displayType: string;
  type: string;
  userId: string;
  onChange: (val?) => void;
}

const Header = (props: IProps) => {
  const { displayType, type, userId, onChange } = props;

  const { data: users } = useQuery(
    ['get-users'],
    () => PortalAPI.getUsers({}),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const userOptions = users?.data
    ? users.data.map((user) => ({
        value: String(user.id),
        label: (
          <>
            <span className="text-green-500">[{user?.code}]</span> -{' '}
            {user?.full_name}
          </>
        ),
      }))
    : [];

  // useEffect(() => {
  //   setUserId(queryParams?.userId);
  // }, [queryParams?.userId]);

  // useEffect(() => {
  //   setType(queryParams?.type);
  // }, [queryParams?.type]);

  return (
    <div className="flex justify-between">
      <div className="flex gap-5">
        <Select
          allowClear
          className="w-[300px]"
          placeholder="Loại"
          value={String(type)}
          options={[
            { value: 'image', label: 'Hình ảnh' },
            { value: 'video', label: 'Video' },
            { value: 'document', label: 'Tài liệu' },
            { value: 'compressed', label: 'Tệp lưu trữ (Zip, rar...)' },
          ]}
          onChange={(type) => onChange({ file_type: type })}
        />
        <Select
          allowClear
          className="w-[300px]"
          placeholder="Người đăng"
          value={String(userId)}
          options={userOptions}
          onChange={(userId) => {
            onChange({ uploaded_by: userId });
          }}
        />
      </div>
      <div className="flex gap-3">
        {displayType === 'list' ? (
          <ListBulletOutlined
            className="w-[1.5rem] h-[1.5rem] cursor-pointer"
            onClick={() => onChange({ displayType: 'table' })}
          />
        ) : (
          <Squares2x2Outlined
            className="w-[1.5rem] h-[1.5rem] cursor-pointer"
            onClick={() => onChange({ displayType: 'list' })}
          />
        )}
        <InformationCircleOutlined className="w-[1.5rem] h-[1.5rem]" />
      </div>
    </div>
  );
};

export default Header;
