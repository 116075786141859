import { useTeraForm } from '_common/dof/FormTera/TeraFormContext';
import { useTeraFormItem } from '_common/dof/FormTera/TeraItemContext';
import React from 'react';
import { RadioProps, Radio as RadioTera } from 'tera-dls';

interface IProps extends RadioProps {
  listOption: any[];
  handleChange?: (valueChecked: any) => void;
  inline: boolean;
}

const Radio = React.memo(({ listOption, ...props }: IProps) => {
  const { form } = useTeraForm();
  const { item } = useTeraFormItem();

  const { register } = form;
  return (
    <RadioTera.Group
      {...props}
      {...register(item?.name ?? 'shipping_type')}
      inline={props?.inline}
    >
      {listOption?.map((child) => (
        <RadioTera key={child?.key} value={child?.value}>
          {child?.label}
        </RadioTera>
      ))}
    </RadioTera.Group>
  );
});

export default Radio;
