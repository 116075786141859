import { observer } from 'mobx-react-lite';
import {
  BuildingStorefrontOutlined,
  ChevronRightOutlined,
  PlusCircleOutlined,
  Popover,
} from 'tera-dls';

const UserCompany = observer(() => {
  const Content = () => {
    return (
      <div className="flex flex-col gap-y-2.5 shadow">
        <div
          className="p-2.5 flex items-center gap-x-[10px] cursor-pointer hover:text-blue-500"
          style={{ boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.05)' }}
        >
          <PlusCircleOutlined className="w-5 h-5 text-blue-600" />
          <span>Tạo cửa hàng, doanh nghiệp mới</span>
        </div>
      </div>
    );
  };

  return (
    <Popover
      trigger="click"
      content={<Content />}
      placement="bottom-start"
      className="p-0 border"
      showArrow={false}
    >
      <div className="flex p-2 items-center gap-x-[5px] text-blue-600 cursor-pointer">
        <BuildingStorefrontOutlined className="w-5 h-5" />
        <div className="flex gap-x-[15px]">
          <span className="font-bold max-w-[150px] truncate">
            Tera Solutions
          </span>
          <ChevronRightOutlined className="w-4 h-4" />
        </div>

        {/* <Select
        options={optionLocations}
        onSelect={(value: number) => {
          setLocationId(value);
          navigate(0);
        }}
        value={location_id}
      /> */}
      </div>
    </Popover>
  );
});

export default UserCompany;
