import { useMutation } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { messageValidate, messageWarning } from '_common/constants/message';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectColumnConfig from '_common/dof/Select/SelectColumnConfig';
import SelectPageConfig from '_common/dof/Select/SelectPageConfig';
import SelectTableConfig from '_common/dof/Select/SelectTableConfig';
import useConfirm from '_common/hooks/useConfirm';
import { getFieldObjToArr } from '_common/utils';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, OptionProps, notification } from 'tera-dls';
import SystemTableConfigApi from '../../_api';

interface IProps {
  open: boolean;
  pageId?: number;
  onClose: () => void;
  onCopySuccess?: () => void;
}

interface IForm {
  page_root_id: number;
  config_tables: number[] | string[];
  config_columns: number[] | string[];
}

const TableConfigCopy = (props: IProps) => {
  const { open = false, pageId, onClose, onCopySuccess } = props;
  const form = useForm<IForm>();
  const {
    reset,
    handleSubmit,
    watch,
    formState: { isDirty },
  } = form;
  const [selectedTable, setSelectedTable] = useState<OptionProps[]>([]);
  const [selectedColumn, setSelectedColumn] = useState<OptionProps[]>([]);

  const confirm = useConfirm();

  const handleClose = (): void => {
    reset();
    onClose();
  };

  const resetField = (type: 'page' | 'table') => {
    switch (type) {
      case 'table': {
        setSelectedColumn([]);
        break;
      }
      case 'page': {
        setSelectedColumn([]);
        setSelectedTable([]);
        break;
      }
    }
  };

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        className: 'modal-button-center',
        onOk: () => {
          handleClose();
        },
      });
    } else {
      handleClose();
    }
  };

  const mutateCopy = useMutation(
    (variables: any) => {
      return SystemTableConfigApi.clone({ params: variables });
    },
    {
      onSuccess: ({ code, msg }) => {
        if (code === 200) {
          onCopySuccess && onCopySuccess();
          handleClose();
          notification.success({
            message: msg,
          });
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleSubmitForm = (values: { [key: string]: any }): void => {
    mutateCopy.mutate({ ...values, page_current_id: pageId });
  };

  return (
    <Modal
      centered={true}
      title="SAO CHÉP BẢNG DỮ LIỆU"
      open={open}
      className="xxs:w-[95%] md:w-[418px]"
      closeIcon={false}
      okText="Lưu"
      cancelText="Huỷ"
      onOk={() => handleSubmit(handleSubmitForm)()}
      onCancel={handleCloseConfirm}
      destroyOnClose={true}
    >
      <FormTera className="h-full" form={form}>
        <FormTeraItem
          label="Chọn trang nguồn"
          name="page_root_id"
          rules={[
            {
              required: messageValidate.emptySelect,
            },
          ]}
        >
          <SelectPageConfig
            onChangeCustom={() => {
              resetField('page');
            }}
            paramsApi={{
              page_id: pageId,
              limit: 50,
            }}
          />
        </FormTeraItem>
        <FormTeraItem
          label="Chọn cấu hình bảng dữ liệu"
          name="config_tables"
          rules={[
            {
              required: messageValidate.emptySelect,
            },
          ]}
        >
          <SelectTableConfig
            mode="multiple"
            isCheckAll
            selectedValue={selectedTable}
            disabled={!watch('page_root_id')}
            onClear={() => setSelectedTable([])}
            pageId={watch('page_root_id')}
            onChangeCustom={(value: OptionProps[]) => {
              setSelectedTable(value);
              resetField('table');
            }}
            paramsApi={{
              limit: 50,
            }}
          />
        </FormTeraItem>
        <FormTeraItem label="Chọn cấu hình cột dữ liệu" name="config_columns">
          <SelectColumnConfig
            disabled={!selectedTable.length}
            mode="multiple"
            selectedValue={selectedColumn}
            onChangeCustom={(value: OptionProps[]) => {
              setSelectedColumn(value);
            }}
            paramsApi={{
              table_id: getFieldObjToArr(selectedTable, 'value'),
              page_id: watch('page_root_id'),
              limit: 50,
            }}
            isCheckAll
            onClear={() => setSelectedColumn([])}
            typeLabel="table-column"
          />
        </FormTeraItem>
      </FormTera>
    </Modal>
  );
};

export default TableConfigCopy;
