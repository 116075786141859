import { useMutation, useQueryClient } from '@tanstack/react-query';
import HoverQuickView from '_common/component/HoverQuickView';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import useConfirm from '_common/hooks/useConfirm';
import moment from 'moment';
import InvoiceApi from 'pages/Invoice/_api';
import {
  INVOICE_STATUS,
  INVOICE_STATUS_COLOR,
  TRANSACTION_TYPE,
} from 'pages/Invoice/constants';
import { INVOICE_URL } from 'pages/Invoice/url';
import { useNavigate } from 'react-router-dom';
import { DropdownItem, formatCurrency, notification, Tag } from 'tera-dls';

const InvoiceTable = ({ data, ...restProps }) => {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const { mutate: mutatePayment } = useMutation(
    (variables: any) => InvoiceApi.payment(variables),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-invoice-list']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handlePayment = (id: number, name: string): void => {
    confirm.warning({
      title: 'Xác nhận thanh toán',
      content: (
        <p className="break-word">
          <p> Bạn có chắc muốn thanh toán hóa đơn</p>
          <p>
            <span className="font-bold break-word">{` ${name ?? ''} `} </span>
            này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutatePayment({ params: { bill_id: id } });
      },
    });
  };

  const { mutate: mutateDelete } = useMutation(
    (variables: any) => InvoiceApi.delete(variables),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-invoice-list']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleDelete = (id: number, name: string): void => {
    confirm.warning({
      title: 'Xác nhận xóa hóa đơn',
      content: (
        <p className="break-word">
          <p> Bạn có chắc muốn xóa hóa đơn</p>
          <p>
            <span className="font-bold break-word">{` ${name ?? ''} `} </span>
            này không?
          </p>
        </p>
      ),
      onOk: () => {
        mutateDelete({ id });
      },
    });
  };

  const renderItemActions = (item: any): DropdownItem[] => {
    const dropdownItems: DropdownItem[] = [];
    const isPayment = item.status === 'unpaid' && item.transaction_type === '3';

    dropdownItems.push({
      key: 1,
      label: 'Xem',
      onClick: () => {
        navigate(`${INVOICE_URL.detail.path}/${item?.id}`);
      },
    });
    isPayment &&
      dropdownItems.push({
        key: 2,
        label: 'Xác nhận thanh toán',
        onClick: () => handlePayment(item?.id, item?.code),
      });

    dropdownItems.push({
      key: 2,
      label: <a className="text-red-500">Xóa</a>,
      onClick: () => handleDelete(item?.id, item?.code),
    });

    return dropdownItems;
  };

  const columns = [
    {
      title: 'Mã hóa đơn',
      dataIndex: 'code',
      width: 150,
      render: (val, record) => (
        <span
          className="line-clamp-1 text-blue-500 cursor-pointer inline-block"
          onClick={() => navigate(`${INVOICE_URL.detail.path}/${record?.id}`)}
        >
          {val}
        </span>
      ),
    },
    {
      dataIndex: 'transaction_type',
      title: 'Loại giao dịch',
      width: 150,
      render: (val) => TRANSACTION_TYPE[val],
    },
    {
      dataIndex: 'method',
      title: 'Khách hàng',
      width: 150,
      render: (_, record) => (
        <HoverQuickView
          // avatarUrl={value?.avatar_url}
          email={record?.customer_email}
          name={record?.customer_name}
          phone={record?.customer_phone}
          className="line-clamp-2"
        >
          {record?.customer_name}
        </HoverQuickView>
      ),
    },
    {
      dataIndex: 'total_amount',
      title: 'Tổng giá trị',
      width: 150,
      render: (val) => formatCurrency(val ?? 0),
    },
    {
      dataIndex: 'methods_text',
      title: 'Phương thức thanh toán',
      width: 150,
    },
    {
      dataIndex: 'status',
      title: 'Trạng thái',
      width: 150,
      render: (val) => (
        <Tag color={INVOICE_STATUS_COLOR[val]}>{INVOICE_STATUS[val]}</Tag>
      ),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      width: 150,
      render: (val) => val && moment(val).format(DATE_TIME_FORMAT),
    },
    {
      dataIndex: 'action',
      title: '',
      width: 50,
      render: (_, record) => {
        const items = renderItemActions(record);
        return <ActionDropdown dropdownItems={items} trigger="click" />;
      },
    },
  ];
  return <TableTera {...restProps} data={data} columns={columns} />;
};

export default InvoiceTable;
