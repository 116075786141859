import CardDetail from '_common/component/CardDetail';
import { EmployeeText } from '_common/component/EmployeeText';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import { renderDescription } from '_common/shared/utils';
import { SERVICE_PACKAGE_DETAIL_TAB } from 'pages/ServicePackage/constants';
import { useState } from 'react';
import {
  Badge,
  formatDate,
  Image,
  PhotoOutlined,
  Row,
  Tabs,
  Tag,
} from 'tera-dls';
import Business from './containers/Business';
import Permission from './containers/Permission';
import ServicePackageTab from './containers/ServicePackageTab';

interface IProps {
  dataDetail?: any;
  summary?: any;
}

function ServicePackageDetailContent({ dataDetail, summary }: IProps) {
  const [params, setParams] = useState<any>({ tab: 'servicePackage' });

  const tabItems = Object.keys(SERVICE_PACKAGE_DETAIL_TAB).map((key) => {
    let keyValue;
    if (key === 'servicePackage') {
      keyValue = 'packages';
    } else {
      keyValue = 'businesses';
    }
    const data = summary?.find((item) => item.key === keyValue);
    return {
      key: key,
      label: (
        <h3 className="tab-table">
          {SERVICE_PACKAGE_DETAIL_TAB[key]}
          {key !== 'permission' && <Badge showZero count={data?.count ?? 0} />}
        </h3>
      ),
    };
  });

  const data = [
    {
      title: 'Người tạo',
      value: (
        <EmployeeText
          code={dataDetail?.user_created?.code}
          name={dataDetail?.user_created?.full_name}
        />
      ),
    },
    {
      title: 'Phân quyền',
      value: dataDetail?.role?.title,
    },
    {
      title: 'Ngày tạo',
      value:
        dataDetail?.created_at &&
        formatDate(dataDetail?.created_at, DATE_TIME_FORMAT),
    },
    {
      title: 'Loại gói dịch vụ',
      value: dataDetail?.type_text,
    },
    {
      title: 'Người cập nhật',
      value: (
        <EmployeeText
          code={dataDetail?.user_updated?.code}
          name={dataDetail?.user_updated?.full_name}
        />
      ),
    },
    {
      title: 'Trạng thái',
      value:
        dataDetail?.status === 1 ? (
          <Tag color="green05">Bật</Tag>
        ) : (
          <Tag color="gray02">Tắt</Tag>
        ),
    },
    {
      title: 'Ngày cập nhật',
      value:
        dataDetail?.user_updated &&
        formatDate(dataDetail?.updated_at, DATE_TIME_FORMAT),
    },
  ];

  const tabRender = {
    servicePackage: (
      <ServicePackageTab
        id={dataDetail?.id}
        isDefault={dataDetail?.is_trial_default}
      />
    ),
    permission: <Permission roleId={dataDetail?.role_id} />,
    business: <Business id={dataDetail?.id} />,
  };

  return (
    <>
      <Row className="grid grid-cols-5 gap-2.5">
        <div className="col-span-3 ">
          <CardDetail>
            <div className="grid grid-cols-2 gap-y-[5px] gap-x-5">
              <div className="flex items-center gap-1">
                <span className="text-green-500 break-word ">
                  [{dataDetail?.code}]
                </span>
                -
                <span className="font-medium text-base break-word ">
                  {dataDetail?.name}
                </span>
              </div>
              {renderDescription(data)}
            </div>
          </CardDetail>
        </div>
        <div className="col-span-2">
          <CardDetail>
            <div className="flex gap-[16px]">
              {dataDetail?.file_url ? (
                <Image
                  imageClassName="max-w-[100px] min-w-[100px] max-h-[100px] min-h-[100px] bg-center drop-shadow-md rounded-[5px] overflow-hidden"
                  containerClassName="max-w-[100px] min-w-[100px] max-h-[100px] min-h-[100px] rounded-[5px] overflow-hidden"
                  src={dataDetail?.file_url}
                />
              ) : (
                <div
                  className={
                    ' max-w-[100px] min-w-[100px] max-h-[100px] min-h-[100px] border border-dashed border-gray-200 bg-gray-50 rounded-[10px] flex items-center justify-center cursor-auto'
                  }
                >
                  <PhotoOutlined className="w-8 h-8 text-gray-500 " />
                </div>
              )}
              <p className="break-word ">{dataDetail?.description}</p>
            </div>
          </CardDetail>
        </div>
      </Row>
      <Row>
        <CardDetail>
          <Tabs
            onChange={(val) => setParams({ tab: val })}
            items={tabItems}
            activeKey={params?.tab}
          />
          {tabRender[params?.tab]}
        </CardDetail>
      </Row>
    </>
  );
}

export default ServicePackageDetailContent;
