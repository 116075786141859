import { useMutation, useQuery } from '@tanstack/react-query';
import ButtonCommon from '_common/component/Button';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import useConfirm from '_common/hooks/useConfirm';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  FunnelOutlined,
  getQueryParams,
  notification,
  PlusOutlined,
  Row,
  updateQueryParams,
} from 'tera-dls';
import ConfigPaymentMethodApi from './_api';
import { CardBank } from './components/CardBank';
import ModalQrCode from './components/ModalQrCode';
import { typeBank } from './constants';
import PaymentMethodFilter from './containers/Filter';
import { ModalStatement } from './containers/ModalStatement';
import Searching from './containers/Searching';
import { CONFIG_PAYMENT_METHOD_URL } from './url';

const PaymentMethod = () => {
  const [openModalStatement, setOpenModalStatement] = useState(false);
  const [openQrCode, setOpenQrCode] = useState(false);
  const [record, setRecord] = useState(null);
  const confirm = useConfirm();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };
  const { data: response, refetch } = useQuery(
    ['get-payment-method-list', queryParams],
    () =>
      ConfigPaymentMethodApi.getList({
        limit: 9999,
        ...(queryParams?.card_type_id && {
          card_type_id: queryParams.card_type_id,
        }),
        keyword: queryParams?.keyword,
      }),
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
  }, []);
  const listPaymentMethod = response?.data;
  const { mutate: mutateDeleteCard } = useMutation(
    (id: number) => ConfigPaymentMethodApi.delete(id),
    {
      onSuccess(res) {
        if (res?.code === 200) {
          refetch();
          notification.success({ message: res?.msg });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const onDelete = (id: number, name: string) => {
    confirm.warning({
      title: 'Xoá thẻ',
      content: (
        <>
          <p>Bạn có chắc muốn xóa thẻ</p>
          <p>
            <span className="font-bold break-word">{name}</span> này không?
          </p>
        </>
      ),
      onOk: () => {
        mutateDeleteCard(id);
      },
    });
  };
  const handleSearch = ({ keyword }) => {
    handleUpdateQueryParams({ keyword, page: 1 });
  };

  const handleFilter = (values: any): void => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  return (
    <>
      <div className="p-2.5">
        <div className="flex justify-between items-center mb-4">
          <h3 className="uppercase font-semibold text-base">
            Danh sách phương thức thanh toán
          </h3>
          <ButtonCommon
            className="px-3 py-[5px] rounded flex gap-2.5 bg-blue-500"
            onClick={() => navigate(CONFIG_PAYMENT_METHOD_URL.create.path)}
            icon={<PlusOutlined className="w-4 h-4" />}
          >
            Thêm mới
          </ButtonCommon>
        </div>
        <div className="mb-3 flex justify-between">
          <Searching onSearch={handleSearch} />
          <Button
            type="alternative"
            className="rounded-xsm py-1 px-1 bg-blue-50"
            icon={
              <FunnelOutlined className=" text-blue-500 rounded-[4px] shrink-0" />
            }
            onClick={() => setOpenFilter(true)}
          />
        </div>
        <Row className="grid-cols-2 gap-x-5 gap-y-4">
          {listPaymentMethod &&
            listPaymentMethod.map((item) => (
              <CardBank
                onShowQrCode={() => {
                  setRecord(item);
                  setOpenQrCode(true);
                }}
                onOpenStatement={() => {
                  setOpenModalStatement(true);
                  setRecord(item);
                }}
                onEdit={() =>
                  navigate(
                    `${CONFIG_PAYMENT_METHOD_URL.update.path}/${item?.id}`,
                  )
                }
                onDelete={() => onDelete(item?.id, item?.cardholder)}
                paymentType={typeBank[item?.card_type?.object_type]}
                cardName={item?.card_type?.card_name}
                cardDesc={item?.card_type?.description}
                accountNumber={item?.account_number || item?.account_phone}
                cardHolder={item?.cardholder}
                availableBalance={item?.available_balance}
                imageBank={item?.card_type?.image_bank}
                totalDeposit={item?.total_deposit}
                totalWithdrawal={item.total_withdrawal}
                transactionCount={item.transaction_count}
              />
            ))}
        </Row>
      </div>
      {openModalStatement && (
        <ModalStatement
          id={record?.id}
          open={openModalStatement}
          onClose={() => setOpenModalStatement(false)}
        />
      )}
      {openQrCode && (
        <ModalQrCode
          id={record?.id}
          open={openQrCode}
          onClose={() => {
            setOpenQrCode(false);
          }}
        />
      )}
      {openFilter && (
        <PaymentMethodFilter
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
    </>
  );
};

export default PaymentMethod;
