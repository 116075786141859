import FileItem from './FileItem';

interface IProps {
  value?: any;
}

const File = (props: IProps) => {
  const { value } = props;

  return value && value.length > 0 ? (
    <>
      <h3 className="pb-5">Tệp</h3>
      <div className="grid gap-5 grid-cols-5">
        {value?.map((item) => (
          <FileItem key={item.id} value={item} />
        ))}
      </div>
    </>
  ) : null;
};

export default File;
