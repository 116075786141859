export const TYPE_SERVICE = {
  official: 'Chính thức',
  trial: 'Dùng thử',
};

export const PRODUCT_TIME = {
  1: '1 tháng',
  6: '6 tháng',
  12: '12 tháng',
  18: '18 tháng',
  24: '24 tháng',
};

export const PRODUCT_AMOUNT_USER = {
  5: '5 user',
  10: '10 user',
  15: '15 user',
  20: '20 user',
  25: '25 user',
  30: '30 user',
  50: '50 user',
};

export const PRODUCT_ORDER_AMOUNT = {
  100: '100 đơn',
  500: '500 đơn',
  1000: '1000 đơn',
  2000: '2000 đơn',
  5000: '5000 đơn',
  20000: '20000 đơn',
  10000: '10000 đơn',
  50000: '50000 đơn',
};

export const PRODUCT_MEMORY_CAPACITY = {
  4: '4 GB',
  5: '5 GB',
  20: '20 GB',
  50: '50 GB',
  128: '128 GB',
  256: '256 GB',
  512: '512 GB',
  1024: '1 TB',
};

export const SERVICE_PACKAGE_DETAIL_TAB = {
  servicePackage: 'Gói sản phẩm',
  permission: 'DS quyền',
  business: 'DS Doanh nghiệp',
};

export const STATUS_SERVICE_BUSINESS = {
  not_activated: 'Chưa kích hoạt',
  is_active: 'Hoạt động',
  expired: 'Hết hạn',
  finished: 'Kết thúc',
};

export const STATUS_SERVICE_BUSINESS_COLOR = {
  not_activated: 'blue05',
  is_active: 'green05',
  expired: 'gray02',
  finished: 'red05',
};
