import { useQuery } from '@tanstack/react-query';
import ConfigPermissionApi from 'pages/System/containers/ConfigPermission/_api';
import { useEffect, useState } from 'react';
import { Modal, PaginationProps, Tag, Toggle } from 'tera-dls';
import Searching from './Searching';
import TableTera from '_common/dof/TableTera';
import { TYPE_ROLE } from 'pages/System/containers/ConfigPermission/constants';

interface IProps {
  open: boolean;
  onClose: () => void;
  businessId: number;
  value: any;
  onSubmit: (selectedData: any) => void;
  disableToggle?: boolean;
}
const PermissionRole = ({
  open,
  onClose,
  businessId,
  value,
  onSubmit,
  disableToggle = false,
}: IProps) => {
  const [params, setParams] = useState<any>({ page: 1, limit: 10 });
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [selectedData, setSelectedData] = useState<any>();

  const { data, refetch, isLoading } = useQuery(
    ['get-config-permission', params, businessId],
    () =>
      ConfigPermissionApi.getList({
        ...params,
        ...(!params?.toggle && { business_id: businessId }),
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    businessId && refetch();
  }, [businessId, params]);

  useEffect(() => {
    setSelectedRowKeys([value?.id]);
    setSelectedData(value);
  }, [value]);

  const handleSearch = ({ keyword }) => {
    setParams((prev) => ({ ...prev, keyword, page: 1 }));
  };

  const columns = [
    {
      title: 'Role ID',
      dataIndex: 'id',
      width: 100,
    },
    {
      title: 'Mã quyền',
      dataIndex: 'code',
      width: 100,
    },
    {
      title: 'Tiêu đề',
      dataIndex: 'title',
      width: 200,
      render: (val) => <span className="line-clamp-2">{val}</span>,
    },
    {
      title: 'Loại quyền',
      dataIndex: 'type',
      width: 100,
      render: (val) => (val ? TYPE_ROLE[val] : ''),
    },

    {
      title: 'Mặc định',
      dataIndex: 'is_default',
      width: 80,
      render: (value) =>
        Boolean(value) ? (
          <Tag color="green05">Có</Tag>
        ) : (
          <Tag color="gray01">Không</Tag>
        ),
    },
    {
      title: 'Business ID',
      dataIndex: 'business_id',
      width: 100,
    },
  ];

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    setParams((prev) => ({ ...prev, page: page, limit: pageSize }));
  };

  const rowSelections = {
    type: 'radio',
    selectedRowKeys,
    onChange: (rowKeys, records) => {
      setSelectedRowKeys(rowKeys);
      setSelectedData(records[0]);
    },
  };

  return (
    <Modal
      centered={true}
      title="Danh sách phân quyền"
      open={open}
      width={1000}
      okText="Đồng ý"
      cancelText="Hủy"
      closeIcon={false}
      maskClosable={false}
      onOk={() => {
        if (selectedData) {
          onSubmit(selectedData);
          onClose();
        }
      }}
      onCancel={onClose}
      okButtonProps={{
        disabled: !selectedData,
      }}
    >
      <div className="flex gap-[16px] flex-col">
        <div className="flex justify-between">
          <Searching onSearch={handleSearch} />
          {!disableToggle && (
            <div className="flex gap-2.5 items-center">
              <div className="flex items-center">Hiển thị tất cả các quyền</div>
              <Toggle
                className="items-start"
                checked={params?.toggle ?? false}
                onChange={(e) => {
                  setParams((prev) => ({ ...prev, toggle: e.target?.checked }));
                  // setSelectedRowKeys([]);
                  // setSelectedData(undefined);
                }}
              />
            </div>
          )}
        </div>
        <TableTera
          rowKey={'id'}
          columns={columns}
          data={data?.data}
          loading={isLoading}
          rowSelection={rowSelections}
          pagination={{
            onChange: handleChangePage,
            total: data?.total || 0,
            current: data?.current_page,
            pageSize: data?.per_page,
            to: data?.to,
            from: data?.from,
          }}
          onRow={(record: any) => ({
            onClick: () => {
              setSelectedData(record);
              setSelectedRowKeys([record?.id]);
            },
            className:
              selectedRowKeys?.includes(record?.id) &&
              'tera-table-cell-row-focused',
          })}
        />
      </div>
    </Modal>
  );
};

export default PermissionRole;
