export const BUSINESS_TABS = {
  all: 'Tất cả',
  no_activated: 'Chưa kích hoạt',
  waiting_for_activation: 'Chờ kích hoạt',
  is_active: 'Đang hoạt động',
  cancelled: 'Ngưng hoạt động',
};

export const BUSINESS_DETAIL_TABS = {
  information: 'Chi tiết doanh nghiệp',
  services: 'DS gói dịch vụ',
  permission: 'DS quyền',
  users: 'DS người dùng',
  group_role: 'Nhóm quyền',
};

export const BUSINESS_STATUS = {
  no_activated: 'Chưa kích hoạt',
  waiting_for_activation: 'Chờ kích hoạt',
  is_active: 'Đang hoạt động',
  cancelled: 'Ngưng hoạt động',
};

export const BUSINESS_STATUS_COLOR = {
  no_activated: 'blue03',
  waiting_for_activation: 'yellow03',
  is_active: 'green03',
  expired: 'red03',
  cancelled: 'gray01',
};

export const TRIAL_TIME = {
  7: '7 ngày',
  15: '15 ngày',
  30: '30 ngày',
};
