import { REGEX } from '_common/constants/common';
import { messageValidate } from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import TextArea from '_common/dof/Control/TextArea';
import { FormTeraItem } from '_common/dof/FormTera';
import SelectBank, { OptionsSelectBank } from '_common/dof/Select/SelectBank';
type BankFormProps = {
  onChangeBank: (value: OptionsSelectBank) => void;
};
const BankForm = ({ onChangeBank }: BankFormProps) => {
  return (
    <>
      <FormTeraItem
        name="card_type_id"
        label="Ngân hàng"
        rules={[{ required: messageValidate.emptySelect }]}
      >
        <SelectBank
          onChangeCustom={(value: OptionsSelectBank) => onChangeBank(value)}
          paramsApi={{ object_type: 1 }}
        />
      </FormTeraItem>
      <FormTeraItem
        name="account_number"
        label="Số tài khoản"
        rules={[
          {
            required: messageValidate.emptyText,
          },
          {
            pattern: {
              value: new RegExp(REGEX.CARD_NUMBER),
              message: messageValidate.format,
            },
          },
        ]}
      >
        <Input maxLength={20} />
      </FormTeraItem>
      <FormTeraItem
        name="cardholder"
        label="Người thụ hưởng"
        rules={[{ required: messageValidate.emptyText }]}
      >
        <Input className="uppercase placeholder-shown:normal-case" />
      </FormTeraItem>
      <FormTeraItem name="description" label="Format nội dụng">
        <TextArea rows={5} maxLength={1000} />
      </FormTeraItem>
    </>
  );
};

export default BankForm;
