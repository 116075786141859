import Search from '_common/dof/Control/Search';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { MagnifyingGlassOutlined, getQueryParams } from 'tera-dls';

function ManagePageHeader({ onSearch }) {
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const form = useForm({
    defaultValues: {
      keyword: queryParams?.keyword,
    },
  });
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = form;
  const handleSearch = (value) => {
    if (isDirty) {
      onSearch(value);
      reset({ ...value }, { keepValues: true });
    }
  };

  return (
    <FormTera
      form={form}
      onSubmit={handleSubmit(handleSearch)}
      className="sm:w-full md:w-full lg:w-[400px]"
    >
      <FormTeraItem className="mb-0" name="keyword">
        <Search
          placeholder="Tìm kiếm theo mã, tên trang"
          icon={<MagnifyingGlassOutlined />}
        />
      </FormTeraItem>
    </FormTera>
  );
}

export default ManagePageHeader;
