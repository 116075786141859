import InputVerificationCode from '_common/component/InputVerificationCode';

type VerificationCodeProps = {
  onChangeCode: (code: string) => void;
};
const VerificationCode = ({ onChangeCode }: VerificationCodeProps) => {
  return (
    <div className="space-y-4">
      <h3 className="font-semibold text-base">Nhập mã xác thực</h3>
      <p>
        Chúng tôi đã gửi cho bạn 1 mã xác thực gồm 6 chữ số vào email{' '}
        <span className="text-blue-600">terasolutionvn@gmail.com</span>. Mã xác
        thực có sẽ hết hạn trong 2 phút. Liên hệ với chúng tôi nếu bạn có gặp
        vấn đề rắc rối nào.
      </p>
      <div className="h-[300px] flex items-center justify-center flex-col">
        <div className="flex items-center gap-x-4">
          <InputVerificationCode onChangeCode={(code) => onChangeCode(code)} />
        </div>
        <p className="mt-[30px]">Thời gian còn lại: 2:00</p>
      </div>
    </div>
  );
};

export default VerificationCode;
