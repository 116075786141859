import { Col, Row } from 'tera-dls';
import StatisticCashExpense from './containers/StatisticCashExpense';
import StatisticCashExpenseByPaymentMethod from './containers/StatisticCashExpenseByPaymentMethod';
import StatisticCashExpenseByTime from './containers/StatisticCashExpenseByTime';
import StatisticCashExpenseByCustomer from './containers/StatisticCustomer';
import { usePermission } from '_common/hooks/usePermission';
import { PERMISSION_KEY } from './permission';

const Statistic = () => {
  const { hasPage } = usePermission();
  return (
    <div className="p-2.5">
      <div className="font-semibold	text-base uppercase mb-2.5">
        Thống kê thu chi
      </div>
      <Row className="grid grid-cols-2 gap-[16px]">
        {hasPage(PERMISSION_KEY.STATISTIC_VIEW_STATISTIC_TYPE) && (
          <Col className="col-span-1">
            <StatisticCashExpense />
          </Col>
        )}
        {hasPage(PERMISSION_KEY.STATISTIC_VIEW_STATISTIC_TIME) && (
          <Col className="col-span-1">
            <StatisticCashExpenseByTime />
          </Col>
        )}
        {hasPage(PERMISSION_KEY.STATISTIC_VIEW_STATISTIC_PAYMENT_METHOD) && (
          <Col className="col-span-1">
            <StatisticCashExpenseByPaymentMethod />
          </Col>
        )}
        {hasPage(PERMISSION_KEY.STATISTIC_VIEW_STATISTIC_CUSTOMER) && (
          <Col className="col-span-1">
            <StatisticCashExpenseByCustomer />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Statistic;
