import { endpoint, adminEndpoint } from '_common/constants/common';
import api from 'states/drivers';

const ConfigPermissionEndpoint = `${endpoint}/administrator/business/group-role`;
const AdminEndpoint = `${adminEndpoint}/administrator`;

const PermissionGroupApi = {
  getList: async ({ params }) =>
    await api
      .get(`${AdminEndpoint}/business/list-group-role`, params)
      .then((result) => result.data?.data),
  getMembers: async ({ params }) =>
    await api
      .get(`${ConfigPermissionEndpoint}/list-member`, params)
      .then((result) => result.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${ConfigPermissionEndpoint}/detail/${id}`)
      .then((result) => result.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${ConfigPermissionEndpoint}/create`, params)
      .then((result) => result?.data),
  update: async ({ id, params }) =>
    await api
      .put(`${ConfigPermissionEndpoint}/update/${id}`, params)
      .then((result) => result?.data),
  delete: async ({ id }) =>
    await api
      .delete(`${ConfigPermissionEndpoint}/delete/${id}`)
      .then((result) => result?.data),
  getListConfigControl: async (params?: any) =>
    await api
      .get(`${AdminEndpoint}/business/list-role`, params)
      .then((result) => result?.data?.data),
  getListModule: async (params?: any) =>
    await api
      .get(`${ConfigPermissionEndpoint}/list-module`, params)
      .then((result) => result?.data?.data),
  getListRoleHasPermission: async (params) =>
    await api
      .get(`${ConfigPermissionEndpoint}/role-has-permission`, params)
      .then((result) => result?.data?.data),
  setting: async (params) =>
    await api
      .post(`${ConfigPermissionEndpoint}/config-permission`, params)
      .then((result) => result.data),
};

export default PermissionGroupApi;
