import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueries, useQueryClient } from '@tanstack/react-query';
import { messageWarning } from '_common/constants/message';

import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { REGEX } from '_common/constants/common';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { Form, FormItem, Input, Modal, notification } from 'tera-dls';
import * as yup from 'yup';
import ModuleApi from '../../_api';

interface IProps {
  open: boolean;
  id?: number;
  onClose: () => void;
  onChangeDataSuccess?: () => void;
}

interface IForm {
  code: string;
  title: string;
  sub_title: string;
  url: string;
  color: string;
}
const schema = yup.object().shape({
  code: yup
    .string()
    .required('Vui lòng nhập trường này!')
    .trim()
    .max(191, 'Không nhập quá 191 ký tự!')
    .matches(REGEX.CODE, "Mã trang không dấu và chỉ chứa kí tự '_'"),
  title: yup
    .string()
    .required('Vui lòng nhập trường này!')
    .trim()
    .max(255, 'Không nhập quá 255 ký tự!'),
  sub_title: yup.string().nullable(),
  url: yup.string().nullable().max(255, 'Không nhập quá 255 ký tự!'),
  color: yup.string().nullable(),
});

const ModuleForm = (props: IProps) => {
  const { open = false, id, onClose, onChangeDataSuccess } = props;

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<IForm>({
    resolver: yupResolver<IForm>(schema),
    mode: 'onChange',
  });
  const confirm = useConfirm();

  const queryClient = useQueryClient();

  const handleClose = (): void => {
    reset();
    onClose();
  };

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        className: 'modal-button-center',
        onOk: () => {
          handleClose();
        },
      });
    } else {
      handleClose();
    }
  };

  const updateData = (data) => {
    const values = _.pick(data, ['code', 'title', 'sub_title', 'url', 'color']);
    reset({
      ...values,
    });
  };

  useQueries({
    queries: [
      {
        queryKey: ['get-page-detail', id],
        queryFn: () => ModuleApi.getDetail(id),
        enabled: !!open && !!id,
        onSuccess: (data) => {
          updateData(data);
        },
      },
    ],
  });

  const { mutate: mutateForm } = useMutation(
    (variables: any) => {
      return id ? ModuleApi.update(id, variables) : ModuleApi.create(variables);
    },
    {
      onSuccess: ({ code, msg }) => {
        if (code === 200) {
          onChangeDataSuccess && onChangeDataSuccess();
          queryClient.removeQueries({
            queryKey: ['get-page-by-menu-id'],
          });
          onClose();
          notification.success({
            message: msg,
          });
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const trimData = (code: string): string => {
    return code.split(' ').join('');
  };

  const handleSubmitForm = (values: { [key: string]: any }): void => {
    const code = trimData(values.code);
    mutateForm({
      ...values,
      code,
      status: values?.status ? 'active' : 'inactive',
    });
  };

  return (
    <Modal
      centered={true}
      title={id ? ' SỬA MODULE' : 'THÊM MODULE'}
      open={open}
      width={500}
      closeIcon={false}
      okText="Lưu"
      cancelText="Huỷ"
      onOk={() => handleSubmit(handleSubmitForm)()}
      onCancel={handleCloseConfirm}
      destroyOnClose={true}
    >
      <Form className="h-full">
        <FormItem
          label="Mã trang"
          isError={!!errors?.code}
          messages={errors?.code?.message as string}
        >
          <Input
            {...register('code')}
            placeholder="Vui lòng nhập"
            className="w-full"
          />
        </FormItem>
        <FormItem
          label="Tiêu đề"
          isError={!!errors?.title}
          messages={errors?.title?.message as string}
        >
          <Input
            {...register('title')}
            placeholder="Vui lòng nhập"
            className="w-full"
          />
        </FormItem>
        <FormItem label="Tiêu đề phụ" isRequired={false}>
          <Input
            {...register('sub_title')}
            placeholder="Vui lòng nhập"
            className="w-full"
          />
        </FormItem>
        <FormItem
          label="Đường dẫn"
          isError={!!errors?.url}
          messages={errors?.url?.message as string}
        >
          <Input
            {...register('url')}
            placeholder="Vui lòng nhập"
            className="w-full"
          />
        </FormItem>
        <FormItem label="Màu sắc" isRequired={false}>
          <Input
            {...register('color')}
            placeholder="Vui lòng nhập"
            className="w-full"
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default ModuleForm;
