import { useQuery } from '@tanstack/react-query';
import TableTera from '_common/dof/TableTera';
import _ from 'lodash';
import ServicePackageApi from 'pages/ServicePackage/_api';
import { useEffect, useMemo } from 'react';
import { CheckOutlined } from 'tera-dls';

const Permission = ({ roleId }) => {
  const {
    data: detail,
    refetch,
    isLoading,
  } = useQuery(
    ['get-role-permission-detail', roleId],
    () => ServicePackageApi.getListRoleHasPermission(roleId),
    {
      enabled: !!roleId,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    roleId && refetch();
  }, [roleId]);

  // console.log(detail);

  const data = useMemo(() => {
    const data = detail?.map((item) => ({
      id: item?.id,
      function: item.group_control?.title,
      epicId: item.group_control?.epic_id,
      epic: item.group_control?.epic?.name,
      moduleId: item.group_control?.module?.id,
      module: item.group_control?.module?.title,
    }));

    const epics: any = _.groupBy(data, 'epicId');
    const value = Object.values(epics)?.map((item: any) => {
      const mergedObject = item?.reduce(
        (prev: any, cur: any) => {
          return {
            ...prev,
            ...cur,
            functions: [...prev?.functions, cur?.function],
          };
        },
        { functions: [] },
      );
      return mergedObject;
    });
    return value?.sort((a, b) => a?.module?.localeCompare(b?.module));
  }, [detail]);

  const columns = [
    {
      dataIndex: 'module',
      title: 'Module',
      width: 300,
      onCell: (record) => {
        const modules = data.filter(
          (item) => item?.moduleId === record?.moduleId,
        );
        return {
          rowSpan: modules[0]?.epicId === record?.epicId ? modules?.length : 0,
          className: '!align-middle border !bg-inherit',
        };
      },
    },
    {
      dataIndex: 'epic',
      title: 'Epic',
      width: 300,
      onCell: () => {
        return {
          className: '!align-middle border !bg-inherit',
        };
      },
    },
    {
      dataIndex: 'functions',
      title: 'Function',
      onCell: () => {
        return {
          className: '!bg-inherit border',
        };
      },
      render: (val) => {
        return (
          <div className="grid gap-5 grid-cols-4">
            {val.map((item) => (
              <div className="flex gap-[5px] col-span-1">
                <CheckOutlined className="w-4 text-blue-600" />{' '}
                <span>{item}</span>
              </div>
            ))}
          </div>
        );
      },
    },
  ];
  return (
    <>
      <TableTera
        loading={isLoading}
        rowKey={'id'}
        columns={columns}
        data={data ?? []}
        zebra={false}
      />
    </>
  );
};

export default Permission;
