import { useQuery } from '@tanstack/react-query';
import { useDebounce } from '_common/hooks/useDebounce';
import { forwardRef, useState } from 'react';
import { OptionProps, SelectProps } from 'tera-dls';
import SelectEntity from '../SelectEntity';
import { AdministratorApi } from '../../_api';
import { TParamsApiDof } from '_common/dof/interfaces';
import { useTeraForm } from '_common/dof/FormTera/TeraFormContext';
import { useTeraFormItem } from '_common/dof/FormTera/TeraItemContext';
import { EmployeeText } from '_common/component/EmployeeText';

interface SelectModuleProps extends SelectProps {
  placeholder?: string;
  paramsApi?: TParamsApiDof;
}

const SelectModule = forwardRef(
  (
    { placeholder = 'Chọn Module', paramsApi, ...props }: SelectModuleProps,
    ref,
  ) => {
    const [searchPosition, setSearchPosition] = useState('');
    const searchPositionDebounce = useDebounce(searchPosition, 300);
    const { form } = useTeraForm();
    const { item } = useTeraFormItem();
    const includesID = form?.watch(`${item?.name}`);

    const paramsQuery = {
      limit: 15,
      page: 1,
      keyword: searchPositionDebounce,
      include_id: includesID,
      ...paramsApi,
    };
    const { data: listModule } = useQuery(
      ['get-modules', paramsQuery],
      () => AdministratorApi.getListModule(paramsQuery),
      {
        staleTime: 300000,
        cacheTime: 300000,
      },
    );

    const optionsPosition: OptionProps[] =
      listModule?.data?.map((module) => ({
        labelDisplay: (
          <EmployeeText
            code={module?.code}
            name={module?.title}
            className="uppercase"
          />
        ),
        label: module?.title,
        value: module?.id,
      })) ?? [];

    return (
      <SelectEntity
        allowClear
        showSearch
        onSearch={setSearchPosition}
        placeholder={placeholder}
        options={optionsPosition}
        {...props}
        ref={ref}
      />
    );
  },
);

export default SelectModule;
