import { useQuery } from '@tanstack/react-query';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ChartBarProps, Spin } from 'tera-dls';
import { StatisticCashExpenseByTimeParamContext } from '..';
import Header from '../../Header';
import StatisticApi from '../../StatisticCashExpense/api';
import ModalChart from './Modal';
import SettingModal from './Setting';

import { DATE_BACKEND_FORMAT, DATE_FORMAT } from '_common/constants/common';
import moment from 'moment';
import {
  CASH_RECEIPT_TYPE,
  EXPENSE_VOUCHER_TYPE,
} from 'pages/Invoice/constants';
import HorizontalBarChart from '_common/component/Chart/BarChart/HorizontalBarChart';

//const labels = ['20/9 ', '20/10 ', '20/11 ', '20/12 '];

// const dataChart = {
//   labels,
//   datasets: [
//     {
//       label: 'Dataset 1',
//       data: [1202, 423, 351, 145, 256, 321, 564],
//       borderColor: 'rgb(255, 99, 132)',
//       backgroundColor: '#3F83F8',
//     },
//     {
//       label: 'Dataset 2',
//       data: [168, 325, 450, 256, 356, 156, 254],
//       borderColor: 'rgb(53, 162, 235)',
//       backgroundColor: '#F9C74F',
//     },
//   ],
// };

export type OpportunityValueStatisticByStage = 'small' | 'large';

interface IProps {
  type?: OpportunityValueStatisticByStage;
  onClose?: () => void;
  chartOptions?: ChartBarProps['options'];
  chartClassName?: string;
  defaultLengthShowTick?: number;
  defaultLengthScroll?: number;
  labelMaxLength?: number;
}

const Content = (props: IProps) => {
  const { type = 'small', onClose, chartClassName = '' } = props;
  const [openModal, setOpenModal] = useState<boolean>();
  const [openSetting, setOpenSetting] = useState<boolean>(false);
  const { contextParams } = useContext(StatisticCashExpenseByTimeParamContext);

  const currentWeek = [
    moment().startOf('week').format(DATE_BACKEND_FORMAT),
    moment().endOf('week').format(DATE_BACKEND_FORMAT),
  ];

  const [params, setParams] = useState<any>({
    pay_method_type: ['cash', 'transfer'],
    receipt_type: Object.keys(CASH_RECEIPT_TYPE),
    expense_type: Object.keys(EXPENSE_VOUCHER_TYPE),
    date: currentWeek,
    time: 'currentWeek',
    show_by: 'day',
  });

  const {
    data: response,
    refetch,
    isRefetching,
  } = useQuery(
    ['get-statistic-time', params],
    () =>
      StatisticApi.byTime({
        params: {
          ...(params?.pay_method_type?.[0] && {
            pay_method_type: params?.pay_method_type?.join(','),
          }),
          ...(params?.receipt_type?.[0] && {
            receipt_type: params?.receipt_type?.join(','),
          }),
          ...(params?.expense_type?.[0] && {
            expense_type: params?.expense_type?.join(','),
          }),
          ...(params?.date?.[0] && {
            date: params?.date?.join(','),
          }),
          show_by: params?.show_by,
        },
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const dataSource = useMemo(() => {
    const labels = [];
    const dataCash = [];
    const dataExpense = [];
    const backgroundColor = [];
    response &&
      Object.entries(response)?.forEach(([key, value]) => {
        labels.push(key);
        dataCash.push((value as any)?.receipt);
        dataExpense.push((value as any)?.expense_voucher);
        backgroundColor.push(CASH_RECEIPT_TYPE[key] ? '#31C48D' : '#F98080');
      });
    return {
      labels: labels?.map((date) => {
        if (date.includes(',')) {
          const data = date.split(',');
          const dateStart = moment(data[0]).format(DATE_FORMAT);
          const dateEnd = moment(data[1]).format(DATE_FORMAT);
          return `${dateStart} - ${dateEnd}`;
        }
        return date;
      }),
      datasets: [
        {
          label: 'Thu',
          data: dataCash,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: '#3F83F8',
        },
        {
          label: 'Chi',
          data: dataExpense,
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: '#F9C74F',
        },
      ],
    };
  }, [response]);

  useEffect(() => {
    contextParams && setParams(contextParams);
  }, [contextParams]);

  useEffect(() => {
    refetch();
  }, [params]);

  const isSmallType = type === 'small';

  return (
    <>
      <div className="rounded-[5px] border-[1px] bg-white h-full flex flex-col overflow-hidden">
        <Header
          title={'Thu chi theo thời gian'}
          onClickRefresh={() => refetch()}
          onClickSetting={() => setOpenSetting(true)}
          onZoomOut={() => setOpenModal(true)}
          onZoomIn={() => onClose()}
          isSmallType={isSmallType}
        />
        <Spin spinning={isRefetching}>
          <div className="p-[16px] h-full ">
            <HorizontalBarChart
              defaultLabelMaxLength={30}
              type={type}
              data={dataSource}
              chartClassName={chartClassName}
              containerClassName="pl-2 pr-5"
            />
          </div>
        </Spin>
      </div>
      {openModal && (
        <ModalChart
          open={openModal}
          onClose={() => setOpenModal(false)}
          type="large"
        />
      )}
      {openSetting && (
        <SettingModal
          open={openSetting}
          onClose={() => setOpenSetting(false)}
          onSubmit={setParams}
          defaultValues={params}
        />
      )}
    </>
  );
};

export default Content;
