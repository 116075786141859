export const PERMISSION_KEY = {
  SERVICE_PACKAGE_VIEW_SERVICE_LIST: 'service_package_view_service_list',
  SERVICE_PACKAGE_VIEW_SERVICE_DETAIL: 'service_package_view_service_detail',

  SERVICE_PACKAGE_CREATE_SERVICE: 'service_package_create_service',
  SERVICE_PACKAGE_UPDATE_SERVICE: 'service_package_update_service',
  SERVICE_PACKAGE_DELETE_SERVICE: 'service_package_delete_service',

  SERVICE_PACKAGE_SEARCH_SERVICE: 'service_package_search_service',
};
