import { useDrop } from 'react-dnd';
import { Checkbox } from 'tera-dls';
import { IColumnType } from '../_interface';
import { TYPE_HIDDEN_TABLE_COLUMN_DND } from '../_constant';

interface IProps {
  value: Array<IColumnType>;
  onShowColumn: (val?) => void;
  onHideColumn?: (val?) => void;
}

const HiddenColumn = (props: IProps) => {
  const { value = [], onShowColumn, onHideColumn } = props;
  const sortedColumns: Array<IColumnType> = value?.sort(
    (a, b) => a.order - b.order,
  );

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: TYPE_HIDDEN_TABLE_COLUMN_DND,
      drop({ id }) {
        const column = sortedColumns?.find((item) => item.id == id);
        onHideColumn && onHideColumn(column);
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [sortedColumns, onHideColumn],
  );

  return (
    <div ref={drop} className={`${isOver && 'bg-[#e8f5ff]'} rounded-md`}>
      {sortedColumns?.map((column: IColumnType) => {
        const { id, status = 'active', title, type } = column;
        return (
          <div className="py-[0.9375rem] pl-2.5" key={id}>
            <Checkbox
              onChange={() => status === 'inactive' && onShowColumn(column)}
              value={id}
              checked={status === 'inactive'}
              className={`${status === 'inactive' ? 'cursor-default' : ''}`}
            >
              <span
                className={`text-normal font-normal ${
                  status !== 'inactive' ? 'text-gray-400 cursor-default' : ''
                }`}
              >
                {type === 'action' ? 'action' : title}
              </span>
            </Checkbox>
          </div>
        );
      })}
    </div>
  );
};

export default HiddenColumn;
