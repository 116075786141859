import Search from '_common/dof/Control/Search';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { useForm } from 'react-hook-form';
import { MagnifyingGlassOutlined } from 'tera-dls';

interface IPropsHeader {
  onSearch: (value) => void;
  title: string;
}

function Header({ onSearch, title }: IPropsHeader) {
  const form = useForm();

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = form;

  const handleSearch = (value) => {
    if (isDirty) {
      onSearch(value);
      reset({ ...value }, { keepValues: true });
    }
  };

  return (
    <FormTera
      form={form}
      onSubmit={handleSubmit(handleSearch)}
      className="sm:w-full md:w-full lg:w-[400px]"
    >
      <FormTeraItem className="mb-0" name="keyword">
        <Search
          placeholder={title}
          icon={<MagnifyingGlassOutlined />}
          className="h-10"
        />
      </FormTeraItem>
    </FormTera>
  );
}

export default Header;
