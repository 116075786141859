import { useQuery } from '@tanstack/react-query';
import { TParamsApiDof } from '_common/dof/interfaces';
import { useDebounce } from '_common/hooks/useDebounce';
import UserApi from 'pages/User/_api/userList';
import { forwardRef, useMemo, useState } from 'react';
import { OptionProps } from 'tera-dls';
import { SelectProps } from 'tera-dls/lib/components/Select';
import SelectEntity from '../SelectEntity';

interface SelectEmployeeProps extends SelectProps {
  paramsApi?: TParamsApiDof;
}

const SelectUser = forwardRef<HTMLInputElement, SelectEmployeeProps>(
  (
    { selectedValue, placeholder = 'Vui lòng chọn', paramsApi, mode, ...props },
    ref,
  ) => {
    const [searchEmployee, setSearchEmployee] = useState<string>('');
    const searchEmployeeDebounce = useDebounce(searchEmployee, 300);

    const { data: listUser } = useQuery(
      ['get-list-user', searchEmployeeDebounce, paramsApi],
      () =>
        UserApi.getList({
          params: {
            page: 1,
            limit: 10,
            keyword: searchEmployeeDebounce,
            ...paramsApi,
          },
        }),
      {
        staleTime: 300000,
        cacheTime: 300000,
      },
    );

    const optionsEmployee: OptionProps[] = useMemo(() => {
      const options = listUser?.data?.map((user) => ({
        label: mode === 'multiple' ? user?.full_name : `${user?.full_name}`,
        value: user?.id,
      }));
      return options;
    }, [listUser, mode]);

    return (
      <SelectEntity
        ref={ref}
        allowClear
        showSearch
        labelInValue
        placeholder={placeholder}
        options={optionsEmployee}
        searchValue={searchEmployee}
        selectedValue={selectedValue}
        onSearch={setSearchEmployee}
        mode={mode}
        {...props}
      />
    );
  },
);

export default SelectUser;
