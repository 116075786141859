import { ReactComponent as IconRecharge } from 'styles/images/payment-method/recharge.svg';
import { ReactComponent as IconWithdrawal } from 'styles/images/payment-method/withdrawal.svg';
import { CreditCardOutlined, Tag } from 'tera-dls';
export const typeBank = {
  1: 'bank',
  2: 'e-wallet',
};
export const RenderTransactionType = ({ type }) => {
  const objectType = {
    1: { name: 'Nạp tiền', icon: <IconRecharge /> },
    2: { name: 'Rút tiền', icon: <IconWithdrawal /> },
    3: {
      name: 'Thanh toán',
      icon: <CreditCardOutlined className="text-blue-500 w-4" />,
    },
  };

  return (
    <div className="flex items-center gap-x-2">
      {objectType[type]?.icon}
      <span> {objectType[type]?.name}</span>
    </div>
  );
};

export const statusTransactionRender = {
  1: <Tag color="green03">Hoàn thành</Tag>,
  2: <Tag color="gray01">Thất bại</Tag>,
  3: <Tag color="yellow03">Đang xử lý</Tag>,
  4: <Tag color="red03">Báo lỗi</Tag>,
};

export const PAYMENT_METHOD = {
  1: 'Nạp tiền',
  2: 'Rút tiền',
};
export const methodsText = {
  1: 'Chuyển khoản',
  2: 'Ví điện tử',
};

export const TransactionStatus = {
  1: 'Hoàn thành',
  2: 'Thất bại',
  3: 'Đang xử lý',
  4: 'Báo lỗi',
};

export const TransactionStatusColor = {
  1: 'green03',
  2: 'gray01',
  3: 'yellow03',
  4: 'red03',
};

export const TransactionMethod = {
  1: 'Chuyển khoản',
  2: 'Ví điện tử',
  3: 'Ví Tera',
};
