import { DATE_TIME_FORMAT } from '_common/constants/common';
import { renderDescription } from '_common/shared/utils';
import _ from 'lodash';
import moment from 'moment';
import {
  INVOICE_STATUS,
  INVOICE_STATUS_COLOR,
  INVOICE_TYPE,
  TRANSACTION_TYPE,
} from 'pages/Invoice/constants';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Col, formatCurrency, Row, Spin, Tag } from 'tera-dls';
import CartTable from '../CartPayment/CartTable';
import PaymentInformation from '../CartPayment/Payment/containers/PaymentInformation';
import PaymentMethod from '../CartPayment/Payment/containers/PaymentMethod';
import FormTera from '_common/dof/FormTera';

interface IProps {
  value: any;
  loading: boolean;
}
const InvoiceDetailContent = (props: IProps) => {
  const { value, loading } = props;
  const form = useForm({ mode: 'onChange' });

  useEffect(() => {
    if (value) {
      form.setValue('methods', String(value?.methods ?? 1));
    } else {
      form.setValue('methods', '1');
    }
    form.setValue('rule', false);
  }, [value]);

  const invoice = [
    {
      key: 'code',
      title: 'Mã hoá đơn',
      value: value?.code,
    },
    {
      key: 'amount',
      title: 'Tổng giá trị',
      value: formatCurrency(value?.total_amount ?? 0),
    },
    {
      key: 'transaction_type',
      title: 'Loại giao dịch',
      value: TRANSACTION_TYPE[value?.transaction_type],
    },
    {
      key: 'created_date',
      title: 'Ngày tạo đơn',
      value: moment(value?.created_at).format(DATE_TIME_FORMAT),
    },
    {
      key: 'type',
      title: 'Loại hóa đơn',
      value: INVOICE_TYPE[value?.type],
    },

    {
      key: 'note',
      title: 'Ghi chú',
      value: value?.note,
    },
    {
      key: 'status',
      title: 'Trạng thái',
      value: (
        <Tag color={INVOICE_STATUS_COLOR[value?.status]}>
          {INVOICE_STATUS[value?.status]}
        </Tag>
      ),
    },
  ];

  const information = [
    {
      key: 'name',
      title: 'Họ tên',
      value: value?.customer_name,
    },
    {
      key: 'email',
      title: 'Email',
      value: value?.customer_email,
    },
    {
      key: 'phone',
      title: 'Số điện thoại',
      value: value?.customer_phone,
    },
    {
      key: 'temp',
      title: '',
      value: <div className="h-[20px]" />,
    },
  ];

  const address = [
    {
      key: 'tax_number',
      title: 'Mã số thuế',
      value: value?.number_tax,
    },
    {
      key: 'company_name',
      title: 'Tên công ty',
      value: value?.company_name,
    },
    {
      key: 'address',
      title: 'Địa chỉ đầy đủ',
      value: value?.address,
    },
    {
      key: 'email_receive',
      title: 'Email nhận hoá đơn',
      value: value?.email_receive,
    },
  ];

  return (
    <Spin spinning={loading}>
      <FormTera form={form}>
        <div className=" flex flex-col gap-[15px]">
          <Row className="grid grid-cols-3 gap-[30px]">
            <Col className="col-span-2">
              <div className="p-[32px] shadow-md bg-gray-50 flex flex-col gap-[16px]">
                <div className="flex flex-col gap-[32px]">
                  <div className="font-bold text-[22px] leading-[30px]">
                    Hoá đơn
                  </div>
                  <div className="grid grid-cols-2 gap-1">
                    {renderDescription(invoice)}
                  </div>
                  <div className="grid grid-cols-2 text-[13px] leading-[17px]">
                    <div className="grid col-span-1 gap-1">
                      <div className="col-span-1 text-[#3E63DD]">
                        Thông tin khách hàng
                      </div>
                      {renderDescription(information)}
                    </div>
                    {value?.export_bill === 1 && (
                      <div className="grid col-span-1 gap-1">
                        <div className="col-span-1 text-[#3E63DD]">
                          Thông tin nhận hóa đơn
                        </div>
                        {renderDescription(address)}
                      </div>
                    )}
                  </div>
                </div>
                {value?.transaction_type === '3' && (
                  <CartTable
                    value={value?.items}
                    isView
                    tableProps={{
                      scroll: { y: 700 },
                      disableShadow: true,
                      hiddenColumns:
                        value?.type === 'upgrade' ? [] : ['old_package'],
                    }}
                  />
                )}
              </div>
            </Col>
            <Col className="col-span-1 flex flex-col gap-[30px]">
              <PaymentInformation
                value={_.pick(value, [
                  'total_amount',
                  'total_amount_product',
                  'discount',
                  'vat_tax',
                  'start_up_fee',
                ])}
              />
              {value?.status !== 'unpaid' && (
                <PaymentMethod
                  form={form}
                  isView={value?.status !== 'fail'}
                  disabledAllowPay
                  value={value}
                />
              )}
            </Col>
          </Row>
        </div>
      </FormTera>
    </Spin>
  );
};

export default InvoiceDetailContent;
