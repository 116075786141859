import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  messageValidate,
  messageValidateLength,
  messageWarning,
} from '_common/constants/message';

import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { REGEX } from '_common/constants/common';
import Input from '_common/dof/Control/Input';
import Toggle from '_common/dof/Control/Switch';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectEpic from '_common/dof/Select/SelectEpic';
import SelectIcon from '_common/dof/Select/SelectIcon';
import SelectModule from '_common/dof/Select/SelectModule';
import SelectPageConfig from '_common/dof/Select/SelectPageConfig';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, OptionProps, Spin, notification } from 'tera-dls';
import { defaultStandingFirstOption } from '../../../constants';
import ManagePageApi from '../../_api';
import SelectBusinessId from '_common/dof/Select/SelectBusinessId';

interface IProps {
  open: boolean;
  id?: number;
  onClose: () => void;
  onChangeDataSuccess?: () => void;
}

interface IForm {
  code: string;
  name: string;
  title: string;
  module_id: number;
  epic_id: number;
  path: string;
  icon: string;
  stand_behind: number;
  business_id: number;
  status: boolean;
}

const ManagePageForm = (props: IProps) => {
  const { open = false, id, onClose, onChangeDataSuccess } = props;
  const [standPage, setStandPage] = useState<OptionProps>();

  const form = useForm<IForm>({
    defaultValues: {
      business_id: null,
      code: '',
      epic_id: null,
      icon: null,
      module_id: null,
      name: '',
      path: '',
      stand_behind: null,
      status: true,
      title: '',
    },
  });

  const {
    reset,
    setValue,
    watch,
    handleSubmit,
    clearErrors,
    formState: { isDirty },
  } = form;
  const [module_id, epic_id] = watch(['module_id', 'epic_id']);
  const confirm = useConfirm();

  const queryClient = useQueryClient();

  const handleClose = (): void => {
    reset();
    onClose();
  };

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        className: 'modal-button-center',
        onOk: () => {
          handleClose();
        },
      });
    } else {
      handleClose();
    }
  };

  const updateData = (data) => {
    const values = _.pick(data, [
      'code',
      'name',
      'title',
      'path',
      'icon',
      'module_id',
      'epic_id',
      'business_id',
      'status',
    ]);
    reset({
      ...values,
      status: values?.status === 'active' ? true : false,
    });

    if (data?.standing_behind === 0) {
      setStandPage(defaultStandingFirstOption);
      setValue('stand_behind', 0);
    } else {
      setValue('stand_behind', data?.standing_behind?.id);
      setStandPage({
        value: data?.standing_behind?.id,
        label: data?.standing_behind?.name,
      });
    }
  };

  const { data: dataDetail, isFetching } = useQuery(
    ['get-page-detail', id],
    () => ManagePageApi.getDetail(id),
    {
      enabled: !!open && !!id,
      onSuccess: (data) => {
        updateData(data);
      },
    },
  );

  const { mutate: mutateForm, isLoading: isLoadingSubmit } = useMutation(
    (variables: any) => {
      return id
        ? ManagePageApi.update(id, variables)
        : ManagePageApi.create(variables);
    },
    {
      onSuccess: ({ code, msg }) => {
        if (code === 200) {
          onChangeDataSuccess && onChangeDataSuccess();
          queryClient.removeQueries({
            queryKey: ['get-page-by-menu-id'],
          });
          onClose();
          notification.success({
            message: msg,
          });
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const trimData = (code: string): string => {
    return code.split(' ').join('');
  };

  const handleSubmitForm = (values: { [key: string]: any }): void => {
    const code = trimData(values.code);
    mutateForm({
      ...values,
      code,
      status: values?.status ? 'active' : 'inactive',
    });
  };

  return (
    <Modal
      centered={true}
      title={id ? ' SỬA TRANG' : 'THÊM TRANG'}
      open={open}
      width="25%"
      closeIcon={false}
      okText="Lưu"
      cancelText="Huỷ"
      onOk={() => handleSubmit(handleSubmitForm)()}
      onCancel={handleCloseConfirm}
      confirmLoading={isFetching || isLoadingSubmit}
    >
      <Spin spinning={isFetching || isLoadingSubmit}>
        <FormTera form={form}>
          <FormTeraItem
            label="Mã trang"
            name="code"
            rules={[
              {
                required: messageValidate.emptyText,
                maxLength: {
                  value: 191,
                  message: messageValidateLength.key,
                },
                pattern: {
                  value: REGEX.CODE,
                  message: messageValidate.code,
                },
              },
            ]}
            help={
              <p className="text-gray-400 text-xss italic mt-1">
                Nhập thông tin <span className="text-gray-800">[page]</span>, hệ
                thống sẽ tự gán theo Format:
                <span className="text-gray-800">[module]_[page]</span>{' '}
              </p>
            }
          >
            <Input />
          </FormTeraItem>
          <FormTeraItem
            label="Tên trang"
            name="name"
            rules={[
              {
                required: messageValidate.emptyText,
                maxLength: {
                  value: 255,
                  message: messageValidateLength?.textLong,
                },
              },
            ]}
          >
            <Input />
          </FormTeraItem>
          <FormTeraItem
            label="Tiêu đề"
            name="title"
            rules={[
              {
                required: messageValidate.emptyText,
                maxLength: {
                  value: 255,
                  message: messageValidateLength?.textLong,
                },
              },
            ]}
          >
            <Input />
          </FormTeraItem>

          <FormTeraItem
            label="Module"
            name="module_id"
            rules={[
              {
                required: messageValidate.emptySelect,
              },
            ]}
          >
            <SelectModule
              paramsApi={{ include_id: dataDetail?.module_id }}
              onClear={() => setValue('module_id', null)}
              onChangeCustom={() => {
                clearErrors('epic_id');
                setValue('epic_id', null);
              }}
            />
          </FormTeraItem>

          <FormTeraItem
            label="Epic"
            name="epic_id"
            rules={[
              {
                required: messageValidate.emptySelect,
              },
            ]}
          >
            <SelectEpic
              paramsApi={{
                include_id: dataDetail?.epic_id,
                module_id: module_id,
              }}
              disabled={!module_id}
            />
          </FormTeraItem>

          <FormTeraItem
            label="Đường dẫn"
            name="path"
            rules={[
              {
                maxLength: {
                  value: 255,
                  message: messageValidateLength?.textLong,
                },
              },
            ]}
          >
            <Input />
          </FormTeraItem>
          <FormTeraItem label="Icon" name="icon">
            <SelectIcon />
          </FormTeraItem>
          <FormTeraItem label="Thứ tự đứng sau" name="stand_behind">
            <SelectPageConfig
              disabled={!epic_id}
              parentMenuId={epic_id}
              defaultOptions={[defaultStandingFirstOption]}
              selectedValue={standPage}
              onSelect={(val: OptionProps) => {
                setStandPage(val);
              }}
              paramsApi={{
                page_id: id,
                limit: 50,
              }}
              onClear={() => setStandPage({})}
            />
          </FormTeraItem>
          <FormTeraItem label="Business_id" name="business_id">
            <SelectBusinessId
              paramsApi={{ include_id: dataDetail?.business_id }}
            />
          </FormTeraItem>
          <FormTeraItem label="Trạng thái" name="status">
            <Toggle />
          </FormTeraItem>
        </FormTera>
      </Spin>
    </Modal>
  );
};

export default ManagePageForm;
