import { TRANSACTION_TYPE } from 'pages/Invoice/constants';
import { ReactNode } from 'react';
import { formatCurrency, Spin } from 'tera-dls';

type type = keyof typeof TRANSACTION_TYPE;

interface IProps {
  bottomRender?: ReactNode;
  value?: any;
  loading?: boolean;
  type?: type;
}
const index = ({ bottomRender, value, loading = false, type = 3 }: IProps) => {
  return (
    <Spin spinning={loading}>
      <div className="p-[16px] flex flex-col gap-[32px] shadow-md bg-gray-50 rounded-[6px]">
        <div className="font-semibold text-base">Thanh toán</div>
        {type === 3 ? (
          <div className="flex flex-col gap-[34px]">
            <div className="flex justify-between items-center">
              <span className="text-xs">Tổng giá trị sản phẩm</span>
              <span className="text-yellow-500 text-base font-medium">
                {formatCurrency(value?.total_amount_product ?? 0)}
              </span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-xs">Phí khởi tạo</span>
              <span className="text-base font-medium">
                {formatCurrency(value?.start_up_fee ?? 0)}
              </span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-xs">Giảm giá</span>
              <span className="text-base font-medium">
                {value?.discount ?? 0} %
              </span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-xs">VAT (8%)</span>
              <span className="text-base font-medium">
                {formatCurrency(value?.vat_tax ?? 0)}
              </span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-xs">Tổng thanh toán</span>
              <span className="text-base font-medium text-green-700 text-[20px] leading-[23px]">
                {formatCurrency(value?.total_amount ?? 0)}
              </span>
            </div>
          </div>
        ) : (
          <div className="flex justify-between items-center">
            <span className="text-xs">
              {type === 1 ? 'Tổng nạp' : 'Tổng rút'}
            </span>
            <span className="text-base font-medium text-green-700 text-[20px] leading-[23px]">
              {formatCurrency(value?.total_amount ?? 0)}
            </span>
          </div>
        )}
        {bottomRender}
      </div>
    </Spin>
  );
};

export default index;
