import useConfirm from '_common/hooks/useConfirm';

import { useMutation, useQueries, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { REGEX } from '_common/constants/common';
import {
  messageValidate,
  messageValidateLength,
} from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import InputNumber from '_common/dof/Control/InputNumber';
import Select from '_common/dof/Control/Select';
import Toggle from '_common/dof/Control/Switch';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { convertListOrder } from '_common/utils';
import { isArray } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Col, Modal, OptionProps, Row, Spin, notification } from 'tera-dls';
import FormConfigApi from '../../_api';
import { listLayout } from '../../constants';

interface IDetailEmployeeGroup {
  id?: number;
  isOpen?: boolean;
  handleClose?: () => void;
}
interface IForm {
  code: string;
  priority: string | number;
  title: string;
  layout: string;
  group: string;
  numOfColumn: number;
  className: string;
  status: boolean;
}

const CreateConfigForm = ({
  id,
  isOpen,
  handleClose,
}: IDetailEmployeeGroup) => {
  const defaultValues = {
    code: '',
    priority: '',
    title: '',
    layout: '',
    group: '',
    numOfColumn: null,
    className: '',
    status: true,
  };
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const { pageId } = useParams();

  const form = useForm<IForm>({
    defaultValues,
  });
  const {
    reset,
    handleSubmit,
    setValue,
    formState: { isDirty },
    getValues,
    watch,
  } = form;
  const watchLayout = watch('layout');

  const [
    { data: detailFormGroup, isLoading: loadingDetail },
    { data: listFormGroup },
    { data: listFormOrder, refetch: getListFormOrder },
  ] = useQueries({
    queries: [
      {
        queryKey: ['detail_form_group'],
        queryFn: () => {
          return FormConfigApi.getDetail(id);
        },
        enabled: !!id,
      },
      {
        queryKey: ['list_form_group'],
        queryFn: () => {
          return FormConfigApi.getFormGroup({});
        },
      },
      {
        queryKey: ['list_form_order'],
        queryFn: () => {
          const groupID = getValues('group');
          return FormConfigApi.getFormOrder({
            page_id: id ? detailFormGroup?.page_id : Number(pageId),
            group_id: Number(groupID),
          });
        },
        enabled: false,
      },
    ],
  });
  const optionsFormGroup: OptionProps[] = useMemo(() => {
    return isArray(listFormGroup?.data)
      ? listFormGroup.data.map((formGroup) => ({
          value: formGroup?.id,
          label: formGroup?.title,
        }))
      : [];
  }, [listFormGroup]);

  const optionsFormOrder: OptionProps[] = useMemo(() => {
    return convertListOrder(listFormOrder, id, detailFormGroup);
  }, [listFormOrder, detailFormGroup]);

  const handleResetForm = () => {
    reset();
    queryClient.setQueryData(['list_form_order'], null);
  };

  const handleCancel = () => {
    handleClose();
    handleResetForm();
  };

  const { mutate: handleMutation, isLoading } = useMutation(
    (variables: any) => {
      if (id) {
        return FormConfigApi.update(id, variables);
      }

      return FormConfigApi.create(variables);
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          handleCancel();
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['list-form-config']);
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const checkLayout = (layout: string, column: number) => {
    if (layout === 'grid_view') {
      return column ? Number(column) : null;
    }
    return null;
  };

  const handleSubmitForm = (valueForm: IForm) => {
    console.log('valueForm', valueForm);
    const convertData = {
      page_id: id ? detailFormGroup?.page_id : Number(pageId),
      code: valueForm?.code,
      title: valueForm?.title,
      group_id: Number(valueForm?.group),
      class_name: valueForm?.className,
      order: valueForm?.priority ? Number(valueForm?.priority) : null,
      status: valueForm?.status ? 'active' : 'inactive',
      layout: valueForm?.layout, //table, grid_view
      column: checkLayout(valueForm?.layout, valueForm?.numOfColumn),
    };
    console.log('convertData', convertData);
    handleMutation(convertData);
  };

  const onExitModal = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        onOk: () => {
          handleCancel();
        },
        content: (
          <>
            <p>Bạn có chắc muốn thoát bản ghi này không?</p>
            <p>Sau khi thoát dữ liệu sẽ không được lưu.</p>
          </>
        ),
      });
      return;
    }
    handleCancel();
  };

  useEffect(() => {
    if (!!id && detailFormGroup) {
      const values: IForm = {
        code: detailFormGroup?.code_guard,
        priority: detailFormGroup?.item_prev_order?.order || 0,
        title: detailFormGroup?.title,
        layout: detailFormGroup?.layout || '',
        group: detailFormGroup?.group_form_control?.id,
        numOfColumn: detailFormGroup?.column || '',
        className: detailFormGroup?.class_name || '',
        status: detailFormGroup?.status === 'active' ? true : false,
      };
      Object.entries(values).forEach(([fieldName, fieldValue]: [any, any]) => {
        setValue(fieldName, fieldValue);
      });
      getListFormOrder();
    }
  }, [detailFormGroup, id]);

  return (
    <>
      <Modal
        centered={true}
        title={`${
          id ? 'Sửa cấu hình form dữ liệu' : 'Thêm cấu hình form dữ liệu'
        }`}
        open={isOpen}
        onCancel={onExitModal}
        onOk={handleSubmit(handleSubmitForm)}
        closeIcon={false}
        okText="Lưu"
        cancelText="Huỷ"
        className="sm:w-[65%] md:w-[65%] lg:w-[50%]"
        confirmLoading={isLoading}
        destroyOnClose
      >
        <Spin spinning={!!id && loadingDetail}>
          <FormTera className="overflow-auto h-full" form={form}>
            <Row className="grid-cols-2 gap-x-10">
              <Col>
                <Col>
                  <FormTeraItem
                    name="code"
                    label="Mã form dữ liệu"
                    help={
                      <span className="text-gray-400 lg:text-xs md:text-[10px] sm:text-[10px]">
                        Nhập thông tin{' '}
                        <span className="text-gray-800">[form]</span>, hệ thống
                        sẽ tự gán theo Format:{' '}
                        <span className="text-gray-800">
                          [module]_[page]_[form]
                        </span>
                      </span>
                    }
                    rules={[
                      {
                        required: messageValidate.emptyText,
                        maxLength: {
                          message: messageValidateLength.key,
                          value: 191,
                        },
                        pattern: {
                          message: messageValidate.code,
                          value: REGEX.CODE,
                        },
                      },
                    ]}
                  >
                    <Input className="mb-2" />
                  </FormTeraItem>
                </Col>
                <Col>
                  <FormTeraItem
                    name="title"
                    label="Tiêu đề"
                    rules={[
                      {
                        required: messageValidate.emptyText,
                        maxLength: {
                          message: messageValidateLength.textLong,
                          value: 255,
                        },
                      },
                    ]}
                  >
                    <Input />
                  </FormTeraItem>
                </Col>
                <Col>
                  <FormTeraItem
                    name="group"
                    label="Nhóm form dữ liệu"
                    rules={[
                      {
                        required: messageValidate.emptySelect,
                        maxLength: {
                          message: messageValidateLength.textLong,
                          value: 255,
                        },
                      },
                    ]}
                  >
                    <Select
                      options={optionsFormGroup}
                      onChangeCustom={() => {
                        getListFormOrder();
                      }}
                    />
                  </FormTeraItem>
                </Col>
                <Col>
                  <FormTeraItem
                    name="className"
                    label="ClassName"
                    rules={[
                      {
                        maxLength: {
                          message: messageValidateLength.textLong,
                          value: 255,
                        },
                      },
                    ]}
                  >
                    <Input />
                  </FormTeraItem>
                </Col>
              </Col>
              <Col>
                <Col>
                  <FormTeraItem
                    name="priority"
                    label="Thứ tự đứng sau"
                    rules={[
                      {
                        maxLength: {
                          message: messageValidateLength.textLong,
                          value: 255,
                        },
                      },
                    ]}
                  >
                    <Select options={optionsFormOrder} />
                  </FormTeraItem>
                </Col>
                <Col>
                  <FormTeraItem
                    name="layout"
                    label="Layout"
                    rules={[
                      {
                        maxLength: {
                          message: messageValidateLength.textLong,
                          value: 255,
                        },
                      },
                    ]}
                  >
                    <Select options={listLayout} />
                  </FormTeraItem>
                </Col>
                <Col>
                  <FormTeraItem
                    name="numOfColumn"
                    label="Số lượng cột"
                    rules={[
                      {
                        maxLength: {
                          message: messageValidateLength.textLong,
                          value: 255,
                        },
                      },
                    ]}
                  >
                    <InputNumber
                      min={0}
                      disabled={watchLayout !== 'grid_view'}
                    />
                  </FormTeraItem>
                </Col>
                <Col>
                  <FormTeraItem name="status" label="Trạng thái">
                    <Toggle />
                  </FormTeraItem>
                </Col>
              </Col>
            </Row>
          </FormTera>
        </Spin>
      </Modal>
    </>
  );
};

export default CreateConfigForm;
