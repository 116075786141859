import { FILE_MANAGEMENT_URL } from '_common/constants/url';
import { IRouteProps } from '_common/interface/router';
import FileManagement from '.';

export const FileManagementRouter: IRouteProps[] = [
  {
    key: FILE_MANAGEMENT_URL.list.key,
    path: FILE_MANAGEMENT_URL.list.shortenUrl,
    component: <FileManagement />,
  },
  {
    key: FILE_MANAGEMENT_URL.detail.key,
    path: FILE_MANAGEMENT_URL.detail.shortenUrl,
    component: <FileManagement />,
  },
];
