import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { ReactComponent as IconBank } from 'styles/images/Icons/bank.svg';
import { ReactComponent as IconEWallet } from 'styles/images/Icons/e-wallet.svg';
import { Button, Modal, notification } from 'tera-dls';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import ModalConfirmPayment from '../../components/ModalConfirmPayment';
import ConfigPaymentMethodApi from '../../_api';
import ConfirmInfo from './components/ConfirmInfo';
import InputAmount from './components/InputAmount';
import VerificationCode from './components/VerificationCode';

const Withdraw = ({ open, onClose, record }) => {
  const queryClient = useQueryClient();
  const [dataTransaction, setDataTransaction] = useState({});
  const [amount, setAmount] = useState(0);
  const [code, setCode] = useState('');
  const [step, setStep] = useState(1);

  const { mutate: mutateTransaction, isLoading: loadingSubmit } = useMutation(
    (variables: any) => {
      return ConfigPaymentMethodApi.transaction(variables);
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          const { data } = res;
          setDataTransaction({
            status: data?.status,
            transaction_date: data?.transaction_date,
            transaction_code: data?.transaction_code,
            transaction_type: data?.transaction_type,
            amount: data?.amount,
            methods: data?.card_type?.object_type,
            card: {
              account_number: record?.account_number,
              account_phone: record?.account_phone,
              cardholder: record?.cardholder,
              card_type: record?.card_type,
            },
          });
          queryClient.invalidateQueries(['get-payment-method-list']);
          queryClient.invalidateQueries(['get-payment-method-detail']);
          queryClient.invalidateQueries(['get-transaction-detail']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) => ErrorToast({ errorProp: error?.data }),
    },
  );

  const renderContent = () => {
    if (step === 1 || step === 2) {
      const renderComponent = {
        1: (
          <InputAmount
            record={record}
            onChangeAmount={(value) => setAmount(value)}
          />
        ),
        2: <VerificationCode onChangeCode={(value) => setCode(value)} />,
      };

      return (
        <div className="space-y-4">
          <p className="text-gray-500">Đến</p>
          <div className="p-4 border border-gray-200 rounded">
            <div className="flex items-center gap-4">
              <div className="bg-stone-50 rounded-full flex items-center justify-center">
                {record?.card_type?.object_type === 1 ? (
                  <i className="w-10 h-10 stroke-blue-600">
                    <IconBank />
                  </i>
                ) : (
                  <i className="w-10 h-10 fill-yellow-800">
                    <IconEWallet />
                  </i>
                )}
              </div>
              <div className="space-y-1">
                <h3 className="uppercase font-semibold text-xl">
                  {record?.cardholder}
                </h3>
                <p>
                  <span className="text-gray-500">
                    {record?.account_number || record?.account_phone} -{' '}
                  </span>
                  <span>{record?.card_type?.card_name}</span>
                </p>
              </div>
            </div>
          </div>
          {renderComponent[step]}
        </div>
      );
    } else if (step === 3) {
      return <ConfirmInfo record={record} amount={amount} />;
    }
  };

  const disabledBtnNextStep = () => {
    if (step === 1 && !amount) {
      return true;
    }
    if (step === 2 && code.length < 6) {
      return true;
    }
    return false;
  };

  const onClickConfirm = () => {
    setStep(step + 1);
    if (step === 3) {
      mutateTransaction({
        card_id: record?.id,
        amount: amount,
        transaction_type: 2,
        methods: record?.card_type?.object_type,
      });
    }
  };
  return (
    <>
      {step === 4 ? (
        <ModalConfirmPayment
          data={dataTransaction}
          isLoading={loadingSubmit}
          open={step === 4}
          onClose={() => {
            onClose();
          }}
        />
      ) : (
        <Modal
          width={step === 3 ? '25%' : '40%'}
          open={open}
          bodyClassName="min-h-[500px]"
          title="Rút tiền"
          footerClassName="flex-col !border-none"
          footer={
            <>
              {step === 2 && (
                <p className="font-medium">
                  Chưa nhận được mã?{' '}
                  <span className="text-blue-600">Gửi lại</span>
                </p>
              )}
              <div className="flex justify-evenly items-center w-full gap-4">
                {step > 1 && (
                  <Button
                    outlined
                    className="grow"
                    onClick={() => setStep(step - 1)}
                  >
                    Quay lại
                  </Button>
                )}
                <Button
                  outlined
                  onClick={() => {
                    onClose();
                  }}
                  className="grow"
                >
                  Hủy
                </Button>
                <Button
                  className="grow"
                  onClick={onClickConfirm}
                  disabled={disabledBtnNextStep()}
                >
                  Xác nhận
                </Button>
              </div>
            </>
          }
        >
          {renderContent()}
        </Modal>
      )}
    </>
  );
};

export default Withdraw;
