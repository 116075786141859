import { Description, formatCurrency } from 'tera-dls';
import { methodsText } from '../../../constants';

const ConfirmInfo = ({ record, amount }) => {
  const general = [
    {
      label: 'Loại giao dịch',
      value: 'Rút tiền',
    },
    {
      label: 'Phương thức thanh toán',
      value: methodsText[record?.card_type?.object_type],
    },
    {
      label: 'Số tiền',
      value: formatCurrency(amount),
    },
  ];

  const content = [
    {
      label: 'Số tài khoản',
      value: record?.account_number || record?.account_phone,
    },
    {
      label: 'Người thụ hưởng',
      value: record?.cardholder,
    },
    {
      label: 'Ngân hàng',
      value: record?.card_type?.card_name,
    },
    {
      label: 'Số tiền',
      value: (
        <span className="text-blue-500 font-bold">
          {formatCurrency(amount)}
        </span>
      ),
    },
    {
      label: 'Nội dung',
      value: record?.card_type?.description,
    },
  ];

  return (
    <>
      {general.map((item) => (
        <Description
          label={`${item.label}:`}
          childrenClassName="text-right"
          className="mb-4"
        >
          {item.value}
        </Description>
      ))}
      <p className="text-blue-700 mb-4 text-center">
        Vui lòng xác nhận thông tin tài khoản nhận tiền
      </p>
      <div className="space-y-2.5 border border-gray-400 rounded-[10px] p-4">
        {content.map((item) => (
          <Description
            label={item.label}
            labelClassName="text-right"
            className="mb-0"
          >
            {item.value}
          </Description>
        ))}
      </div>
    </>
  );
};

export default ConfirmInfo;
