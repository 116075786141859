import { useQuery } from '@tanstack/react-query';
import ConfigPaymentMethodApi from 'pages/System/containers/ConfigPaymentMethod/_api';
import TableTransactionHistory from 'pages/System/containers/ConfigPaymentMethod/containers/TransactionHistory/containers/Table';
import { useState } from 'react';
import { Button, FunnelOutlined, PaginationProps } from 'tera-dls';
import Header from './Header';
import { HistoryFilter } from './HistoryFilter';

const TransactionHistory = ({ id, onUpdateParams }) => {
  const [params, setParams] = useState<any>({});
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const { data: response, isLoading } = useQuery(
    ['get-transaction-all', params],
    () =>
      ConfigPaymentMethodApi.getTransactionAll({
        page: 1,
        limit: 10,
        get_created_by: 2,
        user_id: id,
        ...params,
      }),
    {},
  );

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    setParams((prev) => ({ ...prev, page: page, limit: pageSize }));
  };

  const handleFilter = (values) => {
    setParams((prev) => ({ ...prev, ...values, page: 1 }));
    onUpdateParams((prev) => ({
      ...prev,
      transaction_methods: values?.methods,
      transaction_status: values?.status,
      transaction_type: values?.transaction_type,
    }));
  };

  const handleSearch = ({ keyword }) => {
    setParams((prev) => ({ ...prev, keyword, page: 1 }));
    onUpdateParams((prev) => ({
      ...prev,
      transaction_keyword: keyword,
    }));
  };

  return (
    <div
      className="p-[9px] rounded-[5px] flex flex-col gap-2.5"
      style={{
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
      }}
    >
      <div className="flex items-center justify-end gap-x-2">
        <Header onSearch={handleSearch} initialValue={params} />
        <Button
          type="alternative"
          className="rounded-xsm py-1 px-1 bg-blue-50 p-[6px]"
          icon={<FunnelOutlined className=" text-blue-500 rounded-[4px] " />}
          onClick={() => setOpenFilter(true)}
        />
      </div>
      <TableTransactionHistory
        hiddenColumns={['username', 'full_name', 'action']}
        loading={isLoading}
        data={response?.data}
        pagination={{
          onChange: handleChangePage,
          total: response?.total || 0,
          current: response?.current_page || 1,
          pageSize: response?.per_page,
          to: response?.to,
          from: response?.from,
        }}
      />
      {openFilter && (
        <HistoryFilter
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          initialValue={params}
          onFilter={handleFilter}
        />
      )}
    </div>
  );
};

export default TransactionHistory;
