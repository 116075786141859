export const PERMISSION_KEY = {
  CASH_RECEIPT_VIEW_CASH_RECEIPT_LIST: 'fin_cashbook_view_cash_receipt_list',
  CASH_RECEIPT_VIEW_CASH_RECEIPT_DETAIL:
    'fin_cashbook_view_cash_receipt_detail',
  CASH_RECEIPT_CREATE_CASH_RECEIPT: 'fin_cashbook_create_cash_receipt',
  CASH_RECEIPT_UPDATE_CASH_RECEIPT: 'fin_cashbook_update_cash_receipt',
  CASH_RECEIPT_DELETE_CASH_RECEIPT: 'fin_cashbook_delete_cash_receipt',
  CASH_RECEIPT_SEARCH_CASH_RECEIPT: 'fin_cashbook_search_cash_receipt',
  CASH_RECEIPT_IMPORT_CASH_RECEIPT: 'fin_cashbook_import_cash_receipt',
  CASH_RECEIPT_EXPORT_CASH_RECEIPT: 'fin_cashbook_export_cash_receipt',
  CASH_RECEIPT_CONFIG_COLUMN_CASH_RECEIPT:
    'fin_cashbook_config_column_cash_receipt',
};
