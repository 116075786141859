import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import ModalImport from '_common/component/ModalImport';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { messageError } from '_common/constants/message';
import TableTera from '_common/dof/TableTera';
import useConfirm from '_common/hooks/useConfirm';
import moment from 'moment';
import { Key, ReactNode, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import {
  DropdownItem,
  PaginationProps,
  downloadFile,
  getQueryParams,
  notification,
  updateQueryParams,
} from 'tera-dls';
import DataTypeApi from './_api';
import ObjectTypeDetail from './containers/Detail';
import ObjectTypeForm from './containers/Form';
import Header from './containers/Header';
import ObjectTypeList from './containers/ObjectTypeList';
import DataTypeFilter from './containers/Filter';
import PaginationCustom from '_common/component/PaginationCustom';
import CountSelection from '_common/component/CountSelection';

const ConfigDataType = () => {
  const confirm = useConfirm();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const queryClient = useQueryClient();
  const [isOpenForm, setIsOpenForm] = useState<boolean>(false);
  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [isOpenDetail, setIsOpenDetail] = useState<boolean>(false);
  const [isOpenImport, setIsOpenImport] = useState<boolean>(false);
  const [isOpenObjectType, setIsOpenObjectType] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [id, setId] = useState<string>(null);

  const handleUpdateFilter = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(paramString);
  };
  const {
    data: listDataType,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ['get-list-data-type', queryParams],
    () => {
      const data = {
        page: 1,
        limit: 10,
        ...queryParams,
      };
      return DataTypeApi.getList(data);
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const { mutate: deleteData, isLoading: loadingDelete } = useMutation(
    (id: number) => DataTypeApi.delete(id),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          if (listDataType?.data?.length === 1) {
            queryClient.removeQueries(['get-list-data-type']);
            handleUpdateFilter({
              page: queryParams?.page !== 1 && queryParams?.page - 1,
              limit: queryParams?.limit,
            });
            return;
          }
          refetch();
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const { mutate: exportExcel } = useMutation(
    (params: any) => {
      const data = {
        keyword: queryParams?.keyword,
        object_type: queryParams?.object_type,
        selected: params,
      };
      return DataTypeApi.export(data);
    },
    {
      onSuccess(res) {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          const date = moment().format('DDMMYYYY_HHmmss');
          downloadFile(res?.data?.src, `export_data-type_${date}`);
          setSelectedRowKeys([]);
        }
      },
      onError(error: any) {
        const errorMessage = error?.data?.errors || [messageError.ERROR_API];
        const flattenMessage = Object.values(errorMessage).flat();
        notification.error({
          message: (
            <ul>
              {flattenMessage?.map((message: ReactNode) => (
                <li>{message}</li>
              ))}
            </ul>
          ),
        });
      },
    },
  );

  const { mutate: importExcel } = useMutation(
    (params: any) => {
      return DataTypeApi.import(params);
    },
    {
      onSuccess(res) {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          refetch();
          setIsOpenImport(false);
        }
      },
      onError(error: any) {
        const errorMessage = error?.data?.errors || [messageError.ERROR_API];
        const flattenMessage = Object.values(errorMessage).flat();
        notification.error({
          message: (
            <ul>
              {flattenMessage?.map((message: ReactNode) => (
                <li>{message}</li>
              ))}
            </ul>
          ),
        });
      },
    },
  );

  const handleImport = (data) => {
    importExcel(data);
  };

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize =
      queryParams?.limit && pageSize !== Number(queryParams?.limit);
    handleUpdateFilter({
      page: isDiffPageSize ? 1 : page,
      limit: pageSize,
    });
  };

  const handleSearch = (values) => {
    handleUpdateFilter({ ...values, page: 1 });
  };

  const handleFilter = (values: any) => {
    handleUpdateFilter({
      page: 1,
      ...values,
    });
  };

  const handleDelete = (item) => {
    confirm.warning({
      title: 'XÓA LOẠI DỮ LIỆU',
      content: (
        <div className="break-all">
          <p>Bạn có chắc muốn xóa loại dữ liệu</p>
          <p>
            <b>{item?.title}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        deleteData(item?.id);
      },
    });
  };

  const itemsAction = (item): DropdownItem[] => {
    return [
      {
        key: 1,
        label: 'Xem',
        onClick: () => {
          setIsOpenDetail(true);
          setId(item?.id);
        },
      },
      {
        key: 2,
        label: 'Sửa',
        onClick: () => {
          setId(item?.id);
          setIsOpenForm(true);
        },
      },
      {
        key: 3,
        label: <span className="text-red-500">Xóa</span>,
        onClick: () => handleDelete(item),
      },
    ];
  };

  const itemsHeader: DropdownItem[] = [
    {
      key: 1,
      label: 'Export file excel',
      onClick: () => exportExcel(selectedRowKeys),
    },
    {
      key: 2,
      label: 'Import file excel',
      onClick: () => setIsOpenImport(true),
    },
    {
      key: 3,
      label: 'Danh sách loại dữ liệu',
      onClick: () => setIsOpenObjectType(true),
    },
  ];

  const columns: any = [
    {
      title: 'Mã dữ liệu',
      dataIndex: 'concatenated_key',
    },
    {
      title: 'Loại dữ liệu',
      dataIndex: 'object_type',
      render: (data) => data?.object_type,
    },
    {
      title: 'Tiêu đề',
      dataIndex: 'title',
      render: (title) => <p className="line-clamp-2">{title}</p>,
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      render: (description) => <p className="line-clamp-2">{description}</p>,
    },
    {
      title: 'Màu sắc',
      dataIndex: 'color',
    },
    {
      width: '6%',
      fixed: 'right',
      render: (record) => {
        return (
          <ActionDropdown dropdownItems={itemsAction(record)} trigger="click" />
        );
      },
    },
  ];

  if (isError)
    notification.error({
      message: messageError.DATA_NOT_FOUND,
    });

  return (
    <>
      <HeaderViewList
        title="CẤU HÌNH DỮ LIỆU"
        dropdownConfig={itemsHeader}
        onClickButtonAdd={() => setIsOpenForm(true)}
        onClickFilter={() => setIsFilter(true)}
      >
        <Header
          onSearch={handleSearch}
          initialValue={{
            keyword: queryParams.keyword,
            object_type: queryParams.object_type,
          }}
        />
      </HeaderViewList>
      <CountSelection count={selectedRowKeys?.length} />
      <div className="bg-white shadow-xsm rounded-[5px] overflow-hidden">
        <TableTera
          columns={columns}
          data={listDataType?.data || []}
          rowSelection={{
            onChange: (rowsKey) => setSelectedRowKeys(rowsKey),
            selectedRowKeys: selectedRowKeys,
          }}
          loading={isLoading || loadingDelete}
        />
        {listDataType?.total > 0 && (
          <PaginationCustom
            onChange={handleChangePage}
            total={listDataType?.total || 0}
            current={listDataType?.current_page || 1}
            pageSize={listDataType?.per_page}
            to={listDataType?.to}
            from={listDataType?.from}
          />
        )}
      </div>
      {isOpenForm && (
        <ObjectTypeForm
          id={id}
          open={isOpenForm}
          onClose={() => {
            setIsOpenForm(false);
            setId(null);
          }}
          onRefetch={refetch}
        />
      )}
      {isOpenDetail && (
        <ObjectTypeDetail
          id={id}
          open={isOpenDetail}
          onClose={() => {
            setIsOpenDetail(false);
            setId(null);
          }}
        />
      )}
      {isOpenImport && (
        <ModalImport
          open={isOpenImport}
          onCancel={() => setIsOpenImport(false)}
          title="IMPORT DỮ LIỆU"
          onOk={handleImport}
        />
      )}
      {isOpenObjectType && (
        <ObjectTypeList
          open={isOpenObjectType}
          onClose={() => setIsOpenObjectType(false)}
        />
      )}
      {isFilter && (
        <DataTypeFilter
          open={isFilter}
          onClose={() => setIsFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
    </>
  );
};

export default ConfigDataType;
