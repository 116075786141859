import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const InvoiceEndpoint = `${endpoint}/administrator/bill`;

const InvoiceApi = {
  getList: async ({ params }) =>
    await api
      .get(`${InvoiceEndpoint}/list`, params)
      .then((result) => result.data?.data),
  getReceipt: async ({ params }) =>
    await api
      .get(`${InvoiceEndpoint}/list-receipt`, params)
      .then((result) => result.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${InvoiceEndpoint}/detail/${id}`)
      .then((result) => result.data?.data),
  getSummaryTab: async ({ params }) =>
    await api
      .get(`${InvoiceEndpoint}/summary-tab`, params)
      .then((result) => result.data?.data),
  pay: async ({ params }) =>
    await api
      .post(`${InvoiceEndpoint}/payment-confirmation`, params)
      .then((result) => result.data),
  getTransaction: async ({ params }) =>
    await api
      .get(`${InvoiceEndpoint}/list-transaction`, params)
      .then((result) => result.data?.data),
  delete: async ({ id }) =>
    await api
      .delete(`${InvoiceEndpoint}/delete/${id}`)
      .then((result) => result.data),
  payment: async ({ params }) =>
    await api
      .put(`${InvoiceEndpoint}/payment-confirmation`, params)
      .then((result) => result.data),
};

export default InvoiceApi;
