import Search from '_common/dof/Control/Search';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { useForm } from 'react-hook-form';
import { MagnifyingGlassOutlined } from 'tera-dls';
function ColumnConfigHeader({ onSearch }) {
  const form = useForm();
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = form;

  const handleSearch = (value) => {
    if (isDirty) {
      onSearch(value);
      reset({ ...value }, { keepValues: true });
    }
  };

  return (
    <FormTera
      form={form}
      onSubmit={handleSubmit(handleSearch)}
      className="lg:w-[400px] flex-1"
    >
      <FormTeraItem className="mb-0" name="keyword">
        <Search
          placeholder="Tìm kiếm theo mã cột, tiêu đề"
          icon={<MagnifyingGlassOutlined />}
        />
      </FormTeraItem>
    </FormTera>
  );
}

export default ColumnConfigHeader;
